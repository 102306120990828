// global JQuery
$ = require("jquery");

require('./accordion');
require('./button-next-page.js');
require('./gmaps_partner.js');
require('./gmaps.js');

//require('./cookieMsg.js');
jQuery = $;
var usedbyUI = false;

$(document).ready(function() {
	require('./responsiveMenu.js');

	$('#kundenportal-link > a + div').hide(0);
	//Header
	$('#kundenportal-link > a').on('click', function(e) {
		e.preventDefault();

		var _this = $(this);
		var container = _this.next();

		if(container.hasClass('hidden-form')) {

			container.slideDown(200, function() {
				container.removeClass('hidden-form');
			});

		}
		else {
			container.slideUp(200, function() {
				container.addClass('hidden-form');
			})
		}
	});

	$('#kundenportal-link form, #kundenportal-mobile-link form').on('submit', function(e) {
		//e.preventDefault();

		var _this = $(this);
		var usr = _this.find('input.user');
		_this.find('input[name="login"]').val(usr.val());

		var pw = _this.find('input.pass');
		_this.find('input[name="password"]').val(pw.val());
	});

	var screenWidth = window.innerWidth;
	var screenHeight = window.innerHeight;


	var autoScroll = false;
	var fitToSection = false;
	if(screenHeight > 768) {
		autoScroll = true;
		fitToSection = true;
	}
	if(screenWidth > 736 /*&& screenHeight > 768*/) {
		$('#fullpage').fullpage({
			anchors: ['partner-finden', 'eag', 'umwelt', 'service', 'kontakt', 'partner-karte', 'impressum'],
			menu: '#nav ul, .menuInner .menu',
			navigation: true,
			autoScrolling: autoScroll,
			fitToSection: fitToSection,
			normalScrollElements: '#footer',
			fixedElements: '#footer',
			keyboardScrolling: false,
			onLeave: function(index, nextIndex, direction) {
				var nav = $('#fp-nav');
				if(nextIndex == 2 || nextIndex == 5) {
					nav.addClass('blue-colored');
				}
				else {
					nav.removeClass('blue-colored');
				}

				if(index == 6 && !usedbyUI && isMobile()) {

					return false;
				}
				if(nextIndex < 6) {
					$('#scrollToTop').fadeOut(50);
				}
				/*
				if(nextIndex == 5 && !standort) {
					navigator.geolocation.getCurrentPosition(function(position){
						console.log("akzeptiert")

					}, function () {
						console.log('nope');
					});
				}
				*/
				if($('body').hasClass('slideout-open')) {
					$('body').toggleClass('slideout-open');
				}

				setFlagUsedByUI(false);
				$('#next-page').fadeOut(50);
			},

			afterLoad: function(anchorLink, index) {
				var nextBtn = $('#next-page');
				if(index == 4) {
					nextBtn.addClass('white-colored');
				}
				else {
					nextBtn.removeClass('white-colored');
				}

				if(index < 6)
					nextBtn.fadeIn(250);
				if(index == 6) {
					$('#scrollToTop').fadeIn(50);
				}


			},
			afterResize: function() {
				fixGmap();
			}
		});

		fixGmap();
		//FireFox / IE Bugfix gmap
		function fixGmap() {
			//console.log('gmap Fix');
			var mapTableCell = $('#partner-karte-anchor .fp-tableCell');
			var mapTableCellHeight = mapTableCell.css('height').replace('px','');
			mapTableCell.css('height', mapTableCellHeight - 80 + 'px');
			mapTableCell.css('display','block');
		}


	}
	$('#fp-nav').on('touchstart', function() {
		setFlagUsedByUI(true);
	});

	if(isMobile()) {
		$('#partner-finden-anchor').css('min-height', screenHeight + "px");
	}

	//footer
	$('.imprint-open .btn, .menu li.imprint, .hinweise-btn').on('click', function() {
		var _this = $(this);
		var footer = $('#footer');
		//Responsive
		if($('body').hasClass('slideout-open')) {
			$('body').removeClass('slideout-open');
			//Prüfen ob Footer geschlossen werden muss
			if((footer.hasClass('current-hinweise') && _this.hasClass('hinweise-btn')) ||
			   (footer.hasClass('current-impressum') && _this.hasClass('impressum-btn')) ||
			   (footer.hasClass('current-rechtshinweise') && _this.hasClass('rechtshinweise-btn')) ||
			   (footer.hasClass('current-downloads') && _this.hasClass('downloads-btn'))) {
				footer.removeClass();
				return;
			}
		}
		var class_name = "";
		//Öffne Footer
		if(_this.hasClass('hinweise-btn')) {
			class_name = "current-hinweise";
			footer.find('.footer-content.imprint').hide(50);
			footer.find('.footer-content.downloads').hide(50);
			footer.find('.footer-content.rechtshinweise').hide(50);
			footer.find('.footer-content.hinweise').show(50);
			footer.removeClass('current-impressum current-downloads current-rechtshinweise');
		}
		else if(_this.hasClass('impressum-btn')) {
			class_name = "current-impressum";
			footer.find('.footer-content.hinweise').hide(50);
			footer.find('.footer-content.downloads').hide(50);
			footer.find('.footer-content.rechtshinweise').hide(50);
			footer.find('.footer-content.imprint').show(50);
			footer.removeClass('current-hinweise current-downloads current-rechtshinweise');
		}
		else if(_this.hasClass('rechtshinweise-btn')) {
			class_name = "current-rechtshinweise";
			footer.find('.footer-content.hinweise').hide(50);
			footer.find('.footer-content.imprint').hide(50);
			footer.find('.footer-content.downloads').hide(50);
			footer.find('.footer-content.rechtshinweise').show(50);
			footer.removeClass('current-hinweise current-impressum current-downloads');
		}
		else if(_this.hasClass('downloads-btn')) {
			class_name = "current-downloads";
			footer.find('.footer-content.hinweise').hide(50);
			footer.find('.footer-content.imprint').hide(50);
			footer.find('.footer-content.rechtshinweise').hide(50);
			footer.find('.footer-content.downloads').show(50);
			footer.removeClass('current-hinweise current-impressum current-rechtshinweise');
		}
		footer.addClass(class_name);
		footer.addClass('open');
	});

	$('.btn-close').on('click', function(){
		var footer = $('#footer');
		footer.removeClass();
	});

	//nav
	$('#nav a, .menu li:not(".imprint") a').on('click', function() {
		var footer = $('#footer');
		if(footer.hasClass('open')) {
			footer.removeClass();
		}
		setFlagUsedByUI(true);
	});

	//Partner nicht gefunden-box
	$('#msg-no-partner, #msg-no-partner .close-btn').on('click', function(e) {
		if($('#msg-no-partner').hasClass('open')) {
			$('#msg-no-partner').removeClass('open');
		}
	});

	//ScrollToTop
	$('#scrollToTop').on('click', function() {
		if(screenWidth <= 667) {
			$('html, body').animate({
				scrollTop: 0
			}, 300);
			return;
		}

		setFlagUsedByUI(true);
		$.fn.fullpage.moveTo(1);

	});

	$(window).scroll(function() {
		if(screenWidth <= 667) {
			if($(window).scrollTop() + $(window).height() >= $(document).height() - 150) {
				$('#scrollToTop:hidden').fadeIn(150);
			}
			else {
				$('#scrollToTop:visible').fadeOut(150);
			}
		}

	});



});



function setFlagUsedByUI(byUI) {
	usedbyUI = byUI;
}
window.isMobile = function() {
	if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		return true;
	}
	return false;
}

//Gmaps styling
window.onload = function() {
	var iwOuter = $('.gm-style-iw');
	var iwBackground = iwOuter.prev();
	iwBackground.css('display','none');
	iwBackground.prev().css('display', 'none');

}


function openImpressum(){
	$('footer').addClass('current-impressum open');
	$('.hinweise').hide();
	$('.imprint').show();
}


$(document).ready(function(){
	$('.js--link-impressum').on('click', function(){

		$('footer').removeClass();
		setTimeout(openImpressum, 500);
	});
});


/*
require("./routes");
require("./viewport");
require("../../node_modules/bootstrap/js/modal");
require("./newsletterUI");
require("./shop-view");
require("./youtube");
var shoppingcart = require("./shopping-cart");
window.add_to_cart = shoppingcart.add_to_cart;

var link = document.createElement("link");
link.setAttribute("rel", "stylesheet");
link.setAttribute("type", "text/css");
link.setAttribute("href", "http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200italic,300,600,400,300italic");
document.getElementsByTagName("head")[0].appendChild(link);

// Back to Top
$("a[href='#top']").click(function () {
    $("html, body").animate({scrollTop: 0}, "slow");
    return false;
});
*/