
createAccordion($('.accordion').eq(0),250);
createAccordion($('.accordion').eq(1),250);

function createAccordion(ele, duration) {

	var items = ele.find('.accordion-item');
	var headlines = items.find('> h3');
	headlines.on('click', function() {
		var _this = $(this);
		var _parent = _this.parent();

		//Alle Schließen
		headlines.parent().find('.item-content').slideUp(duration, function() {
			$(this).parent().removeClass('open');
			if(screen.width > 667) {
				$.fn.fullpage.reBuild();
			}

		});

		//Geklickten öffnen
		if(!_parent.hasClass('open')) {
			_parent.find('.item-content').slideDown(duration, function() {
				_parent.addClass('open');
				if(screen.width > 667) {
					$.fn.fullpage.reBuild();
				}
			});
		}
	});
}
