/*var slideout = new Slideout({
	'panel': document.getElementById('fullpage'),
	'menu': document.getElementById('responsiveMenu'),
	'padding': 256,
	'tolerance': 70,
	'side': 'right',
	'touch': false
});*/
if(screen.width <= 667) {
	var menuItems = $('#responsiveMenu').find('a');
	for(var i = 0; i < menuItems.length-2; i++) {
		//menuItems.eq(i).attr('href',menuItems.eq(i).attr('href').replace("-anchor",""));
		menuItems.eq(i).on('click', function(e) {
			e.preventDefault();
			$('#responsiveMenu').find('a').parent().removeClass('active');
			$(this).parent().addClass('active');
			var target = $(this).attr("href");
			var $target = $(target+"-anchor");
			$('html, body').stop().animate({
				'scrollTop': $target.offset().top - 60
			}, 300, 'swing', function () {
				window.location.hash = target;
			});
			$('body').toggleClass('slideout-open');
		});
	}
}
$('#hamburgerMenu').on('click', function(e) {

	$('body').toggleClass('slideout-open');
});