/**
 * Created by FischerCGD on 17.11.15.
 */

scrollToNextPage($('#next-page'));

function scrollToNextPage(elem) {
	elem.on('click', function() {
		$.fn.fullpage.moveSectionDown();
	});
}