
var partner = [
	{
		"id": 0,
		"plz": 32120 ,
		"title": "Hempelmann Wittemöller GmbH",
		"str": "Oetinghauser Str. 16",
		"ort": "Hiddenhausen",
		"tel": "05221 688610",
		"fax": "05221 688624",
		"mail": "kontakt@hempelmann-wittemoeller.de",
		"pos": [52.1615391, 8.6344699],
		"img": "hempelmann.jpg",
		"site": "http://www.hempelmann-wittemoeller.de/erdgas/",
		"marker": null
	},
	{
		"id": 1,
		"plz": 33034 ,
		"title": "Keck Energieservice  GmbH & Co. KG",
		"str": "Driburgerstr. 9",
		"ort": "Brakel",
		"tel": "05272 80820",
		"fax": "05272 9518",
		"mail": "plusgas@keck-energie.de",
		"pos": [51.7121097, 9.176720400000022],
		"img": "keck.jpg",
		"site": "https://www.keck-energie.de/home.html",
		"marker": null
	},
	{
		"id": 2,
		"plz": 45478 ,
		"title": "Uhlenbruck Energie GmbH & Co. KG",
		"str": "Duisburger Str. 351-353",
		"ort": "Mülheim an der Ruhr",
		"tel": "0208 589550",
		"fax": "0208 5895520",
		"mail": "erdgas@uhlenbruck.de",
		"pos": [51.43094780000001, 6.833926199999951],
		"img": "uhlenbruck.jpg",
		"site": "http://uhlenbruck.de/",
		"marker": null
	},
	{
		"id": 3,
		"plz": 58119 ,
		"title": "Lipps Energie GmbH",
		"str": "Gotenweg 18",
		"ort": "Hagen-Hohenlimburg",
		"tel": "02334 95800",
		"fax": "02334 9580200",
		"mail": "erdgas@lipps-energie.de",
		"pos": [51.36389059999999, 7.5583957000000055],
		"img": "lipps.jpg",
		"site": "http://lipps-energie.de/",
		"marker": null
	},
	{
		"id": 4,
		"plz": 21107 ,
		"title": "Klindworth-Kronol Energie GmbH",
		"str": "Kattwykstraße 12",
		"ort": "Hamburg",
		"tel": "040 2378320",
		"fax": "040 23783278",
		"mail": "kontakt@klindworth-kronol.de",
		"pos": [53.500235, 9.950036899999986],
		"img": "klindworth.jpg",
		"site": "http://klindworth-kronol.de/produkte/gas/",
		"marker": null
	},
	{
		"id": 5,
		"plz": 28325 ,
		"title": "Mühlenbruch Stinnes TOTAL GmbH",
		"str": "Osterholzer Möhlendamm 42-44",
		"ort": "Bremen",
		"tel": "0421 420071",
		"fax": "0421 400588",
		"mail": "kontakt@mst-energie.de",
		"pos": [53.0587526, 8.950681400000008],
		"img": "muehlenbruch.jpg",
		"site": "http://www.mst-energie.de",
		"marker": null
	},
	{
		"id": 6,
		"plz": 97789 ,
		"title": "Hartmann Energie GmbH",
		"str": "Industriestr. 2",
		"ort": "Oberleichtersbach",
		"tel": "09741 756",
		"fax": "09741 1534",
		"mail": "info@hartmann-brennstoffe.de",
		"pos": [50.28747000000001, 9.789049999999975],
		"img": "hartmann.jpg",
		"site": "http://hartmann-brennstoffe.de/",
		"marker": null
	},
	{
		"id": 7,
		"plz": 98574 ,
		"title": "DIGOL Energieservice, Zweigniederlassung der Hartmann Energie GmbH",
		"str": "Fuchsenkothe 1",
		"ort": "Schmalkalden",
		"tel": "03683 6027-51",
		"fax": "",
		"mail": "kontakt@digol.de",
		"pos": [50.718610, 10.432160],
		"img": "digol.jpg",
		"site": "https://www.digol.de/",
		"marker": null
	},
	{
		"id": 8,
		"plz": 60386 ,
		"title": "Benol Energieservice GmbH",
		"str": "Carl-Benz-Str. 35",
		"ort": "Frankfurt am Main",
		"tel": "069 424044",
		"fax": "069 425203",
		"mail": "frankfurt@benol.de",
		"pos": [50.1189696, 8.757299699999976],
		"img": "benol.jpg",
		"site": "http://benol.de/",
		"marker": null
	},
	{
		"id": 9,
		"plz": 86529 ,
		"title": "ZIEGLMEIER Energie GmbH",
		"str": "Gerolsbacher Straße 9",
		"ort": "Schrobenhausen",
		"tel": "08252 88770",
		"fax": "08252 887720",
		"mail": "office@zieglmeier-energie.de",
		"pos": [48.55538, 11.269289999999955],
		"img": "zieglmeier.jpg",
		"site": "http://www.zieglmeier-energie.de/produkte/erdgas",
		"marker": null
	},
	{
		"id": 10,
		"plz": 63500,
		"title": "H. Sattler KG",
		"str": "Steinheimerstr. 68a",
		"ort": "Seligenstadt",
		"tel": "",
		"fax": "",
		"mail": "",
		"pos": [50.051082, 8.974174],
		"img": "sattler_kg.png",
		"site": "https://sattler-energie.de/",
		"marker": null
	},
	{
		"id": 11,
		"plz": 59387 ,
		"title": "Schmidt Energiehandel GmbH",
		"str": "Merschstraße 62",
		"ort": "Ascheberg-Herbern",
		"tel": "",
		"fax": "",
		"mail": "",
		"pos": [51.744129, 7.670885],
		"img": "schmidt.png",
		"site": "https://heizoel-schmidt.de",
		"marker": null
	},
	{
		"id": 12,
		"plz": 22607 ,
		"title": "Carl Hass GmbH",
		"str": "Osdorfer Weg 147",
		"ort": "Hamburg",
		"tel": "040 892001",
		"fax": "040 8904544",
		"mail": "info@carlhass.de",
		"pos": [53.568578, 9.881010],
		"img": "hass.png",
		"site": "http://www.carlhass.de/",
		"marker": null
	},
	{
		"id": 13,
		"plz": 31073 ,
		"title": "Mönneke Mineralöle GmbH & Co. KG",
		"str": "Hilsstraße 51c",
		"ort": "Delligsen",
		"tel": "05187 30040",
		"fax": "05187 300419",
		"mail": "info@moenneke.de",
		"pos": [51.941520, 9.790423],
		"img": "moenneke.png",
		"site": "https://moenneke.de/",
		"marker": null
	},
	{
		"id": 14,
		"plz": 34590 ,
		"title": "Greiner GmbH",
		"str": "Engelstr. 30",
		"ort": "Wabern",
		"tel": "05683 7001",
		"fax": "05683 8128",
		"mail": "info@greiner-energie.de",
		"pos": [51.099610, 9.352700],
		"img": "greiner.jpg",
		"site": "https://greiner-energie.de/",
		"marker": null
	},
	{
		"id": 15,
		"plz": 37235 ,
		"title": "Söder Energie – ZN der Greiner GmbH",
		"str": "An der Koppe 13",
		"ort": "Hess. Lichtenau",
		"tel": "05602 2411",
		"fax": "05602 700812",
		"mail": "info@soeder-energie.de",
		"pos": [51.212700, 9.681470],
		"img": "soeder.jpg",        //ToDo
		"site": "https://soeder-brennstoffe.de/",
		"marker": null
	},
	{
		"id": 16,
		"plz": 93055 ,
		"title": "Böttcher Energie GmbH & Co. KG",
		"str": "Maxhüttenstr. 7",
		"ort": "Regensburg",
		"tel": "0941 7803760",
		"fax": "0941 57905",
		"mail": "info@boettcher-energie.de",
		"pos": [49.012140, 12.146900],
		"img": "boettcher.jpg",
		"site": "https://www.boettcher-energie.de/",
		"marker": null
	},
	{
		"id": 17,
		"plz": 92283 ,
		"title": "Kiessling Energie GmbH & Co. KG",
		"str": "Hohe Birke 5",
		"ort": "Lauterhofen-Gebertshofen",
		"tel": "09157 92900",
		"fax": "09157 929040",
		"mail": "kontakt@kiessling-energie.de",
		"pos": [49.406870, 11.544690],
		"img": "kiessling.jpg",
		"site": "https://www.kiessling-energie.de/",
		"marker": null
	},
	{
		"id": 18,
		"plz": 92637 ,
		"title": "Deglmann Energie GmbH & Co. KG",
		"str": "Hinterm Rangierbahnhof 24",
		"ort": "Weiden",
		"tel": "0961 471000",
		"fax": "",
		"mail": "info@deglmann.de",
		"pos": [49.665030, 12.150820],
		"img": "deglmann.jpg",
		"site": "https://www.deglmann.de/",
		"marker": null
	},
	{
		"id": 19,
		"plz": 95478 ,
		"title": "Stich Energie – ZN der Deglmann Energie GmbH & Co.KG",
		"str": "Röntgenstr. 13",
		"ort": "Kemnath-Stadt",
		"tel": "09642 915859-0",
		"fax": "",
		"mail": "info@stich-kemnath.de",
		"pos": [49.865700, 11.881010],
		"img": "stich.jpg",
		"site": "https://www.stich-kemnath.de/",
		"marker": null
	}


];


var lastInfoWindow = null;

function getCookie(name) {
	// Add the = sign
	name = name + '=';

	// Get the decoded cookie
	const decodedCookie = decodeURIComponent(document.cookie);

	// Get all cookies, split on ; sign
	const cookies = decodedCookie.split(';');

	// Loop over the cookies
	for (let i = 0; i < cookies.length; i++) {
		// Define the single cookie, and remove whitespace
		const cookie = cookies[i].trim();

		// If this cookie has the name of what we are searching
		if (cookie.indexOf(name) == 0) {
			// Return everything after the cookies name
			return cookie.substring(name.length, cookie.length);
		}
	}
}

function initialize() {

	initPlzSearch();
	initForms();

	var initGMap = function() {
		var myStyles =[
			{
				featureType: "poi",
				elementType: "labels",
				stylers: [
					{ visibility: "off" }
				]
			},
			{
				featureTyoe: "transit",
				elementType: "labels",
				stylers: [
					{
						visibilitiy: "off"
					}
				]
			}
		];

		var mapOptions = {
			center: new google.maps.LatLng(51, 9),
			zoom: 6,
			mapTypeId: google.maps.MapTypeId.ROADMAP,

			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
			scaleControl: false,
			draggable: true,
			disableDefaultUI: false,
			styles: myStyles

		};
		var map = new google.maps.Map(document.getElementById("map"), mapOptions);
		window._map = map;

		var markericon = new google.maps.MarkerImage(
				'images/gmaps/ballon.png',
				null,
				null,
				null,
				new google.maps.Size(49, 71)
		);
		//Marker setzen
		for(var i = 0; i < partner.length; i++) {
			var marker = new google.maps.Marker({
				position: new google.maps.LatLng(partner[i].pos[0], partner[i].pos[1]),
				icon: markericon,
				map: map
			});
			partner[i].marker = marker;
			createMarkerDesc(marker, partner[i]);
		}

		function createMarkerDesc(m, p) {
			google.maps.event.addListener(m, 'click', function(e) {
				map.setZoom(15);
				map.setCenter({lat: p.pos[0], lng: p.pos[1] + .0055});  //Offset
				closeOverlays();
				showMessage(m, p, map);

			});
		}




		zoomSetByJS = false;
		google.maps.event.addListener(map, 'bounds_changed', function(event) {
			if (this.getZoom() > 15 && zoomSetByJS) {
				// Change max/min zoom here
				this.setZoom(15);
				zoomSetByJS = false;
			}
			else if(zoomSetByJS) {
				this.setZoom(map.getZoom()-1);
				zoomSetByJS = false;
			}

		});


		$('#partner-karte-anchor .search-reset').on('click', function(e) {
			e.preventDefault();
			var _this = $(this);
			_this.prev().val("");
			plzSearch("",map, null);
			closeOverlays();
		});

		$(window).on('resize', function() {
			google.maps.event.trigger(map, "resize");
		})
	}

	var checkAccept = function(d,cb) {
		var btn = d.querySelector("#gmap-opt-in .gmap-opt-in-button"),
			gmapScript = d.createElement("script");

		var loadMap = function() {
			console.log("loadMap");
			gmapScript.setAttribute("src","https://maps.googleapis.com/maps/api/js?key=AIzaSyBBwUdkV59eejvCbmXPGxEz0QvFVPkMLaQ");
			document.cookie = 'custom_gmapAccepted=true';
			gmapScript.addEventListener("load", function() {
				cb();
			})
			document.getElementsByTagName('head')[0].appendChild(gmapScript);
		}

		var c = getCookie("custom_gmapAccepted");
		if( c && c == "true" ) {
			loadMap();
		}


		btn.addEventListener("click", loadMap, false);
	}

	checkAccept(document, initGMap);
}

function closeOverlays() {
		//Close Overlays
	$(".gmaps-marker-window").remove();
}

function showMessage(m,p,map) {

	var window_id = p.id;
	var msg = $('<div id="gmaps-marker-window-nr-' + window_id + '" class="gmaps-marker-window"><div class="gmaps-marker-desc">' +
					'<h2 class="heading">Wir haben einen Partner für Sie gefunden</h2>' +
					'<div class="close-box">c</div>' +
						'<img src="images/gmaps/partner/'+ p.img+'" /><br>' +
						'<p>'+p.title+'<br>' +
							p.str+'<br/>' +
							p.plz + " " + p.ort +
						'</p>' +
						'<a class="btn" href="'+ p.site+'" target="_blank">Jetzt wechseln</a>' +
					'</div>' +
				'</div>');


	var latlng = {
		lat: m.position.lat(),
		lng: m.position.lng()
	};



	/**
	 * Custom Google MAps Overlay (HTML als Marker)
	 */
	var overlay;
	USGSOverlay.prototype = new google.maps.OverlayView();

	var bounds = new google.maps.LatLngBounds(latlng, latlng);

	overlay = new USGSOverlay(bounds,map);

	/** @constructor */
	function USGSOverlay(bounds, map) {

		// Initialize all properties.
		this.bounds_ = bounds;
		this.map_ = map;

		// Define a property to hold the image's div. We'll
		// actually create this div upon receipt of the onAdd()
		// method so we'll leave it null for now.
		this.div_ = null;

		// Explicitly call setMap on this overlay.
		this.setMap(map);
	}

	USGSOverlay.prototype.onAdd = function() {

		//Button Markup
		var div = $(msg)[0];
		div.style.position = 'absolute';

		this.div_ = div;

		$(msg).find(".close-box").on('click', function(e) {

			$("#gmaps-marker-window-nr-"+window_id).remove();
			map.setOptions({
				draggable: true,
				disableDoubleClickZoom: false
			});


			//Prüfe ob der aktuell zu schließende Partner zusammen mit einen anderen angezeigt wurde,
			//Wenn ja trigger suche erneut um raus zu zoomen bis beide wieder zu sehn sind.
			if(multiplePartners !== null) {
				var match = false;
				for(var i = 0; i < multiplePartners.partners.length; i++) {
					var _id = multiplePartners.partners[i].id;
					if(_id == window_id) {
						match = true;
						break;
					}
				}
				if(match) {
					plzSearch(multiplePartners.sharedPlz, map, null);
				}
			}


		});


		$(msg).on("click", function(e) {
			$(".gmaps-marker-window").removeClass("in-focus");
			$(this).addClass("in-focus");
		});


		if(!isMobile()) {
			google.maps.event.addDomListener(this.div_, 'mouseenter', function (e) {
				map.setOptions({
					draggable: false,
					disableDoubleClickZoom: true
				});
			});

			google.maps.event.addDomListener(this.div_, 'mouseleave', function (e) {
				map.setOptions({
					draggable: true,
					disableDoubleClickZoom: false
				});
			});
		}

		// Add the element to the "overlayLayer" pane.
		var panes = this.getPanes();
		panes.overlayMouseTarget.appendChild(div);
	};

	USGSOverlay.prototype.draw = function() {

		// We use the south-west and north-east
		// coordinates of the overlay to peg it to the correct position and size.
		// To do this, we need to retrieve the projection from the overlay.
		var overlayProjection = this.getProjection();

		// Retrieve the south-west and north-east coordinates of this overlay
		// in LatLngs and convert them to pixel coordinates.
		// We'll use these coordinates to resize the div.
		var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
		var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

		// Resize the image's div to fit the indicated dimensions.
		var div = this.div_;
		div.style.left = sw.x + 'px';
		div.style.top = ne.y + 'px';
		var mt = div.offsetHeight / 2;
		div.style.marginTop = ( mt + 30 ) * -1 + "px";
	};

	// The onRemove() method will be called automatically from the API if
	// we ever set the overlay's map property to 'null'.
	USGSOverlay.prototype.onRemove = function() {
		this.div_.parentNode.removeChild(this.div_);
		this.div_ = null;
	};


}

function initPlzSearch() {
	var searchBtn = $('#partner-karte-anchor .button, #partner-finden-anchor .button');
	searchBtn.on('click', function(e) {
		e.preventDefault();
		var plz = $(this).parent().find('input[type="text"]').val();

		plzSearch(plz,this);
	});
}

function plzSearch(plz, _this, map = window._map) {
	console.log(map);
	var checkPLZ = function(plz) {
		return !(plz.match(/^[0-9]{5}$/))? false:true;
	}

	var errorMsg = "Bitte geben Sie eine gültige PLZ ein";
	_this = $(_this);
	if(!checkPLZ(plz) && plz !== "") {
		var form = null;
		//Submit
		if(_this.is('form')) {
			//oben
			if(_this.prev().is('h3')) {
				_this.prev().html(errorMsg);
			}
			//unten
			else {
				_this.find('label').html(errorMsg);
			}
			_this.addClass('error');
		}
		//Click
		else {
			if(_this.prev().prev().is('label')){
				_this.prev().prev().html(errorMsg);
			}
			if(_this.parent().prev().is('h3')) {
				_this.parent().prev().html(errorMsg);
			}
			_this.parent().addClass('error');
		}
		return;
	}

	//PLZ für unteres Input setzen
	$('.suche input').val(plz);
	var partnerfound = false;

	//evtl fehlermeldungen enfernen
	removeInputErrors();
	if(map) {
		if(plz === "") {

			if(lastInfoWindow !== null) {
				lastInfoWindow.close();
				lastInfoWindow = null;
			}
			map.setCenter({lat: 51, lng: 9});
			map.setZoom(7);
			return;
		}
		closeOverlays();
		for(var i = 0;i < orte.length; i++) {

			var tempPlz = orte[i].plz;

			if(plz === tempPlz) {
				var tempPartner = orte[i].partner;
				if(Array.isArray(tempPartner)) {

					window.multiplePartners = [];
					var bounds = new google.maps.LatLngBounds();
					for(var j = 0; j < tempPartner.length; j++) {
						var pm = partner[orte[i].partner[j]].marker;
						bounds.extend(new google.maps.LatLng(pm.position.lat(), pm.position.lng() + 0.0055));
						multiplePartners.push(partner[orte[i].partner[j]])
					}

					//
					if(multiplePartners.length > 1) {
						multiplePartners = {
							sharedPlz: plz,
							partners: multiplePartners
						}
					}
					else {
						showMessage(partner[orte[i].partner[0]].marker, partner[orte[i].partner[0]],map);
						multiplePartners = null;
					}
					zoomSetByJS = true;

					map.fitBounds(bounds);
					partnerfound = true;
					break;
				}


			}
		}
	}

	if(partnerfound || !map) {
		if(screen.width > 667) {
			$.fn.fullpage.moveTo(6);
		}
		else {
			$('html, body').stop().animate({
				'scrollTop': $('#partner-karte-anchor').offset().top - 60
			}, 300, 'swing');
		}
	}
	else {
		noPartnerFound();
	}

}

function noPartnerFound() {
	var box = $('#msg-no-partner');
	box.addClass('open');
}

function removeInputErrors() {
	var form = $('form.error');
	form.removeClass('error');
	form.find('label').html('Jetzt Daten eingeben und wechseln');
	if(form.prev().is('h3')) {
		form.prev().html('PLZ eingeben und plusgas Partner finden');
	}
}
function initForms() {
	//Inputs
	$('#partner-karte-anchor form, #partner-finden-anchor form').on('submit', function(e) {
		e.preventDefault();
		var plz = $(this).find('input').val();
		plzSearch(plz,this);
	});
}

initialize();
