orte = [
  {
    "plz": "01067",
    "partner": [
      1
    ]
  },
  {
    "plz": "01069",
    "partner": [
      1
    ]
  },
  {
    "plz": "01097",
    "partner": [
      1
    ]
  },
  {
    "plz": "01099",
    "partner": [
      1
    ]
  },
  {
    "plz": "01108",
    "partner": [
      1
    ]
  },
  {
    "plz": "01109",
    "partner": [
      1
    ]
  },
  {
    "plz": "01127",
    "partner": [
      1
    ]
  },
  {
    "plz": "01129",
    "partner": [
      1
    ]
  },
  {
    "plz": "01139",
    "partner": [
      1
    ]
  },
  {
    "plz": "01156",
    "partner": [
      1
    ]
  },
  {
    "plz": "01157",
    "partner": [
      1
    ]
  },
  {
    "plz": "01159",
    "partner": [
      1
    ]
  },
  {
    "plz": "01169",
    "partner": [
      1
    ]
  },
  {
    "plz": "01187",
    "partner": [
      1
    ]
  },
  {
    "plz": "01189",
    "partner": [
      1
    ]
  },
  {
    "plz": "01217",
    "partner": [
      1
    ]
  },
  {
    "plz": "01219",
    "partner": [
      1
    ]
  },
  {
    "plz": "01237",
    "partner": [
      1
    ]
  },
  {
    "plz": "01239",
    "partner": [
      1
    ]
  },
  {
    "plz": "01257",
    "partner": [
      1
    ]
  },
  {
    "plz": "01259",
    "partner": [
      1
    ]
  },
  {
    "plz": "01277",
    "partner": [
      1
    ]
  },
  {
    "plz": "01279",
    "partner": [
      1
    ]
  },
  {
    "plz": "01307",
    "partner": [
      1
    ]
  },
  {
    "plz": "01309",
    "partner": [
      1
    ]
  },
  {
    "plz": "01324",
    "partner": [
      1
    ]
  },
  {
    "plz": "01326",
    "partner": [
      1
    ]
  },
  {
    "plz": "01328",
    "partner": [
      1
    ]
  },
  {
    "plz": "01445",
    "partner": [
      1
    ]
  },
  {
    "plz": "01454",
    "partner": [
      1
    ]
  },
  {
    "plz": "01454",
    "partner": [
      1
    ]
  },
  {
    "plz": "01458",
    "partner": [
      1
    ]
  },
  {
    "plz": "01465",
    "partner": [
      1
    ]
  },
  {
    "plz": "01465",
    "partner": [
      1
    ]
  },
  {
    "plz": "01468",
    "partner": [
      1
    ]
  },
  {
    "plz": "01471",
    "partner": [
      1
    ]
  },
  {
    "plz": "01477",
    "partner": [
      1
    ]
  },
  {
    "plz": "01558",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01561",
    "partner": [
      1
    ]
  },
  {
    "plz": "01587",
    "partner": [
      1
    ]
  },
  {
    "plz": "01589",
    "partner": [
      1
    ]
  },
  {
    "plz": "01591",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01594",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01609",
    "partner": [
      1
    ]
  },
  {
    "plz": "01612",
    "partner": [
      1
    ]
  },
  {
    "plz": "01612",
    "partner": [
      1
    ]
  },
  {
    "plz": "01612",
    "partner": [
      1
    ]
  },
  {
    "plz": "01616",
    "partner": [
      1
    ]
  },
  {
    "plz": "01619",
    "partner": [
      1
    ]
  },
  {
    "plz": "01623",
    "partner": [
      1
    ]
  },
  {
    "plz": "01640",
    "partner": [
      1
    ]
  },
  {
    "plz": "01662",
    "partner": [
      1
    ]
  },
  {
    "plz": "01665",
    "partner": [
      1
    ]
  },
  {
    "plz": "01665",
    "partner": [
      1
    ]
  },
  {
    "plz": "01665",
    "partner": [
      1
    ]
  },
  {
    "plz": "01683",
    "partner": [
      1
    ]
  },
  {
    "plz": "01689",
    "partner": [
      1
    ]
  },
  {
    "plz": "01689",
    "partner": [
      1
    ]
  },
  {
    "plz": "01705",
    "partner": [
      1
    ]
  },
  {
    "plz": "01723",
    "partner": [
      1
    ]
  },
  {
    "plz": "01723",
    "partner": [
      1
    ]
  },
  {
    "plz": "01723",
    "partner": [
      1
    ]
  },
  {
    "plz": "01723",
    "partner": [
      1
    ]
  },
  {
    "plz": "01723",
    "partner": [
      1
    ]
  },
  {
    "plz": "01728",
    "partner": [
      1
    ]
  },
  {
    "plz": "01731",
    "partner": [
      1
    ]
  },
  {
    "plz": "01734",
    "partner": [
      1
    ]
  },
  {
    "plz": "01737",
    "partner": [
      1
    ]
  },
  {
    "plz": "01737",
    "partner": [
      1
    ]
  },
  {
    "plz": "01737",
    "partner": [
      1
    ]
  },
  {
    "plz": "01737",
    "partner": [
      1
    ]
  },
  {
    "plz": "01737",
    "partner": [
      1
    ]
  },
  {
    "plz": "01737",
    "partner": [
      1
    ]
  },
  {
    "plz": "01738",
    "partner": [
      1
    ]
  },
  {
    "plz": "01744",
    "partner": [
      1
    ]
  },
  {
    "plz": "01762",
    "partner": [
      1
    ]
  },
  {
    "plz": "01768",
    "partner": [
      1
    ]
  },
  {
    "plz": "01773",
    "partner": [
      1
    ]
  },
  {
    "plz": "01774",
    "partner": [
      1
    ]
  },
  {
    "plz": "01776",
    "partner": [
      1
    ]
  },
  {
    "plz": "01778",
    "partner": [
      1
    ]
  },
  {
    "plz": "01778",
    "partner": [
      1
    ]
  },
  {
    "plz": "01778",
    "partner": [
      1
    ]
  },
  {
    "plz": "01778",
    "partner": [
      1
    ]
  },
  {
    "plz": "01778",
    "partner": [
      1
    ]
  },
  {
    "plz": "01778",
    "partner": [
      1
    ]
  },
  {
    "plz": "01796",
    "partner": [
      1
    ]
  },
  {
    "plz": "01796",
    "partner": [
      1
    ]
  },
  {
    "plz": "01796",
    "partner": [
      1
    ]
  },
  {
    "plz": "01809",
    "partner": [
      1
    ]
  },
  {
    "plz": "01809",
    "partner": [
      1
    ]
  },
  {
    "plz": "01809",
    "partner": [
      1
    ]
  },
  {
    "plz": "01814",
    "partner": [
      1
    ]
  },
  {
    "plz": "01814",
    "partner": [
      1
    ]
  },
  {
    "plz": "01814",
    "partner": [
      1
    ]
  },
  {
    "plz": "01814",
    "partner": [
      1
    ]
  },
  {
    "plz": "01814",
    "partner": [
      1
    ]
  },
  {
    "plz": "01816",
    "partner": [
      1
    ]
  },
  {
    "plz": "01819",
    "partner": [
      1
    ]
  },
  {
    "plz": "01824",
    "partner": [
      1
    ]
  },
  {
    "plz": "01824",
    "partner": [
      1
    ]
  },
  {
    "plz": "01824",
    "partner": [
      1
    ]
  },
  {
    "plz": "01824",
    "partner": [
      1
    ]
  },
  {
    "plz": "01825",
    "partner": [
      1
    ]
  },
  {
    "plz": "01829",
    "partner": [
      1
    ]
  },
  {
    "plz": "01833",
    "partner": [
      1
    ]
  },
  {
    "plz": "01833",
    "partner": [
      1
    ]
  },
  {
    "plz": "01844",
    "partner": [
      1
    ]
  },
  {
    "plz": "01847",
    "partner": [
      1
    ]
  },
  {
    "plz": "01848",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01855",
    "partner": [
      1
    ]
  },
  {
    "plz": "01877",
    "partner": [
      1
    ]
  },
  {
    "plz": "01877",
    "partner": [
      1
    ]
  },
  {
    "plz": "01877",
    "partner": [
      1
    ]
  },
  {
    "plz": "01877",
    "partner": [
      1
    ]
  },
  {
    "plz": "01877",
    "partner": [
      1
    ]
  },
  {
    "plz": "01896",
    "partner": [
      1
    ]
  },
  {
    "plz": "01896",
    "partner": [
      1
    ]
  },
  {
    "plz": "01896",
    "partner": [
      1
    ]
  },
  {
    "plz": "01900",
    "partner": [
      1
    ]
  },
  {
    "plz": "01904",
    "partner": [
      1
    ]
  },
  {
    "plz": "01904",
    "partner": [
      1
    ]
  },
  {
    "plz": "01904",
    "partner": [
      1
    ]
  },
  {
    "plz": "01904",
    "partner": [
      1
    ]
  },
  {
    "plz": "01906",
    "partner": [
      1
    ]
  },
  {
    "plz": "01909",
    "partner": [
      1
    ]
  },
  {
    "plz": "01909",
    "partner": [
      1
    ]
  },
  {
    "plz": "01909",
    "partner": [
      1
    ]
  },
  {
    "plz": "01909",
    "partner": [
      1
    ]
  },
  {
    "plz": "01909",
    "partner": [
      1
    ]
  },
  {
    "plz": "01917",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01920",
    "partner": [
      1
    ]
  },
  {
    "plz": "01936",
    "partner": [
      1
    ]
  },
  {
    "plz": "01936",
    "partner": [
      1
    ]
  },
  {
    "plz": "01936",
    "partner": [
      1
    ]
  },
  {
    "plz": "01936",
    "partner": [
      1
    ]
  },
  {
    "plz": "01936",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01945",
    "partner": [
      1
    ]
  },
  {
    "plz": "01968",
    "partner": [
      1
    ]
  },
  {
    "plz": "01968",
    "partner": [
      1
    ]
  },
  {
    "plz": "01968",
    "partner": [
      1
    ]
  },
  {
    "plz": "01968",
    "partner": [
      1
    ]
  },
  {
    "plz": "01968",
    "partner": [
      1
    ]
  },
  {
    "plz": "01968",
    "partner": [
      1
    ]
  },
  {
    "plz": "01979",
    "partner": [
      1
    ]
  },
  {
    "plz": "01983",
    "partner": [
      1
    ]
  },
  {
    "plz": "01987",
    "partner": [
      1
    ]
  },
  {
    "plz": "01990",
    "partner": [
      1
    ]
  },
  {
    "plz": "01990",
    "partner": [
      1
    ]
  },
  {
    "plz": "01990",
    "partner": [
      1
    ]
  },
  {
    "plz": "01990",
    "partner": [
      1
    ]
  },
  {
    "plz": "01993",
    "partner": [
      1
    ]
  },
  {
    "plz": "01994",
    "partner": [
      1
    ]
  },
  {
    "plz": "01994",
    "partner": [
      1
    ]
  },
  {
    "plz": "01994",
    "partner": [
      1
    ]
  },
  {
    "plz": "01996",
    "partner": [
      1
    ]
  },
  {
    "plz": "01998",
    "partner": [
      1
    ]
  },
  {
    "plz": "02625",
    "partner": [
      1
    ]
  },
  {
    "plz": "02627",
    "partner": [
      1
    ]
  },
  {
    "plz": "02627",
    "partner": [
      1
    ]
  },
  {
    "plz": "02627",
    "partner": [
      1
    ]
  },
  {
    "plz": "02627",
    "partner": [
      1
    ]
  },
  {
    "plz": "02633",
    "partner": [
      1
    ]
  },
  {
    "plz": "02633",
    "partner": [
      1
    ]
  },
  {
    "plz": "02633",
    "partner": [
      1
    ]
  },
  {
    "plz": "02681",
    "partner": [
      1
    ]
  },
  {
    "plz": "02681",
    "partner": [
      1
    ]
  },
  {
    "plz": "02689",
    "partner": [
      1
    ]
  },
  {
    "plz": "02692",
    "partner": [
      1
    ]
  },
  {
    "plz": "02692",
    "partner": [
      1
    ]
  },
  {
    "plz": "02692",
    "partner": [
      1
    ]
  },
  {
    "plz": "02692",
    "partner": [
      1
    ]
  },
  {
    "plz": "02694",
    "partner": [
      1
    ]
  },
  {
    "plz": "02694",
    "partner": [
      1
    ]
  },
  {
    "plz": "02699",
    "partner": [
      1
    ]
  },
  {
    "plz": "02699",
    "partner": [
      1
    ]
  },
  {
    "plz": "02699",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02708",
    "partner": [
      1
    ]
  },
  {
    "plz": "02727",
    "partner": [
      1
    ]
  },
  {
    "plz": "02730",
    "partner": [
      1
    ]
  },
  {
    "plz": "02733",
    "partner": [
      1
    ]
  },
  {
    "plz": "02733",
    "partner": [
      1
    ]
  },
  {
    "plz": "02736",
    "partner": [
      1
    ]
  },
  {
    "plz": "02736",
    "partner": [
      1
    ]
  },
  {
    "plz": "02739",
    "partner": [
      1
    ]
  },
  {
    "plz": "02739",
    "partner": [
      1
    ]
  },
  {
    "plz": "02739",
    "partner": [
      1
    ]
  },
  {
    "plz": "02742",
    "partner": [
      1
    ]
  },
  {
    "plz": "02742",
    "partner": [
      1
    ]
  },
  {
    "plz": "02747",
    "partner": [
      1
    ]
  },
  {
    "plz": "02747",
    "partner": [
      1
    ]
  },
  {
    "plz": "02747",
    "partner": [
      1
    ]
  },
  {
    "plz": "02747",
    "partner": [
      1
    ]
  },
  {
    "plz": "02747",
    "partner": [
      1
    ]
  },
  {
    "plz": "02748",
    "partner": [
      1
    ]
  },
  {
    "plz": "02763",
    "partner": [
      1
    ]
  },
  {
    "plz": "02763",
    "partner": [
      1
    ]
  },
  {
    "plz": "02763",
    "partner": [
      1
    ]
  },
  {
    "plz": "02763",
    "partner": [
      1
    ]
  },
  {
    "plz": "02763",
    "partner": [
      1
    ]
  },
  {
    "plz": "02763",
    "partner": [
      1
    ]
  },
  {
    "plz": "02779",
    "partner": [
      1
    ]
  },
  {
    "plz": "02779",
    "partner": [
      1
    ]
  },
  {
    "plz": "02782",
    "partner": [
      1
    ]
  },
  {
    "plz": "02785",
    "partner": [
      1
    ]
  },
  {
    "plz": "02788",
    "partner": [
      1
    ]
  },
  {
    "plz": "02788",
    "partner": [
      1
    ]
  },
  {
    "plz": "02788",
    "partner": [
      1
    ]
  },
  {
    "plz": "02791",
    "partner": [
      1
    ]
  },
  {
    "plz": "02794",
    "partner": [
      1
    ]
  },
  {
    "plz": "02796",
    "partner": [
      1
    ]
  },
  {
    "plz": "02797",
    "partner": [
      1
    ]
  },
  {
    "plz": "02797",
    "partner": [
      1
    ]
  },
  {
    "plz": "02799",
    "partner": [
      1
    ]
  },
  {
    "plz": "02826",
    "partner": [
      1
    ]
  },
  {
    "plz": "02827",
    "partner": [
      1
    ]
  },
  {
    "plz": "02828",
    "partner": [
      1
    ]
  },
  {
    "plz": "02829",
    "partner": [
      1
    ]
  },
  {
    "plz": "02829",
    "partner": [
      1
    ]
  },
  {
    "plz": "02829",
    "partner": [
      1
    ]
  },
  {
    "plz": "02829",
    "partner": [
      1
    ]
  },
  {
    "plz": "02894",
    "partner": [
      1
    ]
  },
  {
    "plz": "02894",
    "partner": [
      1
    ]
  },
  {
    "plz": "02894",
    "partner": [
      1
    ]
  },
  {
    "plz": "02899",
    "partner": [
      1
    ]
  },
  {
    "plz": "02899",
    "partner": [
      1
    ]
  },
  {
    "plz": "02906",
    "partner": [
      1
    ]
  },
  {
    "plz": "02906",
    "partner": [
      1
    ]
  },
  {
    "plz": "02906",
    "partner": [
      1
    ]
  },
  {
    "plz": "02906",
    "partner": [
      1
    ]
  },
  {
    "plz": "02906",
    "partner": [
      1
    ]
  },
  {
    "plz": "02906",
    "partner": [
      1
    ]
  },
  {
    "plz": "02923",
    "partner": [
      1
    ]
  },
  {
    "plz": "02923",
    "partner": [
      1
    ]
  },
  {
    "plz": "02923",
    "partner": [
      1
    ]
  },
  {
    "plz": "02929",
    "partner": [
      1
    ]
  },
  {
    "plz": "02943",
    "partner": [
      1
    ]
  },
  {
    "plz": "02943",
    "partner": [
      1
    ]
  },
  {
    "plz": "02953",
    "partner": [
      1
    ]
  },
  {
    "plz": "02953",
    "partner": [
      1
    ]
  },
  {
    "plz": "02953",
    "partner": [
      1
    ]
  },
  {
    "plz": "02953",
    "partner": [
      1
    ]
  },
  {
    "plz": "02956",
    "partner": [
      1
    ]
  },
  {
    "plz": "02957",
    "partner": [
      1
    ]
  },
  {
    "plz": "02957",
    "partner": [
      1
    ]
  },
  {
    "plz": "02959",
    "partner": [
      1
    ]
  },
  {
    "plz": "02959",
    "partner": [
      1
    ]
  },
  {
    "plz": "02959",
    "partner": [
      1
    ]
  },
  {
    "plz": "02959",
    "partner": [
      1
    ]
  },
  {
    "plz": "02959",
    "partner": [
      1
    ]
  },
  {
    "plz": "02959",
    "partner": [
      1
    ]
  },
  {
    "plz": "02977",
    "partner": [
      1
    ]
  },
  {
    "plz": "02979",
    "partner": [
      1
    ]
  },
  {
    "plz": "02979",
    "partner": [
      1
    ]
  },
  {
    "plz": "02991",
    "partner": [
      1
    ]
  },
  {
    "plz": "02991",
    "partner": [
      1
    ]
  },
  {
    "plz": "02991",
    "partner": [
      1
    ]
  },
  {
    "plz": "02994",
    "partner": [
      1
    ]
  },
  {
    "plz": "02997",
    "partner": [
      1
    ]
  },
  {
    "plz": "02999",
    "partner": [
      1
    ]
  },
  {
    "plz": "03042",
    "partner": [
      1
    ]
  },
  {
    "plz": "03044",
    "partner": [
      1
    ]
  },
  {
    "plz": "03046",
    "partner": [
      1
    ]
  },
  {
    "plz": "03048",
    "partner": [
      1
    ]
  },
  {
    "plz": "03050",
    "partner": [
      1
    ]
  },
  {
    "plz": "03051",
    "partner": [
      1
    ]
  },
  {
    "plz": "03052",
    "partner": [
      1
    ]
  },
  {
    "plz": "03053",
    "partner": [
      1
    ]
  },
  {
    "plz": "03054",
    "partner": [
      1
    ]
  },
  {
    "plz": "03055",
    "partner": [
      1
    ]
  },
  {
    "plz": "03058",
    "partner": [
      1
    ]
  },
  {
    "plz": "03096",
    "partner": [
      1
    ]
  },
  {
    "plz": "03096",
    "partner": [
      1
    ]
  },
  {
    "plz": "03096",
    "partner": [
      1
    ]
  },
  {
    "plz": "03096",
    "partner": [
      1
    ]
  },
  {
    "plz": "03096",
    "partner": [
      1
    ]
  },
  {
    "plz": "03096",
    "partner": [
      1
    ]
  },
  {
    "plz": "03099",
    "partner": [
      1
    ]
  },
  {
    "plz": "03103",
    "partner": [
      1
    ]
  },
  {
    "plz": "03103",
    "partner": [
      1
    ]
  },
  {
    "plz": "03116",
    "partner": [
      1
    ]
  },
  {
    "plz": "03119",
    "partner": [
      1
    ]
  },
  {
    "plz": "03130",
    "partner": [
      1
    ]
  },
  {
    "plz": "03130",
    "partner": [
      1
    ]
  },
  {
    "plz": "03130",
    "partner": [
      1
    ]
  },
  {
    "plz": "03130",
    "partner": [
      1
    ]
  },
  {
    "plz": "03149",
    "partner": [
      1
    ]
  },
  {
    "plz": "03149",
    "partner": [
      1
    ]
  },
  {
    "plz": "03149",
    "partner": [
      1
    ]
  },
  {
    "plz": "03159",
    "partner": [
      1
    ]
  },
  {
    "plz": "03159",
    "partner": [
      1
    ]
  },
  {
    "plz": "03172",
    "partner": [
      1
    ]
  },
  {
    "plz": "03172",
    "partner": [
      1
    ]
  },
  {
    "plz": "03172",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03185",
    "partner": [
      1
    ]
  },
  {
    "plz": "03197",
    "partner": [
      1
    ]
  },
  {
    "plz": "03197",
    "partner": [
      1
    ]
  },
  {
    "plz": "03197",
    "partner": [
      1
    ]
  },
  {
    "plz": "03205",
    "partner": [
      1
    ]
  },
  {
    "plz": "03205",
    "partner": [
      1
    ]
  },
  {
    "plz": "03222",
    "partner": [
      1
    ]
  },
  {
    "plz": "03226",
    "partner": [
      1
    ]
  },
  {
    "plz": "03229",
    "partner": [
      1
    ]
  },
  {
    "plz": "03229",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03238",
    "partner": [
      1
    ]
  },
  {
    "plz": "03246",
    "partner": [
      1
    ]
  },
  {
    "plz": "03246",
    "partner": [
      1
    ]
  },
  {
    "plz": "03246",
    "partner": [
      1
    ]
  },
  {
    "plz": "03249",
    "partner": [
      1
    ]
  },
  {
    "plz": "03253",
    "partner": [
      1
    ]
  },
  {
    "plz": "03253",
    "partner": [
      1
    ]
  },
  {
    "plz": "03253",
    "partner": [
      1
    ]
  },
  {
    "plz": "03253",
    "partner": [
      1
    ]
  },
  {
    "plz": "04103",
    "partner": [
      1
    ]
  },
  {
    "plz": "04105",
    "partner": [
      1
    ]
  },
  {
    "plz": "04107",
    "partner": [
      1
    ]
  },
  {
    "plz": "04109",
    "partner": [
      1
    ]
  },
  {
    "plz": "04129",
    "partner": [
      1
    ]
  },
  {
    "plz": "04155",
    "partner": [
      1
    ]
  },
  {
    "plz": "04157",
    "partner": [
      1
    ]
  },
  {
    "plz": "04158",
    "partner": [
      1
    ]
  },
  {
    "plz": "04159",
    "partner": [
      1
    ]
  },
  {
    "plz": "04177",
    "partner": [
      1
    ]
  },
  {
    "plz": "04178",
    "partner": [
      1
    ]
  },
  {
    "plz": "04179",
    "partner": [
      1
    ]
  },
  {
    "plz": "04205",
    "partner": [
      1
    ]
  },
  {
    "plz": "04207",
    "partner": [
      1
    ]
  },
  {
    "plz": "04209",
    "partner": [
      1
    ]
  },
  {
    "plz": "04229",
    "partner": [
      1
    ]
  },
  {
    "plz": "04249",
    "partner": [
      1
    ]
  },
  {
    "plz": "04275",
    "partner": [
      1
    ]
  },
  {
    "plz": "04277",
    "partner": [
      1
    ]
  },
  {
    "plz": "04279",
    "partner": [
      1
    ]
  },
  {
    "plz": "04288",
    "partner": [
      1
    ]
  },
  {
    "plz": "04289",
    "partner": [
      1
    ]
  },
  {
    "plz": "04299",
    "partner": [
      1
    ]
  },
  {
    "plz": "04315",
    "partner": [
      1
    ]
  },
  {
    "plz": "04316",
    "partner": [
      1
    ]
  },
  {
    "plz": "04317",
    "partner": [
      1
    ]
  },
  {
    "plz": "04318",
    "partner": [
      1
    ]
  },
  {
    "plz": "04319",
    "partner": [
      1
    ]
  },
  {
    "plz": "04328",
    "partner": [
      1
    ]
  },
  {
    "plz": "04329",
    "partner": [
      1
    ]
  },
  {
    "plz": "04347",
    "partner": [
      1
    ]
  },
  {
    "plz": "04349",
    "partner": [
      1
    ]
  },
  {
    "plz": "04356",
    "partner": [
      1
    ]
  },
  {
    "plz": "04357",
    "partner": [
      1
    ]
  },
  {
    "plz": "04416",
    "partner": [
      1
    ]
  },
  {
    "plz": "04420",
    "partner": [
      1
    ]
  },
  {
    "plz": "04425",
    "partner": [
      1
    ]
  },
  {
    "plz": "04435",
    "partner": [
      1
    ]
  },
  {
    "plz": "04435",
    "partner": [
      1
    ]
  },
  {
    "plz": "04442",
    "partner": [
      1
    ]
  },
  {
    "plz": "04451",
    "partner": [
      1
    ]
  },
  {
    "plz": "04463",
    "partner": [
      1
    ]
  },
  {
    "plz": "04509",
    "partner": [
      1
    ]
  },
  {
    "plz": "04509",
    "partner": [
      1
    ]
  },
  {
    "plz": "04509",
    "partner": [
      1
    ]
  },
  {
    "plz": "04509",
    "partner": [
      1
    ]
  },
  {
    "plz": "04509",
    "partner": [
      1
    ]
  },
  {
    "plz": "04519",
    "partner": [
      1
    ]
  },
  {
    "plz": "04523",
    "partner": [
      1
    ]
  },
  {
    "plz": "04523",
    "partner": [
      1
    ]
  },
  {
    "plz": "04539",
    "partner": [
      1
    ]
  },
  {
    "plz": "04552",
    "partner": [
      1
    ]
  },
  {
    "plz": "04564",
    "partner": [
      1
    ]
  },
  {
    "plz": "04565",
    "partner": [
      1
    ]
  },
  {
    "plz": "04567",
    "partner": [
      1
    ]
  },
  {
    "plz": "04571",
    "partner": [
      1
    ]
  },
  {
    "plz": "04575",
    "partner": [
      1
    ]
  },
  {
    "plz": "04600",
    "partner": [
      1
    ]
  },
  {
    "plz": "04603",
    "partner": [
      1
    ]
  },
  {
    "plz": "04603",
    "partner": [
      1
    ]
  },
  {
    "plz": "04603",
    "partner": [
      1
    ]
  },
  {
    "plz": "04610",
    "partner": [
      1
    ]
  },
  {
    "plz": "04613",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04617",
    "partner": [
      1
    ]
  },
  {
    "plz": "04618",
    "partner": [
      1
    ]
  },
  {
    "plz": "04618",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04626",
    "partner": [
      1
    ]
  },
  {
    "plz": "04639",
    "partner": [
      1
    ]
  },
  {
    "plz": "04639",
    "partner": [
      1
    ]
  },
  {
    "plz": "04643",
    "partner": [
      1
    ]
  },
  {
    "plz": "04651",
    "partner": [
      1
    ]
  },
  {
    "plz": "04654",
    "partner": [
      1
    ]
  },
  {
    "plz": "04668",
    "partner": [
      1
    ]
  },
  {
    "plz": "04668",
    "partner": [
      1
    ]
  },
  {
    "plz": "04668",
    "partner": [
      1
    ]
  },
  {
    "plz": "04680",
    "partner": [
      1
    ]
  },
  {
    "plz": "04683",
    "partner": [
      1
    ]
  },
  {
    "plz": "04683",
    "partner": [
      1
    ]
  },
  {
    "plz": "04683",
    "partner": [
      1
    ]
  },
  {
    "plz": "04687",
    "partner": [
      1
    ]
  },
  {
    "plz": "04703",
    "partner": [
      1
    ]
  },
  {
    "plz": "04720",
    "partner": [
      1
    ]
  },
  {
    "plz": "04720",
    "partner": [
      1
    ]
  },
  {
    "plz": "04720",
    "partner": [
      1
    ]
  },
  {
    "plz": "04736",
    "partner": [
      1
    ]
  },
  {
    "plz": "04741",
    "partner": [
      1
    ]
  },
  {
    "plz": "04746",
    "partner": [
      1
    ]
  },
  {
    "plz": "04749",
    "partner": [
      1
    ]
  },
  {
    "plz": "04758",
    "partner": [
      1
    ]
  },
  {
    "plz": "04758",
    "partner": [
      1
    ]
  },
  {
    "plz": "04758",
    "partner": [
      1
    ]
  },
  {
    "plz": "04769",
    "partner": [
      1
    ]
  },
  {
    "plz": "04769",
    "partner": [
      1
    ]
  },
  {
    "plz": "04774",
    "partner": [
      1
    ]
  },
  {
    "plz": "04779",
    "partner": [
      1
    ]
  },
  {
    "plz": "04808",
    "partner": [
      1
    ]
  },
  {
    "plz": "04808",
    "partner": [
      1
    ]
  },
  {
    "plz": "04808",
    "partner": [
      1
    ]
  },
  {
    "plz": "04821",
    "partner": [
      1
    ]
  },
  {
    "plz": "04821",
    "partner": [
      1
    ]
  },
  {
    "plz": "04824",
    "partner": [
      1
    ]
  },
  {
    "plz": "04827",
    "partner": [
      1
    ]
  },
  {
    "plz": "04827",
    "partner": [
      1
    ]
  },
  {
    "plz": "04827",
    "partner": [
      1
    ]
  },
  {
    "plz": "04828",
    "partner": [
      1
    ]
  },
  {
    "plz": "04828",
    "partner": [
      1
    ]
  },
  {
    "plz": "04838",
    "partner": [
      1
    ]
  },
  {
    "plz": "04838",
    "partner": [
      1
    ]
  },
  {
    "plz": "04838",
    "partner": [
      1
    ]
  },
  {
    "plz": "04838",
    "partner": [
      1
    ]
  },
  {
    "plz": "04838",
    "partner": [
      1
    ]
  },
  {
    "plz": "04849",
    "partner": [
      1
    ]
  },
  {
    "plz": "04849",
    "partner": [
      1
    ]
  },
  {
    "plz": "04849",
    "partner": [
      1
    ]
  },
  {
    "plz": "04849",
    "partner": [
      1
    ]
  },
  {
    "plz": "04860",
    "partner": [
      1
    ]
  },
  {
    "plz": "04860",
    "partner": [
      1
    ]
  },
  {
    "plz": "04860",
    "partner": [
      1
    ]
  },
  {
    "plz": "04860",
    "partner": [
      1
    ]
  },
  {
    "plz": "04861",
    "partner": [
      1
    ]
  },
  {
    "plz": "04862",
    "partner": [
      1
    ]
  },
  {
    "plz": "04874",
    "partner": [
      1
    ]
  },
  {
    "plz": "04880",
    "partner": [
      1
    ]
  },
  {
    "plz": "04880",
    "partner": [
      1
    ]
  },
  {
    "plz": "04880",
    "partner": [
      1
    ]
  },
  {
    "plz": "04880",
    "partner": [
      1
    ]
  },
  {
    "plz": "04886",
    "partner": [
      1
    ]
  },
  {
    "plz": "04886",
    "partner": [
      1
    ]
  },
  {
    "plz": "04889",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04895",
    "partner": [
      1
    ]
  },
  {
    "plz": "04910",
    "partner": [
      1
    ]
  },
  {
    "plz": "04910",
    "partner": [
      1
    ]
  },
  {
    "plz": "04916",
    "partner": [
      1
    ]
  },
  {
    "plz": "04916",
    "partner": [
      1
    ]
  },
  {
    "plz": "04916",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04924",
    "partner": [
      1
    ]
  },
  {
    "plz": "04928",
    "partner": [
      1
    ]
  },
  {
    "plz": "04928",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04931",
    "partner": [
      1
    ]
  },
  {
    "plz": "04932",
    "partner": [
      1
    ]
  },
  {
    "plz": "04932",
    "partner": [
      1
    ]
  },
  {
    "plz": "04932",
    "partner": [
      1
    ]
  },
  {
    "plz": "04932",
    "partner": [
      1
    ]
  },
  {
    "plz": "04932",
    "partner": [
      1
    ]
  },
  {
    "plz": "04934",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04936",
    "partner": [
      1
    ]
  },
  {
    "plz": "04938",
    "partner": [
      1
    ]
  },
  {
    "plz": "04938",
    "partner": [
      1
    ]
  },
  {
    "plz": "04938",
    "partner": [
      1
    ]
  },
  {
    "plz": "04938",
    "partner": [
      1
    ]
  },
  {
    "plz": "06108",
    "partner": [
      1
    ]
  },
  {
    "plz": "06110",
    "partner": [
      1
    ]
  },
  {
    "plz": "06112",
    "partner": [
      1
    ]
  },
  {
    "plz": "06114",
    "partner": [
      1
    ]
  },
  {
    "plz": "06116",
    "partner": [
      1
    ]
  },
  {
    "plz": "06118",
    "partner": [
      1
    ]
  },
  {
    "plz": "06120",
    "partner": [
      1
    ]
  },
  {
    "plz": "06122",
    "partner": [
      1
    ]
  },
  {
    "plz": "06124",
    "partner": [
      1
    ]
  },
  {
    "plz": "06126",
    "partner": [
      1
    ]
  },
  {
    "plz": "06128",
    "partner": [
      1
    ]
  },
  {
    "plz": "06130",
    "partner": [
      1
    ]
  },
  {
    "plz": "06132",
    "partner": [
      1
    ]
  },
  {
    "plz": "06179",
    "partner": [
      1
    ]
  },
  {
    "plz": "06184",
    "partner": [
      1
    ]
  },
  {
    "plz": "06188",
    "partner": [
      1
    ]
  },
  {
    "plz": "06193",
    "partner": [
      1
    ]
  },
  {
    "plz": "06193",
    "partner": [
      1
    ]
  },
  {
    "plz": "06198",
    "partner": [
      1
    ]
  },
  {
    "plz": "06217",
    "partner": [
      1
    ]
  },
  {
    "plz": "06231",
    "partner": [
      1
    ]
  },
  {
    "plz": "06237",
    "partner": [
      1
    ]
  },
  {
    "plz": "06242",
    "partner": [
      1
    ]
  },
  {
    "plz": "06242",
    "partner": [
      1
    ]
  },
  {
    "plz": "06242",
    "partner": [
      1
    ]
  },
  {
    "plz": "06242",
    "partner": [
      1
    ]
  },
  {
    "plz": "06246",
    "partner": [
      1
    ]
  },
  {
    "plz": "06249",
    "partner": [
      1
    ]
  },
  {
    "plz": "06255",
    "partner": [
      1
    ]
  },
  {
    "plz": "06258",
    "partner": [
      1
    ]
  },
  {
    "plz": "06259",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06268",
    "partner": [
      1
    ]
  },
  {
    "plz": "06279",
    "partner": [
      1
    ]
  },
  {
    "plz": "06279",
    "partner": [
      1
    ]
  },
  {
    "plz": "06295",
    "partner": [
      1
    ]
  },
  {
    "plz": "06295",
    "partner": [
      1
    ]
  },
  {
    "plz": "06308",
    "partner": [
      1
    ]
  },
  {
    "plz": "06308",
    "partner": [
      1
    ]
  },
  {
    "plz": "06311",
    "partner": [
      1
    ]
  },
  {
    "plz": "06313",
    "partner": [
      1
    ]
  },
  {
    "plz": "06313",
    "partner": [
      1
    ]
  },
  {
    "plz": "06313",
    "partner": [
      1
    ]
  },
  {
    "plz": "06317",
    "partner": [
      1
    ]
  },
  {
    "plz": "06333",
    "partner": [
      1
    ]
  },
  {
    "plz": "06333",
    "partner": [
      1
    ]
  },
  {
    "plz": "06333",
    "partner": [
      1
    ]
  },
  {
    "plz": "06343",
    "partner": [
      1
    ]
  },
  {
    "plz": "06347",
    "partner": [
      1
    ]
  },
  {
    "plz": "06366",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06369",
    "partner": [
      1
    ]
  },
  {
    "plz": "06385",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06386",
    "partner": [
      1
    ]
  },
  {
    "plz": "06388",
    "partner": [
      1
    ]
  },
  {
    "plz": "06388",
    "partner": [
      1
    ]
  },
  {
    "plz": "06388",
    "partner": [
      1
    ]
  },
  {
    "plz": "06388",
    "partner": [
      1
    ]
  },
  {
    "plz": "06388",
    "partner": [
      1
    ]
  },
  {
    "plz": "06388",
    "partner": [
      1
    ]
  },
  {
    "plz": "06406",
    "partner": [
      1
    ]
  },
  {
    "plz": "06408",
    "partner": [
      1
    ]
  },
  {
    "plz": "06420",
    "partner": [
      1
    ]
  },
  {
    "plz": "06425",
    "partner": [
      1
    ]
  },
  {
    "plz": "06425",
    "partner": [
      1
    ]
  },
  {
    "plz": "06429",
    "partner": [
      1
    ]
  },
  {
    "plz": "06449",
    "partner": [
      1
    ]
  },
  {
    "plz": "06449",
    "partner": [
      1
    ]
  },
  {
    "plz": "06449",
    "partner": [
      1
    ]
  },
  {
    "plz": "06449",
    "partner": [
      1
    ]
  },
  {
    "plz": "06456",
    "partner": [
      1
    ]
  },
  {
    "plz": "06458",
    "partner": [
      1
    ]
  },
  {
    "plz": "06458",
    "partner": [
      1
    ]
  },
  {
    "plz": "06463",
    "partner": [
      1
    ]
  },
  {
    "plz": "06463",
    "partner": [
      1
    ]
  },
  {
    "plz": "06463",
    "partner": [
      1
    ]
  },
  {
    "plz": "06464",
    "partner": [
      1
    ]
  },
  {
    "plz": "06466",
    "partner": [
      1
    ]
  },
  {
    "plz": "06467",
    "partner": [
      1
    ]
  },
  {
    "plz": "06469",
    "partner": [
      1
    ]
  },
  {
    "plz": "06484",
    "partner": [
      1
    ]
  },
  {
    "plz": "06484",
    "partner": [
      1
    ]
  },
  {
    "plz": "06485",
    "partner": [
      1
    ]
  },
  {
    "plz": "06493",
    "partner": [
      1
    ]
  },
  {
    "plz": "06493",
    "partner": [
      1
    ]
  },
  {
    "plz": "06502",
    "partner": [
      1
    ]
  },
  {
    "plz": "06502",
    "partner": [
      1
    ]
  },
  {
    "plz": "06526",
    "partner": [
      1
    ]
  },
  {
    "plz": "06528",
    "partner": [
      1
    ]
  },
  {
    "plz": "06528",
    "partner": [
      1
    ]
  },
  {
    "plz": "06528",
    "partner": [
      1
    ]
  },
  {
    "plz": "06528",
    "partner": [
      1
    ]
  },
  {
    "plz": "06528",
    "partner": [
      1
    ]
  },
  {
    "plz": "06528",
    "partner": [
      1
    ]
  },
  {
    "plz": "06536",
    "partner": [
      1
    ]
  },
  {
    "plz": "06536",
    "partner": [
      1
    ]
  },
  {
    "plz": "06537",
    "partner": [
      1
    ]
  },
  {
    "plz": "06542",
    "partner": [
      1
    ]
  },
  {
    "plz": "06543",
    "partner": [
      1
    ]
  },
  {
    "plz": "06543",
    "partner": [
      1
    ]
  },
  {
    "plz": "06556",
    "partner": [
      1
    ]
  },
  {
    "plz": "06556",
    "partner": [
      1
    ]
  },
  {
    "plz": "06556",
    "partner": [
      1
    ]
  },
  {
    "plz": "06556",
    "partner": [
      1
    ]
  },
  {
    "plz": "06556",
    "partner": [
      1
    ]
  },
  {
    "plz": "06567",
    "partner": [
      1
    ]
  },
  {
    "plz": "06571",
    "partner": [
      1
    ]
  },
  {
    "plz": "06571",
    "partner": [
      1
    ]
  },
  {
    "plz": "06577",
    "partner": [
      1
    ]
  },
  {
    "plz": "06577",
    "partner": [
      1
    ]
  },
  {
    "plz": "06577",
    "partner": [
      1
    ]
  },
  {
    "plz": "06578",
    "partner": [
      1
    ]
  },
  {
    "plz": "06578",
    "partner": [
      1
    ]
  },
  {
    "plz": "06618",
    "partner": [
      1
    ]
  },
  {
    "plz": "06618",
    "partner": [
      1
    ]
  },
  {
    "plz": "06618",
    "partner": [
      1
    ]
  },
  {
    "plz": "06618",
    "partner": [
      1
    ]
  },
  {
    "plz": "06618",
    "partner": [
      1
    ]
  },
  {
    "plz": "06628",
    "partner": [
      1
    ]
  },
  {
    "plz": "06628",
    "partner": [
      1
    ]
  },
  {
    "plz": "06632",
    "partner": [
      1
    ]
  },
  {
    "plz": "06632",
    "partner": [
      1
    ]
  },
  {
    "plz": "06632",
    "partner": [
      1
    ]
  },
  {
    "plz": "06632",
    "partner": [
      1
    ]
  },
  {
    "plz": "06632",
    "partner": [
      1
    ]
  },
  {
    "plz": "06636",
    "partner": [
      1
    ]
  },
  {
    "plz": "06638",
    "partner": [
      1
    ]
  },
  {
    "plz": "06642",
    "partner": [
      1
    ]
  },
  {
    "plz": "06642",
    "partner": [
      1
    ]
  },
  {
    "plz": "06642",
    "partner": [
      1
    ]
  },
  {
    "plz": "06647",
    "partner": [
      1
    ]
  },
  {
    "plz": "06647",
    "partner": [
      1
    ]
  },
  {
    "plz": "06647",
    "partner": [
      1
    ]
  },
  {
    "plz": "06647",
    "partner": [
      1
    ]
  },
  {
    "plz": "06648",
    "partner": [
      1
    ]
  },
  {
    "plz": "06667",
    "partner": [
      1
    ]
  },
  {
    "plz": "06667",
    "partner": [
      1
    ]
  },
  {
    "plz": "06667",
    "partner": [
      1
    ]
  },
  {
    "plz": "06679",
    "partner": [
      1
    ]
  },
  {
    "plz": "06682",
    "partner": [
      1
    ]
  },
  {
    "plz": "06686",
    "partner": [
      1
    ]
  },
  {
    "plz": "06688",
    "partner": [
      1
    ]
  },
  {
    "plz": "06711",
    "partner": [
      1
    ]
  },
  {
    "plz": "06712",
    "partner": [
      1
    ]
  },
  {
    "plz": "06712",
    "partner": [
      1
    ]
  },
  {
    "plz": "06712",
    "partner": [
      1
    ]
  },
  {
    "plz": "06712",
    "partner": [
      1
    ]
  },
  {
    "plz": "06721",
    "partner": [
      1
    ]
  },
  {
    "plz": "06721",
    "partner": [
      1
    ]
  },
  {
    "plz": "06722",
    "partner": [
      1
    ]
  },
  {
    "plz": "06722",
    "partner": [
      1
    ]
  },
  {
    "plz": "06729",
    "partner": [
      1
    ]
  },
  {
    "plz": "06749",
    "partner": [
      1
    ]
  },
  {
    "plz": "06766",
    "partner": [
      1
    ]
  },
  {
    "plz": "06772",
    "partner": [
      1
    ]
  },
  {
    "plz": "06773",
    "partner": [
      1
    ]
  },
  {
    "plz": "06774",
    "partner": [
      1
    ]
  },
  {
    "plz": "06779",
    "partner": [
      1
    ]
  },
  {
    "plz": "06780",
    "partner": [
      1
    ]
  },
  {
    "plz": "06785",
    "partner": [
      1
    ]
  },
  {
    "plz": "06792",
    "partner": [
      1
    ]
  },
  {
    "plz": "06794",
    "partner": [
      1
    ]
  },
  {
    "plz": "06796",
    "partner": [
      1
    ]
  },
  {
    "plz": "06800",
    "partner": [
      1
    ]
  },
  {
    "plz": "06803",
    "partner": [
      1
    ]
  },
  {
    "plz": "06808",
    "partner": [
      1
    ]
  },
  {
    "plz": "06809",
    "partner": [
      1
    ]
  },
  {
    "plz": "06809",
    "partner": [
      1
    ]
  },
  {
    "plz": "06842",
    "partner": [
      1
    ]
  },
  {
    "plz": "06844",
    "partner": [
      1
    ]
  },
  {
    "plz": "06846",
    "partner": [
      1
    ]
  },
  {
    "plz": "06847",
    "partner": [
      1
    ]
  },
  {
    "plz": "06849",
    "partner": [
      1
    ]
  },
  {
    "plz": "06861",
    "partner": [
      1
    ]
  },
  {
    "plz": "06862",
    "partner": [
      1
    ]
  },
  {
    "plz": "06868",
    "partner": [
      1
    ]
  },
  {
    "plz": "06869",
    "partner": [
      1
    ]
  },
  {
    "plz": "06886",
    "partner": [
      1
    ]
  },
  {
    "plz": "06888",
    "partner": [
      1
    ]
  },
  {
    "plz": "06889",
    "partner": [
      1
    ]
  },
  {
    "plz": "06895",
    "partner": [
      1
    ]
  },
  {
    "plz": "06901",
    "partner": [
      1
    ]
  },
  {
    "plz": "06905",
    "partner": [
      1
    ]
  },
  {
    "plz": "06917",
    "partner": [
      1
    ]
  },
  {
    "plz": "06925",
    "partner": [
      1
    ]
  },
  {
    "plz": "07318",
    "partner": [
      1
    ]
  },
  {
    "plz": "07330",
    "partner": [
      1
    ]
  },
  {
    "plz": "07333",
    "partner": [
      1
    ]
  },
  {
    "plz": "07338",
    "partner": [
      1
    ]
  },
  {
    "plz": "07338",
    "partner": [
      1
    ]
  },
  {
    "plz": "07338",
    "partner": [
      1
    ]
  },
  {
    "plz": "07338",
    "partner": [
      1
    ]
  },
  {
    "plz": "07338",
    "partner": [
      1
    ]
  },
  {
    "plz": "07343",
    "partner": [
      1
    ]
  },
  {
    "plz": "07349",
    "partner": [
      1
    ]
  },
  {
    "plz": "07356",
    "partner": [
      1
    ]
  },
  {
    "plz": "07366",
    "partner": [
      1
    ]
  },
  {
    "plz": "07368",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07381",
    "partner": [
      1
    ]
  },
  {
    "plz": "07387",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07389",
    "partner": [
      1
    ]
  },
  {
    "plz": "07407",
    "partner": [
      1
    ]
  },
  {
    "plz": "07407",
    "partner": [
      1
    ]
  },
  {
    "plz": "07422",
    "partner": [
      1
    ]
  },
  {
    "plz": "07426",
    "partner": [
      1
    ]
  },
  {
    "plz": "07426",
    "partner": [
      1
    ]
  },
  {
    "plz": "07426",
    "partner": [
      1
    ]
  },
  {
    "plz": "07427",
    "partner": [
      1
    ]
  },
  {
    "plz": "07429",
    "partner": [
      1
    ]
  },
  {
    "plz": "07429",
    "partner": [
      1
    ]
  },
  {
    "plz": "07429",
    "partner": [
      1
    ]
  },
  {
    "plz": "07545",
    "partner": [
      1
    ]
  },
  {
    "plz": "07546",
    "partner": [
      1
    ]
  },
  {
    "plz": "07548",
    "partner": [
      1
    ]
  },
  {
    "plz": "07549",
    "partner": [
      1
    ]
  },
  {
    "plz": "07551",
    "partner": [
      1
    ]
  },
  {
    "plz": "07552",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07554",
    "partner": [
      1
    ]
  },
  {
    "plz": "07557",
    "partner": [
      1
    ]
  },
  {
    "plz": "07557",
    "partner": [
      1
    ]
  },
  {
    "plz": "07557",
    "partner": [
      1
    ]
  },
  {
    "plz": "07557",
    "partner": [
      1
    ]
  },
  {
    "plz": "07570",
    "partner": [
      1
    ]
  },
  {
    "plz": "07570",
    "partner": [
      1
    ]
  },
  {
    "plz": "07570",
    "partner": [
      1
    ]
  },
  {
    "plz": "07570",
    "partner": [
      1
    ]
  },
  {
    "plz": "07570",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07580",
    "partner": [
      1
    ]
  },
  {
    "plz": "07586",
    "partner": [
      1
    ]
  },
  {
    "plz": "07586",
    "partner": [
      1
    ]
  },
  {
    "plz": "07586",
    "partner": [
      1
    ]
  },
  {
    "plz": "07586",
    "partner": [
      1
    ]
  },
  {
    "plz": "07589",
    "partner": [
      1
    ]
  },
  {
    "plz": "07589",
    "partner": [
      1
    ]
  },
  {
    "plz": "07589",
    "partner": [
      1
    ]
  },
  {
    "plz": "07589",
    "partner": [
      1
    ]
  },
  {
    "plz": "07589",
    "partner": [
      1
    ]
  },
  {
    "plz": "07589",
    "partner": [
      1
    ]
  },
  {
    "plz": "07607",
    "partner": [
      1
    ]
  },
  {
    "plz": "07607",
    "partner": [
      1
    ]
  },
  {
    "plz": "07607",
    "partner": [
      1
    ]
  },
  {
    "plz": "07613",
    "partner": [
      1
    ]
  },
  {
    "plz": "07613",
    "partner": [
      1
    ]
  },
  {
    "plz": "07613",
    "partner": [
      1
    ]
  },
  {
    "plz": "07613",
    "partner": [
      1
    ]
  },
  {
    "plz": "07613",
    "partner": [
      1
    ]
  },
  {
    "plz": "07613",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07616",
    "partner": [
      1
    ]
  },
  {
    "plz": "07619",
    "partner": [
      1
    ]
  },
  {
    "plz": "07619",
    "partner": [
      1
    ]
  },
  {
    "plz": "07629",
    "partner": [
      1
    ]
  },
  {
    "plz": "07629",
    "partner": [
      1
    ]
  },
  {
    "plz": "07629",
    "partner": [
      1
    ]
  },
  {
    "plz": "07629",
    "partner": [
      1
    ]
  },
  {
    "plz": "07639",
    "partner": [
      1
    ]
  },
  {
    "plz": "07639",
    "partner": [
      1
    ]
  },
  {
    "plz": "07639",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07646",
    "partner": [
      1
    ]
  },
  {
    "plz": "07743",
    "partner": [
      1
    ]
  },
  {
    "plz": "07745",
    "partner": [
      1
    ]
  },
  {
    "plz": "07747",
    "partner": [
      1
    ]
  },
  {
    "plz": "07749",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07751",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07768",
    "partner": [
      1
    ]
  },
  {
    "plz": "07774",
    "partner": [
      1
    ]
  },
  {
    "plz": "07774",
    "partner": [
      1
    ]
  },
  {
    "plz": "07774",
    "partner": [
      1
    ]
  },
  {
    "plz": "07774",
    "partner": [
      1
    ]
  },
  {
    "plz": "07778",
    "partner": [
      1
    ]
  },
  {
    "plz": "07778",
    "partner": [
      1
    ]
  },
  {
    "plz": "07778",
    "partner": [
      1
    ]
  },
  {
    "plz": "07778",
    "partner": [
      1
    ]
  },
  {
    "plz": "07778",
    "partner": [
      1
    ]
  },
  {
    "plz": "07806",
    "partner": [
      1
    ]
  },
  {
    "plz": "07806",
    "partner": [
      1
    ]
  },
  {
    "plz": "07806",
    "partner": [
      1
    ]
  },
  {
    "plz": "07806",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07819",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07907",
    "partner": [
      1
    ]
  },
  {
    "plz": "07919",
    "partner": [
      1
    ]
  },
  {
    "plz": "07919",
    "partner": [
      1
    ]
  },
  {
    "plz": "07919",
    "partner": [
      1
    ]
  },
  {
    "plz": "07922",
    "partner": [
      1
    ]
  },
  {
    "plz": "07924",
    "partner": [
      1
    ]
  },
  {
    "plz": "07924",
    "partner": [
      1
    ]
  },
  {
    "plz": "07924",
    "partner": [
      1
    ]
  },
  {
    "plz": "07924",
    "partner": [
      1
    ]
  },
  {
    "plz": "07924",
    "partner": [
      1
    ]
  },
  {
    "plz": "07926",
    "partner": [
      1
    ]
  },
  {
    "plz": "07927",
    "partner": [
      1
    ]
  },
  {
    "plz": "07929",
    "partner": [
      1
    ]
  },
  {
    "plz": "07937",
    "partner": [
      1
    ]
  },
  {
    "plz": "07937",
    "partner": [
      1
    ]
  },
  {
    "plz": "07950",
    "partner": [
      1
    ]
  },
  {
    "plz": "07950",
    "partner": [
      1
    ]
  },
  {
    "plz": "07952",
    "partner": [
      1
    ]
  },
  {
    "plz": "07955",
    "partner": [
      1
    ]
  },
  {
    "plz": "07957",
    "partner": [
      1
    ]
  },
  {
    "plz": "07958",
    "partner": [
      1
    ]
  },
  {
    "plz": "07973",
    "partner": [
      1
    ]
  },
  {
    "plz": "07980",
    "partner": [
      1
    ]
  },
  {
    "plz": "07980",
    "partner": [
      1
    ]
  },
  {
    "plz": "07985",
    "partner": [
      1
    ]
  },
  {
    "plz": "07987",
    "partner": [
      1
    ]
  },
  {
    "plz": "08056",
    "partner": [
      1
    ]
  },
  {
    "plz": "08058",
    "partner": [
      1
    ]
  },
  {
    "plz": "08060",
    "partner": [
      1
    ]
  },
  {
    "plz": "08062",
    "partner": [
      1
    ]
  },
  {
    "plz": "08064",
    "partner": [
      1
    ]
  },
  {
    "plz": "08066",
    "partner": [
      1
    ]
  },
  {
    "plz": "08107",
    "partner": [
      1
    ]
  },
  {
    "plz": "08107",
    "partner": [
      1
    ]
  },
  {
    "plz": "08112",
    "partner": [
      1
    ]
  },
  {
    "plz": "08115",
    "partner": [
      1
    ]
  },
  {
    "plz": "08118",
    "partner": [
      1
    ]
  },
  {
    "plz": "08132",
    "partner": [
      1
    ]
  },
  {
    "plz": "08134",
    "partner": [
      1
    ]
  },
  {
    "plz": "08134",
    "partner": [
      1
    ]
  },
  {
    "plz": "08141",
    "partner": [
      1
    ]
  },
  {
    "plz": "08144",
    "partner": [
      1
    ]
  },
  {
    "plz": "08147",
    "partner": [
      1
    ]
  },
  {
    "plz": "08209",
    "partner": [
      1
    ]
  },
  {
    "plz": "08223",
    "partner": [
      1
    ]
  },
  {
    "plz": "08223",
    "partner": [
      1
    ]
  },
  {
    "plz": "08223",
    "partner": [
      1
    ]
  },
  {
    "plz": "08223",
    "partner": [
      1
    ]
  },
  {
    "plz": "08223",
    "partner": [
      1
    ]
  },
  {
    "plz": "08228",
    "partner": [
      1
    ]
  },
  {
    "plz": "08233",
    "partner": [
      1
    ]
  },
  {
    "plz": "08233",
    "partner": [
      1
    ]
  },
  {
    "plz": "08233",
    "partner": [
      1
    ]
  },
  {
    "plz": "08236",
    "partner": [
      1
    ]
  },
  {
    "plz": "08237",
    "partner": [
      1
    ]
  },
  {
    "plz": "08239",
    "partner": [
      1
    ]
  },
  {
    "plz": "08239",
    "partner": [
      1
    ]
  },
  {
    "plz": "08239",
    "partner": [
      1
    ]
  },
  {
    "plz": "08248",
    "partner": [
      1
    ]
  },
  {
    "plz": "08258",
    "partner": [
      1
    ]
  },
  {
    "plz": "08261",
    "partner": [
      1
    ]
  },
  {
    "plz": "08262",
    "partner": [
      1
    ]
  },
  {
    "plz": "08267",
    "partner": [
      1
    ]
  },
  {
    "plz": "08280",
    "partner": [
      1
    ]
  },
  {
    "plz": "08289",
    "partner": [
      1
    ]
  },
  {
    "plz": "08294",
    "partner": [
      1
    ]
  },
  {
    "plz": "08297",
    "partner": [
      1
    ]
  },
  {
    "plz": "08301",
    "partner": [
      1
    ]
  },
  {
    "plz": "08304",
    "partner": [
      1
    ]
  },
  {
    "plz": "08309",
    "partner": [
      1
    ]
  },
  {
    "plz": "08315",
    "partner": [
      1
    ]
  },
  {
    "plz": "08321",
    "partner": [
      1
    ]
  },
  {
    "plz": "08324",
    "partner": [
      1
    ]
  },
  {
    "plz": "08328",
    "partner": [
      1
    ]
  },
  {
    "plz": "08340",
    "partner": [
      1
    ]
  },
  {
    "plz": "08344",
    "partner": [
      1
    ]
  },
  {
    "plz": "08349",
    "partner": [
      1
    ]
  },
  {
    "plz": "08352",
    "partner": [
      1
    ]
  },
  {
    "plz": "08359",
    "partner": [
      1
    ]
  },
  {
    "plz": "08371",
    "partner": [
      1
    ]
  },
  {
    "plz": "08373",
    "partner": [
      1
    ]
  },
  {
    "plz": "08393",
    "partner": [
      1
    ]
  },
  {
    "plz": "08393",
    "partner": [
      1
    ]
  },
  {
    "plz": "08393",
    "partner": [
      1
    ]
  },
  {
    "plz": "08396",
    "partner": [
      1
    ]
  },
  {
    "plz": "08396",
    "partner": [
      1
    ]
  },
  {
    "plz": "08412",
    "partner": [
      1
    ]
  },
  {
    "plz": "08427",
    "partner": [
      1
    ]
  },
  {
    "plz": "08428",
    "partner": [
      1
    ]
  },
  {
    "plz": "08451",
    "partner": [
      1
    ]
  },
  {
    "plz": "08459",
    "partner": [
      1
    ]
  },
  {
    "plz": "08468",
    "partner": [
      1
    ]
  },
  {
    "plz": "08468",
    "partner": [
      1
    ]
  },
  {
    "plz": "08468",
    "partner": [
      1
    ]
  },
  {
    "plz": "08468",
    "partner": [
      1
    ]
  },
  {
    "plz": "08485",
    "partner": [
      1
    ]
  },
  {
    "plz": "08485",
    "partner": [
      1
    ]
  },
  {
    "plz": "08485",
    "partner": [
      1
    ]
  },
  {
    "plz": "08491",
    "partner": [
      1
    ]
  },
  {
    "plz": "08491",
    "partner": [
      1
    ]
  },
  {
    "plz": "08491",
    "partner": [
      1
    ]
  },
  {
    "plz": "08491",
    "partner": [
      1
    ]
  },
  {
    "plz": "08491",
    "partner": [
      1
    ]
  },
  {
    "plz": "08491",
    "partner": [
      1
    ]
  },
  {
    "plz": "08496",
    "partner": [
      1
    ]
  },
  {
    "plz": "08499",
    "partner": [
      1
    ]
  },
  {
    "plz": "08523",
    "partner": [
      1
    ]
  },
  {
    "plz": "08525",
    "partner": [
      1
    ]
  },
  {
    "plz": "08525",
    "partner": [
      1
    ]
  },
  {
    "plz": "08527",
    "partner": [
      1
    ]
  },
  {
    "plz": "08527",
    "partner": [
      1
    ]
  },
  {
    "plz": "08527",
    "partner": [
      1
    ]
  },
  {
    "plz": "08527",
    "partner": [
      1
    ]
  },
  {
    "plz": "08527",
    "partner": [
      1
    ]
  },
  {
    "plz": "08529",
    "partner": [
      1
    ]
  },
  {
    "plz": "08538",
    "partner": [
      1
    ]
  },
  {
    "plz": "08539",
    "partner": [
      1
    ]
  },
  {
    "plz": "08539",
    "partner": [
      1
    ]
  },
  {
    "plz": "08539",
    "partner": [
      1
    ]
  },
  {
    "plz": "08539",
    "partner": [
      1
    ]
  },
  {
    "plz": "08539",
    "partner": [
      1
    ]
  },
  {
    "plz": "08541",
    "partner": [
      1
    ]
  },
  {
    "plz": "08541",
    "partner": [
      1
    ]
  },
  {
    "plz": "08541",
    "partner": [
      1
    ]
  },
  {
    "plz": "08541",
    "partner": [
      1
    ]
  },
  {
    "plz": "08541",
    "partner": [
      1
    ]
  },
  {
    "plz": "08541",
    "partner": [
      1
    ]
  },
  {
    "plz": "08543",
    "partner": [
      1
    ]
  },
  {
    "plz": "08547",
    "partner": [
      1
    ]
  },
  {
    "plz": "08548",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08606",
    "partner": [
      1
    ]
  },
  {
    "plz": "08626",
    "partner": [
      1
    ]
  },
  {
    "plz": "08626",
    "partner": [
      1
    ]
  },
  {
    "plz": "08626",
    "partner": [
      1
    ]
  },
  {
    "plz": "08626",
    "partner": [
      1
    ]
  },
  {
    "plz": "08626",
    "partner": [
      1
    ]
  },
  {
    "plz": "08626",
    "partner": [
      1
    ]
  },
  {
    "plz": "08645",
    "partner": [
      1
    ]
  },
  {
    "plz": "08648",
    "partner": [
      1
    ]
  },
  {
    "plz": "09111",
    "partner": [
      1
    ]
  },
  {
    "plz": "09112",
    "partner": [
      1
    ]
  },
  {
    "plz": "09113",
    "partner": [
      1
    ]
  },
  {
    "plz": "09114",
    "partner": [
      1
    ]
  },
  {
    "plz": "09116",
    "partner": [
      1
    ]
  },
  {
    "plz": "09117",
    "partner": [
      1
    ]
  },
  {
    "plz": "09119",
    "partner": [
      1
    ]
  },
  {
    "plz": "09120",
    "partner": [
      1
    ]
  },
  {
    "plz": "09122",
    "partner": [
      1
    ]
  },
  {
    "plz": "09123",
    "partner": [
      1
    ]
  },
  {
    "plz": "09125",
    "partner": [
      1
    ]
  },
  {
    "plz": "09126",
    "partner": [
      1
    ]
  },
  {
    "plz": "09127",
    "partner": [
      1
    ]
  },
  {
    "plz": "09128",
    "partner": [
      1
    ]
  },
  {
    "plz": "09130",
    "partner": [
      1
    ]
  },
  {
    "plz": "09131",
    "partner": [
      1
    ]
  },
  {
    "plz": "09212",
    "partner": [
      1
    ]
  },
  {
    "plz": "09217",
    "partner": [
      1
    ]
  },
  {
    "plz": "09221",
    "partner": [
      1
    ]
  },
  {
    "plz": "09224",
    "partner": [
      1
    ]
  },
  {
    "plz": "09228",
    "partner": [
      1
    ]
  },
  {
    "plz": "09232",
    "partner": [
      1
    ]
  },
  {
    "plz": "09235",
    "partner": [
      1
    ]
  },
  {
    "plz": "09236",
    "partner": [
      1
    ]
  },
  {
    "plz": "09241",
    "partner": [
      1
    ]
  },
  {
    "plz": "09243",
    "partner": [
      1
    ]
  },
  {
    "plz": "09244",
    "partner": [
      1
    ]
  },
  {
    "plz": "09247",
    "partner": [
      1
    ]
  },
  {
    "plz": "09249",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09306",
    "partner": [
      1
    ]
  },
  {
    "plz": "09322",
    "partner": [
      1
    ]
  },
  {
    "plz": "09326",
    "partner": [
      1
    ]
  },
  {
    "plz": "09328",
    "partner": [
      1
    ]
  },
  {
    "plz": "09337",
    "partner": [
      1
    ]
  },
  {
    "plz": "09337",
    "partner": [
      1
    ]
  },
  {
    "plz": "09337",
    "partner": [
      1
    ]
  },
  {
    "plz": "09350",
    "partner": [
      1
    ]
  },
  {
    "plz": "09353",
    "partner": [
      1
    ]
  },
  {
    "plz": "09355",
    "partner": [
      1
    ]
  },
  {
    "plz": "09356",
    "partner": [
      1
    ]
  },
  {
    "plz": "09366",
    "partner": [
      1
    ]
  },
  {
    "plz": "09366",
    "partner": [
      1
    ]
  },
  {
    "plz": "09376",
    "partner": [
      1
    ]
  },
  {
    "plz": "09380",
    "partner": [
      1
    ]
  },
  {
    "plz": "09385",
    "partner": [
      1
    ]
  },
  {
    "plz": "09387",
    "partner": [
      1
    ]
  },
  {
    "plz": "09390",
    "partner": [
      1
    ]
  },
  {
    "plz": "09392",
    "partner": [
      1
    ]
  },
  {
    "plz": "09394",
    "partner": [
      1
    ]
  },
  {
    "plz": "09399",
    "partner": [
      1
    ]
  },
  {
    "plz": "09405",
    "partner": [
      1
    ]
  },
  {
    "plz": "09405",
    "partner": [
      1
    ]
  },
  {
    "plz": "09419",
    "partner": [
      1
    ]
  },
  {
    "plz": "09423",
    "partner": [
      1
    ]
  },
  {
    "plz": "09427",
    "partner": [
      1
    ]
  },
  {
    "plz": "09429",
    "partner": [
      1
    ]
  },
  {
    "plz": "09430",
    "partner": [
      1
    ]
  },
  {
    "plz": "09432",
    "partner": [
      1
    ]
  },
  {
    "plz": "09434",
    "partner": [
      1
    ]
  },
  {
    "plz": "09437",
    "partner": [
      1
    ]
  },
  {
    "plz": "09437",
    "partner": [
      1
    ]
  },
  {
    "plz": "09439",
    "partner": [
      1
    ]
  },
  {
    "plz": "09456",
    "partner": [
      1
    ]
  },
  {
    "plz": "09456",
    "partner": [
      1
    ]
  },
  {
    "plz": "09465",
    "partner": [
      1
    ]
  },
  {
    "plz": "09465",
    "partner": [
      1
    ]
  },
  {
    "plz": "09465",
    "partner": [
      1
    ]
  },
  {
    "plz": "09468",
    "partner": [
      1
    ]
  },
  {
    "plz": "09468",
    "partner": [
      1
    ]
  },
  {
    "plz": "09471",
    "partner": [
      1
    ]
  },
  {
    "plz": "09471",
    "partner": [
      1
    ]
  },
  {
    "plz": "09474",
    "partner": [
      1
    ]
  },
  {
    "plz": "09477",
    "partner": [
      1
    ]
  },
  {
    "plz": "09477",
    "partner": [
      1
    ]
  },
  {
    "plz": "09477",
    "partner": [
      1
    ]
  },
  {
    "plz": "09481",
    "partner": [
      1
    ]
  },
  {
    "plz": "09481",
    "partner": [
      1
    ]
  },
  {
    "plz": "09484",
    "partner": [
      1
    ]
  },
  {
    "plz": "09487",
    "partner": [
      1
    ]
  },
  {
    "plz": "09488",
    "partner": [
      1
    ]
  },
  {
    "plz": "09496",
    "partner": [
      1
    ]
  },
  {
    "plz": "09509",
    "partner": [
      1
    ]
  },
  {
    "plz": "09514",
    "partner": [
      1
    ]
  },
  {
    "plz": "09518",
    "partner": [
      1
    ]
  },
  {
    "plz": "09526",
    "partner": [
      1
    ]
  },
  {
    "plz": "09526",
    "partner": [
      1
    ]
  },
  {
    "plz": "09544",
    "partner": [
      1
    ]
  },
  {
    "plz": "09548",
    "partner": [
      1
    ]
  },
  {
    "plz": "09548",
    "partner": [
      1
    ]
  },
  {
    "plz": "09557",
    "partner": [
      1
    ]
  },
  {
    "plz": "09569",
    "partner": [
      1
    ]
  },
  {
    "plz": "09573",
    "partner": [
      1
    ]
  },
  {
    "plz": "09573",
    "partner": [
      1
    ]
  },
  {
    "plz": "09573",
    "partner": [
      1
    ]
  },
  {
    "plz": "09575",
    "partner": [
      1
    ]
  },
  {
    "plz": "09577",
    "partner": [
      1
    ]
  },
  {
    "plz": "09579",
    "partner": [
      1
    ]
  },
  {
    "plz": "09599",
    "partner": [
      1
    ]
  },
  {
    "plz": "09600",
    "partner": [
      1
    ]
  },
  {
    "plz": "09600",
    "partner": [
      1
    ]
  },
  {
    "plz": "09603",
    "partner": [
      1
    ]
  },
  {
    "plz": "09618",
    "partner": [
      1
    ]
  },
  {
    "plz": "09618",
    "partner": [
      1
    ]
  },
  {
    "plz": "09619",
    "partner": [
      1
    ]
  },
  {
    "plz": "09619",
    "partner": [
      1
    ]
  },
  {
    "plz": "09619",
    "partner": [
      1
    ]
  },
  {
    "plz": "09619",
    "partner": [
      1
    ]
  },
  {
    "plz": "09619",
    "partner": [
      1
    ]
  },
  {
    "plz": "09623",
    "partner": [
      1
    ]
  },
  {
    "plz": "09623",
    "partner": [
      1
    ]
  },
  {
    "plz": "09627",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09629",
    "partner": [
      1
    ]
  },
  {
    "plz": "09633",
    "partner": [
      1
    ]
  },
  {
    "plz": "09634",
    "partner": [
      1
    ]
  },
  {
    "plz": "09638",
    "partner": [
      1
    ]
  },
  {
    "plz": "09648",
    "partner": [
      1
    ]
  },
  {
    "plz": "09648",
    "partner": [
      1
    ]
  },
  {
    "plz": "09648",
    "partner": [
      1
    ]
  },
  {
    "plz": "09661",
    "partner": [
      1
    ]
  },
  {
    "plz": "09661",
    "partner": [
      1
    ]
  },
  {
    "plz": "09661",
    "partner": [
      1
    ]
  },
  {
    "plz": "09669",
    "partner": [
      1
    ]
  },
  {
    "plz": "10115",
    "partner": [
      0
    ]
  },
  {
    "plz": "10117",
    "partner": [
      0
    ]
  },
  {
    "plz": "10119",
    "partner": [
      0
    ]
  },
  {
    "plz": "10178",
    "partner": [
      0
    ]
  },
  {
    "plz": "10179",
    "partner": [
      0
    ]
  },
  {
    "plz": "10243",
    "partner": [
      0
    ]
  },
  {
    "plz": "10245",
    "partner": [
      0
    ]
  },
  {
    "plz": "10247",
    "partner": [
      0
    ]
  },
  {
    "plz": "10249",
    "partner": [
      0
    ]
  },
  {
    "plz": "10315",
    "partner": [
      0
    ]
  },
  {
    "plz": "10317",
    "partner": [
      0
    ]
  },
  {
    "plz": "10318",
    "partner": [
      0
    ]
  },
  {
    "plz": "10319",
    "partner": [
      0
    ]
  },
  {
    "plz": "10365",
    "partner": [
      0
    ]
  },
  {
    "plz": "10367",
    "partner": [
      0
    ]
  },
  {
    "plz": "10369",
    "partner": [
      0
    ]
  },
  {
    "plz": "10405",
    "partner": [
      0
    ]
  },
  {
    "plz": "10407",
    "partner": [
      0
    ]
  },
  {
    "plz": "10409",
    "partner": [
      0
    ]
  },
  {
    "plz": "10435",
    "partner": [
      0
    ]
  },
  {
    "plz": "10437",
    "partner": [
      0
    ]
  },
  {
    "plz": "10439",
    "partner": [
      0
    ]
  },
  {
    "plz": "10551",
    "partner": [
      0
    ]
  },
  {
    "plz": "10553",
    "partner": [
      0
    ]
  },
  {
    "plz": "10555",
    "partner": [
      0
    ]
  },
  {
    "plz": "10557",
    "partner": [
      0
    ]
  },
  {
    "plz": "10559",
    "partner": [
      0
    ]
  },
  {
    "plz": "10585",
    "partner": [
      0
    ]
  },
  {
    "plz": "10587",
    "partner": [
      0
    ]
  },
  {
    "plz": "10589",
    "partner": [
      0
    ]
  },
  {
    "plz": "10623",
    "partner": [
      0
    ]
  },
  {
    "plz": "10625",
    "partner": [
      0
    ]
  },
  {
    "plz": "10627",
    "partner": [
      0
    ]
  },
  {
    "plz": "10629",
    "partner": [
      0
    ]
  },
  {
    "plz": "10707",
    "partner": [
      0
    ]
  },
  {
    "plz": "10709",
    "partner": [
      0
    ]
  },
  {
    "plz": "10711",
    "partner": [
      0
    ]
  },
  {
    "plz": "10713",
    "partner": [
      0
    ]
  },
  {
    "plz": "10715",
    "partner": [
      0
    ]
  },
  {
    "plz": "10717",
    "partner": [
      0
    ]
  },
  {
    "plz": "10719",
    "partner": [
      0
    ]
  },
  {
    "plz": "10777",
    "partner": [
      0
    ]
  },
  {
    "plz": "10779",
    "partner": [
      0
    ]
  },
  {
    "plz": "10781",
    "partner": [
      0
    ]
  },
  {
    "plz": "10783",
    "partner": [
      0
    ]
  },
  {
    "plz": "10785",
    "partner": [
      0
    ]
  },
  {
    "plz": "10787",
    "partner": [
      0
    ]
  },
  {
    "plz": "10789",
    "partner": [
      0
    ]
  },
  {
    "plz": "10823",
    "partner": [
      0
    ]
  },
  {
    "plz": "10825",
    "partner": [
      0
    ]
  },
  {
    "plz": "10827",
    "partner": [
      0
    ]
  },
  {
    "plz": "10829",
    "partner": [
      0
    ]
  },
  {
    "plz": "10961",
    "partner": [
      0
    ]
  },
  {
    "plz": "10963",
    "partner": [
      0
    ]
  },
  {
    "plz": "10965",
    "partner": [
      0
    ]
  },
  {
    "plz": "10967",
    "partner": [
      0
    ]
  },
  {
    "plz": "10969",
    "partner": [
      0
    ]
  },
  {
    "plz": "10997",
    "partner": [
      0
    ]
  },
  {
    "plz": "10999",
    "partner": [
      0
    ]
  },
  {
    "plz": "12043",
    "partner": [
      0
    ]
  },
  {
    "plz": "12045",
    "partner": [
      0
    ]
  },
  {
    "plz": "12047",
    "partner": [
      0
    ]
  },
  {
    "plz": "12049",
    "partner": [
      0
    ]
  },
  {
    "plz": "12051",
    "partner": [
      0
    ]
  },
  {
    "plz": "12053",
    "partner": [
      0
    ]
  },
  {
    "plz": "12055",
    "partner": [
      0
    ]
  },
  {
    "plz": "12057",
    "partner": [
      0
    ]
  },
  {
    "plz": "12059",
    "partner": [
      0
    ]
  },
  {
    "plz": "12099",
    "partner": [
      0
    ]
  },
  {
    "plz": "12101",
    "partner": [
      0
    ]
  },
  {
    "plz": "12103",
    "partner": [
      0
    ]
  },
  {
    "plz": "12105",
    "partner": [
      0
    ]
  },
  {
    "plz": "12107",
    "partner": [
      0
    ]
  },
  {
    "plz": "12109",
    "partner": [
      0
    ]
  },
  {
    "plz": "12157",
    "partner": [
      0
    ]
  },
  {
    "plz": "12159",
    "partner": [
      0
    ]
  },
  {
    "plz": "12161",
    "partner": [
      0
    ]
  },
  {
    "plz": "12163",
    "partner": [
      0
    ]
  },
  {
    "plz": "12165",
    "partner": [
      0
    ]
  },
  {
    "plz": "12167",
    "partner": [
      0
    ]
  },
  {
    "plz": "12169",
    "partner": [
      0
    ]
  },
  {
    "plz": "12203",
    "partner": [
      0
    ]
  },
  {
    "plz": "12205",
    "partner": [
      0
    ]
  },
  {
    "plz": "12207",
    "partner": [
      0
    ]
  },
  {
    "plz": "12209",
    "partner": [
      0
    ]
  },
  {
    "plz": "12247",
    "partner": [
      0
    ]
  },
  {
    "plz": "12249",
    "partner": [
      0
    ]
  },
  {
    "plz": "12277",
    "partner": [
      0
    ]
  },
  {
    "plz": "12279",
    "partner": [
      0
    ]
  },
  {
    "plz": "12305",
    "partner": [
      0
    ]
  },
  {
    "plz": "12307",
    "partner": [
      0
    ]
  },
  {
    "plz": "12309",
    "partner": [
      0
    ]
  },
  {
    "plz": "12347",
    "partner": [
      0
    ]
  },
  {
    "plz": "12349",
    "partner": [
      0
    ]
  },
  {
    "plz": "12351",
    "partner": [
      0
    ]
  },
  {
    "plz": "12353",
    "partner": [
      0
    ]
  },
  {
    "plz": "12355",
    "partner": [
      0
    ]
  },
  {
    "plz": "12357",
    "partner": [
      0
    ]
  },
  {
    "plz": "12359",
    "partner": [
      0
    ]
  },
  {
    "plz": "12435",
    "partner": [
      0
    ]
  },
  {
    "plz": "12437",
    "partner": [
      0
    ]
  },
  {
    "plz": "12439",
    "partner": [
      0
    ]
  },
  {
    "plz": "12459",
    "partner": [
      0
    ]
  },
  {
    "plz": "12487",
    "partner": [
      0
    ]
  },
  {
    "plz": "12489",
    "partner": [
      0
    ]
  },
  {
    "plz": "12524",
    "partner": [
      0
    ]
  },
  {
    "plz": "12526",
    "partner": [
      0
    ]
  },
  {
    "plz": "12527",
    "partner": [
      0
    ]
  },
  {
    "plz": "12529",
    "partner": [
      0
    ]
  },
  {
    "plz": "12555",
    "partner": [
      0
    ]
  },
  {
    "plz": "12557",
    "partner": [
      0
    ]
  },
  {
    "plz": "12559",
    "partner": [
      0
    ]
  },
  {
    "plz": "12587",
    "partner": [
      0
    ]
  },
  {
    "plz": "12589",
    "partner": [
      0
    ]
  },
  {
    "plz": "12619",
    "partner": [
      0
    ]
  },
  {
    "plz": "12621",
    "partner": [
      0
    ]
  },
  {
    "plz": "12623",
    "partner": [
      0
    ]
  },
  {
    "plz": "12627",
    "partner": [
      0
    ]
  },
  {
    "plz": "12629",
    "partner": [
      0
    ]
  },
  {
    "plz": "12679",
    "partner": [
      0
    ]
  },
  {
    "plz": "12681",
    "partner": [
      0
    ]
  },
  {
    "plz": "12683",
    "partner": [
      0
    ]
  },
  {
    "plz": "12685",
    "partner": [
      0
    ]
  },
  {
    "plz": "12687",
    "partner": [
      0
    ]
  },
  {
    "plz": "12689",
    "partner": [
      0
    ]
  },
  {
    "plz": "13051",
    "partner": [
      0
    ]
  },
  {
    "plz": "13053",
    "partner": [
      0
    ]
  },
  {
    "plz": "13055",
    "partner": [
      0
    ]
  },
  {
    "plz": "13057",
    "partner": [
      0
    ]
  },
  {
    "plz": "13059",
    "partner": [
      0
    ]
  },
  {
    "plz": "13086",
    "partner": [
      0
    ]
  },
  {
    "plz": "13088",
    "partner": [
      0
    ]
  },
  {
    "plz": "13089",
    "partner": [
      0
    ]
  },
  {
    "plz": "13125",
    "partner": [
      0
    ]
  },
  {
    "plz": "13127",
    "partner": [
      0
    ]
  },
  {
    "plz": "13129",
    "partner": [
      0
    ]
  },
  {
    "plz": "13156",
    "partner": [
      0
    ]
  },
  {
    "plz": "13158",
    "partner": [
      0
    ]
  },
  {
    "plz": "13159",
    "partner": [
      0
    ]
  },
  {
    "plz": "13187",
    "partner": [
      0
    ]
  },
  {
    "plz": "13189",
    "partner": [
      0
    ]
  },
  {
    "plz": "13347",
    "partner": [
      0
    ]
  },
  {
    "plz": "13349",
    "partner": [
      0
    ]
  },
  {
    "plz": "13351",
    "partner": [
      0
    ]
  },
  {
    "plz": "13353",
    "partner": [
      0
    ]
  },
  {
    "plz": "13355",
    "partner": [
      0
    ]
  },
  {
    "plz": "13357",
    "partner": [
      0
    ]
  },
  {
    "plz": "13359",
    "partner": [
      0
    ]
  },
  {
    "plz": "13403",
    "partner": [
      0
    ]
  },
  {
    "plz": "13405",
    "partner": [
      0
    ]
  },
  {
    "plz": "13407",
    "partner": [
      0
    ]
  },
  {
    "plz": "13409",
    "partner": [
      0
    ]
  },
  {
    "plz": "13435",
    "partner": [
      0
    ]
  },
  {
    "plz": "13437",
    "partner": [
      0
    ]
  },
  {
    "plz": "13439",
    "partner": [
      0
    ]
  },
  {
    "plz": "13465",
    "partner": [
      0
    ]
  },
  {
    "plz": "13467",
    "partner": [
      0
    ]
  },
  {
    "plz": "13469",
    "partner": [
      0
    ]
  },
  {
    "plz": "13503",
    "partner": [
      0
    ]
  },
  {
    "plz": "13505",
    "partner": [
      0
    ]
  },
  {
    "plz": "13507",
    "partner": [
      0
    ]
  },
  {
    "plz": "13509",
    "partner": [
      0
    ]
  },
  {
    "plz": "13581",
    "partner": [
      0
    ]
  },
  {
    "plz": "13583",
    "partner": [
      0
    ]
  },
  {
    "plz": "13585",
    "partner": [
      0
    ]
  },
  {
    "plz": "13587",
    "partner": [
      0
    ]
  },
  {
    "plz": "13589",
    "partner": [
      0
    ]
  },
  {
    "plz": "13591",
    "partner": [
      0
    ]
  },
  {
    "plz": "13593",
    "partner": [
      0
    ]
  },
  {
    "plz": "13595",
    "partner": [
      0
    ]
  },
  {
    "plz": "13597",
    "partner": [
      0
    ]
  },
  {
    "plz": "13599",
    "partner": [
      0
    ]
  },
  {
    "plz": "13627",
    "partner": [
      0
    ]
  },
  {
    "plz": "13629",
    "partner": [
      0
    ]
  },
  {
    "plz": "14050",
    "partner": [
      0
    ]
  },
  {
    "plz": "14052",
    "partner": [
      0
    ]
  },
  {
    "plz": "14053",
    "partner": [
      0
    ]
  },
  {
    "plz": "14055",
    "partner": [
      0
    ]
  },
  {
    "plz": "14057",
    "partner": [
      0
    ]
  },
  {
    "plz": "14059",
    "partner": [
      0
    ]
  },
  {
    "plz": "14089",
    "partner": [
      0
    ]
  },
  {
    "plz": "14109",
    "partner": [
      0
    ]
  },
  {
    "plz": "14129",
    "partner": [
      0
    ]
  },
  {
    "plz": "14163",
    "partner": [
      0
    ]
  },
  {
    "plz": "14165",
    "partner": [
      0
    ]
  },
  {
    "plz": "14167",
    "partner": [
      0
    ]
  },
  {
    "plz": "14169",
    "partner": [
      0
    ]
  },
  {
    "plz": "14193",
    "partner": [
      0
    ]
  },
  {
    "plz": "14195",
    "partner": [
      0
    ]
  },
  {
    "plz": "14197",
    "partner": [
      0
    ]
  },
  {
    "plz": "14199",
    "partner": [
      0
    ]
  },
  {
    "plz": "14467",
    "partner": [
      0
    ]
  },
  {
    "plz": "14469",
    "partner": [
      0
    ]
  },
  {
    "plz": "14471",
    "partner": [
      0
    ]
  },
  {
    "plz": "14473",
    "partner": [
      0
    ]
  },
  {
    "plz": "14476",
    "partner": [
      0
    ]
  },
  {
    "plz": "14478",
    "partner": [
      0
    ]
  },
  {
    "plz": "14480",
    "partner": [
      0
    ]
  },
  {
    "plz": "14482",
    "partner": [
      0
    ]
  },
  {
    "plz": "14513",
    "partner": [
      0
    ]
  },
  {
    "plz": "14532",
    "partner": [
      0
    ]
  },
  {
    "plz": "14532",
    "partner": [
      0
    ]
  },
  {
    "plz": "14542",
    "partner": [
      0
    ]
  },
  {
    "plz": "14547",
    "partner": [
      0
    ]
  },
  {
    "plz": "14548",
    "partner": [
      0
    ]
  },
  {
    "plz": "14550",
    "partner": [
      0
    ]
  },
  {
    "plz": "14552",
    "partner": [
      0
    ]
  },
  {
    "plz": "14554",
    "partner": [
      0
    ]
  },
  {
    "plz": "14558",
    "partner": [
      0
    ]
  },
  {
    "plz": "14612",
    "partner": [
      0
    ]
  },
  {
    "plz": "14621",
    "partner": [
      0
    ]
  },
  {
    "plz": "14624",
    "partner": [
      0
    ]
  },
  {
    "plz": "14641",
    "partner": [
      0
    ]
  },
  {
    "plz": "14641",
    "partner": [
      0
    ]
  },
  {
    "plz": "14641",
    "partner": [
      0
    ]
  },
  {
    "plz": "14641",
    "partner": [
      0
    ]
  },
  {
    "plz": "14641",
    "partner": [
      0
    ]
  },
  {
    "plz": "14656",
    "partner": [
      0
    ]
  },
  {
    "plz": "14662",
    "partner": [
      0
    ]
  },
  {
    "plz": "14662",
    "partner": [
      0
    ]
  },
  {
    "plz": "14662",
    "partner": [
      0
    ]
  },
  {
    "plz": "14669",
    "partner": [
      0
    ]
  },
  {
    "plz": "14712",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14715",
    "partner": [
      0
    ]
  },
  {
    "plz": "14727",
    "partner": [
      0
    ]
  },
  {
    "plz": "14728",
    "partner": [
      0
    ]
  },
  {
    "plz": "14728",
    "partner": [
      0
    ]
  },
  {
    "plz": "14728",
    "partner": [
      0
    ]
  },
  {
    "plz": "14770",
    "partner": [
      0
    ]
  },
  {
    "plz": "14772",
    "partner": [
      0
    ]
  },
  {
    "plz": "14774",
    "partner": [
      0
    ]
  },
  {
    "plz": "14776",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14778",
    "partner": [
      0
    ]
  },
  {
    "plz": "14789",
    "partner": [
      0
    ]
  },
  {
    "plz": "14789",
    "partner": [
      0
    ]
  },
  {
    "plz": "14789",
    "partner": [
      0
    ]
  },
  {
    "plz": "14793",
    "partner": [
      0
    ]
  },
  {
    "plz": "14793",
    "partner": [
      0
    ]
  },
  {
    "plz": "14793",
    "partner": [
      0
    ]
  },
  {
    "plz": "14797",
    "partner": [
      0
    ]
  },
  {
    "plz": "14798",
    "partner": [
      0
    ]
  },
  {
    "plz": "14806",
    "partner": [
      0
    ]
  },
  {
    "plz": "14806",
    "partner": [
      0
    ]
  },
  {
    "plz": "14806",
    "partner": [
      0
    ]
  },
  {
    "plz": "14806",
    "partner": [
      0
    ]
  },
  {
    "plz": "14806",
    "partner": [
      0
    ]
  },
  {
    "plz": "14806",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14822",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14823",
    "partner": [
      0
    ]
  },
  {
    "plz": "14827",
    "partner": [
      0
    ]
  },
  {
    "plz": "14828",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14913",
    "partner": [
      0
    ]
  },
  {
    "plz": "14929",
    "partner": [
      0
    ]
  },
  {
    "plz": "14943",
    "partner": [
      0
    ]
  },
  {
    "plz": "14947",
    "partner": [
      0
    ]
  },
  {
    "plz": "14959",
    "partner": [
      0
    ]
  },
  {
    "plz": "14974",
    "partner": [
      0
    ]
  },
  {
    "plz": "14979",
    "partner": [
      0
    ]
  },
  {
    "plz": "15230",
    "partner": [
      0
    ]
  },
  {
    "plz": "15232",
    "partner": [
      0
    ]
  },
  {
    "plz": "15234",
    "partner": [
      0
    ]
  },
  {
    "plz": "15236",
    "partner": [
      0
    ]
  },
  {
    "plz": "15236",
    "partner": [
      0
    ]
  },
  {
    "plz": "15236",
    "partner": [
      0
    ]
  },
  {
    "plz": "15295",
    "partner": [
      0
    ]
  },
  {
    "plz": "15295",
    "partner": [
      0
    ]
  },
  {
    "plz": "15295",
    "partner": [
      0
    ]
  },
  {
    "plz": "15295",
    "partner": [
      0
    ]
  },
  {
    "plz": "15299",
    "partner": [
      0
    ]
  },
  {
    "plz": "15299",
    "partner": [
      0
    ]
  },
  {
    "plz": "15299",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15306",
    "partner": [
      0
    ]
  },
  {
    "plz": "15320",
    "partner": [
      0
    ]
  },
  {
    "plz": "15320",
    "partner": [
      0
    ]
  },
  {
    "plz": "15324",
    "partner": [
      0
    ]
  },
  {
    "plz": "15326",
    "partner": [
      0
    ]
  },
  {
    "plz": "15326",
    "partner": [
      0
    ]
  },
  {
    "plz": "15326",
    "partner": [
      0
    ]
  },
  {
    "plz": "15326",
    "partner": [
      0
    ]
  },
  {
    "plz": "15326",
    "partner": [
      0
    ]
  },
  {
    "plz": "15326",
    "partner": [
      0
    ]
  },
  {
    "plz": "15328",
    "partner": [
      0
    ]
  },
  {
    "plz": "15328",
    "partner": [
      0
    ]
  },
  {
    "plz": "15328",
    "partner": [
      0
    ]
  },
  {
    "plz": "15328",
    "partner": [
      0
    ]
  },
  {
    "plz": "15328",
    "partner": [
      0
    ]
  },
  {
    "plz": "15328",
    "partner": [
      0
    ]
  },
  {
    "plz": "15344",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15345",
    "partner": [
      0
    ]
  },
  {
    "plz": "15366",
    "partner": [
      0
    ]
  },
  {
    "plz": "15366",
    "partner": [
      0
    ]
  },
  {
    "plz": "15370",
    "partner": [
      0
    ]
  },
  {
    "plz": "15370",
    "partner": [
      0
    ]
  },
  {
    "plz": "15374",
    "partner": [
      0
    ]
  },
  {
    "plz": "15377",
    "partner": [
      0
    ]
  },
  {
    "plz": "15377",
    "partner": [
      0
    ]
  },
  {
    "plz": "15377",
    "partner": [
      0
    ]
  },
  {
    "plz": "15377",
    "partner": [
      0
    ]
  },
  {
    "plz": "15378",
    "partner": [
      0
    ]
  },
  {
    "plz": "15378",
    "partner": [
      0
    ]
  },
  {
    "plz": "15517",
    "partner": [
      0
    ]
  },
  {
    "plz": "15518",
    "partner": [
      0
    ]
  },
  {
    "plz": "15518",
    "partner": [
      0
    ]
  },
  {
    "plz": "15518",
    "partner": [
      0
    ]
  },
  {
    "plz": "15518",
    "partner": [
      0
    ]
  },
  {
    "plz": "15518",
    "partner": [
      0
    ]
  },
  {
    "plz": "15526",
    "partner": [
      0
    ]
  },
  {
    "plz": "15526",
    "partner": [
      0
    ]
  },
  {
    "plz": "15528",
    "partner": [
      0
    ]
  },
  {
    "plz": "15537",
    "partner": [
      0
    ]
  },
  {
    "plz": "15537",
    "partner": [
      0
    ]
  },
  {
    "plz": "15537",
    "partner": [
      0
    ]
  },
  {
    "plz": "15562",
    "partner": [
      0
    ]
  },
  {
    "plz": "15566",
    "partner": [
      0
    ]
  },
  {
    "plz": "15569",
    "partner": [
      0
    ]
  },
  {
    "plz": "15711",
    "partner": [
      0
    ]
  },
  {
    "plz": "15712",
    "partner": [
      0
    ]
  },
  {
    "plz": "15713",
    "partner": [
      0
    ]
  },
  {
    "plz": "15732",
    "partner": [
      0
    ]
  },
  {
    "plz": "15732",
    "partner": [
      0
    ]
  },
  {
    "plz": "15738",
    "partner": [
      0
    ]
  },
  {
    "plz": "15741",
    "partner": [
      0
    ]
  },
  {
    "plz": "15745",
    "partner": [
      0
    ]
  },
  {
    "plz": "15746",
    "partner": [
      0
    ]
  },
  {
    "plz": "15748",
    "partner": [
      0
    ]
  },
  {
    "plz": "15748",
    "partner": [
      0
    ]
  },
  {
    "plz": "15749",
    "partner": [
      0
    ]
  },
  {
    "plz": "15754",
    "partner": [
      0
    ]
  },
  {
    "plz": "15755",
    "partner": [
      0
    ]
  },
  {
    "plz": "15755",
    "partner": [
      0
    ]
  },
  {
    "plz": "15757",
    "partner": [
      0
    ]
  },
  {
    "plz": "15806",
    "partner": [
      0
    ]
  },
  {
    "plz": "15827",
    "partner": [
      0
    ]
  },
  {
    "plz": "15831",
    "partner": [
      0
    ]
  },
  {
    "plz": "15834",
    "partner": [
      0
    ]
  },
  {
    "plz": "15837",
    "partner": [
      0
    ]
  },
  {
    "plz": "15838",
    "partner": [
      0
    ]
  },
  {
    "plz": "15848",
    "partner": [
      0
    ]
  },
  {
    "plz": "15848",
    "partner": [
      0
    ]
  },
  {
    "plz": "15848",
    "partner": [
      0
    ]
  },
  {
    "plz": "15848",
    "partner": [
      0
    ]
  },
  {
    "plz": "15848",
    "partner": [
      0
    ]
  },
  {
    "plz": "15859",
    "partner": [
      0
    ]
  },
  {
    "plz": "15864",
    "partner": [
      0
    ]
  },
  {
    "plz": "15864",
    "partner": [
      0
    ]
  },
  {
    "plz": "15868",
    "partner": [
      0
    ]
  },
  {
    "plz": "15868",
    "partner": [
      0
    ]
  },
  {
    "plz": "15890",
    "partner": [
      0
    ]
  },
  {
    "plz": "15890",
    "partner": [
      0
    ]
  },
  {
    "plz": "15890",
    "partner": [
      0
    ]
  },
  {
    "plz": "15890",
    "partner": [
      0
    ]
  },
  {
    "plz": "15898",
    "partner": [
      0
    ]
  },
  {
    "plz": "15898",
    "partner": [
      0
    ]
  },
  {
    "plz": "15898",
    "partner": [
      0
    ]
  },
  {
    "plz": "15907",
    "partner": [
      0
    ]
  },
  {
    "plz": "15910",
    "partner": [
      0
    ]
  },
  {
    "plz": "15910",
    "partner": [
      0
    ]
  },
  {
    "plz": "15910",
    "partner": [
      0
    ]
  },
  {
    "plz": "15910",
    "partner": [
      0
    ]
  },
  {
    "plz": "15910",
    "partner": [
      0
    ]
  },
  {
    "plz": "15910",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15913",
    "partner": [
      0
    ]
  },
  {
    "plz": "15926",
    "partner": [
      0
    ]
  },
  {
    "plz": "15926",
    "partner": [
      0
    ]
  },
  {
    "plz": "15936",
    "partner": [
      0
    ]
  },
  {
    "plz": "15936",
    "partner": [
      0
    ]
  },
  {
    "plz": "15936",
    "partner": [
      0
    ]
  },
  {
    "plz": "15938",
    "partner": [
      0
    ]
  },
  {
    "plz": "15938",
    "partner": [
      0
    ]
  },
  {
    "plz": "15938",
    "partner": [
      0
    ]
  },
  {
    "plz": "15938",
    "partner": [
      0
    ]
  },
  {
    "plz": "16225",
    "partner": [
      0
    ]
  },
  {
    "plz": "16227",
    "partner": [
      0
    ]
  },
  {
    "plz": "16230",
    "partner": [
      0
    ]
  },
  {
    "plz": "16230",
    "partner": [
      0
    ]
  },
  {
    "plz": "16230",
    "partner": [
      0
    ]
  },
  {
    "plz": "16230",
    "partner": [
      0
    ]
  },
  {
    "plz": "16230",
    "partner": [
      0
    ]
  },
  {
    "plz": "16244",
    "partner": [
      0
    ]
  },
  {
    "plz": "16247",
    "partner": [
      0
    ]
  },
  {
    "plz": "16247",
    "partner": [
      0
    ]
  },
  {
    "plz": "16247",
    "partner": [
      0
    ]
  },
  {
    "plz": "16247",
    "partner": [
      0
    ]
  },
  {
    "plz": "16248",
    "partner": [
      0
    ]
  },
  {
    "plz": "16248",
    "partner": [
      0
    ]
  },
  {
    "plz": "16248",
    "partner": [
      0
    ]
  },
  {
    "plz": "16248",
    "partner": [
      0
    ]
  },
  {
    "plz": "16248",
    "partner": [
      0
    ]
  },
  {
    "plz": "16248",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16259",
    "partner": [
      0
    ]
  },
  {
    "plz": "16269",
    "partner": [
      0
    ]
  },
  {
    "plz": "16269",
    "partner": [
      0
    ]
  },
  {
    "plz": "16278",
    "partner": [
      0
    ]
  },
  {
    "plz": "16278",
    "partner": [
      0
    ]
  },
  {
    "plz": "16278",
    "partner": [
      0
    ]
  },
  {
    "plz": "16278",
    "partner": [
      0
    ]
  },
  {
    "plz": "16303",
    "partner": [
      0
    ]
  },
  {
    "plz": "16306",
    "partner": [
      0
    ]
  },
  {
    "plz": "16306",
    "partner": [
      0
    ]
  },
  {
    "plz": "16306",
    "partner": [
      0
    ]
  },
  {
    "plz": "16306",
    "partner": [
      0
    ]
  },
  {
    "plz": "16306",
    "partner": [
      0
    ]
  },
  {
    "plz": "16307",
    "partner": [
      0
    ]
  },
  {
    "plz": "16307",
    "partner": [
      0
    ]
  },
  {
    "plz": "16307",
    "partner": [
      0
    ]
  },
  {
    "plz": "16321",
    "partner": [
      0
    ]
  },
  {
    "plz": "16321",
    "partner": [
      0
    ]
  },
  {
    "plz": "16341",
    "partner": [
      0
    ]
  },
  {
    "plz": "16348",
    "partner": [
      0
    ]
  },
  {
    "plz": "16348",
    "partner": [
      0
    ]
  },
  {
    "plz": "16356",
    "partner": [
      0
    ]
  },
  {
    "plz": "16356",
    "partner": [
      0
    ]
  },
  {
    "plz": "16359",
    "partner": [
      0
    ]
  },
  {
    "plz": "16515",
    "partner": [
      0
    ]
  },
  {
    "plz": "16515",
    "partner": [
      0
    ]
  },
  {
    "plz": "16540",
    "partner": [
      0
    ]
  },
  {
    "plz": "16547",
    "partner": [
      0
    ]
  },
  {
    "plz": "16548",
    "partner": [
      0
    ]
  },
  {
    "plz": "16552",
    "partner": [
      0
    ]
  },
  {
    "plz": "16556",
    "partner": [
      0
    ]
  },
  {
    "plz": "16559",
    "partner": [
      0
    ]
  },
  {
    "plz": "16562",
    "partner": [
      0
    ]
  },
  {
    "plz": "16567",
    "partner": [
      0
    ]
  },
  {
    "plz": "16567",
    "partner": [
      0
    ]
  },
  {
    "plz": "16727",
    "partner": [
      0
    ]
  },
  {
    "plz": "16727",
    "partner": [
      0
    ]
  },
  {
    "plz": "16761",
    "partner": [
      0
    ]
  },
  {
    "plz": "16766",
    "partner": [
      0
    ]
  },
  {
    "plz": "16767",
    "partner": [
      0
    ]
  },
  {
    "plz": "16775",
    "partner": [
      0
    ]
  },
  {
    "plz": "16775",
    "partner": [
      0
    ]
  },
  {
    "plz": "16775",
    "partner": [
      0
    ]
  },
  {
    "plz": "16775",
    "partner": [
      0
    ]
  },
  {
    "plz": "16775",
    "partner": [
      0
    ]
  },
  {
    "plz": "16775",
    "partner": [
      0
    ]
  },
  {
    "plz": "16792",
    "partner": [
      0
    ]
  },
  {
    "plz": "16798",
    "partner": [
      0
    ]
  },
  {
    "plz": "16816",
    "partner": [
      0
    ]
  },
  {
    "plz": "16816",
    "partner": [
      0
    ]
  },
  {
    "plz": "16816",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16818",
    "partner": [
      0
    ]
  },
  {
    "plz": "16827",
    "partner": [
      0
    ]
  },
  {
    "plz": "16827",
    "partner": [
      0
    ]
  },
  {
    "plz": "16827",
    "partner": [
      0
    ]
  },
  {
    "plz": "16827",
    "partner": [
      0
    ]
  },
  {
    "plz": "16827",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16831",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16833",
    "partner": [
      0
    ]
  },
  {
    "plz": "16835",
    "partner": [
      0
    ]
  },
  {
    "plz": "16835",
    "partner": [
      0
    ]
  },
  {
    "plz": "16835",
    "partner": [
      0
    ]
  },
  {
    "plz": "16835",
    "partner": [
      0
    ]
  },
  {
    "plz": "16835",
    "partner": [
      0
    ]
  },
  {
    "plz": "16835",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16837",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16845",
    "partner": [
      0
    ]
  },
  {
    "plz": "16866",
    "partner": [
      0
    ]
  },
  {
    "plz": "16866",
    "partner": [
      0
    ]
  },
  {
    "plz": "16866",
    "partner": [
      0
    ]
  },
  {
    "plz": "16866",
    "partner": [
      0
    ]
  },
  {
    "plz": "16866",
    "partner": [
      0
    ]
  },
  {
    "plz": "16868",
    "partner": [
      0
    ]
  },
  {
    "plz": "16868",
    "partner": [
      0
    ]
  },
  {
    "plz": "16909",
    "partner": [
      0
    ]
  },
  {
    "plz": "16909",
    "partner": [
      0
    ]
  },
  {
    "plz": "16928",
    "partner": [
      0
    ]
  },
  {
    "plz": "16928",
    "partner": [
      0
    ]
  },
  {
    "plz": "16928",
    "partner": [
      0
    ]
  },
  {
    "plz": "16945",
    "partner": [
      0
    ]
  },
  {
    "plz": "16945",
    "partner": [
      0
    ]
  },
  {
    "plz": "16945",
    "partner": [
      0
    ]
  },
  {
    "plz": "16945",
    "partner": [
      0
    ]
  },
  {
    "plz": "16949",
    "partner": [
      0
    ]
  },
  {
    "plz": "16949",
    "partner": [
      0
    ]
  },
  {
    "plz": "17033",
    "partner": [
      0
    ]
  },
  {
    "plz": "17034",
    "partner": [
      0
    ]
  },
  {
    "plz": "17036",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17039",
    "partner": [
      0
    ]
  },
  {
    "plz": "17087",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17089",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17091",
    "partner": [
      0
    ]
  },
  {
    "plz": "17094",
    "partner": [
      0
    ]
  },
  {
    "plz": "17094",
    "partner": [
      0
    ]
  },
  {
    "plz": "17094",
    "partner": [
      0
    ]
  },
  {
    "plz": "17094",
    "partner": [
      0
    ]
  },
  {
    "plz": "17094",
    "partner": [
      0
    ]
  },
  {
    "plz": "17098",
    "partner": [
      0
    ]
  },
  {
    "plz": "17099",
    "partner": [
      0
    ]
  },
  {
    "plz": "17099",
    "partner": [
      0
    ]
  },
  {
    "plz": "17109",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17111",
    "partner": [
      0
    ]
  },
  {
    "plz": "17121",
    "partner": [
      0
    ]
  },
  {
    "plz": "17121",
    "partner": [
      0
    ]
  },
  {
    "plz": "17121",
    "partner": [
      0
    ]
  },
  {
    "plz": "17126",
    "partner": [
      0
    ]
  },
  {
    "plz": "17129",
    "partner": [
      0
    ]
  },
  {
    "plz": "17129",
    "partner": [
      0
    ]
  },
  {
    "plz": "17129",
    "partner": [
      0
    ]
  },
  {
    "plz": "17129",
    "partner": [
      0
    ]
  },
  {
    "plz": "17129",
    "partner": [
      0
    ]
  },
  {
    "plz": "17129",
    "partner": [
      0
    ]
  },
  {
    "plz": "17139",
    "partner": [
      0
    ]
  },
  {
    "plz": "17139",
    "partner": [
      0
    ]
  },
  {
    "plz": "17139",
    "partner": [
      0
    ]
  },
  {
    "plz": "17139",
    "partner": [
      0
    ]
  },
  {
    "plz": "17139",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17153",
    "partner": [
      0
    ]
  },
  {
    "plz": "17154",
    "partner": [
      0
    ]
  },
  {
    "plz": "17159",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17166",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17168",
    "partner": [
      0
    ]
  },
  {
    "plz": "17179",
    "partner": [
      0
    ]
  },
  {
    "plz": "17179",
    "partner": [
      0
    ]
  },
  {
    "plz": "17179",
    "partner": [
      0
    ]
  },
  {
    "plz": "17179",
    "partner": [
      0
    ]
  },
  {
    "plz": "17179",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17192",
    "partner": [
      0
    ]
  },
  {
    "plz": "17194",
    "partner": [
      0
    ]
  },
  {
    "plz": "17194",
    "partner": [
      0
    ]
  },
  {
    "plz": "17194",
    "partner": [
      0
    ]
  },
  {
    "plz": "17194",
    "partner": [
      0
    ]
  },
  {
    "plz": "17194",
    "partner": [
      0
    ]
  },
  {
    "plz": "17194",
    "partner": [
      0
    ]
  },
  {
    "plz": "17207",
    "partner": [
      0
    ]
  },
  {
    "plz": "17207",
    "partner": [
      0
    ]
  },
  {
    "plz": "17207",
    "partner": [
      0
    ]
  },
  {
    "plz": "17207",
    "partner": [
      0
    ]
  },
  {
    "plz": "17207",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17209",
    "partner": [
      0
    ]
  },
  {
    "plz": "17213",
    "partner": [
      0
    ]
  },
  {
    "plz": "17213",
    "partner": [
      0
    ]
  },
  {
    "plz": "17213",
    "partner": [
      0
    ]
  },
  {
    "plz": "17213",
    "partner": [
      0
    ]
  },
  {
    "plz": "17214",
    "partner": [
      0
    ]
  },
  {
    "plz": "17214",
    "partner": [
      0
    ]
  },
  {
    "plz": "17214",
    "partner": [
      0
    ]
  },
  {
    "plz": "17217",
    "partner": [
      0
    ]
  },
  {
    "plz": "17217",
    "partner": [
      0
    ]
  },
  {
    "plz": "17219",
    "partner": [
      0
    ]
  },
  {
    "plz": "17219",
    "partner": [
      0
    ]
  },
  {
    "plz": "17219",
    "partner": [
      0
    ]
  },
  {
    "plz": "17235",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17237",
    "partner": [
      0
    ]
  },
  {
    "plz": "17248",
    "partner": [
      0
    ]
  },
  {
    "plz": "17248",
    "partner": [
      0
    ]
  },
  {
    "plz": "17252",
    "partner": [
      0
    ]
  },
  {
    "plz": "17252",
    "partner": [
      0
    ]
  },
  {
    "plz": "17255",
    "partner": [
      0
    ]
  },
  {
    "plz": "17255",
    "partner": [
      0
    ]
  },
  {
    "plz": "17255",
    "partner": [
      0
    ]
  },
  {
    "plz": "17258",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17268",
    "partner": [
      0
    ]
  },
  {
    "plz": "17279",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17291",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17309",
    "partner": [
      0
    ]
  },
  {
    "plz": "17321",
    "partner": [
      0
    ]
  },
  {
    "plz": "17321",
    "partner": [
      0
    ]
  },
  {
    "plz": "17321",
    "partner": [
      0
    ]
  },
  {
    "plz": "17321",
    "partner": [
      0
    ]
  },
  {
    "plz": "17321",
    "partner": [
      0
    ]
  },
  {
    "plz": "17322",
    "partner": [
      0
    ]
  },
  {
    "plz": "17322",
    "partner": [
      0
    ]
  },
  {
    "plz": "17322",
    "partner": [
      0
    ]
  },
  {
    "plz": "17322",
    "partner": [
      0
    ]
  },
  {
    "plz": "17322",
    "partner": [
      0
    ]
  },
  {
    "plz": "17326",
    "partner": [
      0
    ]
  },
  {
    "plz": "17328",
    "partner": [
      0
    ]
  },
  {
    "plz": "17329",
    "partner": [
      0
    ]
  },
  {
    "plz": "17329",
    "partner": [
      0
    ]
  },
  {
    "plz": "17335",
    "partner": [
      0
    ]
  },
  {
    "plz": "17337",
    "partner": [
      0
    ]
  },
  {
    "plz": "17337",
    "partner": [
      0
    ]
  },
  {
    "plz": "17337",
    "partner": [
      0
    ]
  },
  {
    "plz": "17348",
    "partner": [
      0
    ]
  },
  {
    "plz": "17349",
    "partner": [
      0
    ]
  },
  {
    "plz": "17349",
    "partner": [
      0
    ]
  },
  {
    "plz": "17349",
    "partner": [
      0
    ]
  },
  {
    "plz": "17349",
    "partner": [
      0
    ]
  },
  {
    "plz": "17349",
    "partner": [
      0
    ]
  },
  {
    "plz": "17349",
    "partner": [
      0
    ]
  },
  {
    "plz": "17358",
    "partner": [
      0
    ]
  },
  {
    "plz": "17358",
    "partner": [
      0
    ]
  },
  {
    "plz": "17367",
    "partner": [
      0
    ]
  },
  {
    "plz": "17373",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17375",
    "partner": [
      0
    ]
  },
  {
    "plz": "17379",
    "partner": [
      0
    ]
  },
  {
    "plz": "17379",
    "partner": [
      0
    ]
  },
  {
    "plz": "17379",
    "partner": [
      0
    ]
  },
  {
    "plz": "17379",
    "partner": [
      0
    ]
  },
  {
    "plz": "17379",
    "partner": [
      0
    ]
  },
  {
    "plz": "17379",
    "partner": [
      0
    ]
  },
  {
    "plz": "17389",
    "partner": [
      0
    ]
  },
  {
    "plz": "17390",
    "partner": [
      0
    ]
  },
  {
    "plz": "17390",
    "partner": [
      0
    ]
  },
  {
    "plz": "17390",
    "partner": [
      0
    ]
  },
  {
    "plz": "17390",
    "partner": [
      0
    ]
  },
  {
    "plz": "17390",
    "partner": [
      0
    ]
  },
  {
    "plz": "17390",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17391",
    "partner": [
      0
    ]
  },
  {
    "plz": "17392",
    "partner": [
      0
    ]
  },
  {
    "plz": "17392",
    "partner": [
      0
    ]
  },
  {
    "plz": "17392",
    "partner": [
      0
    ]
  },
  {
    "plz": "17392",
    "partner": [
      0
    ]
  },
  {
    "plz": "17392",
    "partner": [
      0
    ]
  },
  {
    "plz": "17392",
    "partner": [
      0
    ]
  },
  {
    "plz": "17398",
    "partner": [
      0
    ]
  },
  {
    "plz": "17398",
    "partner": [
      0
    ]
  },
  {
    "plz": "17398",
    "partner": [
      0
    ]
  },
  {
    "plz": "17398",
    "partner": [
      0
    ]
  },
  {
    "plz": "17398",
    "partner": [
      0
    ]
  },
  {
    "plz": "17406",
    "partner": [
      0
    ]
  },
  {
    "plz": "17406",
    "partner": [
      0
    ]
  },
  {
    "plz": "17406",
    "partner": [
      0
    ]
  },
  {
    "plz": "17419",
    "partner": [
      0
    ]
  },
  {
    "plz": "17419",
    "partner": [
      0
    ]
  },
  {
    "plz": "17419",
    "partner": [
      0
    ]
  },
  {
    "plz": "17419",
    "partner": [
      0
    ]
  },
  {
    "plz": "17419",
    "partner": [
      0
    ]
  },
  {
    "plz": "17419",
    "partner": [
      0
    ]
  },
  {
    "plz": "17424",
    "partner": [
      0
    ]
  },
  {
    "plz": "17429",
    "partner": [
      0
    ]
  },
  {
    "plz": "17429",
    "partner": [
      0
    ]
  },
  {
    "plz": "17429",
    "partner": [
      0
    ]
  },
  {
    "plz": "17429",
    "partner": [
      0
    ]
  },
  {
    "plz": "17429",
    "partner": [
      0
    ]
  },
  {
    "plz": "17438",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17440",
    "partner": [
      0
    ]
  },
  {
    "plz": "17449",
    "partner": [
      0
    ]
  },
  {
    "plz": "17449",
    "partner": [
      0
    ]
  },
  {
    "plz": "17449",
    "partner": [
      0
    ]
  },
  {
    "plz": "17449",
    "partner": [
      0
    ]
  },
  {
    "plz": "17454",
    "partner": [
      0
    ]
  },
  {
    "plz": "17459",
    "partner": [
      0
    ]
  },
  {
    "plz": "17459",
    "partner": [
      0
    ]
  },
  {
    "plz": "17459",
    "partner": [
      0
    ]
  },
  {
    "plz": "17459",
    "partner": [
      0
    ]
  },
  {
    "plz": "17489",
    "partner": [
      0
    ]
  },
  {
    "plz": "17491",
    "partner": [
      0
    ]
  },
  {
    "plz": "17493",
    "partner": [
      0
    ]
  },
  {
    "plz": "17495",
    "partner": [
      0
    ]
  },
  {
    "plz": "17495",
    "partner": [
      0
    ]
  },
  {
    "plz": "17495",
    "partner": [
      0
    ]
  },
  {
    "plz": "17495",
    "partner": [
      0
    ]
  },
  {
    "plz": "17495",
    "partner": [
      0
    ]
  },
  {
    "plz": "17495",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17498",
    "partner": [
      0
    ]
  },
  {
    "plz": "17506",
    "partner": [
      0
    ]
  },
  {
    "plz": "17506",
    "partner": [
      0
    ]
  },
  {
    "plz": "17506",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "17509",
    "partner": [
      0
    ]
  },
  {
    "plz": "18055",
    "partner": [
      0
    ]
  },
  {
    "plz": "18057",
    "partner": [
      0
    ]
  },
  {
    "plz": "18059",
    "partner": [
      0
    ]
  },
  {
    "plz": "18059",
    "partner": [
      0
    ]
  },
  {
    "plz": "18059",
    "partner": [
      0
    ]
  },
  {
    "plz": "18059",
    "partner": [
      0
    ]
  },
  {
    "plz": "18069",
    "partner": [
      0
    ]
  },
  {
    "plz": "18069",
    "partner": [
      0
    ]
  },
  {
    "plz": "18106",
    "partner": [
      0
    ]
  },
  {
    "plz": "18107",
    "partner": [
      0
    ]
  },
  {
    "plz": "18107",
    "partner": [
      0
    ]
  },
  {
    "plz": "18109",
    "partner": [
      0
    ]
  },
  {
    "plz": "18119",
    "partner": [
      0
    ]
  },
  {
    "plz": "18146",
    "partner": [
      0
    ]
  },
  {
    "plz": "18147",
    "partner": [
      0
    ]
  },
  {
    "plz": "18181",
    "partner": [
      0
    ]
  },
  {
    "plz": "18181",
    "partner": [
      0
    ]
  },
  {
    "plz": "18182",
    "partner": [
      0
    ]
  },
  {
    "plz": "18182",
    "partner": [
      0
    ]
  },
  {
    "plz": "18182",
    "partner": [
      0
    ]
  },
  {
    "plz": "18182",
    "partner": [
      0
    ]
  },
  {
    "plz": "18182",
    "partner": [
      0
    ]
  },
  {
    "plz": "18182",
    "partner": [
      0
    ]
  },
  {
    "plz": "18184",
    "partner": [
      0
    ]
  },
  {
    "plz": "18184",
    "partner": [
      0
    ]
  },
  {
    "plz": "18184",
    "partner": [
      0
    ]
  },
  {
    "plz": "18184",
    "partner": [
      0
    ]
  },
  {
    "plz": "18190",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18195",
    "partner": [
      0
    ]
  },
  {
    "plz": "18196",
    "partner": [
      0
    ]
  },
  {
    "plz": "18198",
    "partner": [
      0
    ]
  },
  {
    "plz": "18198",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18209",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18211",
    "partner": [
      0
    ]
  },
  {
    "plz": "18225",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18230",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18233",
    "partner": [
      0
    ]
  },
  {
    "plz": "18236",
    "partner": [
      0
    ]
  },
  {
    "plz": "18236",
    "partner": [
      0
    ]
  },
  {
    "plz": "18236",
    "partner": [
      0
    ]
  },
  {
    "plz": "18236",
    "partner": [
      0
    ]
  },
  {
    "plz": "18239",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18246",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18249",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18258",
    "partner": [
      0
    ]
  },
  {
    "plz": "18273",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18276",
    "partner": [
      0
    ]
  },
  {
    "plz": "18279",
    "partner": [
      0
    ]
  },
  {
    "plz": "18292",
    "partner": [
      0
    ]
  },
  {
    "plz": "18292",
    "partner": [
      0
    ]
  },
  {
    "plz": "18292",
    "partner": [
      0
    ]
  },
  {
    "plz": "18292",
    "partner": [
      0
    ]
  },
  {
    "plz": "18299",
    "partner": [
      0
    ]
  },
  {
    "plz": "18299",
    "partner": [
      0
    ]
  },
  {
    "plz": "18299",
    "partner": [
      0
    ]
  },
  {
    "plz": "18299",
    "partner": [
      0
    ]
  },
  {
    "plz": "18311",
    "partner": [
      0
    ]
  },
  {
    "plz": "18314",
    "partner": [
      0
    ]
  },
  {
    "plz": "18314",
    "partner": [
      0
    ]
  },
  {
    "plz": "18314",
    "partner": [
      0
    ]
  },
  {
    "plz": "18314",
    "partner": [
      0
    ]
  },
  {
    "plz": "18317",
    "partner": [
      0
    ]
  },
  {
    "plz": "18320",
    "partner": [
      0
    ]
  },
  {
    "plz": "18320",
    "partner": [
      0
    ]
  },
  {
    "plz": "18320",
    "partner": [
      0
    ]
  },
  {
    "plz": "18334",
    "partner": [
      0
    ]
  },
  {
    "plz": "18334",
    "partner": [
      0
    ]
  },
  {
    "plz": "18334",
    "partner": [
      0
    ]
  },
  {
    "plz": "18334",
    "partner": [
      0
    ]
  },
  {
    "plz": "18334",
    "partner": [
      0
    ]
  },
  {
    "plz": "18337",
    "partner": [
      0
    ]
  },
  {
    "plz": "18347",
    "partner": [
      0
    ]
  },
  {
    "plz": "18347",
    "partner": [
      0
    ]
  },
  {
    "plz": "18347",
    "partner": [
      0
    ]
  },
  {
    "plz": "18356",
    "partner": [
      0
    ]
  },
  {
    "plz": "18356",
    "partner": [
      0
    ]
  },
  {
    "plz": "18356",
    "partner": [
      0
    ]
  },
  {
    "plz": "18374",
    "partner": [
      0
    ]
  },
  {
    "plz": "18375",
    "partner": [
      0
    ]
  },
  {
    "plz": "18375",
    "partner": [
      0
    ]
  },
  {
    "plz": "18375",
    "partner": [
      0
    ]
  },
  {
    "plz": "18435",
    "partner": [
      0
    ]
  },
  {
    "plz": "18437",
    "partner": [
      0
    ]
  },
  {
    "plz": "18439",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18442",
    "partner": [
      0
    ]
  },
  {
    "plz": "18445",
    "partner": [
      0
    ]
  },
  {
    "plz": "18445",
    "partner": [
      0
    ]
  },
  {
    "plz": "18445",
    "partner": [
      0
    ]
  },
  {
    "plz": "18445",
    "partner": [
      0
    ]
  },
  {
    "plz": "18445",
    "partner": [
      0
    ]
  },
  {
    "plz": "18445",
    "partner": [
      0
    ]
  },
  {
    "plz": "18461",
    "partner": [
      0
    ]
  },
  {
    "plz": "18461",
    "partner": [
      0
    ]
  },
  {
    "plz": "18461",
    "partner": [
      0
    ]
  },
  {
    "plz": "18461",
    "partner": [
      0
    ]
  },
  {
    "plz": "18461",
    "partner": [
      0
    ]
  },
  {
    "plz": "18465",
    "partner": [
      0
    ]
  },
  {
    "plz": "18465",
    "partner": [
      0
    ]
  },
  {
    "plz": "18465",
    "partner": [
      0
    ]
  },
  {
    "plz": "18469",
    "partner": [
      0
    ]
  },
  {
    "plz": "18469",
    "partner": [
      0
    ]
  },
  {
    "plz": "18507",
    "partner": [
      0
    ]
  },
  {
    "plz": "18510",
    "partner": [
      0
    ]
  },
  {
    "plz": "18510",
    "partner": [
      0
    ]
  },
  {
    "plz": "18510",
    "partner": [
      0
    ]
  },
  {
    "plz": "18510",
    "partner": [
      0
    ]
  },
  {
    "plz": "18513",
    "partner": [
      0
    ]
  },
  {
    "plz": "18513",
    "partner": [
      0
    ]
  },
  {
    "plz": "18513",
    "partner": [
      0
    ]
  },
  {
    "plz": "18513",
    "partner": [
      0
    ]
  },
  {
    "plz": "18513",
    "partner": [
      0
    ]
  },
  {
    "plz": "18513",
    "partner": [
      0
    ]
  },
  {
    "plz": "18516",
    "partner": [
      0
    ]
  },
  {
    "plz": "18519",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18528",
    "partner": [
      0
    ]
  },
  {
    "plz": "18546",
    "partner": [
      0
    ]
  },
  {
    "plz": "18551",
    "partner": [
      0
    ]
  },
  {
    "plz": "18551",
    "partner": [
      0
    ]
  },
  {
    "plz": "18551",
    "partner": [
      0
    ]
  },
  {
    "plz": "18556",
    "partner": [
      0
    ]
  },
  {
    "plz": "18556",
    "partner": [
      0
    ]
  },
  {
    "plz": "18556",
    "partner": [
      0
    ]
  },
  {
    "plz": "18556",
    "partner": [
      0
    ]
  },
  {
    "plz": "18556",
    "partner": [
      0
    ]
  },
  {
    "plz": "18565",
    "partner": [
      0
    ]
  },
  {
    "plz": "18569",
    "partner": [
      0
    ]
  },
  {
    "plz": "18569",
    "partner": [
      0
    ]
  },
  {
    "plz": "18569",
    "partner": [
      0
    ]
  },
  {
    "plz": "18569",
    "partner": [
      0
    ]
  },
  {
    "plz": "18569",
    "partner": [
      0
    ]
  },
  {
    "plz": "18569",
    "partner": [
      0
    ]
  },
  {
    "plz": "18573",
    "partner": [
      0
    ]
  },
  {
    "plz": "18573",
    "partner": [
      0
    ]
  },
  {
    "plz": "18573",
    "partner": [
      0
    ]
  },
  {
    "plz": "18573",
    "partner": [
      0
    ]
  },
  {
    "plz": "18574",
    "partner": [
      0
    ]
  },
  {
    "plz": "18574",
    "partner": [
      0
    ]
  },
  {
    "plz": "18574",
    "partner": [
      0
    ]
  },
  {
    "plz": "18581",
    "partner": [
      0
    ]
  },
  {
    "plz": "18586",
    "partner": [
      0
    ]
  },
  {
    "plz": "18586",
    "partner": [
      0
    ]
  },
  {
    "plz": "18586",
    "partner": [
      0
    ]
  },
  {
    "plz": "18586",
    "partner": [
      0
    ]
  },
  {
    "plz": "18586",
    "partner": [
      0
    ]
  },
  {
    "plz": "18609",
    "partner": [
      0
    ]
  },
  {
    "plz": "19053",
    "partner": [
      0
    ]
  },
  {
    "plz": "19055",
    "partner": [
      0
    ]
  },
  {
    "plz": "19057",
    "partner": [
      0
    ]
  },
  {
    "plz": "19059",
    "partner": [
      0
    ]
  },
  {
    "plz": "19061",
    "partner": [
      0
    ]
  },
  {
    "plz": "19063",
    "partner": [
      0
    ]
  },
  {
    "plz": "19065",
    "partner": [
      0
    ]
  },
  {
    "plz": "19065",
    "partner": [
      0
    ]
  },
  {
    "plz": "19065",
    "partner": [
      0
    ]
  },
  {
    "plz": "19065",
    "partner": [
      0
    ]
  },
  {
    "plz": "19067",
    "partner": [
      0
    ]
  },
  {
    "plz": "19067",
    "partner": [
      0
    ]
  },
  {
    "plz": "19067",
    "partner": [
      0
    ]
  },
  {
    "plz": "19067",
    "partner": [
      0
    ]
  },
  {
    "plz": "19069",
    "partner": [
      0
    ]
  },
  {
    "plz": "19069",
    "partner": [
      0
    ]
  },
  {
    "plz": "19069",
    "partner": [
      0
    ]
  },
  {
    "plz": "19069",
    "partner": [
      0
    ]
  },
  {
    "plz": "19069",
    "partner": [
      0
    ]
  },
  {
    "plz": "19069",
    "partner": [
      0
    ]
  },
  {
    "plz": "19071",
    "partner": [
      0
    ]
  },
  {
    "plz": "19071",
    "partner": [
      0
    ]
  },
  {
    "plz": "19071",
    "partner": [
      0
    ]
  },
  {
    "plz": "19071",
    "partner": [
      0
    ]
  },
  {
    "plz": "19071",
    "partner": [
      0
    ]
  },
  {
    "plz": "19071",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19073",
    "partner": [
      0
    ]
  },
  {
    "plz": "19075",
    "partner": [
      0
    ]
  },
  {
    "plz": "19075",
    "partner": [
      0
    ]
  },
  {
    "plz": "19075",
    "partner": [
      0
    ]
  },
  {
    "plz": "19075",
    "partner": [
      0
    ]
  },
  {
    "plz": "19077",
    "partner": [
      0
    ]
  },
  {
    "plz": "19077",
    "partner": [
      0
    ]
  },
  {
    "plz": "19077",
    "partner": [
      0
    ]
  },
  {
    "plz": "19077",
    "partner": [
      0
    ]
  },
  {
    "plz": "19079",
    "partner": [
      0
    ]
  },
  {
    "plz": "19079",
    "partner": [
      0
    ]
  },
  {
    "plz": "19079",
    "partner": [
      0
    ]
  },
  {
    "plz": "19086",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19089",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19205",
    "partner": [
      0
    ]
  },
  {
    "plz": "19209",
    "partner": [
      0
    ]
  },
  {
    "plz": "19209",
    "partner": [
      0
    ]
  },
  {
    "plz": "19209",
    "partner": [
      0
    ]
  },
  {
    "plz": "19209",
    "partner": [
      0
    ]
  },
  {
    "plz": "19209",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19217",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19230",
    "partner": [
      0
    ]
  },
  {
    "plz": "19243",
    "partner": [
      0
    ]
  },
  {
    "plz": "19243",
    "partner": [
      0
    ]
  },
  {
    "plz": "19246",
    "partner": [
      0
    ]
  },
  {
    "plz": "19246",
    "partner": [
      0
    ]
  },
  {
    "plz": "19246",
    "partner": [
      0
    ]
  },
  {
    "plz": "19249",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19258",
    "partner": [
      0
    ]
  },
  {
    "plz": "19260",
    "partner": [
      0
    ]
  },
  {
    "plz": "19260",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19273",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19288",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19294",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19300",
    "partner": [
      0
    ]
  },
  {
    "plz": "19303",
    "partner": [
      0
    ]
  },
  {
    "plz": "19303",
    "partner": [
      0
    ]
  },
  {
    "plz": "19306",
    "partner": [
      0
    ]
  },
  {
    "plz": "19306",
    "partner": [
      0
    ]
  },
  {
    "plz": "19306",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19309",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19322",
    "partner": [
      0
    ]
  },
  {
    "plz": "19336",
    "partner": [
      0
    ]
  },
  {
    "plz": "19336",
    "partner": [
      0
    ]
  },
  {
    "plz": "19339",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19348",
    "partner": [
      0
    ]
  },
  {
    "plz": "19357",
    "partner": [
      0
    ]
  },
  {
    "plz": "19357",
    "partner": [
      0
    ]
  },
  {
    "plz": "19357",
    "partner": [
      0
    ]
  },
  {
    "plz": "19370",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19372",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19374",
    "partner": [
      0
    ]
  },
  {
    "plz": "19376",
    "partner": [
      0
    ]
  },
  {
    "plz": "19376",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19386",
    "partner": [
      0
    ]
  },
  {
    "plz": "19395",
    "partner": [
      0
    ]
  },
  {
    "plz": "19395",
    "partner": [
      0
    ]
  },
  {
    "plz": "19395",
    "partner": [
      0
    ]
  },
  {
    "plz": "19399",
    "partner": [
      0
    ]
  },
  {
    "plz": "19399",
    "partner": [
      0
    ]
  },
  {
    "plz": "19399",
    "partner": [
      0
    ]
  },
  {
    "plz": "19399",
    "partner": [
      0
    ]
  },
  {
    "plz": "19399",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19406",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19412",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "19417",
    "partner": [
      0
    ]
  },
  {
    "plz": "20095",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20097",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20099",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20144",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20146",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20148",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20149",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20249",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20251",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20253",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20255",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20257",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20259",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20354",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20355",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20357",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20359",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20457",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20459",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20535",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20537",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "20539",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21029",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21031",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21033",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21035",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21037",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21039",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21039",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21039",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21039",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21073",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21075",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21077",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21079",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21107",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21109",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21129",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21147",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21149",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21217",
    "partner": [
      4
    ]
  },
  {
    "plz": "21218",
    "partner": [
      4
    ]
  },
  {
    "plz": "21220",
    "partner": [
      4
    ]
  },
  {
    "plz": "21224",
    "partner": [
      4
    ]
  },
  {
    "plz": "21227",
    "partner": [
      4
    ]
  },
  {
    "plz": "21228",
    "partner": [
      4
    ]
  },
  {
    "plz": "21244",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21255",
    "partner": [
      4
    ]
  },
  {
    "plz": "21256",
    "partner": [
      4
    ]
  },
  {
    "plz": "21258",
    "partner": [
      4
    ]
  },
  {
    "plz": "21259",
    "partner": [
      4
    ]
  },
  {
    "plz": "21261",
    "partner": [
      4
    ]
  },
  {
    "plz": "21266",
    "partner": [
      4
    ]
  },
  {
    "plz": "21271",
    "partner": [
      4
    ]
  },
  {
    "plz": "21271",
    "partner": [
      4
    ]
  },
  {
    "plz": "21272",
    "partner": [
      4
    ]
  },
  {
    "plz": "21274",
    "partner": [
      4
    ]
  },
  {
    "plz": "21279",
    "partner": [
      4
    ]
  },
  {
    "plz": "21279",
    "partner": [
      4
    ]
  },
  {
    "plz": "21279",
    "partner": [
      4
    ]
  },
  {
    "plz": "21279",
    "partner": [
      4
    ]
  },
  {
    "plz": "21335",
    "partner": [
      4
    ]
  },
  {
    "plz": "21337",
    "partner": [
      4
    ]
  },
  {
    "plz": "21339",
    "partner": [
      4
    ]
  },
  {
    "plz": "21354",
    "partner": [
      4
    ]
  },
  {
    "plz": "21357",
    "partner": [
      4
    ]
  },
  {
    "plz": "21357",
    "partner": [
      4
    ]
  },
  {
    "plz": "21357",
    "partner": [
      4
    ]
  },
  {
    "plz": "21358",
    "partner": [
      4
    ]
  },
  {
    "plz": "21360",
    "partner": [
      4
    ]
  },
  {
    "plz": "21365",
    "partner": [
      4
    ]
  },
  {
    "plz": "21368",
    "partner": [
      4
    ]
  },
  {
    "plz": "21368",
    "partner": [
      4
    ]
  },
  {
    "plz": "21368",
    "partner": [
      4
    ]
  },
  {
    "plz": "21369",
    "partner": [
      4
    ]
  },
  {
    "plz": "21371",
    "partner": [
      4
    ]
  },
  {
    "plz": "21376",
    "partner": [
      4
    ]
  },
  {
    "plz": "21376",
    "partner": [
      4
    ]
  },
  {
    "plz": "21376",
    "partner": [
      4
    ]
  },
  {
    "plz": "21376",
    "partner": [
      4
    ]
  },
  {
    "plz": "21379",
    "partner": [
      4
    ]
  },
  {
    "plz": "21379",
    "partner": [
      4
    ]
  },
  {
    "plz": "21379",
    "partner": [
      4
    ]
  },
  {
    "plz": "21379",
    "partner": [
      4
    ]
  },
  {
    "plz": "21379",
    "partner": [
      4
    ]
  },
  {
    "plz": "21380",
    "partner": [
      4
    ]
  },
  {
    "plz": "21382",
    "partner": [
      4
    ]
  },
  {
    "plz": "21385",
    "partner": [
      4
    ]
  },
  {
    "plz": "21385",
    "partner": [
      4
    ]
  },
  {
    "plz": "21385",
    "partner": [
      4
    ]
  },
  {
    "plz": "21386",
    "partner": [
      4
    ]
  },
  {
    "plz": "21388",
    "partner": [
      4
    ]
  },
  {
    "plz": "21388",
    "partner": [
      4
    ]
  },
  {
    "plz": "21391",
    "partner": [
      4
    ]
  },
  {
    "plz": "21391",
    "partner": [
      4
    ]
  },
  {
    "plz": "21394",
    "partner": [
      4
    ]
  },
  {
    "plz": "21394",
    "partner": [
      4
    ]
  },
  {
    "plz": "21394",
    "partner": [
      4
    ]
  },
  {
    "plz": "21395",
    "partner": [
      4
    ]
  },
  {
    "plz": "21397",
    "partner": [
      4
    ]
  },
  {
    "plz": "21397",
    "partner": [
      4
    ]
  },
  {
    "plz": "21398",
    "partner": [
      4
    ]
  },
  {
    "plz": "21400",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21401",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21403",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21406",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21406",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21407",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21409",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21423",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21423",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21435",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21438",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21439",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21441",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21442",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21444",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21444",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21445",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21447",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21449",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21465",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21465",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21481",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21481",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21481",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21483",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21493",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21502",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21502",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21502",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21502",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21502",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21509",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21514",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21516",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21516",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21516",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21516",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21521",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21521",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21521",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21521",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21521",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21522",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21522",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21524",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21526",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21527",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21529",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "21614",
    "partner": [
      4
    ]
  },
  {
    "plz": "21629",
    "partner": [
      4
    ]
  },
  {
    "plz": "21635",
    "partner": [
      4
    ]
  },
  {
    "plz": "21640",
    "partner": [
      4
    ]
  },
  {
    "plz": "21640",
    "partner": [
      4
    ]
  },
  {
    "plz": "21640",
    "partner": [
      4
    ]
  },
  {
    "plz": "21640",
    "partner": [
      4
    ]
  },
  {
    "plz": "21641",
    "partner": [
      4
    ]
  },
  {
    "plz": "21643",
    "partner": [
      4
    ]
  },
  {
    "plz": "21644",
    "partner": [
      4
    ]
  },
  {
    "plz": "21646",
    "partner": [
      4
    ]
  },
  {
    "plz": "21647",
    "partner": [
      4
    ]
  },
  {
    "plz": "21649",
    "partner": [
      4
    ]
  },
  {
    "plz": "21680",
    "partner": [
      4
    ]
  },
  {
    "plz": "21682",
    "partner": [
      4
    ]
  },
  {
    "plz": "21683",
    "partner": [
      4
    ]
  },
  {
    "plz": "21684",
    "partner": [
      4
    ]
  },
  {
    "plz": "21684",
    "partner": [
      4
    ]
  },
  {
    "plz": "21698",
    "partner": [
      4
    ]
  },
  {
    "plz": "21698",
    "partner": [
      4
    ]
  },
  {
    "plz": "21698",
    "partner": [
      4
    ]
  },
  {
    "plz": "21702",
    "partner": [
      4
    ]
  },
  {
    "plz": "21706",
    "partner": [
      4
    ]
  },
  {
    "plz": "21706",
    "partner": [
      4
    ]
  },
  {
    "plz": "21709",
    "partner": [
      4
    ]
  },
  {
    "plz": "21709",
    "partner": [
      4
    ]
  },
  {
    "plz": "21709",
    "partner": [
      4
    ]
  },
  {
    "plz": "21710",
    "partner": [
      4
    ]
  },
  {
    "plz": "21712",
    "partner": [
      4
    ]
  },
  {
    "plz": "21714",
    "partner": [
      4
    ]
  },
  {
    "plz": "21717",
    "partner": [
      4
    ]
  },
  {
    "plz": "21717",
    "partner": [
      4
    ]
  },
  {
    "plz": "21720",
    "partner": [
      4
    ]
  },
  {
    "plz": "21720",
    "partner": [
      4
    ]
  },
  {
    "plz": "21720",
    "partner": [
      4
    ]
  },
  {
    "plz": "21720",
    "partner": [
      4
    ]
  },
  {
    "plz": "21723",
    "partner": [
      4
    ]
  },
  {
    "plz": "21726",
    "partner": [
      4
    ]
  },
  {
    "plz": "21726",
    "partner": [
      4
    ]
  },
  {
    "plz": "21726",
    "partner": [
      4
    ]
  },
  {
    "plz": "21727",
    "partner": [
      4
    ]
  },
  {
    "plz": "21729",
    "partner": [
      4
    ]
  },
  {
    "plz": "21730",
    "partner": [
      4
    ]
  },
  {
    "plz": "21732",
    "partner": [
      4
    ]
  },
  {
    "plz": "21734",
    "partner": [
      4
    ]
  },
  {
    "plz": "21734",
    "partner": [
      4
    ]
  },
  {
    "plz": "21737",
    "partner": [
      4
    ]
  },
  {
    "plz": "21739",
    "partner": [
      4
    ]
  },
  {
    "plz": "21745",
    "partner": [
      4
    ]
  },
  {
    "plz": "21755",
    "partner": [
      4
    ]
  },
  {
    "plz": "21756",
    "partner": [
      4
    ]
  },
  {
    "plz": "21762",
    "partner": [
      4
    ]
  },
  {
    "plz": "21762",
    "partner": [
      4
    ]
  },
  {
    "plz": "21763",
    "partner": [
      4
    ]
  },
  {
    "plz": "21765",
    "partner": [
      4
    ]
  },
  {
    "plz": "21769",
    "partner": [
      4
    ]
  },
  {
    "plz": "21769",
    "partner": [
      4
    ]
  },
  {
    "plz": "21769",
    "partner": [
      4
    ]
  },
  {
    "plz": "21770",
    "partner": [
      4
    ]
  },
  {
    "plz": "21772",
    "partner": [
      4
    ]
  },
  {
    "plz": "21775",
    "partner": [
      4
    ]
  },
  {
    "plz": "21775",
    "partner": [
      4
    ]
  },
  {
    "plz": "21775",
    "partner": [
      4
    ]
  },
  {
    "plz": "21776",
    "partner": [
      4
    ]
  },
  {
    "plz": "21781",
    "partner": [
      4
    ]
  },
  {
    "plz": "21782",
    "partner": [
      4
    ]
  },
  {
    "plz": "21785",
    "partner": [
      4
    ]
  },
  {
    "plz": "21785",
    "partner": [
      4
    ]
  },
  {
    "plz": "21787",
    "partner": [
      4
    ]
  },
  {
    "plz": "21789",
    "partner": [
      4
    ]
  },
  {
    "plz": "22041",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22043",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22045",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22047",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22049",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22081",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22083",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22085",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22087",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22089",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22111",
    "partner": [
      4
    ]
  },
  {
    "plz": "22113",
    "partner": [
      4
    ]
  },
  {
    "plz": "22113",
    "partner": [
      4
    ]
  },
  {
    "plz": "22115",
    "partner": [
      4
    ]
  },
  {
    "plz": "22117",
    "partner": [
      4
    ]
  },
  {
    "plz": "22119",
    "partner": [
      4
    ]
  },
  {
    "plz": "22143",
    "partner": [
      4
    ]
  },
  {
    "plz": "22145",
    "partner": [
      4
    ]
  },
  {
    "plz": "22145",
    "partner": [
      4
    ]
  },
  {
    "plz": "22145",
    "partner": [
      4
    ]
  },
  {
    "plz": "22147",
    "partner": [
      4
    ]
  },
  {
    "plz": "22149",
    "partner": [
      4
    ]
  },
  {
    "plz": "22159",
    "partner": [
      4
    ]
  },
  {
    "plz": "22175",
    "partner": [
      4
    ]
  },
  {
    "plz": "22177",
    "partner": [
      4
    ]
  },
  {
    "plz": "22179",
    "partner": [
      4
    ]
  },
  {
    "plz": "22297",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22299",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22301",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22303",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22305",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22307",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22309",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22335",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22337",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22339",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22359",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22391",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22393",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22395",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22397",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22399",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22415",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22417",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22419",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22453",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22455",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22457",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22459",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22523",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22525",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22527",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22529",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22547",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22549",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22559",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22587",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22589",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22605",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22607",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22609",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22761",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22763",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22765",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22767",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22769",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22844",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22846",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22848",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22850",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22851",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22869",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22880",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22885",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22889",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22926",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22927",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22929",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22941",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22941",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22941",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22941",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22946",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22946",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22946",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22946",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22946",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22946",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22949",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22952",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22955",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22956",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22958",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22959",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22961",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22962",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22964",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22965",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22967",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "22969",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "23552",
    "partner": [
      4
    ]
  },
  {
    "plz": "23554",
    "partner": [
      4
    ]
  },
  {
    "plz": "23556",
    "partner": [
      4
    ]
  },
  {
    "plz": "23558",
    "partner": [
      4
    ]
  },
  {
    "plz": "23560",
    "partner": [
      4
    ]
  },
  {
    "plz": "23562",
    "partner": [
      4
    ]
  },
  {
    "plz": "23564",
    "partner": [
      4
    ]
  },
  {
    "plz": "23566",
    "partner": [
      4
    ]
  },
  {
    "plz": "23568",
    "partner": [
      4
    ]
  },
  {
    "plz": "23569",
    "partner": [
      4
    ]
  },
  {
    "plz": "23570",
    "partner": [
      4
    ]
  },
  {
    "plz": "23611",
    "partner": [
      4
    ]
  },
  {
    "plz": "23611",
    "partner": [
      4
    ]
  },
  {
    "plz": "23611",
    "partner": [
      4
    ]
  },
  {
    "plz": "23611",
    "partner": [
      4
    ]
  },
  {
    "plz": "23617",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23619",
    "partner": [
      4
    ]
  },
  {
    "plz": "23623",
    "partner": [
      4
    ]
  },
  {
    "plz": "23623",
    "partner": [
      4
    ]
  },
  {
    "plz": "23626",
    "partner": [
      4
    ]
  },
  {
    "plz": "23627",
    "partner": [
      4
    ]
  },
  {
    "plz": "23627",
    "partner": [
      4
    ]
  },
  {
    "plz": "23627",
    "partner": [
      4
    ]
  },
  {
    "plz": "23628",
    "partner": [
      4
    ]
  },
  {
    "plz": "23628",
    "partner": [
      4
    ]
  },
  {
    "plz": "23629",
    "partner": [
      4
    ]
  },
  {
    "plz": "23669",
    "partner": [
      4
    ]
  },
  {
    "plz": "23669",
    "partner": [
      4
    ]
  },
  {
    "plz": "23669",
    "partner": [
      4
    ]
  },
  {
    "plz": "23683",
    "partner": [
      4
    ]
  },
  {
    "plz": "23684",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23689",
    "partner": [
      4
    ]
  },
  {
    "plz": "23701",
    "partner": [
      4
    ]
  },
  {
    "plz": "23701",
    "partner": [
      4
    ]
  },
  {
    "plz": "23714",
    "partner": [
      4
    ]
  },
  {
    "plz": "23714",
    "partner": [
      4
    ]
  },
  {
    "plz": "23715",
    "partner": [
      4
    ]
  },
  {
    "plz": "23717",
    "partner": [
      4
    ]
  },
  {
    "plz": "23717",
    "partner": [
      4
    ]
  },
  {
    "plz": "23719",
    "partner": [
      4
    ]
  },
  {
    "plz": "23730",
    "partner": [
      4
    ]
  },
  {
    "plz": "23730",
    "partner": [
      4
    ]
  },
  {
    "plz": "23730",
    "partner": [
      4
    ]
  },
  {
    "plz": "23730",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23738",
    "partner": [
      4
    ]
  },
  {
    "plz": "23743",
    "partner": [
      4
    ]
  },
  {
    "plz": "23743",
    "partner": [
      4
    ]
  },
  {
    "plz": "23744",
    "partner": [
      4
    ]
  },
  {
    "plz": "23746",
    "partner": [
      4
    ]
  },
  {
    "plz": "23746",
    "partner": [
      4
    ]
  },
  {
    "plz": "23747",
    "partner": [
      4
    ]
  },
  {
    "plz": "23747",
    "partner": [
      4
    ]
  },
  {
    "plz": "23749",
    "partner": [
      4
    ]
  },
  {
    "plz": "23758",
    "partner": [
      4
    ]
  },
  {
    "plz": "23758",
    "partner": [
      4
    ]
  },
  {
    "plz": "23758",
    "partner": [
      4
    ]
  },
  {
    "plz": "23758",
    "partner": [
      4
    ]
  },
  {
    "plz": "23769",
    "partner": [
      4
    ]
  },
  {
    "plz": "23774",
    "partner": [
      4
    ]
  },
  {
    "plz": "23775",
    "partner": [
      4
    ]
  },
  {
    "plz": "23777",
    "partner": [
      4
    ]
  },
  {
    "plz": "23777",
    "partner": [
      4
    ]
  },
  {
    "plz": "23779",
    "partner": [
      4
    ]
  },
  {
    "plz": "23779",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23795",
    "partner": [
      4
    ]
  },
  {
    "plz": "23812",
    "partner": [
      4
    ]
  },
  {
    "plz": "23812",
    "partner": [
      4
    ]
  },
  {
    "plz": "23813",
    "partner": [
      4
    ]
  },
  {
    "plz": "23813",
    "partner": [
      4
    ]
  },
  {
    "plz": "23815",
    "partner": [
      4
    ]
  },
  {
    "plz": "23815",
    "partner": [
      4
    ]
  },
  {
    "plz": "23815",
    "partner": [
      4
    ]
  },
  {
    "plz": "23816",
    "partner": [
      4
    ]
  },
  {
    "plz": "23816",
    "partner": [
      4
    ]
  },
  {
    "plz": "23816",
    "partner": [
      4
    ]
  },
  {
    "plz": "23816",
    "partner": [
      4
    ]
  },
  {
    "plz": "23818",
    "partner": [
      4
    ]
  },
  {
    "plz": "23820",
    "partner": [
      4
    ]
  },
  {
    "plz": "23821",
    "partner": [
      4
    ]
  },
  {
    "plz": "23823",
    "partner": [
      4
    ]
  },
  {
    "plz": "23824",
    "partner": [
      4
    ]
  },
  {
    "plz": "23824",
    "partner": [
      4
    ]
  },
  {
    "plz": "23826",
    "partner": [
      4
    ]
  },
  {
    "plz": "23826",
    "partner": [
      4
    ]
  },
  {
    "plz": "23826",
    "partner": [
      4
    ]
  },
  {
    "plz": "23827",
    "partner": [
      4
    ]
  },
  {
    "plz": "23827",
    "partner": [
      4
    ]
  },
  {
    "plz": "23827",
    "partner": [
      4
    ]
  },
  {
    "plz": "23829",
    "partner": [
      4
    ]
  },
  {
    "plz": "23829",
    "partner": [
      4
    ]
  },
  {
    "plz": "23843",
    "partner": [
      4
    ]
  },
  {
    "plz": "23843",
    "partner": [
      4
    ]
  },
  {
    "plz": "23843",
    "partner": [
      4
    ]
  },
  {
    "plz": "23843",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23845",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23847",
    "partner": [
      4
    ]
  },
  {
    "plz": "23858",
    "partner": [
      4
    ]
  },
  {
    "plz": "23858",
    "partner": [
      4
    ]
  },
  {
    "plz": "23858",
    "partner": [
      4
    ]
  },
  {
    "plz": "23858",
    "partner": [
      4
    ]
  },
  {
    "plz": "23858",
    "partner": [
      4
    ]
  },
  {
    "plz": "23860",
    "partner": [
      4
    ]
  },
  {
    "plz": "23860",
    "partner": [
      4
    ]
  },
  {
    "plz": "23863",
    "partner": [
      4
    ]
  },
  {
    "plz": "23863",
    "partner": [
      4
    ]
  },
  {
    "plz": "23863",
    "partner": [
      4
    ]
  },
  {
    "plz": "23866",
    "partner": [
      4
    ]
  },
  {
    "plz": "23867",
    "partner": [
      4
    ]
  },
  {
    "plz": "23869",
    "partner": [
      4
    ]
  },
  {
    "plz": "23879",
    "partner": [
      4
    ]
  },
  {
    "plz": "23879",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23881",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23883",
    "partner": [
      4
    ]
  },
  {
    "plz": "23896",
    "partner": [
      4
    ]
  },
  {
    "plz": "23896",
    "partner": [
      4
    ]
  },
  {
    "plz": "23896",
    "partner": [
      4
    ]
  },
  {
    "plz": "23896",
    "partner": [
      4
    ]
  },
  {
    "plz": "23896",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23898",
    "partner": [
      4
    ]
  },
  {
    "plz": "23899",
    "partner": [
      4
    ]
  },
  {
    "plz": "23899",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23909",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23911",
    "partner": [
      4
    ]
  },
  {
    "plz": "23919",
    "partner": [
      4
    ]
  },
  {
    "plz": "23919",
    "partner": [
      4
    ]
  },
  {
    "plz": "23919",
    "partner": [
      4
    ]
  },
  {
    "plz": "23919",
    "partner": [
      4
    ]
  },
  {
    "plz": "23919",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23923",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23936",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23942",
    "partner": [
      4
    ]
  },
  {
    "plz": "23946",
    "partner": [
      4
    ]
  },
  {
    "plz": "23946",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23948",
    "partner": [
      4
    ]
  },
  {
    "plz": "23966",
    "partner": [
      4
    ]
  },
  {
    "plz": "23966",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23968",
    "partner": [
      4
    ]
  },
  {
    "plz": "23970",
    "partner": [
      4
    ]
  },
  {
    "plz": "23970",
    "partner": [
      4
    ]
  },
  {
    "plz": "23970",
    "partner": [
      4
    ]
  },
  {
    "plz": "23970",
    "partner": [
      4
    ]
  },
  {
    "plz": "23972",
    "partner": [
      4
    ]
  },
  {
    "plz": "23972",
    "partner": [
      4
    ]
  },
  {
    "plz": "23972",
    "partner": [
      4
    ]
  },
  {
    "plz": "23972",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23974",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23992",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23996",
    "partner": [
      4
    ]
  },
  {
    "plz": "23999",
    "partner": [
      4
    ]
  },
  {
    "plz": "24103",
    "partner": [
      4
    ]
  },
  {
    "plz": "24105",
    "partner": [
      4
    ]
  },
  {
    "plz": "24106",
    "partner": [
      4
    ]
  },
  {
    "plz": "24107",
    "partner": [
      4
    ]
  },
  {
    "plz": "24107",
    "partner": [
      4
    ]
  },
  {
    "plz": "24107",
    "partner": [
      4
    ]
  },
  {
    "plz": "24109",
    "partner": [
      4
    ]
  },
  {
    "plz": "24109",
    "partner": [
      4
    ]
  },
  {
    "plz": "24111",
    "partner": [
      4
    ]
  },
  {
    "plz": "24113",
    "partner": [
      4
    ]
  },
  {
    "plz": "24113",
    "partner": [
      4
    ]
  },
  {
    "plz": "24114",
    "partner": [
      4
    ]
  },
  {
    "plz": "24116",
    "partner": [
      4
    ]
  },
  {
    "plz": "24118",
    "partner": [
      4
    ]
  },
  {
    "plz": "24119",
    "partner": [
      4
    ]
  },
  {
    "plz": "24143",
    "partner": [
      4
    ]
  },
  {
    "plz": "24145",
    "partner": [
      4
    ]
  },
  {
    "plz": "24146",
    "partner": [
      4
    ]
  },
  {
    "plz": "24147",
    "partner": [
      4
    ]
  },
  {
    "plz": "24148",
    "partner": [
      4
    ]
  },
  {
    "plz": "24149",
    "partner": [
      4
    ]
  },
  {
    "plz": "24159",
    "partner": [
      4
    ]
  },
  {
    "plz": "24161",
    "partner": [
      4
    ]
  },
  {
    "plz": "24161",
    "partner": [
      4
    ]
  },
  {
    "plz": "24161",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24211",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24214",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24217",
    "partner": [
      4
    ]
  },
  {
    "plz": "24220",
    "partner": [
      4
    ]
  },
  {
    "plz": "24220",
    "partner": [
      4
    ]
  },
  {
    "plz": "24220",
    "partner": [
      4
    ]
  },
  {
    "plz": "24220",
    "partner": [
      4
    ]
  },
  {
    "plz": "24220",
    "partner": [
      4
    ]
  },
  {
    "plz": "24222",
    "partner": [
      4
    ]
  },
  {
    "plz": "24223",
    "partner": [
      4
    ]
  },
  {
    "plz": "24223",
    "partner": [
      4
    ]
  },
  {
    "plz": "24226",
    "partner": [
      4
    ]
  },
  {
    "plz": "24229",
    "partner": [
      4
    ]
  },
  {
    "plz": "24229",
    "partner": [
      4
    ]
  },
  {
    "plz": "24229",
    "partner": [
      4
    ]
  },
  {
    "plz": "24232",
    "partner": [
      4
    ]
  },
  {
    "plz": "24232",
    "partner": [
      4
    ]
  },
  {
    "plz": "24235",
    "partner": [
      4
    ]
  },
  {
    "plz": "24235",
    "partner": [
      4
    ]
  },
  {
    "plz": "24235",
    "partner": [
      4
    ]
  },
  {
    "plz": "24235",
    "partner": [
      4
    ]
  },
  {
    "plz": "24235",
    "partner": [
      4
    ]
  },
  {
    "plz": "24238",
    "partner": [
      4
    ]
  },
  {
    "plz": "24238",
    "partner": [
      4
    ]
  },
  {
    "plz": "24238",
    "partner": [
      4
    ]
  },
  {
    "plz": "24238",
    "partner": [
      4
    ]
  },
  {
    "plz": "24239",
    "partner": [
      4
    ]
  },
  {
    "plz": "24241",
    "partner": [
      4
    ]
  },
  {
    "plz": "24241",
    "partner": [
      4
    ]
  },
  {
    "plz": "24241",
    "partner": [
      4
    ]
  },
  {
    "plz": "24241",
    "partner": [
      4
    ]
  },
  {
    "plz": "24241",
    "partner": [
      4
    ]
  },
  {
    "plz": "24241",
    "partner": [
      4
    ]
  },
  {
    "plz": "24242",
    "partner": [
      4
    ]
  },
  {
    "plz": "24244",
    "partner": [
      4
    ]
  },
  {
    "plz": "24245",
    "partner": [
      4
    ]
  },
  {
    "plz": "24245",
    "partner": [
      4
    ]
  },
  {
    "plz": "24245",
    "partner": [
      4
    ]
  },
  {
    "plz": "24245",
    "partner": [
      4
    ]
  },
  {
    "plz": "24247",
    "partner": [
      4
    ]
  },
  {
    "plz": "24247",
    "partner": [
      4
    ]
  },
  {
    "plz": "24248",
    "partner": [
      4
    ]
  },
  {
    "plz": "24250",
    "partner": [
      4
    ]
  },
  {
    "plz": "24250",
    "partner": [
      4
    ]
  },
  {
    "plz": "24250",
    "partner": [
      4
    ]
  },
  {
    "plz": "24250",
    "partner": [
      4
    ]
  },
  {
    "plz": "24251",
    "partner": [
      4
    ]
  },
  {
    "plz": "24253",
    "partner": [
      4
    ]
  },
  {
    "plz": "24253",
    "partner": [
      4
    ]
  },
  {
    "plz": "24253",
    "partner": [
      4
    ]
  },
  {
    "plz": "24253",
    "partner": [
      4
    ]
  },
  {
    "plz": "24254",
    "partner": [
      4
    ]
  },
  {
    "plz": "24256",
    "partner": [
      4
    ]
  },
  {
    "plz": "24256",
    "partner": [
      4
    ]
  },
  {
    "plz": "24256",
    "partner": [
      4
    ]
  },
  {
    "plz": "24257",
    "partner": [
      4
    ]
  },
  {
    "plz": "24257",
    "partner": [
      4
    ]
  },
  {
    "plz": "24257",
    "partner": [
      4
    ]
  },
  {
    "plz": "24257",
    "partner": [
      4
    ]
  },
  {
    "plz": "24259",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24306",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24321",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24326",
    "partner": [
      4
    ]
  },
  {
    "plz": "24327",
    "partner": [
      4
    ]
  },
  {
    "plz": "24327",
    "partner": [
      4
    ]
  },
  {
    "plz": "24327",
    "partner": [
      4
    ]
  },
  {
    "plz": "24327",
    "partner": [
      4
    ]
  },
  {
    "plz": "24329",
    "partner": [
      4
    ]
  },
  {
    "plz": "24329",
    "partner": [
      4
    ]
  },
  {
    "plz": "24329",
    "partner": [
      4
    ]
  },
  {
    "plz": "24340",
    "partner": [
      4
    ]
  },
  {
    "plz": "24340",
    "partner": [
      4
    ]
  },
  {
    "plz": "24340",
    "partner": [
      4
    ]
  },
  {
    "plz": "24340",
    "partner": [
      4
    ]
  },
  {
    "plz": "24340",
    "partner": [
      4
    ]
  },
  {
    "plz": "24340",
    "partner": [
      4
    ]
  },
  {
    "plz": "24351",
    "partner": [
      4
    ]
  },
  {
    "plz": "24351",
    "partner": [
      4
    ]
  },
  {
    "plz": "24354",
    "partner": [
      4
    ]
  },
  {
    "plz": "24354",
    "partner": [
      4
    ]
  },
  {
    "plz": "24354",
    "partner": [
      4
    ]
  },
  {
    "plz": "24354",
    "partner": [
      4
    ]
  },
  {
    "plz": "24354",
    "partner": [
      4
    ]
  },
  {
    "plz": "24357",
    "partner": [
      4
    ]
  },
  {
    "plz": "24357",
    "partner": [
      4
    ]
  },
  {
    "plz": "24357",
    "partner": [
      4
    ]
  },
  {
    "plz": "24357",
    "partner": [
      4
    ]
  },
  {
    "plz": "24358",
    "partner": [
      4
    ]
  },
  {
    "plz": "24358",
    "partner": [
      4
    ]
  },
  {
    "plz": "24358",
    "partner": [
      4
    ]
  },
  {
    "plz": "24360",
    "partner": [
      4
    ]
  },
  {
    "plz": "24360",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24361",
    "partner": [
      4
    ]
  },
  {
    "plz": "24363",
    "partner": [
      4
    ]
  },
  {
    "plz": "24363",
    "partner": [
      4
    ]
  },
  {
    "plz": "24364",
    "partner": [
      4
    ]
  },
  {
    "plz": "24366",
    "partner": [
      4
    ]
  },
  {
    "plz": "24367",
    "partner": [
      4
    ]
  },
  {
    "plz": "24367",
    "partner": [
      4
    ]
  },
  {
    "plz": "24369",
    "partner": [
      4
    ]
  },
  {
    "plz": "24376",
    "partner": [
      4
    ]
  },
  {
    "plz": "24376",
    "partner": [
      4
    ]
  },
  {
    "plz": "24376",
    "partner": [
      4
    ]
  },
  {
    "plz": "24376",
    "partner": [
      4
    ]
  },
  {
    "plz": "24376",
    "partner": [
      4
    ]
  },
  {
    "plz": "24376",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24392",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24395",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24398",
    "partner": [
      4
    ]
  },
  {
    "plz": "24399",
    "partner": [
      4
    ]
  },
  {
    "plz": "24399",
    "partner": [
      4
    ]
  },
  {
    "plz": "24401",
    "partner": [
      4
    ]
  },
  {
    "plz": "24402",
    "partner": [
      4
    ]
  },
  {
    "plz": "24404",
    "partner": [
      4
    ]
  },
  {
    "plz": "24404",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24405",
    "partner": [
      4
    ]
  },
  {
    "plz": "24407",
    "partner": [
      4
    ]
  },
  {
    "plz": "24407",
    "partner": [
      4
    ]
  },
  {
    "plz": "24409",
    "partner": [
      4
    ]
  },
  {
    "plz": "24534",
    "partner": [
      4
    ]
  },
  {
    "plz": "24536",
    "partner": [
      4
    ]
  },
  {
    "plz": "24536",
    "partner": [
      4
    ]
  },
  {
    "plz": "24537",
    "partner": [
      4
    ]
  },
  {
    "plz": "24539",
    "partner": [
      4
    ]
  },
  {
    "plz": "24558",
    "partner": [
      4
    ]
  },
  {
    "plz": "24558",
    "partner": [
      4
    ]
  },
  {
    "plz": "24568",
    "partner": [
      4
    ]
  },
  {
    "plz": "24568",
    "partner": [
      4
    ]
  },
  {
    "plz": "24568",
    "partner": [
      4
    ]
  },
  {
    "plz": "24568",
    "partner": [
      4
    ]
  },
  {
    "plz": "24568",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24576",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24582",
    "partner": [
      4
    ]
  },
  {
    "plz": "24589",
    "partner": [
      4
    ]
  },
  {
    "plz": "24589",
    "partner": [
      4
    ]
  },
  {
    "plz": "24589",
    "partner": [
      4
    ]
  },
  {
    "plz": "24589",
    "partner": [
      4
    ]
  },
  {
    "plz": "24589",
    "partner": [
      4
    ]
  },
  {
    "plz": "24589",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24594",
    "partner": [
      4
    ]
  },
  {
    "plz": "24598",
    "partner": [
      4
    ]
  },
  {
    "plz": "24598",
    "partner": [
      4
    ]
  },
  {
    "plz": "24598",
    "partner": [
      4
    ]
  },
  {
    "plz": "24601",
    "partner": [
      4
    ]
  },
  {
    "plz": "24601",
    "partner": [
      4
    ]
  },
  {
    "plz": "24601",
    "partner": [
      4
    ]
  },
  {
    "plz": "24601",
    "partner": [
      4
    ]
  },
  {
    "plz": "24610",
    "partner": [
      4
    ]
  },
  {
    "plz": "24610",
    "partner": [
      4
    ]
  },
  {
    "plz": "24613",
    "partner": [
      4
    ]
  },
  {
    "plz": "24613",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24616",
    "partner": [
      4
    ]
  },
  {
    "plz": "24619",
    "partner": [
      4
    ]
  },
  {
    "plz": "24619",
    "partner": [
      4
    ]
  },
  {
    "plz": "24619",
    "partner": [
      4
    ]
  },
  {
    "plz": "24620",
    "partner": [
      4
    ]
  },
  {
    "plz": "24622",
    "partner": [
      4
    ]
  },
  {
    "plz": "24623",
    "partner": [
      4
    ]
  },
  {
    "plz": "24625",
    "partner": [
      4
    ]
  },
  {
    "plz": "24625",
    "partner": [
      4
    ]
  },
  {
    "plz": "24626",
    "partner": [
      4
    ]
  },
  {
    "plz": "24628",
    "partner": [
      4
    ]
  },
  {
    "plz": "24629",
    "partner": [
      4
    ]
  },
  {
    "plz": "24631",
    "partner": [
      4
    ]
  },
  {
    "plz": "24632",
    "partner": [
      4
    ]
  },
  {
    "plz": "24632",
    "partner": [
      4
    ]
  },
  {
    "plz": "24634",
    "partner": [
      4
    ]
  },
  {
    "plz": "24634",
    "partner": [
      4
    ]
  },
  {
    "plz": "24635",
    "partner": [
      4
    ]
  },
  {
    "plz": "24635",
    "partner": [
      4
    ]
  },
  {
    "plz": "24637",
    "partner": [
      4
    ]
  },
  {
    "plz": "24637",
    "partner": [
      4
    ]
  },
  {
    "plz": "24638",
    "partner": [
      4
    ]
  },
  {
    "plz": "24640",
    "partner": [
      4
    ]
  },
  {
    "plz": "24640",
    "partner": [
      4
    ]
  },
  {
    "plz": "24640",
    "partner": [
      4
    ]
  },
  {
    "plz": "24640",
    "partner": [
      4
    ]
  },
  {
    "plz": "24641",
    "partner": [
      4
    ]
  },
  {
    "plz": "24641",
    "partner": [
      4
    ]
  },
  {
    "plz": "24641",
    "partner": [
      4
    ]
  },
  {
    "plz": "24643",
    "partner": [
      4
    ]
  },
  {
    "plz": "24644",
    "partner": [
      4
    ]
  },
  {
    "plz": "24644",
    "partner": [
      4
    ]
  },
  {
    "plz": "24644",
    "partner": [
      4
    ]
  },
  {
    "plz": "24646",
    "partner": [
      4
    ]
  },
  {
    "plz": "24647",
    "partner": [
      4
    ]
  },
  {
    "plz": "24647",
    "partner": [
      4
    ]
  },
  {
    "plz": "24649",
    "partner": [
      4
    ]
  },
  {
    "plz": "24649",
    "partner": [
      4
    ]
  },
  {
    "plz": "24768",
    "partner": [
      4
    ]
  },
  {
    "plz": "24768",
    "partner": [
      4
    ]
  },
  {
    "plz": "24782",
    "partner": [
      4
    ]
  },
  {
    "plz": "24782",
    "partner": [
      4
    ]
  },
  {
    "plz": "24782",
    "partner": [
      4
    ]
  },
  {
    "plz": "24783",
    "partner": [
      4
    ]
  },
  {
    "plz": "24784",
    "partner": [
      4
    ]
  },
  {
    "plz": "24787",
    "partner": [
      4
    ]
  },
  {
    "plz": "24787",
    "partner": [
      4
    ]
  },
  {
    "plz": "24790",
    "partner": [
      4
    ]
  },
  {
    "plz": "24790",
    "partner": [
      4
    ]
  },
  {
    "plz": "24790",
    "partner": [
      4
    ]
  },
  {
    "plz": "24790",
    "partner": [
      4
    ]
  },
  {
    "plz": "24790",
    "partner": [
      4
    ]
  },
  {
    "plz": "24791",
    "partner": [
      4
    ]
  },
  {
    "plz": "24791",
    "partner": [
      4
    ]
  },
  {
    "plz": "24793",
    "partner": [
      4
    ]
  },
  {
    "plz": "24793",
    "partner": [
      4
    ]
  },
  {
    "plz": "24793",
    "partner": [
      4
    ]
  },
  {
    "plz": "24794",
    "partner": [
      4
    ]
  },
  {
    "plz": "24794",
    "partner": [
      4
    ]
  },
  {
    "plz": "24794",
    "partner": [
      4
    ]
  },
  {
    "plz": "24794",
    "partner": [
      4
    ]
  },
  {
    "plz": "24794",
    "partner": [
      4
    ]
  },
  {
    "plz": "24796",
    "partner": [
      4
    ]
  },
  {
    "plz": "24796",
    "partner": [
      4
    ]
  },
  {
    "plz": "24796",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24797",
    "partner": [
      4
    ]
  },
  {
    "plz": "24799",
    "partner": [
      4
    ]
  },
  {
    "plz": "24799",
    "partner": [
      4
    ]
  },
  {
    "plz": "24799",
    "partner": [
      4
    ]
  },
  {
    "plz": "24799",
    "partner": [
      4
    ]
  },
  {
    "plz": "24799",
    "partner": [
      4
    ]
  },
  {
    "plz": "24799",
    "partner": [
      4
    ]
  },
  {
    "plz": "24800",
    "partner": [
      4
    ]
  },
  {
    "plz": "24802",
    "partner": [
      4
    ]
  },
  {
    "plz": "24802",
    "partner": [
      4
    ]
  },
  {
    "plz": "24802",
    "partner": [
      4
    ]
  },
  {
    "plz": "24803",
    "partner": [
      4
    ]
  },
  {
    "plz": "24803",
    "partner": [
      4
    ]
  },
  {
    "plz": "24805",
    "partner": [
      4
    ]
  },
  {
    "plz": "24805",
    "partner": [
      4
    ]
  },
  {
    "plz": "24806",
    "partner": [
      4
    ]
  },
  {
    "plz": "24806",
    "partner": [
      4
    ]
  },
  {
    "plz": "24806",
    "partner": [
      4
    ]
  },
  {
    "plz": "24806",
    "partner": [
      4
    ]
  },
  {
    "plz": "24808",
    "partner": [
      4
    ]
  },
  {
    "plz": "24808",
    "partner": [
      4
    ]
  },
  {
    "plz": "24809",
    "partner": [
      4
    ]
  },
  {
    "plz": "24811",
    "partner": [
      4
    ]
  },
  {
    "plz": "24811",
    "partner": [
      4
    ]
  },
  {
    "plz": "24811",
    "partner": [
      4
    ]
  },
  {
    "plz": "24813",
    "partner": [
      4
    ]
  },
  {
    "plz": "24814",
    "partner": [
      4
    ]
  },
  {
    "plz": "24816",
    "partner": [
      4
    ]
  },
  {
    "plz": "24816",
    "partner": [
      4
    ]
  },
  {
    "plz": "24816",
    "partner": [
      4
    ]
  },
  {
    "plz": "24816",
    "partner": [
      4
    ]
  },
  {
    "plz": "24816",
    "partner": [
      4
    ]
  },
  {
    "plz": "24816",
    "partner": [
      4
    ]
  },
  {
    "plz": "24817",
    "partner": [
      4
    ]
  },
  {
    "plz": "24819",
    "partner": [
      4
    ]
  },
  {
    "plz": "24819",
    "partner": [
      4
    ]
  },
  {
    "plz": "24819",
    "partner": [
      4
    ]
  },
  {
    "plz": "24819",
    "partner": [
      4
    ]
  },
  {
    "plz": "24819",
    "partner": [
      4
    ]
  },
  {
    "plz": "24837",
    "partner": [
      4
    ]
  },
  {
    "plz": "24837",
    "partner": [
      4
    ]
  },
  {
    "plz": "24848",
    "partner": [
      4
    ]
  },
  {
    "plz": "24848",
    "partner": [
      4
    ]
  },
  {
    "plz": "24848",
    "partner": [
      4
    ]
  },
  {
    "plz": "24848",
    "partner": [
      4
    ]
  },
  {
    "plz": "24848",
    "partner": [
      4
    ]
  },
  {
    "plz": "24848",
    "partner": [
      4
    ]
  },
  {
    "plz": "24850",
    "partner": [
      4
    ]
  },
  {
    "plz": "24850",
    "partner": [
      4
    ]
  },
  {
    "plz": "24850",
    "partner": [
      4
    ]
  },
  {
    "plz": "24852",
    "partner": [
      4
    ]
  },
  {
    "plz": "24852",
    "partner": [
      4
    ]
  },
  {
    "plz": "24852",
    "partner": [
      4
    ]
  },
  {
    "plz": "24852",
    "partner": [
      4
    ]
  },
  {
    "plz": "24855",
    "partner": [
      4
    ]
  },
  {
    "plz": "24855",
    "partner": [
      4
    ]
  },
  {
    "plz": "24857",
    "partner": [
      4
    ]
  },
  {
    "plz": "24857",
    "partner": [
      4
    ]
  },
  {
    "plz": "24860",
    "partner": [
      4
    ]
  },
  {
    "plz": "24860",
    "partner": [
      4
    ]
  },
  {
    "plz": "24860",
    "partner": [
      4
    ]
  },
  {
    "plz": "24861",
    "partner": [
      4
    ]
  },
  {
    "plz": "24861",
    "partner": [
      4
    ]
  },
  {
    "plz": "24861",
    "partner": [
      4
    ]
  },
  {
    "plz": "24861",
    "partner": [
      4
    ]
  },
  {
    "plz": "24863",
    "partner": [
      4
    ]
  },
  {
    "plz": "24863",
    "partner": [
      4
    ]
  },
  {
    "plz": "24864",
    "partner": [
      4
    ]
  },
  {
    "plz": "24866",
    "partner": [
      4
    ]
  },
  {
    "plz": "24867",
    "partner": [
      4
    ]
  },
  {
    "plz": "24869",
    "partner": [
      4
    ]
  },
  {
    "plz": "24870",
    "partner": [
      4
    ]
  },
  {
    "plz": "24872",
    "partner": [
      4
    ]
  },
  {
    "plz": "24873",
    "partner": [
      4
    ]
  },
  {
    "plz": "24876",
    "partner": [
      4
    ]
  },
  {
    "plz": "24878",
    "partner": [
      4
    ]
  },
  {
    "plz": "24878",
    "partner": [
      4
    ]
  },
  {
    "plz": "24879",
    "partner": [
      4
    ]
  },
  {
    "plz": "24879",
    "partner": [
      4
    ]
  },
  {
    "plz": "24881",
    "partner": [
      4
    ]
  },
  {
    "plz": "24882",
    "partner": [
      4
    ]
  },
  {
    "plz": "24884",
    "partner": [
      4
    ]
  },
  {
    "plz": "24884",
    "partner": [
      4
    ]
  },
  {
    "plz": "24884",
    "partner": [
      4
    ]
  },
  {
    "plz": "24885",
    "partner": [
      4
    ]
  },
  {
    "plz": "24887",
    "partner": [
      4
    ]
  },
  {
    "plz": "24888",
    "partner": [
      4
    ]
  },
  {
    "plz": "24888",
    "partner": [
      4
    ]
  },
  {
    "plz": "24888",
    "partner": [
      4
    ]
  },
  {
    "plz": "24890",
    "partner": [
      4
    ]
  },
  {
    "plz": "24890",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24891",
    "partner": [
      4
    ]
  },
  {
    "plz": "24893",
    "partner": [
      4
    ]
  },
  {
    "plz": "24894",
    "partner": [
      4
    ]
  },
  {
    "plz": "24894",
    "partner": [
      4
    ]
  },
  {
    "plz": "24894",
    "partner": [
      4
    ]
  },
  {
    "plz": "24896",
    "partner": [
      4
    ]
  },
  {
    "plz": "24896",
    "partner": [
      4
    ]
  },
  {
    "plz": "24897",
    "partner": [
      4
    ]
  },
  {
    "plz": "24899",
    "partner": [
      4
    ]
  },
  {
    "plz": "24937",
    "partner": [
      4
    ]
  },
  {
    "plz": "24939",
    "partner": [
      4
    ]
  },
  {
    "plz": "24941",
    "partner": [
      4
    ]
  },
  {
    "plz": "24943",
    "partner": [
      4
    ]
  },
  {
    "plz": "24943",
    "partner": [
      4
    ]
  },
  {
    "plz": "24944",
    "partner": [
      4
    ]
  },
  {
    "plz": "24955",
    "partner": [
      4
    ]
  },
  {
    "plz": "24960",
    "partner": [
      4
    ]
  },
  {
    "plz": "24960",
    "partner": [
      4
    ]
  },
  {
    "plz": "24963",
    "partner": [
      4
    ]
  },
  {
    "plz": "24963",
    "partner": [
      4
    ]
  },
  {
    "plz": "24966",
    "partner": [
      4
    ]
  },
  {
    "plz": "24969",
    "partner": [
      4
    ]
  },
  {
    "plz": "24969",
    "partner": [
      4
    ]
  },
  {
    "plz": "24972",
    "partner": [
      4
    ]
  },
  {
    "plz": "24972",
    "partner": [
      4
    ]
  },
  {
    "plz": "24975",
    "partner": [
      4
    ]
  },
  {
    "plz": "24975",
    "partner": [
      4
    ]
  },
  {
    "plz": "24975",
    "partner": [
      4
    ]
  },
  {
    "plz": "24975",
    "partner": [
      4
    ]
  },
  {
    "plz": "24976",
    "partner": [
      4
    ]
  },
  {
    "plz": "24977",
    "partner": [
      4
    ]
  },
  {
    "plz": "24977",
    "partner": [
      4
    ]
  },
  {
    "plz": "24977",
    "partner": [
      4
    ]
  },
  {
    "plz": "24977",
    "partner": [
      4
    ]
  },
  {
    "plz": "24980",
    "partner": [
      4
    ]
  },
  {
    "plz": "24980",
    "partner": [
      4
    ]
  },
  {
    "plz": "24980",
    "partner": [
      4
    ]
  },
  {
    "plz": "24980",
    "partner": [
      4
    ]
  },
  {
    "plz": "24980",
    "partner": [
      4
    ]
  },
  {
    "plz": "24983",
    "partner": [
      4
    ]
  },
  {
    "plz": "24986",
    "partner": [
      4
    ]
  },
  {
    "plz": "24988",
    "partner": [
      4
    ]
  },
  {
    "plz": "24989",
    "partner": [
      4
    ]
  },
  {
    "plz": "24989",
    "partner": [
      4
    ]
  },
  {
    "plz": "24991",
    "partner": [
      4
    ]
  },
  {
    "plz": "24991",
    "partner": [
      4
    ]
  },
  {
    "plz": "24992",
    "partner": [
      4
    ]
  },
  {
    "plz": "24992",
    "partner": [
      4
    ]
  },
  {
    "plz": "24992",
    "partner": [
      4
    ]
  },
  {
    "plz": "24992",
    "partner": [
      4
    ]
  },
  {
    "plz": "24994",
    "partner": [
      4
    ]
  },
  {
    "plz": "24994",
    "partner": [
      4
    ]
  },
  {
    "plz": "24994",
    "partner": [
      4
    ]
  },
  {
    "plz": "24994",
    "partner": [
      4
    ]
  },
  {
    "plz": "24994",
    "partner": [
      4
    ]
  },
  {
    "plz": "24994",
    "partner": [
      4
    ]
  },
  {
    "plz": "24996",
    "partner": [
      4
    ]
  },
  {
    "plz": "24996",
    "partner": [
      4
    ]
  },
  {
    "plz": "24997",
    "partner": [
      4
    ]
  },
  {
    "plz": "24999",
    "partner": [
      4
    ]
  },
  {
    "plz": "25335",
    "partner": [
      4
    ]
  },
  {
    "plz": "25335",
    "partner": [
      4
    ]
  },
  {
    "plz": "25335",
    "partner": [
      4
    ]
  },
  {
    "plz": "25335",
    "partner": [
      4
    ]
  },
  {
    "plz": "25335",
    "partner": [
      4
    ]
  },
  {
    "plz": "25336",
    "partner": [
      4
    ]
  },
  {
    "plz": "25336",
    "partner": [
      4
    ]
  },
  {
    "plz": "25337",
    "partner": [
      4
    ]
  },
  {
    "plz": "25337",
    "partner": [
      4
    ]
  },
  {
    "plz": "25337",
    "partner": [
      4
    ]
  },
  {
    "plz": "25348",
    "partner": [
      4
    ]
  },
  {
    "plz": "25348",
    "partner": [
      4
    ]
  },
  {
    "plz": "25348",
    "partner": [
      4
    ]
  },
  {
    "plz": "25355",
    "partner": [
      4
    ]
  },
  {
    "plz": "25355",
    "partner": [
      4
    ]
  },
  {
    "plz": "25355",
    "partner": [
      4
    ]
  },
  {
    "plz": "25355",
    "partner": [
      4
    ]
  },
  {
    "plz": "25355",
    "partner": [
      4
    ]
  },
  {
    "plz": "25355",
    "partner": [
      4
    ]
  },
  {
    "plz": "25358",
    "partner": [
      4
    ]
  },
  {
    "plz": "25358",
    "partner": [
      4
    ]
  },
  {
    "plz": "25358",
    "partner": [
      4
    ]
  },
  {
    "plz": "25361",
    "partner": [
      4
    ]
  },
  {
    "plz": "25361",
    "partner": [
      4
    ]
  },
  {
    "plz": "25361",
    "partner": [
      4
    ]
  },
  {
    "plz": "25361",
    "partner": [
      4
    ]
  },
  {
    "plz": "25361",
    "partner": [
      4
    ]
  },
  {
    "plz": "25361",
    "partner": [
      4
    ]
  },
  {
    "plz": "25364",
    "partner": [
      4
    ]
  },
  {
    "plz": "25364",
    "partner": [
      4
    ]
  },
  {
    "plz": "25364",
    "partner": [
      4
    ]
  },
  {
    "plz": "25364",
    "partner": [
      4
    ]
  },
  {
    "plz": "25365",
    "partner": [
      4
    ]
  },
  {
    "plz": "25368",
    "partner": [
      4
    ]
  },
  {
    "plz": "25370",
    "partner": [
      4
    ]
  },
  {
    "plz": "25371",
    "partner": [
      4
    ]
  },
  {
    "plz": "25373",
    "partner": [
      4
    ]
  },
  {
    "plz": "25376",
    "partner": [
      4
    ]
  },
  {
    "plz": "25376",
    "partner": [
      4
    ]
  },
  {
    "plz": "25377",
    "partner": [
      4
    ]
  },
  {
    "plz": "25377",
    "partner": [
      4
    ]
  },
  {
    "plz": "25379",
    "partner": [
      4
    ]
  },
  {
    "plz": "25379",
    "partner": [
      4
    ]
  },
  {
    "plz": "25421",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25436",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25451",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25462",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25469",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25474",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25474",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25474",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25479",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25482",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25485",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25485",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25485",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25486",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25488",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25489",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25489",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25491",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25492",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25492",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25494",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25495",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25497",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25499",
    "partner": [
      4,
      12
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25524",
    "partner": [
      4
    ]
  },
  {
    "plz": "25541",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25548",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25551",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25554",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25557",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25560",
    "partner": [
      4
    ]
  },
  {
    "plz": "25563",
    "partner": [
      4
    ]
  },
  {
    "plz": "25563",
    "partner": [
      4
    ]
  },
  {
    "plz": "25563",
    "partner": [
      4
    ]
  },
  {
    "plz": "25563",
    "partner": [
      4
    ]
  },
  {
    "plz": "25563",
    "partner": [
      4
    ]
  },
  {
    "plz": "25563",
    "partner": [
      4
    ]
  },
  {
    "plz": "25566",
    "partner": [
      4
    ]
  },
  {
    "plz": "25566",
    "partner": [
      4
    ]
  },
  {
    "plz": "25569",
    "partner": [
      4
    ]
  },
  {
    "plz": "25569",
    "partner": [
      4
    ]
  },
  {
    "plz": "25569",
    "partner": [
      4
    ]
  },
  {
    "plz": "25569",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25572",
    "partner": [
      4
    ]
  },
  {
    "plz": "25573",
    "partner": [
      4
    ]
  },
  {
    "plz": "25573",
    "partner": [
      4
    ]
  },
  {
    "plz": "25575",
    "partner": [
      4
    ]
  },
  {
    "plz": "25576",
    "partner": [
      4
    ]
  },
  {
    "plz": "25578",
    "partner": [
      4
    ]
  },
  {
    "plz": "25578",
    "partner": [
      4
    ]
  },
  {
    "plz": "25579",
    "partner": [
      4
    ]
  },
  {
    "plz": "25579",
    "partner": [
      4
    ]
  },
  {
    "plz": "25581",
    "partner": [
      4
    ]
  },
  {
    "plz": "25581",
    "partner": [
      4
    ]
  },
  {
    "plz": "25582",
    "partner": [
      4
    ]
  },
  {
    "plz": "25582",
    "partner": [
      4
    ]
  },
  {
    "plz": "25582",
    "partner": [
      4
    ]
  },
  {
    "plz": "25582",
    "partner": [
      4
    ]
  },
  {
    "plz": "25584",
    "partner": [
      4
    ]
  },
  {
    "plz": "25584",
    "partner": [
      4
    ]
  },
  {
    "plz": "25585",
    "partner": [
      4
    ]
  },
  {
    "plz": "25585",
    "partner": [
      4
    ]
  },
  {
    "plz": "25587",
    "partner": [
      4
    ]
  },
  {
    "plz": "25588",
    "partner": [
      4
    ]
  },
  {
    "plz": "25588",
    "partner": [
      4
    ]
  },
  {
    "plz": "25588",
    "partner": [
      4
    ]
  },
  {
    "plz": "25590",
    "partner": [
      4
    ]
  },
  {
    "plz": "25591",
    "partner": [
      4
    ]
  },
  {
    "plz": "25593",
    "partner": [
      4
    ]
  },
  {
    "plz": "25593",
    "partner": [
      4
    ]
  },
  {
    "plz": "25594",
    "partner": [
      4
    ]
  },
  {
    "plz": "25594",
    "partner": [
      4
    ]
  },
  {
    "plz": "25594",
    "partner": [
      4
    ]
  },
  {
    "plz": "25596",
    "partner": [
      4
    ]
  },
  {
    "plz": "25596",
    "partner": [
      4
    ]
  },
  {
    "plz": "25596",
    "partner": [
      4
    ]
  },
  {
    "plz": "25596",
    "partner": [
      4
    ]
  },
  {
    "plz": "25597",
    "partner": [
      4
    ]
  },
  {
    "plz": "25597",
    "partner": [
      4
    ]
  },
  {
    "plz": "25597",
    "partner": [
      4
    ]
  },
  {
    "plz": "25597",
    "partner": [
      4
    ]
  },
  {
    "plz": "25599",
    "partner": [
      4
    ]
  },
  {
    "plz": "25693",
    "partner": [
      4
    ]
  },
  {
    "plz": "25693",
    "partner": [
      4
    ]
  },
  {
    "plz": "25693",
    "partner": [
      4
    ]
  },
  {
    "plz": "25693",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25704",
    "partner": [
      4
    ]
  },
  {
    "plz": "25709",
    "partner": [
      4
    ]
  },
  {
    "plz": "25709",
    "partner": [
      4
    ]
  },
  {
    "plz": "25709",
    "partner": [
      4
    ]
  },
  {
    "plz": "25709",
    "partner": [
      4
    ]
  },
  {
    "plz": "25709",
    "partner": [
      4
    ]
  },
  {
    "plz": "25709",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25712",
    "partner": [
      4
    ]
  },
  {
    "plz": "25715",
    "partner": [
      4
    ]
  },
  {
    "plz": "25715",
    "partner": [
      4
    ]
  },
  {
    "plz": "25715",
    "partner": [
      4
    ]
  },
  {
    "plz": "25715",
    "partner": [
      4
    ]
  },
  {
    "plz": "25715",
    "partner": [
      4
    ]
  },
  {
    "plz": "25718",
    "partner": [
      4
    ]
  },
  {
    "plz": "25719",
    "partner": [
      4
    ]
  },
  {
    "plz": "25719",
    "partner": [
      4
    ]
  },
  {
    "plz": "25721",
    "partner": [
      4
    ]
  },
  {
    "plz": "25724",
    "partner": [
      4
    ]
  },
  {
    "plz": "25724",
    "partner": [
      4
    ]
  },
  {
    "plz": "25724",
    "partner": [
      4
    ]
  },
  {
    "plz": "25725",
    "partner": [
      4
    ]
  },
  {
    "plz": "25725",
    "partner": [
      4
    ]
  },
  {
    "plz": "25725",
    "partner": [
      4
    ]
  },
  {
    "plz": "25727",
    "partner": [
      4
    ]
  },
  {
    "plz": "25727",
    "partner": [
      4
    ]
  },
  {
    "plz": "25727",
    "partner": [
      4
    ]
  },
  {
    "plz": "25729",
    "partner": [
      4
    ]
  },
  {
    "plz": "25746",
    "partner": [
      4
    ]
  },
  {
    "plz": "25746",
    "partner": [
      4
    ]
  },
  {
    "plz": "25746",
    "partner": [
      4
    ]
  },
  {
    "plz": "25746",
    "partner": [
      4
    ]
  },
  {
    "plz": "25746",
    "partner": [
      4
    ]
  },
  {
    "plz": "25761",
    "partner": [
      4
    ]
  },
  {
    "plz": "25761",
    "partner": [
      4
    ]
  },
  {
    "plz": "25761",
    "partner": [
      4
    ]
  },
  {
    "plz": "25761",
    "partner": [
      4
    ]
  },
  {
    "plz": "25761",
    "partner": [
      4
    ]
  },
  {
    "plz": "25761",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25764",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25767",
    "partner": [
      4
    ]
  },
  {
    "plz": "25770",
    "partner": [
      4
    ]
  },
  {
    "plz": "25770",
    "partner": [
      4
    ]
  },
  {
    "plz": "25774",
    "partner": [
      4
    ]
  },
  {
    "plz": "25774",
    "partner": [
      4
    ]
  },
  {
    "plz": "25774",
    "partner": [
      4
    ]
  },
  {
    "plz": "25774",
    "partner": [
      4
    ]
  },
  {
    "plz": "25774",
    "partner": [
      4
    ]
  },
  {
    "plz": "25774",
    "partner": [
      4
    ]
  },
  {
    "plz": "25776",
    "partner": [
      4
    ]
  },
  {
    "plz": "25776",
    "partner": [
      4
    ]
  },
  {
    "plz": "25776",
    "partner": [
      4
    ]
  },
  {
    "plz": "25776",
    "partner": [
      4
    ]
  },
  {
    "plz": "25776",
    "partner": [
      4
    ]
  },
  {
    "plz": "25776",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25779",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25782",
    "partner": [
      4
    ]
  },
  {
    "plz": "25785",
    "partner": [
      4
    ]
  },
  {
    "plz": "25785",
    "partner": [
      4
    ]
  },
  {
    "plz": "25785",
    "partner": [
      4
    ]
  },
  {
    "plz": "25786",
    "partner": [
      4
    ]
  },
  {
    "plz": "25788",
    "partner": [
      4
    ]
  },
  {
    "plz": "25788",
    "partner": [
      4
    ]
  },
  {
    "plz": "25788",
    "partner": [
      4
    ]
  },
  {
    "plz": "25791",
    "partner": [
      4
    ]
  },
  {
    "plz": "25791",
    "partner": [
      4
    ]
  },
  {
    "plz": "25792",
    "partner": [
      4
    ]
  },
  {
    "plz": "25792",
    "partner": [
      4
    ]
  },
  {
    "plz": "25794",
    "partner": [
      4
    ]
  },
  {
    "plz": "25794",
    "partner": [
      4
    ]
  },
  {
    "plz": "25794",
    "partner": [
      4
    ]
  },
  {
    "plz": "25795",
    "partner": [
      4
    ]
  },
  {
    "plz": "25795",
    "partner": [
      4
    ]
  },
  {
    "plz": "25797",
    "partner": [
      4
    ]
  },
  {
    "plz": "25799",
    "partner": [
      4
    ]
  },
  {
    "plz": "25813",
    "partner": [
      4
    ]
  },
  {
    "plz": "25813",
    "partner": [
      4
    ]
  },
  {
    "plz": "25813",
    "partner": [
      4
    ]
  },
  {
    "plz": "25813",
    "partner": [
      4
    ]
  },
  {
    "plz": "25813",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25821",
    "partner": [
      4
    ]
  },
  {
    "plz": "25826",
    "partner": [
      4
    ]
  },
  {
    "plz": "25832",
    "partner": [
      4
    ]
  },
  {
    "plz": "25832",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25836",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25840",
    "partner": [
      4
    ]
  },
  {
    "plz": "25842",
    "partner": [
      4
    ]
  },
  {
    "plz": "25842",
    "partner": [
      4
    ]
  },
  {
    "plz": "25842",
    "partner": [
      4
    ]
  },
  {
    "plz": "25842",
    "partner": [
      4
    ]
  },
  {
    "plz": "25842",
    "partner": [
      4
    ]
  },
  {
    "plz": "25845",
    "partner": [
      4
    ]
  },
  {
    "plz": "25845",
    "partner": [
      4
    ]
  },
  {
    "plz": "25845",
    "partner": [
      4
    ]
  },
  {
    "plz": "25845",
    "partner": [
      4
    ]
  },
  {
    "plz": "25849",
    "partner": [
      4
    ]
  },
  {
    "plz": "25849",
    "partner": [
      4
    ]
  },
  {
    "plz": "25850",
    "partner": [
      4
    ]
  },
  {
    "plz": "25850",
    "partner": [
      4
    ]
  },
  {
    "plz": "25852",
    "partner": [
      4
    ]
  },
  {
    "plz": "25853",
    "partner": [
      4
    ]
  },
  {
    "plz": "25853",
    "partner": [
      4
    ]
  },
  {
    "plz": "25853",
    "partner": [
      4
    ]
  },
  {
    "plz": "25855",
    "partner": [
      4
    ]
  },
  {
    "plz": "25856",
    "partner": [
      4
    ]
  },
  {
    "plz": "25856",
    "partner": [
      4
    ]
  },
  {
    "plz": "25856",
    "partner": [
      4
    ]
  },
  {
    "plz": "25858",
    "partner": [
      4
    ]
  },
  {
    "plz": "25859",
    "partner": [
      4
    ]
  },
  {
    "plz": "25860",
    "partner": [
      4
    ]
  },
  {
    "plz": "25860",
    "partner": [
      4
    ]
  },
  {
    "plz": "25860",
    "partner": [
      4
    ]
  },
  {
    "plz": "25862",
    "partner": [
      4
    ]
  },
  {
    "plz": "25862",
    "partner": [
      4
    ]
  },
  {
    "plz": "25862",
    "partner": [
      4
    ]
  },
  {
    "plz": "25862",
    "partner": [
      4
    ]
  },
  {
    "plz": "25863",
    "partner": [
      4
    ]
  },
  {
    "plz": "25864",
    "partner": [
      4
    ]
  },
  {
    "plz": "25866",
    "partner": [
      4
    ]
  },
  {
    "plz": "25867",
    "partner": [
      4
    ]
  },
  {
    "plz": "25869",
    "partner": [
      4
    ]
  },
  {
    "plz": "25869",
    "partner": [
      4
    ]
  },
  {
    "plz": "25870",
    "partner": [
      4
    ]
  },
  {
    "plz": "25870",
    "partner": [
      4
    ]
  },
  {
    "plz": "25872",
    "partner": [
      4
    ]
  },
  {
    "plz": "25872",
    "partner": [
      4
    ]
  },
  {
    "plz": "25873",
    "partner": [
      4
    ]
  },
  {
    "plz": "25873",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25876",
    "partner": [
      4
    ]
  },
  {
    "plz": "25878",
    "partner": [
      4
    ]
  },
  {
    "plz": "25878",
    "partner": [
      4
    ]
  },
  {
    "plz": "25879",
    "partner": [
      4
    ]
  },
  {
    "plz": "25881",
    "partner": [
      4
    ]
  },
  {
    "plz": "25881",
    "partner": [
      4
    ]
  },
  {
    "plz": "25881",
    "partner": [
      4
    ]
  },
  {
    "plz": "25882",
    "partner": [
      4
    ]
  },
  {
    "plz": "25884",
    "partner": [
      4
    ]
  },
  {
    "plz": "25884",
    "partner": [
      4
    ]
  },
  {
    "plz": "25884",
    "partner": [
      4
    ]
  },
  {
    "plz": "25885",
    "partner": [
      4
    ]
  },
  {
    "plz": "25885",
    "partner": [
      4
    ]
  },
  {
    "plz": "25885",
    "partner": [
      4
    ]
  },
  {
    "plz": "25885",
    "partner": [
      4
    ]
  },
  {
    "plz": "25885",
    "partner": [
      4
    ]
  },
  {
    "plz": "25887",
    "partner": [
      4
    ]
  },
  {
    "plz": "25889",
    "partner": [
      4
    ]
  },
  {
    "plz": "25889",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25899",
    "partner": [
      4
    ]
  },
  {
    "plz": "25917",
    "partner": [
      4
    ]
  },
  {
    "plz": "25917",
    "partner": [
      4
    ]
  },
  {
    "plz": "25917",
    "partner": [
      4
    ]
  },
  {
    "plz": "25917",
    "partner": [
      4
    ]
  },
  {
    "plz": "25917",
    "partner": [
      4
    ]
  },
  {
    "plz": "25917",
    "partner": [
      4
    ]
  },
  {
    "plz": "25920",
    "partner": [
      4
    ]
  },
  {
    "plz": "25920",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25923",
    "partner": [
      4
    ]
  },
  {
    "plz": "25924",
    "partner": [
      4
    ]
  },
  {
    "plz": "25924",
    "partner": [
      4
    ]
  },
  {
    "plz": "25924",
    "partner": [
      4
    ]
  },
  {
    "plz": "25924",
    "partner": [
      4
    ]
  },
  {
    "plz": "25926",
    "partner": [
      4
    ]
  },
  {
    "plz": "25926",
    "partner": [
      4
    ]
  },
  {
    "plz": "25926",
    "partner": [
      4
    ]
  },
  {
    "plz": "25926",
    "partner": [
      4
    ]
  },
  {
    "plz": "25927",
    "partner": [
      4
    ]
  },
  {
    "plz": "25927",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25938",
    "partner": [
      4
    ]
  },
  {
    "plz": "25946",
    "partner": [
      4
    ]
  },
  {
    "plz": "25946",
    "partner": [
      4
    ]
  },
  {
    "plz": "25946",
    "partner": [
      4
    ]
  },
  {
    "plz": "25980",
    "partner": [
      4
    ]
  },
  {
    "plz": "25992",
    "partner": [
      4
    ]
  },
  {
    "plz": "25992",
    "partner": [
      4
    ]
  },
  {
    "plz": "25992",
    "partner": [
      4
    ]
  },
  {
    "plz": "25996",
    "partner": [
      4
    ]
  },
  {
    "plz": "25997",
    "partner": [
      4
    ]
  },
  {
    "plz": "25999",
    "partner": [
      4
    ]
  },
  {
    "plz": "26121",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26122",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26123",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26125",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26127",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26129",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26131",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26133",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26135",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26160",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26169",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26180",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26188",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26197",
    "partner": [
      5,
      0
    ]
  },
  {
    "plz": "26203",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26209",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26215",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26219",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26316",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26340",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26345",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26349",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26382",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26384",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26386",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26388",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26389",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26409",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26409",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26409",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26419",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26427",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26434",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26441",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26441",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26446",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26452",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26465",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26474",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26486",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26487",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26487",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26489",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26506",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26524",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26524",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26524",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26524",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26524",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26529",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26529",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26529",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26529",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26529",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26529",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26532",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26548",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26553",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26556",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26556",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26556",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26556",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26556",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26571",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26571",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26579",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26603",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26605",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26607",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26624",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26629",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26632",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26639",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26655",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26670",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26676",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26683",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26689",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26721",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26723",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26725",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26736",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26757",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26759",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26759",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26789",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26802",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26810",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26817",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26826",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26831",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26835",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26835",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26835",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26835",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26835",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26835",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26842",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26844",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26845",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26847",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26849",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26871",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26871",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26892",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26892",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26892",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26892",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26892",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26897",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26897",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26897",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26897",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26899",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26901",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26901",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26903",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26904",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26906",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26907",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26909",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26909",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26919",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26931",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26935",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26936",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26937",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26939",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26954",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "26969",
    "partner": [
      0,
      5
    ]
  },
  {
    "plz": "27211",
    "partner": [
      5
    ]
  },
  {
    "plz": "27232",
    "partner": [
      5
    ]
  },
  {
    "plz": "27239",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27243",
    "partner": [
      5
    ]
  },
  {
    "plz": "27245",
    "partner": [
      5
    ]
  },
  {
    "plz": "27245",
    "partner": [
      5
    ]
  },
  {
    "plz": "27245",
    "partner": [
      5
    ]
  },
  {
    "plz": "27246",
    "partner": [
      5
    ]
  },
  {
    "plz": "27248",
    "partner": [
      5
    ]
  },
  {
    "plz": "27249",
    "partner": [
      5
    ]
  },
  {
    "plz": "27249",
    "partner": [
      5
    ]
  },
  {
    "plz": "27251",
    "partner": [
      5
    ]
  },
  {
    "plz": "27251",
    "partner": [
      5
    ]
  },
  {
    "plz": "27252",
    "partner": [
      5
    ]
  },
  {
    "plz": "27254",
    "partner": [
      5
    ]
  },
  {
    "plz": "27254",
    "partner": [
      5
    ]
  },
  {
    "plz": "27257",
    "partner": [
      5
    ]
  },
  {
    "plz": "27257",
    "partner": [
      5
    ]
  },
  {
    "plz": "27259",
    "partner": [
      5
    ]
  },
  {
    "plz": "27259",
    "partner": [
      5
    ]
  },
  {
    "plz": "27259",
    "partner": [
      5
    ]
  },
  {
    "plz": "27283",
    "partner": [
      5
    ]
  },
  {
    "plz": "27299",
    "partner": [
      5
    ]
  },
  {
    "plz": "27305",
    "partner": [
      5
    ]
  },
  {
    "plz": "27308",
    "partner": [
      5
    ]
  },
  {
    "plz": "27313",
    "partner": [
      5
    ]
  },
  {
    "plz": "27318",
    "partner": [
      5
    ]
  },
  {
    "plz": "27318",
    "partner": [
      5
    ]
  },
  {
    "plz": "27318",
    "partner": [
      5
    ]
  },
  {
    "plz": "27321",
    "partner": [
      5
    ]
  },
  {
    "plz": "27321",
    "partner": [
      5
    ]
  },
  {
    "plz": "27324",
    "partner": [
      5
    ]
  },
  {
    "plz": "27324",
    "partner": [
      5
    ]
  },
  {
    "plz": "27324",
    "partner": [
      5
    ]
  },
  {
    "plz": "27324",
    "partner": [
      5
    ]
  },
  {
    "plz": "27324",
    "partner": [
      5
    ]
  },
  {
    "plz": "27327",
    "partner": [
      5
    ]
  },
  {
    "plz": "27327",
    "partner": [
      5
    ]
  },
  {
    "plz": "27330",
    "partner": [
      5
    ]
  },
  {
    "plz": "27333",
    "partner": [
      5
    ]
  },
  {
    "plz": "27333",
    "partner": [
      5
    ]
  },
  {
    "plz": "27333",
    "partner": [
      5
    ]
  },
  {
    "plz": "27336",
    "partner": [
      5
    ]
  },
  {
    "plz": "27336",
    "partner": [
      5
    ]
  },
  {
    "plz": "27336",
    "partner": [
      5
    ]
  },
  {
    "plz": "27337",
    "partner": [
      5
    ]
  },
  {
    "plz": "27339",
    "partner": [
      5
    ]
  },
  {
    "plz": "27356",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27367",
    "partner": [
      5
    ]
  },
  {
    "plz": "27374",
    "partner": [
      5
    ]
  },
  {
    "plz": "27383",
    "partner": [
      5
    ]
  },
  {
    "plz": "27386",
    "partner": [
      5
    ]
  },
  {
    "plz": "27386",
    "partner": [
      5
    ]
  },
  {
    "plz": "27386",
    "partner": [
      5
    ]
  },
  {
    "plz": "27386",
    "partner": [
      5
    ]
  },
  {
    "plz": "27386",
    "partner": [
      5
    ]
  },
  {
    "plz": "27386",
    "partner": [
      5
    ]
  },
  {
    "plz": "27389",
    "partner": [
      5
    ]
  },
  {
    "plz": "27389",
    "partner": [
      5
    ]
  },
  {
    "plz": "27389",
    "partner": [
      5
    ]
  },
  {
    "plz": "27389",
    "partner": [
      5
    ]
  },
  {
    "plz": "27389",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27404",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27412",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27419",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27432",
    "partner": [
      5
    ]
  },
  {
    "plz": "27442",
    "partner": [
      5
    ]
  },
  {
    "plz": "27446",
    "partner": [
      5
    ]
  },
  {
    "plz": "27446",
    "partner": [
      5
    ]
  },
  {
    "plz": "27446",
    "partner": [
      5
    ]
  },
  {
    "plz": "27446",
    "partner": [
      5
    ]
  },
  {
    "plz": "27446",
    "partner": [
      5
    ]
  },
  {
    "plz": "27449",
    "partner": [
      5
    ]
  },
  {
    "plz": "27472",
    "partner": [
      5
    ]
  },
  {
    "plz": "27474",
    "partner": [
      5
    ]
  },
  {
    "plz": "27476",
    "partner": [
      5
    ]
  },
  {
    "plz": "27478",
    "partner": [
      5
    ]
  },
  {
    "plz": "27498",
    "partner": [
      5
    ]
  },
  {
    "plz": "27499",
    "partner": [
      5
    ]
  },
  {
    "plz": "27568",
    "partner": [
      5
    ]
  },
  {
    "plz": "27570",
    "partner": [
      5
    ]
  },
  {
    "plz": "27572",
    "partner": [
      5
    ]
  },
  {
    "plz": "27574",
    "partner": [
      5
    ]
  },
  {
    "plz": "27576",
    "partner": [
      5
    ]
  },
  {
    "plz": "27578",
    "partner": [
      5
    ]
  },
  {
    "plz": "27580",
    "partner": [
      5
    ]
  },
  {
    "plz": "27607",
    "partner": [
      5
    ]
  },
  {
    "plz": "27612",
    "partner": [
      5
    ]
  },
  {
    "plz": "27616",
    "partner": [
      5
    ]
  },
  {
    "plz": "27619",
    "partner": [
      5
    ]
  },
  {
    "plz": "27624",
    "partner": [
      5
    ]
  },
  {
    "plz": "27628",
    "partner": [
      5
    ]
  },
  {
    "plz": "27639",
    "partner": [
      5
    ]
  },
  {
    "plz": "27711",
    "partner": [
      5
    ]
  },
  {
    "plz": "27721",
    "partner": [
      5
    ]
  },
  {
    "plz": "27726",
    "partner": [
      5
    ]
  },
  {
    "plz": "27726",
    "partner": [
      5
    ]
  },
  {
    "plz": "27729",
    "partner": [
      5
    ]
  },
  {
    "plz": "27729",
    "partner": [
      5
    ]
  },
  {
    "plz": "27729",
    "partner": [
      5
    ]
  },
  {
    "plz": "27729",
    "partner": [
      5
    ]
  },
  {
    "plz": "27729",
    "partner": [
      5
    ]
  },
  {
    "plz": "27749",
    "partner": [
      5
    ]
  },
  {
    "plz": "27751",
    "partner": [
      5
    ]
  },
  {
    "plz": "27753",
    "partner": [
      5
    ]
  },
  {
    "plz": "27755",
    "partner": [
      5
    ]
  },
  {
    "plz": "27777",
    "partner": [
      5
    ]
  },
  {
    "plz": "27793",
    "partner": [
      5
    ]
  },
  {
    "plz": "27798",
    "partner": [
      5
    ]
  },
  {
    "plz": "27801",
    "partner": [
      5
    ]
  },
  {
    "plz": "27804",
    "partner": [
      5
    ]
  },
  {
    "plz": "27809",
    "partner": [
      5
    ]
  },
  {
    "plz": "28195",
    "partner": [
      5
    ]
  },
  {
    "plz": "28197",
    "partner": [
      5
    ]
  },
  {
    "plz": "28199",
    "partner": [
      5
    ]
  },
  {
    "plz": "28201",
    "partner": [
      5
    ]
  },
  {
    "plz": "28203",
    "partner": [
      5
    ]
  },
  {
    "plz": "28205",
    "partner": [
      5
    ]
  },
  {
    "plz": "28207",
    "partner": [
      5
    ]
  },
  {
    "plz": "28209",
    "partner": [
      5
    ]
  },
  {
    "plz": "28211",
    "partner": [
      5
    ]
  },
  {
    "plz": "28213",
    "partner": [
      5
    ]
  },
  {
    "plz": "28215",
    "partner": [
      5
    ]
  },
  {
    "plz": "28217",
    "partner": [
      5
    ]
  },
  {
    "plz": "28219",
    "partner": [
      5
    ]
  },
  {
    "plz": "28237",
    "partner": [
      5
    ]
  },
  {
    "plz": "28239",
    "partner": [
      5
    ]
  },
  {
    "plz": "28259",
    "partner": [
      5
    ]
  },
  {
    "plz": "28277",
    "partner": [
      5
    ]
  },
  {
    "plz": "28279",
    "partner": [
      5
    ]
  },
  {
    "plz": "28307",
    "partner": [
      5
    ]
  },
  {
    "plz": "28309",
    "partner": [
      5
    ]
  },
  {
    "plz": "28325",
    "partner": [
      5
    ]
  },
  {
    "plz": "28327",
    "partner": [
      5
    ]
  },
  {
    "plz": "28329",
    "partner": [
      5
    ]
  },
  {
    "plz": "28355",
    "partner": [
      5
    ]
  },
  {
    "plz": "28357",
    "partner": [
      5
    ]
  },
  {
    "plz": "28359",
    "partner": [
      5
    ]
  },
  {
    "plz": "28717",
    "partner": [
      5
    ]
  },
  {
    "plz": "28719",
    "partner": [
      5
    ]
  },
  {
    "plz": "28755",
    "partner": [
      5
    ]
  },
  {
    "plz": "28757",
    "partner": [
      5
    ]
  },
  {
    "plz": "28759",
    "partner": [
      5
    ]
  },
  {
    "plz": "28777",
    "partner": [
      5
    ]
  },
  {
    "plz": "28779",
    "partner": [
      5
    ]
  },
  {
    "plz": "28790",
    "partner": [
      5
    ]
  },
  {
    "plz": "28816",
    "partner": [
      5
    ]
  },
  {
    "plz": "28832",
    "partner": [
      5
    ]
  },
  {
    "plz": "28844",
    "partner": [
      5
    ]
  },
  {
    "plz": "28857",
    "partner": [
      5
    ]
  },
  {
    "plz": "28865",
    "partner": [
      5
    ]
  },
  {
    "plz": "28870",
    "partner": [
      5
    ]
  },
  {
    "plz": "28876",
    "partner": [
      5
    ]
  },
  {
    "plz": "28879",
    "partner": [
      5
    ]
  },
  {
    "plz": "29221",
    "partner": [
      0
    ]
  },
  {
    "plz": "29223",
    "partner": [
      0
    ]
  },
  {
    "plz": "29225",
    "partner": [
      0
    ]
  },
  {
    "plz": "29227",
    "partner": [
      0
    ]
  },
  {
    "plz": "29229",
    "partner": [
      0
    ]
  },
  {
    "plz": "29229",
    "partner": [
      0
    ]
  },
  {
    "plz": "29303",
    "partner": [
      0
    ]
  },
  {
    "plz": "29303",
    "partner": [
      0
    ]
  },
  {
    "plz": "29303",
    "partner": [
      0
    ]
  },
  {
    "plz": "29303",
    "partner": [
      0
    ]
  },
  {
    "plz": "29308",
    "partner": [
      0
    ]
  },
  {
    "plz": "29313",
    "partner": [
      0
    ]
  },
  {
    "plz": "29320",
    "partner": [
      0
    ]
  },
  {
    "plz": "29323",
    "partner": [
      0
    ]
  },
  {
    "plz": "29328",
    "partner": [
      0
    ]
  },
  {
    "plz": "29331",
    "partner": [
      0
    ]
  },
  {
    "plz": "29336",
    "partner": [
      0
    ]
  },
  {
    "plz": "29339",
    "partner": [
      0
    ]
  },
  {
    "plz": "29342",
    "partner": [
      0
    ]
  },
  {
    "plz": "29345",
    "partner": [
      0
    ]
  },
  {
    "plz": "29348",
    "partner": [
      0
    ]
  },
  {
    "plz": "29348",
    "partner": [
      0
    ]
  },
  {
    "plz": "29351",
    "partner": [
      0
    ]
  },
  {
    "plz": "29352",
    "partner": [
      0
    ]
  },
  {
    "plz": "29353",
    "partner": [
      0
    ]
  },
  {
    "plz": "29355",
    "partner": [
      0
    ]
  },
  {
    "plz": "29356",
    "partner": [
      0
    ]
  },
  {
    "plz": "29358",
    "partner": [
      0
    ]
  },
  {
    "plz": "29359",
    "partner": [
      0
    ]
  },
  {
    "plz": "29361",
    "partner": [
      0
    ]
  },
  {
    "plz": "29362",
    "partner": [
      0
    ]
  },
  {
    "plz": "29364",
    "partner": [
      0
    ]
  },
  {
    "plz": "29365",
    "partner": [
      0
    ]
  },
  {
    "plz": "29367",
    "partner": [
      0
    ]
  },
  {
    "plz": "29369",
    "partner": [
      0
    ]
  },
  {
    "plz": "29378",
    "partner": [
      0
    ]
  },
  {
    "plz": "29379",
    "partner": [
      0
    ]
  },
  {
    "plz": "29386",
    "partner": [
      0
    ]
  },
  {
    "plz": "29386",
    "partner": [
      0
    ]
  },
  {
    "plz": "29386",
    "partner": [
      0
    ]
  },
  {
    "plz": "29389",
    "partner": [
      0
    ]
  },
  {
    "plz": "29392",
    "partner": [
      0
    ]
  },
  {
    "plz": "29393",
    "partner": [
      0
    ]
  },
  {
    "plz": "29394",
    "partner": [
      0
    ]
  },
  {
    "plz": "29396",
    "partner": [
      0
    ]
  },
  {
    "plz": "29399",
    "partner": [
      0
    ]
  },
  {
    "plz": "29410",
    "partner": [
      0
    ]
  },
  {
    "plz": "29413",
    "partner": [
      0
    ]
  },
  {
    "plz": "29413",
    "partner": [
      0
    ]
  },
  {
    "plz": "29413",
    "partner": [
      0
    ]
  },
  {
    "plz": "29416",
    "partner": [
      0
    ]
  },
  {
    "plz": "29439",
    "partner": [
      0
    ]
  },
  {
    "plz": "29451",
    "partner": [
      0
    ]
  },
  {
    "plz": "29456",
    "partner": [
      0
    ]
  },
  {
    "plz": "29459",
    "partner": [
      0
    ]
  },
  {
    "plz": "29462",
    "partner": [
      0
    ]
  },
  {
    "plz": "29465",
    "partner": [
      0
    ]
  },
  {
    "plz": "29468",
    "partner": [
      0
    ]
  },
  {
    "plz": "29471",
    "partner": [
      0
    ]
  },
  {
    "plz": "29472",
    "partner": [
      0
    ]
  },
  {
    "plz": "29473",
    "partner": [
      0
    ]
  },
  {
    "plz": "29475",
    "partner": [
      0
    ]
  },
  {
    "plz": "29476",
    "partner": [
      0
    ]
  },
  {
    "plz": "29478",
    "partner": [
      0
    ]
  },
  {
    "plz": "29479",
    "partner": [
      0
    ]
  },
  {
    "plz": "29481",
    "partner": [
      0
    ]
  },
  {
    "plz": "29482",
    "partner": [
      0
    ]
  },
  {
    "plz": "29484",
    "partner": [
      0
    ]
  },
  {
    "plz": "29485",
    "partner": [
      0
    ]
  },
  {
    "plz": "29487",
    "partner": [
      0
    ]
  },
  {
    "plz": "29488",
    "partner": [
      0
    ]
  },
  {
    "plz": "29490",
    "partner": [
      0
    ]
  },
  {
    "plz": "29491",
    "partner": [
      0
    ]
  },
  {
    "plz": "29493",
    "partner": [
      0
    ]
  },
  {
    "plz": "29494",
    "partner": [
      0
    ]
  },
  {
    "plz": "29496",
    "partner": [
      0
    ]
  },
  {
    "plz": "29497",
    "partner": [
      0
    ]
  },
  {
    "plz": "29499",
    "partner": [
      0
    ]
  },
  {
    "plz": "29525",
    "partner": [
      0
    ]
  },
  {
    "plz": "29549",
    "partner": [
      0
    ]
  },
  {
    "plz": "29553",
    "partner": [
      0
    ]
  },
  {
    "plz": "29556",
    "partner": [
      0
    ]
  },
  {
    "plz": "29559",
    "partner": [
      0
    ]
  },
  {
    "plz": "29562",
    "partner": [
      0
    ]
  },
  {
    "plz": "29565",
    "partner": [
      0
    ]
  },
  {
    "plz": "29571",
    "partner": [
      0
    ]
  },
  {
    "plz": "29574",
    "partner": [
      0
    ]
  },
  {
    "plz": "29575",
    "partner": [
      0
    ]
  },
  {
    "plz": "29576",
    "partner": [
      0
    ]
  },
  {
    "plz": "29578",
    "partner": [
      0
    ]
  },
  {
    "plz": "29579",
    "partner": [
      0
    ]
  },
  {
    "plz": "29581",
    "partner": [
      0
    ]
  },
  {
    "plz": "29582",
    "partner": [
      0
    ]
  },
  {
    "plz": "29584",
    "partner": [
      0
    ]
  },
  {
    "plz": "29585",
    "partner": [
      0
    ]
  },
  {
    "plz": "29587",
    "partner": [
      0
    ]
  },
  {
    "plz": "29588",
    "partner": [
      0
    ]
  },
  {
    "plz": "29590",
    "partner": [
      0
    ]
  },
  {
    "plz": "29591",
    "partner": [
      0
    ]
  },
  {
    "plz": "29593",
    "partner": [
      0
    ]
  },
  {
    "plz": "29594",
    "partner": [
      0
    ]
  },
  {
    "plz": "29597",
    "partner": [
      0
    ]
  },
  {
    "plz": "29599",
    "partner": [
      0
    ]
  },
  {
    "plz": "29614",
    "partner": [
      0
    ]
  },
  {
    "plz": "29633",
    "partner": [
      0
    ]
  },
  {
    "plz": "29640",
    "partner": [
      0
    ]
  },
  {
    "plz": "29640",
    "partner": [
      0
    ]
  },
  {
    "plz": "29643",
    "partner": [
      0
    ]
  },
  {
    "plz": "29646",
    "partner": [
      0
    ]
  },
  {
    "plz": "29649",
    "partner": [
      0
    ]
  },
  {
    "plz": "29664",
    "partner": [
      0
    ]
  },
  {
    "plz": "29664",
    "partner": [
      0
    ]
  },
  {
    "plz": "29683",
    "partner": [
      0
    ]
  },
  {
    "plz": "29683",
    "partner": [
      0
    ]
  },
  {
    "plz": "29683",
    "partner": [
      0
    ]
  },
  {
    "plz": "29690",
    "partner": [
      0
    ]
  },
  {
    "plz": "29690",
    "partner": [
      0
    ]
  },
  {
    "plz": "29690",
    "partner": [
      0
    ]
  },
  {
    "plz": "29690",
    "partner": [
      0
    ]
  },
  {
    "plz": "29690",
    "partner": [
      0
    ]
  },
  {
    "plz": "29690",
    "partner": [
      0
    ]
  },
  {
    "plz": "29693",
    "partner": [
      0
    ]
  },
  {
    "plz": "29693",
    "partner": [
      0
    ]
  },
  {
    "plz": "29693",
    "partner": [
      0
    ]
  },
  {
    "plz": "29693",
    "partner": [
      0
    ]
  },
  {
    "plz": "29693",
    "partner": [
      0
    ]
  },
  {
    "plz": "29699",
    "partner": [
      0
    ]
  },
  {
    "plz": "30159",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30161",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30163",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30165",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30167",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30169",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30171",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30173",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30175",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30177",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30179",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30419",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30449",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30451",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30453",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30455",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30457",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30459",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30519",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30521",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30539",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30559",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30625",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30627",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30629",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30655",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30657",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30659",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30669",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30823",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30826",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30827",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30851",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30853",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30855",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30880",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30890",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30900",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30916",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30926",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30938",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30952",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30966",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30974",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30982",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "30989",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31008",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31020",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31028",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31036",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31061",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31073",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31073",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31079",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31084",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31089",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31134",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31135",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31137",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31139",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31141",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31157",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31162",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31167",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31171",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31174",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31177",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31180",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31185",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31188",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31191",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31195",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31199",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31224",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31226",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31228",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31234",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31241",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31246",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31249",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31275",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31303",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31311",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31319",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31515",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31535",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31542",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31547",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31552",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31552",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31553",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31553",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31555",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31556",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31558",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31559",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31559",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31582",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31592",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31595",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31600",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31603",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31604",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31606",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31608",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31609",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31613",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31618",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31619",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31621",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31622",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31623",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31626",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31627",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31628",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31629",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31632",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31633",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31634",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31636",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31637",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31638",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31655",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31675",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31683",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31688",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31691",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31691",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31693",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31698",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31698",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31699",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31700",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31702",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31707",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31707",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31708",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31710",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31711",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31712",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31714",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31715",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31717",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31718",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31719",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "31737",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31749",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31785",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31787",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31789",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31812",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31832",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31840",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31848",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31855",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31860",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31863",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31867",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31867",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31867",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31867",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "31868",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "32049",
    "partner": [
      0
    ]
  },
  {
    "plz": "32051",
    "partner": [
      0
    ]
  },
  {
    "plz": "32052",
    "partner": [
      0
    ]
  },
  {
    "plz": "32105",
    "partner": [
      0
    ]
  },
  {
    "plz": "32107",
    "partner": [
      0
    ]
  },
  {
    "plz": "32108",
    "partner": [
      0
    ]
  },
  {
    "plz": "32120",
    "partner": [
      0
    ]
  },
  {
    "plz": "32130",
    "partner": [
      0
    ]
  },
  {
    "plz": "32139",
    "partner": [
      0
    ]
  },
  {
    "plz": "32257",
    "partner": [
      0
    ]
  },
  {
    "plz": "32278",
    "partner": [
      0
    ]
  },
  {
    "plz": "32289",
    "partner": [
      0
    ]
  },
  {
    "plz": "32312",
    "partner": [
      0
    ]
  },
  {
    "plz": "32339",
    "partner": [
      0
    ]
  },
  {
    "plz": "32351",
    "partner": [
      0
    ]
  },
  {
    "plz": "32361",
    "partner": [
      0
    ]
  },
  {
    "plz": "32369",
    "partner": [
      0
    ]
  },
  {
    "plz": "32423",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32425",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32427",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32429",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32457",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32469",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32479",
    "partner": [
      0,
      13
    ]
  },
  {
    "plz": "32545",
    "partner": [
      0
    ]
  },
  {
    "plz": "32547",
    "partner": [
      0
    ]
  },
  {
    "plz": "32549",
    "partner": [
      0
    ]
  },
  {
    "plz": "32584",
    "partner": [
      0
    ]
  },
  {
    "plz": "32602",
    "partner": [
      0
    ]
  },
  {
    "plz": "32609",
    "partner": [
      0
    ]
  },
  {
    "plz": "32657",
    "partner": [
      1
    ]
  },
  {
    "plz": "32676",
    "partner": [
      1
    ]
  },
  {
    "plz": "32683",
    "partner": [
      1
    ]
  },
  {
    "plz": "32689",
    "partner": [
      1
    ]
  },
  {
    "plz": "32694",
    "partner": [
      1
    ]
  },
  {
    "plz": "32699",
    "partner": [
      1
    ]
  },
  {
    "plz": "32756",
    "partner": [
      1
    ]
  },
  {
    "plz": "32758",
    "partner": [
      1
    ]
  },
  {
    "plz": "32760",
    "partner": [
      1
    ]
  },
  {
    "plz": "32791",
    "partner": [
      1
    ]
  },
  {
    "plz": "32805",
    "partner": [
      1
    ]
  },
  {
    "plz": "32816",
    "partner": [
      1
    ]
  },
  {
    "plz": "32825",
    "partner": [
      1
    ]
  },
  {
    "plz": "32832",
    "partner": [
      1
    ]
  },
  {
    "plz": "32839",
    "partner": [
      1
    ]
  },
  {
    "plz": "33014",
    "partner": [
      1
    ]
  },
  {
    "plz": "33034",
    "partner": [
      1
    ]
  },
  {
    "plz": "33039",
    "partner": [
      1
    ]
  },
  {
    "plz": "33098",
    "partner": [
      1
    ]
  },
  {
    "plz": "33100",
    "partner": [
      1
    ]
  },
  {
    "plz": "33102",
    "partner": [
      1
    ]
  },
  {
    "plz": "33104",
    "partner": [
      1
    ]
  },
  {
    "plz": "33106",
    "partner": [
      1
    ]
  },
  {
    "plz": "33129",
    "partner": [
      1
    ]
  },
  {
    "plz": "33142",
    "partner": [
      1
    ]
  },
  {
    "plz": "33154",
    "partner": [
      1
    ]
  },
  {
    "plz": "33154",
    "partner": [
      1
    ]
  },
  {
    "plz": "33161",
    "partner": [
      1
    ]
  },
  {
    "plz": "33161",
    "partner": [
      1
    ]
  },
  {
    "plz": "33165",
    "partner": [
      1
    ]
  },
  {
    "plz": "33175",
    "partner": [
      1
    ]
  },
  {
    "plz": "33178",
    "partner": [
      1
    ]
  },
  {
    "plz": "33181",
    "partner": [
      1
    ]
  },
  {
    "plz": "33184",
    "partner": [
      1
    ]
  },
  {
    "plz": "33189",
    "partner": [
      1
    ]
  },
  {
    "plz": "33330",
    "partner": [
      0
    ]
  },
  {
    "plz": "33332",
    "partner": [
      0
    ]
  },
  {
    "plz": "33333",
    "partner": [
      0
    ]
  },
  {
    "plz": "33334",
    "partner": [
      0
    ]
  },
  {
    "plz": "33335",
    "partner": [
      0
    ]
  },
  {
    "plz": "33378",
    "partner": [
      0
    ]
  },
  {
    "plz": "33397",
    "partner": [
      0
    ]
  },
  {
    "plz": "33415",
    "partner": [
      0
    ]
  },
  {
    "plz": "33428",
    "partner": [
      0
    ]
  },
  {
    "plz": "33428",
    "partner": [
      0
    ]
  },
  {
    "plz": "33442",
    "partner": [
      0
    ]
  },
  {
    "plz": "33449",
    "partner": [
      0
    ]
  },
  {
    "plz": "33602",
    "partner": [
      0
    ]
  },
  {
    "plz": "33604",
    "partner": [
      0
    ]
  },
  {
    "plz": "33605",
    "partner": [
      0
    ]
  },
  {
    "plz": "33607",
    "partner": [
      0
    ]
  },
  {
    "plz": "33609",
    "partner": [
      0
    ]
  },
  {
    "plz": "33611",
    "partner": [
      0
    ]
  },
  {
    "plz": "33613",
    "partner": [
      0
    ]
  },
  {
    "plz": "33615",
    "partner": [
      0
    ]
  },
  {
    "plz": "33617",
    "partner": [
      0
    ]
  },
  {
    "plz": "33619",
    "partner": [
      0
    ]
  },
  {
    "plz": "33647",
    "partner": [
      0
    ]
  },
  {
    "plz": "33649",
    "partner": [
      0
    ]
  },
  {
    "plz": "33659",
    "partner": [
      0
    ]
  },
  {
    "plz": "33689",
    "partner": [
      0
    ]
  },
  {
    "plz": "33699",
    "partner": [
      0
    ]
  },
  {
    "plz": "33719",
    "partner": [
      0
    ]
  },
  {
    "plz": "33729",
    "partner": [
      0
    ]
  },
  {
    "plz": "33739",
    "partner": [
      0
    ]
  },
  {
    "plz": "33758",
    "partner": [
      0
    ]
  },
  {
    "plz": "33775",
    "partner": [
      0
    ]
  },
  {
    "plz": "33790",
    "partner": [
      0
    ]
  },
  {
    "plz": "33803",
    "partner": [
      0
    ]
  },
  {
    "plz": "33813",
    "partner": [
      0
    ]
  },
  {
    "plz": "33818",
    "partner": [
      0
    ]
  },
  {
    "plz": "33824",
    "partner": [
      0
    ]
  },
  {
    "plz": "33829",
    "partner": [
      0
    ]
  },
  {
    "plz": "34117",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34119",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34121",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34123",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34125",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34127",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34127",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34128",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34130",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34131",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34132",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34134",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34212",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34225",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34225",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34233",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34233",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34246",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34246",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34253",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34260",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34266",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34270",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34277",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34281",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34286",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34289",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34292",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34295",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34298",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34302",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34305",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34305",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34311",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34314",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34317",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34320",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34323",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34326",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34327",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34329",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34346",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34355",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34355",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34359",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34369",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34376",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34379",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34385",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34388",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34393",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34396",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34399",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "34414",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34431",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34434",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34439",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34454",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34466",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34471",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34474",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34477",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34479",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34497",
    "partner": [
      1,
      14
    ]
  },
  {
    "plz": "34508",
    "partner": [
      14
    ]
  },
  {
    "plz": "34513",
    "partner": [
      14
    ]
  },
  {
    "plz": "34513",
    "partner": [
      14
    ]
  },
  {
    "plz": "34516",
    "partner": [
      14
    ]
  },
  {
    "plz": "34516",
    "partner": [
      14
    ]
  },
  {
    "plz": "34519",
    "partner": [
      14
    ]
  },
  {
    "plz": "34537",
    "partner": [
      14
    ]
  },
  {
    "plz": "34549",
    "partner": [
      14
    ]
  },
  {
    "plz": "34560",
    "partner": [
      14
    ]
  },
  {
    "plz": "34576",
    "partner": [
      14
    ]
  },
  {
    "plz": "34576",
    "partner": [
      14
    ]
  },
  {
    "plz": "34582",
    "partner": [
      14
    ]
  },
  {
    "plz": "34587",
    "partner": [
      14
    ]
  },
  {
    "plz": "34590",
    "partner": [
      14
    ]
  },
  {
    "plz": "34593",
    "partner": [
      14
    ]
  },
  {
    "plz": "34596",
    "partner": [
      14
    ]
  },
  {
    "plz": "34599",
    "partner": [
      14
    ]
  },
  {
    "plz": "34613",
    "partner": [
      14
    ]
  },
  {
    "plz": "34621",
    "partner": [
      14
    ]
  },
  {
    "plz": "34626",
    "partner": [
      14
    ]
  },
  {
    "plz": "34628",
    "partner": [
      14
    ]
  },
  {
    "plz": "34630",
    "partner": [
      14
    ]
  },
  {
    "plz": "34632",
    "partner": [
      14
    ]
  },
  {
    "plz": "34633",
    "partner": [
      14
    ]
  },
  {
    "plz": "34637",
    "partner": [
      14
    ]
  },
  {
    "plz": "34639",
    "partner": [
      14
    ]
  },
  {
    "plz": "35037",
    "partner": [
      14
    ]
  },
  {
    "plz": "35039",
    "partner": [
      14
    ]
  },
  {
    "plz": "35041",
    "partner": [
      14
    ]
  },
  {
    "plz": "35043",
    "partner": [
      14
    ]
  },
  {
    "plz": "35043",
    "partner": [
      14
    ]
  },
  {
    "plz": "35066",
    "partner": [
      14
    ]
  },
  {
    "plz": "35075",
    "partner": [
      14
    ]
  },
  {
    "plz": "35080",
    "partner": [
      14
    ]
  },
  {
    "plz": "35083",
    "partner": [
      14
    ]
  },
  {
    "plz": "35085",
    "partner": [
      14
    ]
  },
  {
    "plz": "35088",
    "partner": [
      14
    ]
  },
  {
    "plz": "35091",
    "partner": [
      14
    ]
  },
  {
    "plz": "35094",
    "partner": [
      14
    ]
  },
  {
    "plz": "35094",
    "partner": [
      14
    ]
  },
  {
    "plz": "35096",
    "partner": [
      14
    ]
  },
  {
    "plz": "35099",
    "partner": [
      14
    ]
  },
  {
    "plz": "35102",
    "partner": [
      14
    ]
  },
  {
    "plz": "35104",
    "partner": [
      14
    ]
  },
  {
    "plz": "35108",
    "partner": [
      14
    ]
  },
  {
    "plz": "35110",
    "partner": [
      14
    ]
  },
  {
    "plz": "35112",
    "partner": [
      14
    ]
  },
  {
    "plz": "35114",
    "partner": [
      14
    ]
  },
  {
    "plz": "35116",
    "partner": [
      14
    ]
  },
  {
    "plz": "35117",
    "partner": [
      14
    ]
  },
  {
    "plz": "35119",
    "partner": [
      14
    ]
  },
  {
    "plz": "35216",
    "partner": [
      14
    ]
  },
  {
    "plz": "35216",
    "partner": [
      14
    ]
  },
  {
    "plz": "35232",
    "partner": [
      14
    ]
  },
  {
    "plz": "35236",
    "partner": [
      14
    ]
  },
  {
    "plz": "35239",
    "partner": [
      14
    ]
  },
  {
    "plz": "35260",
    "partner": [
      14
    ]
  },
  {
    "plz": "35274",
    "partner": [
      14
    ]
  },
  {
    "plz": "35279",
    "partner": [
      14
    ]
  },
  {
    "plz": "35282",
    "partner": [
      14
    ]
  },
  {
    "plz": "35285",
    "partner": [
      14
    ]
  },
  {
    "plz": "35287",
    "partner": [
      14
    ]
  },
  {
    "plz": "35288",
    "partner": [
      14
    ]
  },
  {
    "plz": "35305",
    "partner": [
      14
    ]
  },
  {
    "plz": "35315",
    "partner": [
      14
    ]
  },
  {
    "plz": "35321",
    "partner": [
      14
    ]
  },
  {
    "plz": "35325",
    "partner": [
      14
    ]
  },
  {
    "plz": "35327",
    "partner": [
      14
    ]
  },
  {
    "plz": "35329",
    "partner": [
      14
    ]
  },
  {
    "plz": "35390",
    "partner": [
      14
    ]
  },
  {
    "plz": "35392",
    "partner": [
      14
    ]
  },
  {
    "plz": "35394",
    "partner": [
      14
    ]
  },
  {
    "plz": "35396",
    "partner": [
      14
    ]
  },
  {
    "plz": "35398",
    "partner": [
      14
    ]
  },
  {
    "plz": "35410",
    "partner": [
      1
    ]
  },
  {
    "plz": "35415",
    "partner": [
      1
    ]
  },
  {
    "plz": "35418",
    "partner": [
      1
    ]
  },
  {
    "plz": "35423",
    "partner": [
      1
    ]
  },
  {
    "plz": "35428",
    "partner": [
      1
    ]
  },
  {
    "plz": "35435",
    "partner": [
      1
    ]
  },
  {
    "plz": "35440",
    "partner": [
      1
    ]
  },
  {
    "plz": "35444",
    "partner": [
      1
    ]
  },
  {
    "plz": "35447",
    "partner": [
      1
    ]
  },
  {
    "plz": "35452",
    "partner": [
      1
    ]
  },
  {
    "plz": "35457",
    "partner": [
      1
    ]
  },
  {
    "plz": "35460",
    "partner": [
      1
    ]
  },
  {
    "plz": "35463",
    "partner": [
      1
    ]
  },
  {
    "plz": "35466",
    "partner": [
      1
    ]
  },
  {
    "plz": "35469",
    "partner": [
      1
    ]
  },
  {
    "plz": "35510",
    "partner": [
      1
    ]
  },
  {
    "plz": "35516",
    "partner": [
      1
    ]
  },
  {
    "plz": "35519",
    "partner": [
      1
    ]
  },
  {
    "plz": "35576",
    "partner": [
      1
    ]
  },
  {
    "plz": "35578",
    "partner": [
      1
    ]
  },
  {
    "plz": "35579",
    "partner": [
      1
    ]
  },
  {
    "plz": "35580",
    "partner": [
      1
    ]
  },
  {
    "plz": "35581",
    "partner": [
      1
    ]
  },
  {
    "plz": "35582",
    "partner": [
      1
    ]
  },
  {
    "plz": "35583",
    "partner": [
      1
    ]
  },
  {
    "plz": "35584",
    "partner": [
      1
    ]
  },
  {
    "plz": "35585",
    "partner": [
      1
    ]
  },
  {
    "plz": "35586",
    "partner": [
      1
    ]
  },
  {
    "plz": "35606",
    "partner": [
      1
    ]
  },
  {
    "plz": "35614",
    "partner": [
      1
    ]
  },
  {
    "plz": "35619",
    "partner": [
      1
    ]
  },
  {
    "plz": "35625",
    "partner": [
      1
    ]
  },
  {
    "plz": "35630",
    "partner": [
      1
    ]
  },
  {
    "plz": "35630",
    "partner": [
      1
    ]
  },
  {
    "plz": "35633",
    "partner": [
      1
    ]
  },
  {
    "plz": "35638",
    "partner": [
      1
    ]
  },
  {
    "plz": "35641",
    "partner": [
      1
    ]
  },
  {
    "plz": "35644",
    "partner": [
      1
    ]
  },
  {
    "plz": "35647",
    "partner": [
      1
    ]
  },
  {
    "plz": "35649",
    "partner": [
      1
    ]
  },
  {
    "plz": "35683",
    "partner": [
      1
    ]
  },
  {
    "plz": "35684",
    "partner": [
      1
    ]
  },
  {
    "plz": "35685",
    "partner": [
      1
    ]
  },
  {
    "plz": "35686",
    "partner": [
      1
    ]
  },
  {
    "plz": "35687",
    "partner": [
      1
    ]
  },
  {
    "plz": "35688",
    "partner": [
      1
    ]
  },
  {
    "plz": "35689",
    "partner": [
      1
    ]
  },
  {
    "plz": "35690",
    "partner": [
      1
    ]
  },
  {
    "plz": "35708",
    "partner": [
      1
    ]
  },
  {
    "plz": "35713",
    "partner": [
      1
    ]
  },
  {
    "plz": "35716",
    "partner": [
      1
    ]
  },
  {
    "plz": "35719",
    "partner": [
      1
    ]
  },
  {
    "plz": "35745",
    "partner": [
      1
    ]
  },
  {
    "plz": "35753",
    "partner": [
      1
    ]
  },
  {
    "plz": "35756",
    "partner": [
      1
    ]
  },
  {
    "plz": "35759",
    "partner": [
      1
    ]
  },
  {
    "plz": "35764",
    "partner": [
      1
    ]
  },
  {
    "plz": "35767",
    "partner": [
      1
    ]
  },
  {
    "plz": "35768",
    "partner": [
      1
    ]
  },
  {
    "plz": "35781",
    "partner": [
      1
    ]
  },
  {
    "plz": "35789",
    "partner": [
      1
    ]
  },
  {
    "plz": "35792",
    "partner": [
      1
    ]
  },
  {
    "plz": "35794",
    "partner": [
      1
    ]
  },
  {
    "plz": "35796",
    "partner": [
      1
    ]
  },
  {
    "plz": "35799",
    "partner": [
      1
    ]
  },
  {
    "plz": "36037",
    "partner": [
      6
    ]
  },
  {
    "plz": "36039",
    "partner": [
      6
    ]
  },
  {
    "plz": "36041",
    "partner": [
      6
    ]
  },
  {
    "plz": "36043",
    "partner": [
      6
    ]
  },
  {
    "plz": "36088",
    "partner": [
      6
    ]
  },
  {
    "plz": "36093",
    "partner": [
      6
    ]
  },
  {
    "plz": "36100",
    "partner": [
      6
    ]
  },
  {
    "plz": "36103",
    "partner": [
      6
    ]
  },
  {
    "plz": "36110",
    "partner": [
      6
    ]
  },
  {
    "plz": "36115",
    "partner": [
      6
    ]
  },
  {
    "plz": "36115",
    "partner": [
      6
    ]
  },
  {
    "plz": "36119",
    "partner": [
      6
    ]
  },
  {
    "plz": "36124",
    "partner": [
      6
    ]
  },
  {
    "plz": "36129",
    "partner": [
      6
    ]
  },
  {
    "plz": "36132",
    "partner": [
      6
    ]
  },
  {
    "plz": "36137",
    "partner": [
      6
    ]
  },
  {
    "plz": "36142",
    "partner": [
      6
    ]
  },
  {
    "plz": "36145",
    "partner": [
      6
    ]
  },
  {
    "plz": "36148",
    "partner": [
      6
    ]
  },
  {
    "plz": "36151",
    "partner": [
      6
    ]
  },
  {
    "plz": "36154",
    "partner": [
      6
    ]
  },
  {
    "plz": "36154",
    "partner": [
      6
    ]
  },
  {
    "plz": "36157",
    "partner": [
      6
    ]
  },
  {
    "plz": "36160",
    "partner": [
      6
    ]
  },
  {
    "plz": "36163",
    "partner": [
      6
    ]
  },
  {
    "plz": "36166",
    "partner": [
      6
    ]
  },
  {
    "plz": "36166",
    "partner": [
      6
    ]
  },
  {
    "plz": "36167",
    "partner": [
      6
    ]
  },
  {
    "plz": "36169",
    "partner": [
      6
    ]
  },
  {
    "plz": "36179",
    "partner": [
      6
    ]
  },
  {
    "plz": "36179",
    "partner": [
      6
    ]
  },
  {
    "plz": "36199",
    "partner": [
      6
    ]
  },
  {
    "plz": "36205",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36208",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36208",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36211",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36214",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36217",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36219",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36219",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36251",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36251",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36266",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36269",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36272",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36275",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36277",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36280",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36282",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36284",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36286",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36287",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36289",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36304",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36304",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36318",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36320",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36323",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36325",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36326",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36326",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36326",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36329",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "36341",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36355",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36358",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36364",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36367",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36369",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36381",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36391",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36396",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36399",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36404",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36404",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36414",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36419",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36419",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36419",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36419",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36433",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36433",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36448",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36452",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36452",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36456",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36457",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36460",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36466",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36466",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "36469",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "37073",
    "partner": [
      1
    ]
  },
  {
    "plz": "37075",
    "partner": [
      1
    ]
  },
  {
    "plz": "37077",
    "partner": [
      1
    ]
  },
  {
    "plz": "37079",
    "partner": [
      1
    ]
  },
  {
    "plz": "37081",
    "partner": [
      1
    ]
  },
  {
    "plz": "37083",
    "partner": [
      1
    ]
  },
  {
    "plz": "37085",
    "partner": [
      1
    ]
  },
  {
    "plz": "37115",
    "partner": [
      1
    ]
  },
  {
    "plz": "37120",
    "partner": [
      1
    ]
  },
  {
    "plz": "37124",
    "partner": [
      1
    ]
  },
  {
    "plz": "37127",
    "partner": [
      1
    ]
  },
  {
    "plz": "37127",
    "partner": [
      1
    ]
  },
  {
    "plz": "37127",
    "partner": [
      1
    ]
  },
  {
    "plz": "37127",
    "partner": [
      1
    ]
  },
  {
    "plz": "37127",
    "partner": [
      1
    ]
  },
  {
    "plz": "37127",
    "partner": [
      1
    ]
  },
  {
    "plz": "37130",
    "partner": [
      1
    ]
  },
  {
    "plz": "37133",
    "partner": [
      1
    ]
  },
  {
    "plz": "37136",
    "partner": [
      1
    ]
  },
  {
    "plz": "37136",
    "partner": [
      1
    ]
  },
  {
    "plz": "37136",
    "partner": [
      1
    ]
  },
  {
    "plz": "37136",
    "partner": [
      1
    ]
  },
  {
    "plz": "37136",
    "partner": [
      1
    ]
  },
  {
    "plz": "37139",
    "partner": [
      1
    ]
  },
  {
    "plz": "37154",
    "partner": [
      1
    ]
  },
  {
    "plz": "37170",
    "partner": [
      1
    ]
  },
  {
    "plz": "37176",
    "partner": [
      1
    ]
  },
  {
    "plz": "37181",
    "partner": [
      1
    ]
  },
  {
    "plz": "37186",
    "partner": [
      1
    ]
  },
  {
    "plz": "37191",
    "partner": [
      1
    ]
  },
  {
    "plz": "37194",
    "partner": [
      1
    ]
  },
  {
    "plz": "37197",
    "partner": [
      1
    ]
  },
  {
    "plz": "37199",
    "partner": [
      1
    ]
  },
  {
    "plz": "37213",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37214",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37215",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37216",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37217",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37217",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37218",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37235",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37242",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37247",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37249",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37269",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37276",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37281",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37284",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37287",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37290",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37293",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37296",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37297",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37299",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37308",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37318",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37327",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37327",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37339",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37345",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37345",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37351",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37351",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37351",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37351",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37351",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37355",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37359",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37359",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37359",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37359",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37359",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37412",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37412",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37412",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37412",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37431",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37434",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37441",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37444",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37445",
    "partner": [
      1,
      15
    ]
  },
  {
    "plz": "37520",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "37539",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "37574",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "37581",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "37586",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "37589",
    "partner": [
      1,
      13
    ]
  },
  {
    "plz": "37603",
    "partner": [
      1
    ]
  },
  {
    "plz": "37619",
    "partner": [
      1
    ]
  },
  {
    "plz": "37619",
    "partner": [
      1
    ]
  },
  {
    "plz": "37619",
    "partner": [
      1
    ]
  },
  {
    "plz": "37619",
    "partner": [
      1
    ]
  },
  {
    "plz": "37619",
    "partner": [
      1
    ]
  },
  {
    "plz": "37620",
    "partner": [
      1
    ]
  },
  {
    "plz": "37620",
    "partner": [
      1
    ]
  },
  {
    "plz": "37620",
    "partner": [
      1
    ]
  },
  {
    "plz": "37620",
    "partner": [
      1
    ]
  },
  {
    "plz": "37620",
    "partner": [
      1
    ]
  },
  {
    "plz": "37620",
    "partner": [
      1
    ]
  },
  {
    "plz": "37627",
    "partner": [
      1
    ]
  },
  {
    "plz": "37627",
    "partner": [
      1
    ]
  },
  {
    "plz": "37627",
    "partner": [
      1
    ]
  },
  {
    "plz": "37627",
    "partner": [
      1
    ]
  },
  {
    "plz": "37627",
    "partner": [
      1
    ]
  },
  {
    "plz": "37627",
    "partner": [
      1
    ]
  },
  {
    "plz": "37632",
    "partner": [
      1
    ]
  },
  {
    "plz": "37632",
    "partner": [
      1
    ]
  },
  {
    "plz": "37632",
    "partner": [
      1
    ]
  },
  {
    "plz": "37633",
    "partner": [
      1
    ]
  },
  {
    "plz": "37635",
    "partner": [
      1
    ]
  },
  {
    "plz": "37639",
    "partner": [
      1
    ]
  },
  {
    "plz": "37639",
    "partner": [
      1
    ]
  },
  {
    "plz": "37640",
    "partner": [
      1
    ]
  },
  {
    "plz": "37642",
    "partner": [
      1
    ]
  },
  {
    "plz": "37643",
    "partner": [
      1
    ]
  },
  {
    "plz": "37647",
    "partner": [
      1
    ]
  },
  {
    "plz": "37647",
    "partner": [
      1
    ]
  },
  {
    "plz": "37647",
    "partner": [
      1
    ]
  },
  {
    "plz": "37647",
    "partner": [
      1
    ]
  },
  {
    "plz": "37649",
    "partner": [
      1
    ]
  },
  {
    "plz": "37671",
    "partner": [
      1
    ]
  },
  {
    "plz": "37688",
    "partner": [
      1
    ]
  },
  {
    "plz": "37691",
    "partner": [
      1
    ]
  },
  {
    "plz": "37691",
    "partner": [
      1
    ]
  },
  {
    "plz": "37696",
    "partner": [
      1
    ]
  },
  {
    "plz": "37697",
    "partner": [
      1
    ]
  },
  {
    "plz": "37699",
    "partner": [
      1
    ]
  },
  {
    "plz": "38100",
    "partner": [
      0
    ]
  },
  {
    "plz": "38102",
    "partner": [
      0
    ]
  },
  {
    "plz": "38104",
    "partner": [
      0
    ]
  },
  {
    "plz": "38106",
    "partner": [
      0
    ]
  },
  {
    "plz": "38108",
    "partner": [
      0
    ]
  },
  {
    "plz": "38110",
    "partner": [
      0
    ]
  },
  {
    "plz": "38112",
    "partner": [
      0
    ]
  },
  {
    "plz": "38114",
    "partner": [
      0
    ]
  },
  {
    "plz": "38116",
    "partner": [
      0
    ]
  },
  {
    "plz": "38118",
    "partner": [
      0
    ]
  },
  {
    "plz": "38120",
    "partner": [
      0
    ]
  },
  {
    "plz": "38122",
    "partner": [
      0
    ]
  },
  {
    "plz": "38124",
    "partner": [
      0
    ]
  },
  {
    "plz": "38126",
    "partner": [
      0
    ]
  },
  {
    "plz": "38154",
    "partner": [
      0
    ]
  },
  {
    "plz": "38154",
    "partner": [
      0
    ]
  },
  {
    "plz": "38154",
    "partner": [
      0
    ]
  },
  {
    "plz": "38154",
    "partner": [
      0
    ]
  },
  {
    "plz": "38154",
    "partner": [
      0
    ]
  },
  {
    "plz": "38159",
    "partner": [
      0
    ]
  },
  {
    "plz": "38162",
    "partner": [
      0
    ]
  },
  {
    "plz": "38165",
    "partner": [
      0
    ]
  },
  {
    "plz": "38170",
    "partner": [
      0
    ]
  },
  {
    "plz": "38170",
    "partner": [
      0
    ]
  },
  {
    "plz": "38170",
    "partner": [
      0
    ]
  },
  {
    "plz": "38170",
    "partner": [
      0
    ]
  },
  {
    "plz": "38170",
    "partner": [
      0
    ]
  },
  {
    "plz": "38170",
    "partner": [
      0
    ]
  },
  {
    "plz": "38173",
    "partner": [
      0
    ]
  },
  {
    "plz": "38173",
    "partner": [
      0
    ]
  },
  {
    "plz": "38173",
    "partner": [
      0
    ]
  },
  {
    "plz": "38173",
    "partner": [
      0
    ]
  },
  {
    "plz": "38173",
    "partner": [
      0
    ]
  },
  {
    "plz": "38173",
    "partner": [
      0
    ]
  },
  {
    "plz": "38176",
    "partner": [
      0
    ]
  },
  {
    "plz": "38179",
    "partner": [
      0
    ]
  },
  {
    "plz": "38226",
    "partner": [
      0
    ]
  },
  {
    "plz": "38228",
    "partner": [
      0
    ]
  },
  {
    "plz": "38229",
    "partner": [
      0
    ]
  },
  {
    "plz": "38239",
    "partner": [
      0
    ]
  },
  {
    "plz": "38259",
    "partner": [
      0
    ]
  },
  {
    "plz": "38268",
    "partner": [
      0
    ]
  },
  {
    "plz": "38271",
    "partner": [
      0
    ]
  },
  {
    "plz": "38272",
    "partner": [
      0
    ]
  },
  {
    "plz": "38274",
    "partner": [
      0
    ]
  },
  {
    "plz": "38275",
    "partner": [
      0
    ]
  },
  {
    "plz": "38277",
    "partner": [
      0
    ]
  },
  {
    "plz": "38279",
    "partner": [
      0
    ]
  },
  {
    "plz": "38300",
    "partner": [
      0
    ]
  },
  {
    "plz": "38302",
    "partner": [
      0
    ]
  },
  {
    "plz": "38304",
    "partner": [
      0
    ]
  },
  {
    "plz": "38312",
    "partner": [
      0
    ]
  },
  {
    "plz": "38312",
    "partner": [
      0
    ]
  },
  {
    "plz": "38312",
    "partner": [
      0
    ]
  },
  {
    "plz": "38312",
    "partner": [
      0
    ]
  },
  {
    "plz": "38312",
    "partner": [
      0
    ]
  },
  {
    "plz": "38312",
    "partner": [
      0
    ]
  },
  {
    "plz": "38315",
    "partner": [
      0
    ]
  },
  {
    "plz": "38315",
    "partner": [
      0
    ]
  },
  {
    "plz": "38315",
    "partner": [
      0
    ]
  },
  {
    "plz": "38315",
    "partner": [
      0
    ]
  },
  {
    "plz": "38319",
    "partner": [
      0
    ]
  },
  {
    "plz": "38321",
    "partner": [
      0
    ]
  },
  {
    "plz": "38322",
    "partner": [
      0
    ]
  },
  {
    "plz": "38324",
    "partner": [
      0
    ]
  },
  {
    "plz": "38325",
    "partner": [
      0
    ]
  },
  {
    "plz": "38327",
    "partner": [
      0
    ]
  },
  {
    "plz": "38329",
    "partner": [
      0
    ]
  },
  {
    "plz": "38350",
    "partner": [
      0
    ]
  },
  {
    "plz": "38350",
    "partner": [
      0
    ]
  },
  {
    "plz": "38364",
    "partner": [
      0
    ]
  },
  {
    "plz": "38368",
    "partner": [
      0
    ]
  },
  {
    "plz": "38368",
    "partner": [
      0
    ]
  },
  {
    "plz": "38368",
    "partner": [
      0
    ]
  },
  {
    "plz": "38368",
    "partner": [
      0
    ]
  },
  {
    "plz": "38372",
    "partner": [
      0
    ]
  },
  {
    "plz": "38373",
    "partner": [
      0
    ]
  },
  {
    "plz": "38373",
    "partner": [
      0
    ]
  },
  {
    "plz": "38375",
    "partner": [
      0
    ]
  },
  {
    "plz": "38376",
    "partner": [
      0
    ]
  },
  {
    "plz": "38378",
    "partner": [
      0
    ]
  },
  {
    "plz": "38379",
    "partner": [
      0
    ]
  },
  {
    "plz": "38381",
    "partner": [
      0
    ]
  },
  {
    "plz": "38382",
    "partner": [
      0
    ]
  },
  {
    "plz": "38384",
    "partner": [
      0
    ]
  },
  {
    "plz": "38387",
    "partner": [
      0
    ]
  },
  {
    "plz": "38440",
    "partner": [
      0
    ]
  },
  {
    "plz": "38442",
    "partner": [
      0
    ]
  },
  {
    "plz": "38444",
    "partner": [
      0
    ]
  },
  {
    "plz": "38446",
    "partner": [
      0
    ]
  },
  {
    "plz": "38448",
    "partner": [
      0
    ]
  },
  {
    "plz": "38458",
    "partner": [
      0
    ]
  },
  {
    "plz": "38459",
    "partner": [
      0
    ]
  },
  {
    "plz": "38461",
    "partner": [
      0
    ]
  },
  {
    "plz": "38462",
    "partner": [
      0
    ]
  },
  {
    "plz": "38464",
    "partner": [
      0
    ]
  },
  {
    "plz": "38465",
    "partner": [
      0
    ]
  },
  {
    "plz": "38467",
    "partner": [
      0
    ]
  },
  {
    "plz": "38468",
    "partner": [
      0
    ]
  },
  {
    "plz": "38470",
    "partner": [
      0
    ]
  },
  {
    "plz": "38471",
    "partner": [
      0
    ]
  },
  {
    "plz": "38473",
    "partner": [
      0
    ]
  },
  {
    "plz": "38474",
    "partner": [
      0
    ]
  },
  {
    "plz": "38476",
    "partner": [
      0
    ]
  },
  {
    "plz": "38477",
    "partner": [
      0
    ]
  },
  {
    "plz": "38479",
    "partner": [
      0
    ]
  },
  {
    "plz": "38486",
    "partner": [
      0
    ]
  },
  {
    "plz": "38486",
    "partner": [
      0
    ]
  },
  {
    "plz": "38489",
    "partner": [
      0
    ]
  },
  {
    "plz": "38489",
    "partner": [
      0
    ]
  },
  {
    "plz": "38489",
    "partner": [
      0
    ]
  },
  {
    "plz": "38518",
    "partner": [
      0
    ]
  },
  {
    "plz": "38524",
    "partner": [
      0
    ]
  },
  {
    "plz": "38527",
    "partner": [
      0
    ]
  },
  {
    "plz": "38528",
    "partner": [
      0
    ]
  },
  {
    "plz": "38530",
    "partner": [
      0
    ]
  },
  {
    "plz": "38531",
    "partner": [
      0
    ]
  },
  {
    "plz": "38533",
    "partner": [
      0
    ]
  },
  {
    "plz": "38536",
    "partner": [
      0
    ]
  },
  {
    "plz": "38539",
    "partner": [
      0
    ]
  },
  {
    "plz": "38542",
    "partner": [
      0
    ]
  },
  {
    "plz": "38543",
    "partner": [
      0
    ]
  },
  {
    "plz": "38547",
    "partner": [
      0
    ]
  },
  {
    "plz": "38550",
    "partner": [
      0
    ]
  },
  {
    "plz": "38551",
    "partner": [
      0
    ]
  },
  {
    "plz": "38551",
    "partner": [
      0
    ]
  },
  {
    "plz": "38553",
    "partner": [
      0
    ]
  },
  {
    "plz": "38554",
    "partner": [
      0
    ]
  },
  {
    "plz": "38556",
    "partner": [
      0
    ]
  },
  {
    "plz": "38557",
    "partner": [
      0
    ]
  },
  {
    "plz": "38559",
    "partner": [
      0
    ]
  },
  {
    "plz": "38559",
    "partner": [
      0
    ]
  },
  {
    "plz": "38640",
    "partner": [
      0
    ]
  },
  {
    "plz": "38642",
    "partner": [
      0
    ]
  },
  {
    "plz": "38644",
    "partner": [
      0
    ]
  },
  {
    "plz": "38667",
    "partner": [
      0
    ]
  },
  {
    "plz": "38667",
    "partner": [
      0
    ]
  },
  {
    "plz": "38678",
    "partner": [
      0
    ]
  },
  {
    "plz": "38678",
    "partner": [
      0
    ]
  },
  {
    "plz": "38685",
    "partner": [
      0
    ]
  },
  {
    "plz": "38690",
    "partner": [
      0
    ]
  },
  {
    "plz": "38700",
    "partner": [
      0
    ]
  },
  {
    "plz": "38704",
    "partner": [
      0
    ]
  },
  {
    "plz": "38707",
    "partner": [
      0
    ]
  },
  {
    "plz": "38707",
    "partner": [
      0
    ]
  },
  {
    "plz": "38709",
    "partner": [
      0
    ]
  },
  {
    "plz": "38723",
    "partner": [
      0
    ]
  },
  {
    "plz": "38729",
    "partner": [
      0
    ]
  },
  {
    "plz": "38729",
    "partner": [
      0
    ]
  },
  {
    "plz": "38729",
    "partner": [
      0
    ]
  },
  {
    "plz": "38820",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38822",
    "partner": [
      0
    ]
  },
  {
    "plz": "38828",
    "partner": [
      0
    ]
  },
  {
    "plz": "38828",
    "partner": [
      0
    ]
  },
  {
    "plz": "38829",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38835",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38836",
    "partner": [
      0
    ]
  },
  {
    "plz": "38838",
    "partner": [
      0
    ]
  },
  {
    "plz": "38838",
    "partner": [
      0
    ]
  },
  {
    "plz": "38838",
    "partner": [
      0
    ]
  },
  {
    "plz": "38838",
    "partner": [
      0
    ]
  },
  {
    "plz": "38838",
    "partner": [
      0
    ]
  },
  {
    "plz": "38855",
    "partner": [
      0
    ]
  },
  {
    "plz": "38855",
    "partner": [
      0
    ]
  },
  {
    "plz": "38855",
    "partner": [
      0
    ]
  },
  {
    "plz": "38855",
    "partner": [
      0
    ]
  },
  {
    "plz": "38855",
    "partner": [
      0
    ]
  },
  {
    "plz": "38871",
    "partner": [
      0
    ]
  },
  {
    "plz": "38871",
    "partner": [
      0
    ]
  },
  {
    "plz": "38871",
    "partner": [
      0
    ]
  },
  {
    "plz": "38871",
    "partner": [
      0
    ]
  },
  {
    "plz": "38871",
    "partner": [
      0
    ]
  },
  {
    "plz": "38871",
    "partner": [
      0
    ]
  },
  {
    "plz": "38875",
    "partner": [
      0
    ]
  },
  {
    "plz": "38875",
    "partner": [
      0
    ]
  },
  {
    "plz": "38875",
    "partner": [
      0
    ]
  },
  {
    "plz": "38875",
    "partner": [
      0
    ]
  },
  {
    "plz": "38875",
    "partner": [
      0
    ]
  },
  {
    "plz": "38875",
    "partner": [
      0
    ]
  },
  {
    "plz": "38877",
    "partner": [
      0
    ]
  },
  {
    "plz": "38879",
    "partner": [
      0
    ]
  },
  {
    "plz": "38889",
    "partner": [
      0
    ]
  },
  {
    "plz": "38889",
    "partner": [
      0
    ]
  },
  {
    "plz": "38889",
    "partner": [
      0
    ]
  },
  {
    "plz": "38889",
    "partner": [
      0
    ]
  },
  {
    "plz": "38889",
    "partner": [
      0
    ]
  },
  {
    "plz": "38889",
    "partner": [
      0
    ]
  },
  {
    "plz": "38895",
    "partner": [
      0
    ]
  },
  {
    "plz": "38895",
    "partner": [
      0
    ]
  },
  {
    "plz": "38899",
    "partner": [
      0
    ]
  },
  {
    "plz": "38899",
    "partner": [
      0
    ]
  },
  {
    "plz": "38899",
    "partner": [
      0
    ]
  },
  {
    "plz": "39104",
    "partner": [
      0
    ]
  },
  {
    "plz": "39106",
    "partner": [
      0
    ]
  },
  {
    "plz": "39108",
    "partner": [
      0
    ]
  },
  {
    "plz": "39110",
    "partner": [
      0
    ]
  },
  {
    "plz": "39112",
    "partner": [
      0
    ]
  },
  {
    "plz": "39114",
    "partner": [
      0
    ]
  },
  {
    "plz": "39116",
    "partner": [
      0
    ]
  },
  {
    "plz": "39118",
    "partner": [
      0
    ]
  },
  {
    "plz": "39120",
    "partner": [
      0
    ]
  },
  {
    "plz": "39122",
    "partner": [
      0
    ]
  },
  {
    "plz": "39124",
    "partner": [
      0
    ]
  },
  {
    "plz": "39126",
    "partner": [
      0
    ]
  },
  {
    "plz": "39128",
    "partner": [
      0
    ]
  },
  {
    "plz": "39130",
    "partner": [
      0
    ]
  },
  {
    "plz": "39164",
    "partner": [
      0
    ]
  },
  {
    "plz": "39167",
    "partner": [
      0
    ]
  },
  {
    "plz": "39167",
    "partner": [
      0
    ]
  },
  {
    "plz": "39167",
    "partner": [
      0
    ]
  },
  {
    "plz": "39167",
    "partner": [
      0
    ]
  },
  {
    "plz": "39167",
    "partner": [
      0
    ]
  },
  {
    "plz": "39171",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39175",
    "partner": [
      0
    ]
  },
  {
    "plz": "39179",
    "partner": [
      0
    ]
  },
  {
    "plz": "39217",
    "partner": [
      0
    ]
  },
  {
    "plz": "39218",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39221",
    "partner": [
      0
    ]
  },
  {
    "plz": "39240",
    "partner": [
      0
    ]
  },
  {
    "plz": "39240",
    "partner": [
      0
    ]
  },
  {
    "plz": "39240",
    "partner": [
      0
    ]
  },
  {
    "plz": "39240",
    "partner": [
      0
    ]
  },
  {
    "plz": "39240",
    "partner": [
      0
    ]
  },
  {
    "plz": "39240",
    "partner": [
      0
    ]
  },
  {
    "plz": "39245",
    "partner": [
      0
    ]
  },
  {
    "plz": "39245",
    "partner": [
      0
    ]
  },
  {
    "plz": "39249",
    "partner": [
      0
    ]
  },
  {
    "plz": "39249",
    "partner": [
      0
    ]
  },
  {
    "plz": "39249",
    "partner": [
      0
    ]
  },
  {
    "plz": "39249",
    "partner": [
      0
    ]
  },
  {
    "plz": "39249",
    "partner": [
      0
    ]
  },
  {
    "plz": "39249",
    "partner": [
      0
    ]
  },
  {
    "plz": "39261",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39264",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39279",
    "partner": [
      0
    ]
  },
  {
    "plz": "39288",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39291",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39307",
    "partner": [
      0
    ]
  },
  {
    "plz": "39317",
    "partner": [
      0
    ]
  },
  {
    "plz": "39317",
    "partner": [
      0
    ]
  },
  {
    "plz": "39317",
    "partner": [
      0
    ]
  },
  {
    "plz": "39317",
    "partner": [
      0
    ]
  },
  {
    "plz": "39317",
    "partner": [
      0
    ]
  },
  {
    "plz": "39319",
    "partner": [
      0
    ]
  },
  {
    "plz": "39319",
    "partner": [
      0
    ]
  },
  {
    "plz": "39319",
    "partner": [
      0
    ]
  },
  {
    "plz": "39319",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39326",
    "partner": [
      0
    ]
  },
  {
    "plz": "39340",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39343",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39345",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39356",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39359",
    "partner": [
      0
    ]
  },
  {
    "plz": "39365",
    "partner": [
      0
    ]
  },
  {
    "plz": "39365",
    "partner": [
      0
    ]
  },
  {
    "plz": "39365",
    "partner": [
      0
    ]
  },
  {
    "plz": "39365",
    "partner": [
      0
    ]
  },
  {
    "plz": "39365",
    "partner": [
      0
    ]
  },
  {
    "plz": "39387",
    "partner": [
      0
    ]
  },
  {
    "plz": "39393",
    "partner": [
      0
    ]
  },
  {
    "plz": "39393",
    "partner": [
      0
    ]
  },
  {
    "plz": "39393",
    "partner": [
      0
    ]
  },
  {
    "plz": "39393",
    "partner": [
      0
    ]
  },
  {
    "plz": "39397",
    "partner": [
      0
    ]
  },
  {
    "plz": "39397",
    "partner": [
      0
    ]
  },
  {
    "plz": "39397",
    "partner": [
      0
    ]
  },
  {
    "plz": "39397",
    "partner": [
      0
    ]
  },
  {
    "plz": "39418",
    "partner": [
      0
    ]
  },
  {
    "plz": "39435",
    "partner": [
      0
    ]
  },
  {
    "plz": "39435",
    "partner": [
      0
    ]
  },
  {
    "plz": "39435",
    "partner": [
      0
    ]
  },
  {
    "plz": "39435",
    "partner": [
      0
    ]
  },
  {
    "plz": "39435",
    "partner": [
      0
    ]
  },
  {
    "plz": "39439",
    "partner": [
      0
    ]
  },
  {
    "plz": "39443",
    "partner": [
      0
    ]
  },
  {
    "plz": "39444",
    "partner": [
      0
    ]
  },
  {
    "plz": "39446",
    "partner": [
      0
    ]
  },
  {
    "plz": "39448",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39517",
    "partner": [
      0
    ]
  },
  {
    "plz": "39524",
    "partner": [
      0
    ]
  },
  {
    "plz": "39524",
    "partner": [
      0
    ]
  },
  {
    "plz": "39524",
    "partner": [
      0
    ]
  },
  {
    "plz": "39524",
    "partner": [
      0
    ]
  },
  {
    "plz": "39524",
    "partner": [
      0
    ]
  },
  {
    "plz": "39539",
    "partner": [
      0
    ]
  },
  {
    "plz": "39539",
    "partner": [
      0
    ]
  },
  {
    "plz": "39576",
    "partner": [
      0
    ]
  },
  {
    "plz": "39579",
    "partner": [
      0
    ]
  },
  {
    "plz": "39590",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39596",
    "partner": [
      0
    ]
  },
  {
    "plz": "39606",
    "partner": [
      0
    ]
  },
  {
    "plz": "39606",
    "partner": [
      0
    ]
  },
  {
    "plz": "39606",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39615",
    "partner": [
      0
    ]
  },
  {
    "plz": "39619",
    "partner": [
      0
    ]
  },
  {
    "plz": "39624",
    "partner": [
      0
    ]
  },
  {
    "plz": "39628",
    "partner": [
      0
    ]
  },
  {
    "plz": "39629",
    "partner": [
      0
    ]
  },
  {
    "plz": "39638",
    "partner": [
      0
    ]
  },
  {
    "plz": "39638",
    "partner": [
      0
    ]
  },
  {
    "plz": "39638",
    "partner": [
      0
    ]
  },
  {
    "plz": "39638",
    "partner": [
      0
    ]
  },
  {
    "plz": "39638",
    "partner": [
      0
    ]
  },
  {
    "plz": "39646",
    "partner": [
      0
    ]
  },
  {
    "plz": "39649",
    "partner": [
      0
    ]
  },
  {
    "plz": "40210",
    "partner": [
      2
    ]
  },
  {
    "plz": "40211",
    "partner": [
      2
    ]
  },
  {
    "plz": "40212",
    "partner": [
      2
    ]
  },
  {
    "plz": "40213",
    "partner": [
      2
    ]
  },
  {
    "plz": "40215",
    "partner": [
      2
    ]
  },
  {
    "plz": "40217",
    "partner": [
      2
    ]
  },
  {
    "plz": "40219",
    "partner": [
      2
    ]
  },
  {
    "plz": "40221",
    "partner": [
      2
    ]
  },
  {
    "plz": "40223",
    "partner": [
      2
    ]
  },
  {
    "plz": "40225",
    "partner": [
      2
    ]
  },
  {
    "plz": "40227",
    "partner": [
      2
    ]
  },
  {
    "plz": "40229",
    "partner": [
      2
    ]
  },
  {
    "plz": "40231",
    "partner": [
      2
    ]
  },
  {
    "plz": "40233",
    "partner": [
      2
    ]
  },
  {
    "plz": "40235",
    "partner": [
      2
    ]
  },
  {
    "plz": "40237",
    "partner": [
      2
    ]
  },
  {
    "plz": "40239",
    "partner": [
      2
    ]
  },
  {
    "plz": "40468",
    "partner": [
      2
    ]
  },
  {
    "plz": "40470",
    "partner": [
      2
    ]
  },
  {
    "plz": "40472",
    "partner": [
      2
    ]
  },
  {
    "plz": "40472",
    "partner": [
      2
    ]
  },
  {
    "plz": "40474",
    "partner": [
      2
    ]
  },
  {
    "plz": "40476",
    "partner": [
      2
    ]
  },
  {
    "plz": "40477",
    "partner": [
      2
    ]
  },
  {
    "plz": "40479",
    "partner": [
      2
    ]
  },
  {
    "plz": "40489",
    "partner": [
      2
    ]
  },
  {
    "plz": "40545",
    "partner": [
      2
    ]
  },
  {
    "plz": "40547",
    "partner": [
      2
    ]
  },
  {
    "plz": "40549",
    "partner": [
      2
    ]
  },
  {
    "plz": "40589",
    "partner": [
      2
    ]
  },
  {
    "plz": "40591",
    "partner": [
      2
    ]
  },
  {
    "plz": "40593",
    "partner": [
      2
    ]
  },
  {
    "plz": "40595",
    "partner": [
      2
    ]
  },
  {
    "plz": "40597",
    "partner": [
      2
    ]
  },
  {
    "plz": "40599",
    "partner": [
      2
    ]
  },
  {
    "plz": "40625",
    "partner": [
      2
    ]
  },
  {
    "plz": "40627",
    "partner": [
      2
    ]
  },
  {
    "plz": "40629",
    "partner": [
      2
    ]
  },
  {
    "plz": "40667",
    "partner": [
      2
    ]
  },
  {
    "plz": "40668",
    "partner": [
      2
    ]
  },
  {
    "plz": "40670",
    "partner": [
      2
    ]
  },
  {
    "plz": "40699",
    "partner": [
      2
    ]
  },
  {
    "plz": "40721",
    "partner": [
      2
    ]
  },
  {
    "plz": "40721",
    "partner": [
      2
    ]
  },
  {
    "plz": "40723",
    "partner": [
      2
    ]
  },
  {
    "plz": "40724",
    "partner": [
      2
    ]
  },
  {
    "plz": "40764",
    "partner": [
      2
    ]
  },
  {
    "plz": "40789",
    "partner": [
      2
    ]
  },
  {
    "plz": "40822",
    "partner": [
      2
    ]
  },
  {
    "plz": "40878",
    "partner": [
      2
    ]
  },
  {
    "plz": "40880",
    "partner": [
      2
    ]
  },
  {
    "plz": "40882",
    "partner": [
      2
    ]
  },
  {
    "plz": "40883",
    "partner": [
      2
    ]
  },
  {
    "plz": "40885",
    "partner": [
      2
    ]
  },
  {
    "plz": "41061",
    "partner": [
      2
    ]
  },
  {
    "plz": "41063",
    "partner": [
      2
    ]
  },
  {
    "plz": "41065",
    "partner": [
      2
    ]
  },
  {
    "plz": "41066",
    "partner": [
      2
    ]
  },
  {
    "plz": "41068",
    "partner": [
      2
    ]
  },
  {
    "plz": "41069",
    "partner": [
      2
    ]
  },
  {
    "plz": "41169",
    "partner": [
      2
    ]
  },
  {
    "plz": "41179",
    "partner": [
      2
    ]
  },
  {
    "plz": "41189",
    "partner": [
      2
    ]
  },
  {
    "plz": "41199",
    "partner": [
      2
    ]
  },
  {
    "plz": "41236",
    "partner": [
      2
    ]
  },
  {
    "plz": "41238",
    "partner": [
      2
    ]
  },
  {
    "plz": "41239",
    "partner": [
      2
    ]
  },
  {
    "plz": "41334",
    "partner": [
      2
    ]
  },
  {
    "plz": "41352",
    "partner": [
      2
    ]
  },
  {
    "plz": "41363",
    "partner": [
      2
    ]
  },
  {
    "plz": "41366",
    "partner": [
      2
    ]
  },
  {
    "plz": "41372",
    "partner": [
      2
    ]
  },
  {
    "plz": "41379",
    "partner": [
      2
    ]
  },
  {
    "plz": "41460",
    "partner": [
      2
    ]
  },
  {
    "plz": "41462",
    "partner": [
      2
    ]
  },
  {
    "plz": "41464",
    "partner": [
      2
    ]
  },
  {
    "plz": "41466",
    "partner": [
      2
    ]
  },
  {
    "plz": "41468",
    "partner": [
      2
    ]
  },
  {
    "plz": "41469",
    "partner": [
      2
    ]
  },
  {
    "plz": "41470",
    "partner": [
      2
    ]
  },
  {
    "plz": "41472",
    "partner": [
      2
    ]
  },
  {
    "plz": "41515",
    "partner": [
      2
    ]
  },
  {
    "plz": "41516",
    "partner": [
      2
    ]
  },
  {
    "plz": "41517",
    "partner": [
      2
    ]
  },
  {
    "plz": "41539",
    "partner": [
      2
    ]
  },
  {
    "plz": "41540",
    "partner": [
      2
    ]
  },
  {
    "plz": "41541",
    "partner": [
      2
    ]
  },
  {
    "plz": "41542",
    "partner": [
      2
    ]
  },
  {
    "plz": "41564",
    "partner": [
      2
    ]
  },
  {
    "plz": "41569",
    "partner": [
      2
    ]
  },
  {
    "plz": "41747",
    "partner": [
      2
    ]
  },
  {
    "plz": "41748",
    "partner": [
      2
    ]
  },
  {
    "plz": "41749",
    "partner": [
      2
    ]
  },
  {
    "plz": "41751",
    "partner": [
      2
    ]
  },
  {
    "plz": "41812",
    "partner": [
      2
    ]
  },
  {
    "plz": "41836",
    "partner": [
      2
    ]
  },
  {
    "plz": "41844",
    "partner": [
      2
    ]
  },
  {
    "plz": "41849",
    "partner": [
      2
    ]
  },
  {
    "plz": "42103",
    "partner": [
      2
    ]
  },
  {
    "plz": "42105",
    "partner": [
      2
    ]
  },
  {
    "plz": "42107",
    "partner": [
      2
    ]
  },
  {
    "plz": "42109",
    "partner": [
      2
    ]
  },
  {
    "plz": "42111",
    "partner": [
      2
    ]
  },
  {
    "plz": "42113",
    "partner": [
      2
    ]
  },
  {
    "plz": "42115",
    "partner": [
      2
    ]
  },
  {
    "plz": "42117",
    "partner": [
      2
    ]
  },
  {
    "plz": "42119",
    "partner": [
      2
    ]
  },
  {
    "plz": "42275",
    "partner": [
      2
    ]
  },
  {
    "plz": "42277",
    "partner": [
      2
    ]
  },
  {
    "plz": "42279",
    "partner": [
      2
    ]
  },
  {
    "plz": "42281",
    "partner": [
      2
    ]
  },
  {
    "plz": "42283",
    "partner": [
      2
    ]
  },
  {
    "plz": "42285",
    "partner": [
      2
    ]
  },
  {
    "plz": "42287",
    "partner": [
      2
    ]
  },
  {
    "plz": "42289",
    "partner": [
      2
    ]
  },
  {
    "plz": "42327",
    "partner": [
      2
    ]
  },
  {
    "plz": "42329",
    "partner": [
      2
    ]
  },
  {
    "plz": "42349",
    "partner": [
      2
    ]
  },
  {
    "plz": "42369",
    "partner": [
      2
    ]
  },
  {
    "plz": "42389",
    "partner": [
      2
    ]
  },
  {
    "plz": "42399",
    "partner": [
      2
    ]
  },
  {
    "plz": "42477",
    "partner": [
      2
    ]
  },
  {
    "plz": "42489",
    "partner": [
      2
    ]
  },
  {
    "plz": "42499",
    "partner": [
      2
    ]
  },
  {
    "plz": "42549",
    "partner": [
      2
    ]
  },
  {
    "plz": "42551",
    "partner": [
      2
    ]
  },
  {
    "plz": "42553",
    "partner": [
      2
    ]
  },
  {
    "plz": "42555",
    "partner": [
      2
    ]
  },
  {
    "plz": "42579",
    "partner": [
      2
    ]
  },
  {
    "plz": "42651",
    "partner": [
      2
    ]
  },
  {
    "plz": "42653",
    "partner": [
      2
    ]
  },
  {
    "plz": "42655",
    "partner": [
      2
    ]
  },
  {
    "plz": "42657",
    "partner": [
      2
    ]
  },
  {
    "plz": "42659",
    "partner": [
      2
    ]
  },
  {
    "plz": "42697",
    "partner": [
      2
    ]
  },
  {
    "plz": "42699",
    "partner": [
      2
    ]
  },
  {
    "plz": "42719",
    "partner": [
      2
    ]
  },
  {
    "plz": "42781",
    "partner": [
      2
    ]
  },
  {
    "plz": "42799",
    "partner": [
      2
    ]
  },
  {
    "plz": "42853",
    "partner": [
      3
    ]
  },
  {
    "plz": "42855",
    "partner": [
      3
    ]
  },
  {
    "plz": "42857",
    "partner": [
      3
    ]
  },
  {
    "plz": "42859",
    "partner": [
      3
    ]
  },
  {
    "plz": "42897",
    "partner": [
      3
    ]
  },
  {
    "plz": "42899",
    "partner": [
      3
    ]
  },
  {
    "plz": "42929",
    "partner": [
      3
    ]
  },
  {
    "plz": "44135",
    "partner": [
      3
    ]
  },
  {
    "plz": "44137",
    "partner": [
      3
    ]
  },
  {
    "plz": "44139",
    "partner": [
      3
    ]
  },
  {
    "plz": "44141",
    "partner": [
      3
    ]
  },
  {
    "plz": "44143",
    "partner": [
      3
    ]
  },
  {
    "plz": "44145",
    "partner": [
      3
    ]
  },
  {
    "plz": "44147",
    "partner": [
      3
    ]
  },
  {
    "plz": "44149",
    "partner": [
      3
    ]
  },
  {
    "plz": "44225",
    "partner": [
      3
    ]
  },
  {
    "plz": "44227",
    "partner": [
      3
    ]
  },
  {
    "plz": "44229",
    "partner": [
      3
    ]
  },
  {
    "plz": "44263",
    "partner": [
      3
    ]
  },
  {
    "plz": "44265",
    "partner": [
      3
    ]
  },
  {
    "plz": "44267",
    "partner": [
      3
    ]
  },
  {
    "plz": "44269",
    "partner": [
      3
    ]
  },
  {
    "plz": "44287",
    "partner": [
      3
    ]
  },
  {
    "plz": "44289",
    "partner": [
      3
    ]
  },
  {
    "plz": "44309",
    "partner": [
      3
    ]
  },
  {
    "plz": "44319",
    "partner": [
      3
    ]
  },
  {
    "plz": "44328",
    "partner": [
      3
    ]
  },
  {
    "plz": "44329",
    "partner": [
      3
    ]
  },
  {
    "plz": "44339",
    "partner": [
      3
    ]
  },
  {
    "plz": "44357",
    "partner": [
      3
    ]
  },
  {
    "plz": "44359",
    "partner": [
      3
    ]
  },
  {
    "plz": "44369",
    "partner": [
      3
    ]
  },
  {
    "plz": "44379",
    "partner": [
      3
    ]
  },
  {
    "plz": "44388",
    "partner": [
      3
    ]
  },
  {
    "plz": "44532",
    "partner": [
      3
    ]
  },
  {
    "plz": "44534",
    "partner": [
      3
    ]
  },
  {
    "plz": "44536",
    "partner": [
      3
    ]
  },
  {
    "plz": "44575",
    "partner": [
      3
    ]
  },
  {
    "plz": "44577",
    "partner": [
      3
    ]
  },
  {
    "plz": "44579",
    "partner": [
      3
    ]
  },
  {
    "plz": "44581",
    "partner": [
      3
    ]
  },
  {
    "plz": "44623",
    "partner": [
      3
    ]
  },
  {
    "plz": "44625",
    "partner": [
      3
    ]
  },
  {
    "plz": "44627",
    "partner": [
      3
    ]
  },
  {
    "plz": "44628",
    "partner": [
      3
    ]
  },
  {
    "plz": "44629",
    "partner": [
      3
    ]
  },
  {
    "plz": "44649",
    "partner": [
      3
    ]
  },
  {
    "plz": "44651",
    "partner": [
      3
    ]
  },
  {
    "plz": "44652",
    "partner": [
      3
    ]
  },
  {
    "plz": "44653",
    "partner": [
      3
    ]
  },
  {
    "plz": "44787",
    "partner": [
      3
    ]
  },
  {
    "plz": "44789",
    "partner": [
      3
    ]
  },
  {
    "plz": "44791",
    "partner": [
      3
    ]
  },
  {
    "plz": "44793",
    "partner": [
      3
    ]
  },
  {
    "plz": "44795",
    "partner": [
      3
    ]
  },
  {
    "plz": "44797",
    "partner": [
      3
    ]
  },
  {
    "plz": "44799",
    "partner": [
      3
    ]
  },
  {
    "plz": "44801",
    "partner": [
      3
    ]
  },
  {
    "plz": "44803",
    "partner": [
      3
    ]
  },
  {
    "plz": "44805",
    "partner": [
      3
    ]
  },
  {
    "plz": "44807",
    "partner": [
      3
    ]
  },
  {
    "plz": "44809",
    "partner": [
      3
    ]
  },
  {
    "plz": "44866",
    "partner": [
      3
    ]
  },
  {
    "plz": "44867",
    "partner": [
      3
    ]
  },
  {
    "plz": "44869",
    "partner": [
      3
    ]
  },
  {
    "plz": "44879",
    "partner": [
      3
    ]
  },
  {
    "plz": "44892",
    "partner": [
      3
    ]
  },
  {
    "plz": "44894",
    "partner": [
      3
    ]
  },
  {
    "plz": "45127",
    "partner": [
      2
    ]
  },
  {
    "plz": "45128",
    "partner": [
      2
    ]
  },
  {
    "plz": "45130",
    "partner": [
      2
    ]
  },
  {
    "plz": "45131",
    "partner": [
      2
    ]
  },
  {
    "plz": "45133",
    "partner": [
      2
    ]
  },
  {
    "plz": "45134",
    "partner": [
      2
    ]
  },
  {
    "plz": "45136",
    "partner": [
      2
    ]
  },
  {
    "plz": "45138",
    "partner": [
      2
    ]
  },
  {
    "plz": "45139",
    "partner": [
      2
    ]
  },
  {
    "plz": "45141",
    "partner": [
      2
    ]
  },
  {
    "plz": "45143",
    "partner": [
      2
    ]
  },
  {
    "plz": "45144",
    "partner": [
      2
    ]
  },
  {
    "plz": "45145",
    "partner": [
      2
    ]
  },
  {
    "plz": "45147",
    "partner": [
      2
    ]
  },
  {
    "plz": "45149",
    "partner": [
      2
    ]
  },
  {
    "plz": "45219",
    "partner": [
      2
    ]
  },
  {
    "plz": "45239",
    "partner": [
      2
    ]
  },
  {
    "plz": "45257",
    "partner": [
      2
    ]
  },
  {
    "plz": "45259",
    "partner": [
      2
    ]
  },
  {
    "plz": "45276",
    "partner": [
      2
    ]
  },
  {
    "plz": "45277",
    "partner": [
      2
    ]
  },
  {
    "plz": "45279",
    "partner": [
      2
    ]
  },
  {
    "plz": "45289",
    "partner": [
      2
    ]
  },
  {
    "plz": "45307",
    "partner": [
      2
    ]
  },
  {
    "plz": "45309",
    "partner": [
      2
    ]
  },
  {
    "plz": "45326",
    "partner": [
      2
    ]
  },
  {
    "plz": "45327",
    "partner": [
      2
    ]
  },
  {
    "plz": "45329",
    "partner": [
      2
    ]
  },
  {
    "plz": "45355",
    "partner": [
      2
    ]
  },
  {
    "plz": "45356",
    "partner": [
      2
    ]
  },
  {
    "plz": "45357",
    "partner": [
      2
    ]
  },
  {
    "plz": "45359",
    "partner": [
      2
    ]
  },
  {
    "plz": "45468",
    "partner": [
      2
    ]
  },
  {
    "plz": "45470",
    "partner": [
      2
    ]
  },
  {
    "plz": "45472",
    "partner": [
      2
    ]
  },
  {
    "plz": "45473",
    "partner": [
      2
    ]
  },
  {
    "plz": "45475",
    "partner": [
      2
    ]
  },
  {
    "plz": "45476",
    "partner": [
      2
    ]
  },
  {
    "plz": "45478",
    "partner": [
      2
    ]
  },
  {
    "plz": "45479",
    "partner": [
      2
    ]
  },
  {
    "plz": "45481",
    "partner": [
      2
    ]
  },
  {
    "plz": "45525",
    "partner": [
      2
    ]
  },
  {
    "plz": "45527",
    "partner": [
      2
    ]
  },
  {
    "plz": "45529",
    "partner": [
      2
    ]
  },
  {
    "plz": "45549",
    "partner": [
      2
    ]
  },
  {
    "plz": "45657",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45659",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45661",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45663",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45665",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45699",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45701",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45711",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45721",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45731",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45739",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45768",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45770",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45772",
    "partner": [
      2,
      11
    ]
  },
  {
    "plz": "45879",
    "partner": [
      2
    ]
  },
  {
    "plz": "45881",
    "partner": [
      2
    ]
  },
  {
    "plz": "45883",
    "partner": [
      2
    ]
  },
  {
    "plz": "45884",
    "partner": [
      2
    ]
  },
  {
    "plz": "45886",
    "partner": [
      2
    ]
  },
  {
    "plz": "45888",
    "partner": [
      2
    ]
  },
  {
    "plz": "45889",
    "partner": [
      2
    ]
  },
  {
    "plz": "45891",
    "partner": [
      2
    ]
  },
  {
    "plz": "45892",
    "partner": [
      2
    ]
  },
  {
    "plz": "45894",
    "partner": [
      2
    ]
  },
  {
    "plz": "45896",
    "partner": [
      2
    ]
  },
  {
    "plz": "45897",
    "partner": [
      2
    ]
  },
  {
    "plz": "45899",
    "partner": [
      2
    ]
  },
  {
    "plz": "45964",
    "partner": [
      2
    ]
  },
  {
    "plz": "45966",
    "partner": [
      2
    ]
  },
  {
    "plz": "45968",
    "partner": [
      2
    ]
  },
  {
    "plz": "46045",
    "partner": [
      2
    ]
  },
  {
    "plz": "46047",
    "partner": [
      2
    ]
  },
  {
    "plz": "46049",
    "partner": [
      2
    ]
  },
  {
    "plz": "46117",
    "partner": [
      2
    ]
  },
  {
    "plz": "46119",
    "partner": [
      2
    ]
  },
  {
    "plz": "46145",
    "partner": [
      2
    ]
  },
  {
    "plz": "46147",
    "partner": [
      2
    ]
  },
  {
    "plz": "46149",
    "partner": [
      2
    ]
  },
  {
    "plz": "46236",
    "partner": [
      2
    ]
  },
  {
    "plz": "46238",
    "partner": [
      2
    ]
  },
  {
    "plz": "46240",
    "partner": [
      2
    ]
  },
  {
    "plz": "46242",
    "partner": [
      2
    ]
  },
  {
    "plz": "46244",
    "partner": [
      2
    ]
  },
  {
    "plz": "46282",
    "partner": [
      2
    ]
  },
  {
    "plz": "46284",
    "partner": [
      2
    ]
  },
  {
    "plz": "46286",
    "partner": [
      2
    ]
  },
  {
    "plz": "46325",
    "partner": [
      2
    ]
  },
  {
    "plz": "46342",
    "partner": [
      2
    ]
  },
  {
    "plz": "46348",
    "partner": [
      2
    ]
  },
  {
    "plz": "46354",
    "partner": [
      2
    ]
  },
  {
    "plz": "46359",
    "partner": [
      2
    ]
  },
  {
    "plz": "46395",
    "partner": [
      2
    ]
  },
  {
    "plz": "46397",
    "partner": [
      2
    ]
  },
  {
    "plz": "46399",
    "partner": [
      2
    ]
  },
  {
    "plz": "46414",
    "partner": [
      2
    ]
  },
  {
    "plz": "46419",
    "partner": [
      2
    ]
  },
  {
    "plz": "46446",
    "partner": [
      2
    ]
  },
  {
    "plz": "46459",
    "partner": [
      2
    ]
  },
  {
    "plz": "46483",
    "partner": [
      2
    ]
  },
  {
    "plz": "46485",
    "partner": [
      2
    ]
  },
  {
    "plz": "46487",
    "partner": [
      2
    ]
  },
  {
    "plz": "46499",
    "partner": [
      2
    ]
  },
  {
    "plz": "46509",
    "partner": [
      2
    ]
  },
  {
    "plz": "46514",
    "partner": [
      2
    ]
  },
  {
    "plz": "46519",
    "partner": [
      2
    ]
  },
  {
    "plz": "46535",
    "partner": [
      2
    ]
  },
  {
    "plz": "46537",
    "partner": [
      2
    ]
  },
  {
    "plz": "46539",
    "partner": [
      2
    ]
  },
  {
    "plz": "46562",
    "partner": [
      2
    ]
  },
  {
    "plz": "46569",
    "partner": [
      2
    ]
  },
  {
    "plz": "47051",
    "partner": [
      2
    ]
  },
  {
    "plz": "47053",
    "partner": [
      2
    ]
  },
  {
    "plz": "47055",
    "partner": [
      2
    ]
  },
  {
    "plz": "47057",
    "partner": [
      2
    ]
  },
  {
    "plz": "47058",
    "partner": [
      2
    ]
  },
  {
    "plz": "47059",
    "partner": [
      2
    ]
  },
  {
    "plz": "47119",
    "partner": [
      2
    ]
  },
  {
    "plz": "47137",
    "partner": [
      2
    ]
  },
  {
    "plz": "47138",
    "partner": [
      2
    ]
  },
  {
    "plz": "47139",
    "partner": [
      2
    ]
  },
  {
    "plz": "47166",
    "partner": [
      2
    ]
  },
  {
    "plz": "47167",
    "partner": [
      2
    ]
  },
  {
    "plz": "47169",
    "partner": [
      2
    ]
  },
  {
    "plz": "47178",
    "partner": [
      2
    ]
  },
  {
    "plz": "47179",
    "partner": [
      2
    ]
  },
  {
    "plz": "47198",
    "partner": [
      2
    ]
  },
  {
    "plz": "47199",
    "partner": [
      2
    ]
  },
  {
    "plz": "47226",
    "partner": [
      2
    ]
  },
  {
    "plz": "47228",
    "partner": [
      2
    ]
  },
  {
    "plz": "47229",
    "partner": [
      2
    ]
  },
  {
    "plz": "47239",
    "partner": [
      2
    ]
  },
  {
    "plz": "47249",
    "partner": [
      2
    ]
  },
  {
    "plz": "47259",
    "partner": [
      2
    ]
  },
  {
    "plz": "47269",
    "partner": [
      2
    ]
  },
  {
    "plz": "47279",
    "partner": [
      2
    ]
  },
  {
    "plz": "47441",
    "partner": [
      2
    ]
  },
  {
    "plz": "47443",
    "partner": [
      2
    ]
  },
  {
    "plz": "47445",
    "partner": [
      2
    ]
  },
  {
    "plz": "47447",
    "partner": [
      2
    ]
  },
  {
    "plz": "47475",
    "partner": [
      2
    ]
  },
  {
    "plz": "47495",
    "partner": [
      2
    ]
  },
  {
    "plz": "47506",
    "partner": [
      2
    ]
  },
  {
    "plz": "47509",
    "partner": [
      2
    ]
  },
  {
    "plz": "47533",
    "partner": [
      2
    ]
  },
  {
    "plz": "47546",
    "partner": [
      2
    ]
  },
  {
    "plz": "47551",
    "partner": [
      2
    ]
  },
  {
    "plz": "47559",
    "partner": [
      2
    ]
  },
  {
    "plz": "47574",
    "partner": [
      2
    ]
  },
  {
    "plz": "47589",
    "partner": [
      2
    ]
  },
  {
    "plz": "47608",
    "partner": [
      2
    ]
  },
  {
    "plz": "47623",
    "partner": [
      2
    ]
  },
  {
    "plz": "47624",
    "partner": [
      2
    ]
  },
  {
    "plz": "47625",
    "partner": [
      2
    ]
  },
  {
    "plz": "47626",
    "partner": [
      2
    ]
  },
  {
    "plz": "47627",
    "partner": [
      2
    ]
  },
  {
    "plz": "47638",
    "partner": [
      2
    ]
  },
  {
    "plz": "47647",
    "partner": [
      2
    ]
  },
  {
    "plz": "47652",
    "partner": [
      2
    ]
  },
  {
    "plz": "47661",
    "partner": [
      2
    ]
  },
  {
    "plz": "47665",
    "partner": [
      2
    ]
  },
  {
    "plz": "47669",
    "partner": [
      2
    ]
  },
  {
    "plz": "47798",
    "partner": [
      2
    ]
  },
  {
    "plz": "47799",
    "partner": [
      2
    ]
  },
  {
    "plz": "47800",
    "partner": [
      2
    ]
  },
  {
    "plz": "47802",
    "partner": [
      2
    ]
  },
  {
    "plz": "47803",
    "partner": [
      2
    ]
  },
  {
    "plz": "47804",
    "partner": [
      2
    ]
  },
  {
    "plz": "47805",
    "partner": [
      2
    ]
  },
  {
    "plz": "47807",
    "partner": [
      2
    ]
  },
  {
    "plz": "47809",
    "partner": [
      2
    ]
  },
  {
    "plz": "47829",
    "partner": [
      2
    ]
  },
  {
    "plz": "47839",
    "partner": [
      2
    ]
  },
  {
    "plz": "47877",
    "partner": [
      2
    ]
  },
  {
    "plz": "47906",
    "partner": [
      2
    ]
  },
  {
    "plz": "47918",
    "partner": [
      2
    ]
  },
  {
    "plz": "47929",
    "partner": [
      2
    ]
  },
  {
    "plz": "48143",
    "partner": [
      11
    ]
  },
  {
    "plz": "48145",
    "partner": [
      11
    ]
  },
  {
    "plz": "48147",
    "partner": [
      11
    ]
  },
  {
    "plz": "48149",
    "partner": [
      11
    ]
  },
  {
    "plz": "48151",
    "partner": [
      11
    ]
  },
  {
    "plz": "48153",
    "partner": [
      11
    ]
  },
  {
    "plz": "48155",
    "partner": [
      11
    ]
  },
  {
    "plz": "48157",
    "partner": [
      11
    ]
  },
  {
    "plz": "48159",
    "partner": [
      11
    ]
  },
  {
    "plz": "48161",
    "partner": [
      11
    ]
  },
  {
    "plz": "48163",
    "partner": [
      11
    ]
  },
  {
    "plz": "48165",
    "partner": [
      11
    ]
  },
  {
    "plz": "48167",
    "partner": [
      11
    ]
  },
  {
    "plz": "48231",
    "partner": [
      11
    ]
  },
  {
    "plz": "48249",
    "partner": [
      11
    ]
  },
  {
    "plz": "48268",
    "partner": [
      11
    ]
  },
  {
    "plz": "48282",
    "partner": [
      11
    ]
  },
  {
    "plz": "48291",
    "partner": [
      11
    ]
  },
  {
    "plz": "48301",
    "partner": [
      11
    ]
  },
  {
    "plz": "48308",
    "partner": [
      11
    ]
  },
  {
    "plz": "48317",
    "partner": [
      11
    ]
  },
  {
    "plz": "48324",
    "partner": [
      11
    ]
  },
  {
    "plz": "48329",
    "partner": [
      11
    ]
  },
  {
    "plz": "48336",
    "partner": [
      11
    ]
  },
  {
    "plz": "48341",
    "partner": [
      11
    ]
  },
  {
    "plz": "48346",
    "partner": [
      11
    ]
  },
  {
    "plz": "48351",
    "partner": [
      11
    ]
  },
  {
    "plz": "48356",
    "partner": [
      11
    ]
  },
  {
    "plz": "48361",
    "partner": [
      11
    ]
  },
  {
    "plz": "48366",
    "partner": [
      11
    ]
  },
  {
    "plz": "48369",
    "partner": [
      11
    ]
  },
  {
    "plz": "48429",
    "partner": [
      11
    ]
  },
  {
    "plz": "48431",
    "partner": [
      11
    ]
  },
  {
    "plz": "48432",
    "partner": [
      11
    ]
  },
  {
    "plz": "48455",
    "partner": [
      0
    ]
  },
  {
    "plz": "48465",
    "partner": [
      0
    ]
  },
  {
    "plz": "48465",
    "partner": [
      0
    ]
  },
  {
    "plz": "48465",
    "partner": [
      0
    ]
  },
  {
    "plz": "48465",
    "partner": [
      0
    ]
  },
  {
    "plz": "48465",
    "partner": [
      0
    ]
  },
  {
    "plz": "48465",
    "partner": [
      0
    ]
  },
  {
    "plz": "48477",
    "partner": [
      0
    ]
  },
  {
    "plz": "48480",
    "partner": [
      0
    ]
  },
  {
    "plz": "48480",
    "partner": [
      0
    ]
  },
  {
    "plz": "48480",
    "partner": [
      0
    ]
  },
  {
    "plz": "48485",
    "partner": [
      0
    ]
  },
  {
    "plz": "48488",
    "partner": [
      0
    ]
  },
  {
    "plz": "48493",
    "partner": [
      0
    ]
  },
  {
    "plz": "48496",
    "partner": [
      0
    ]
  },
  {
    "plz": "48499",
    "partner": [
      0
    ]
  },
  {
    "plz": "48527",
    "partner": [
      0
    ]
  },
  {
    "plz": "48529",
    "partner": [
      0
    ]
  },
  {
    "plz": "48531",
    "partner": [
      0
    ]
  },
  {
    "plz": "48565",
    "partner": [
      0
    ]
  },
  {
    "plz": "48599",
    "partner": [
      0
    ]
  },
  {
    "plz": "48607",
    "partner": [
      11
    ]
  },
  {
    "plz": "48612",
    "partner": [
      11
    ]
  },
  {
    "plz": "48619",
    "partner": [
      11
    ]
  },
  {
    "plz": "48624",
    "partner": [
      11
    ]
  },
  {
    "plz": "48629",
    "partner": [
      11
    ]
  },
  {
    "plz": "48653",
    "partner": [
      11
    ]
  },
  {
    "plz": "48683",
    "partner": [
      11
    ]
  },
  {
    "plz": "48691",
    "partner": [
      11
    ]
  },
  {
    "plz": "48703",
    "partner": [
      11
    ]
  },
  {
    "plz": "48712",
    "partner": [
      11
    ]
  },
  {
    "plz": "48720",
    "partner": [
      11
    ]
  },
  {
    "plz": "48727",
    "partner": [
      11
    ]
  },
  {
    "plz": "48734",
    "partner": [
      11
    ]
  },
  {
    "plz": "48739",
    "partner": [
      11
    ]
  },
  {
    "plz": "49074",
    "partner": [
      0
    ]
  },
  {
    "plz": "49076",
    "partner": [
      0
    ]
  },
  {
    "plz": "49078",
    "partner": [
      0
    ]
  },
  {
    "plz": "49080",
    "partner": [
      0
    ]
  },
  {
    "plz": "49082",
    "partner": [
      0
    ]
  },
  {
    "plz": "49084",
    "partner": [
      0
    ]
  },
  {
    "plz": "49086",
    "partner": [
      0
    ]
  },
  {
    "plz": "49088",
    "partner": [
      0
    ]
  },
  {
    "plz": "49090",
    "partner": [
      0
    ]
  },
  {
    "plz": "49124",
    "partner": [
      0
    ]
  },
  {
    "plz": "49134",
    "partner": [
      0
    ]
  },
  {
    "plz": "49143",
    "partner": [
      0
    ]
  },
  {
    "plz": "49152",
    "partner": [
      0
    ]
  },
  {
    "plz": "49163",
    "partner": [
      0
    ]
  },
  {
    "plz": "49170",
    "partner": [
      0
    ]
  },
  {
    "plz": "49176",
    "partner": [
      0
    ]
  },
  {
    "plz": "49179",
    "partner": [
      0
    ]
  },
  {
    "plz": "49186",
    "partner": [
      0
    ]
  },
  {
    "plz": "49191",
    "partner": [
      0
    ]
  },
  {
    "plz": "49196",
    "partner": [
      0
    ]
  },
  {
    "plz": "49201",
    "partner": [
      0
    ]
  },
  {
    "plz": "49205",
    "partner": [
      0
    ]
  },
  {
    "plz": "49214",
    "partner": [
      0
    ]
  },
  {
    "plz": "49219",
    "partner": [
      0
    ]
  },
  {
    "plz": "49324",
    "partner": [
      0
    ]
  },
  {
    "plz": "49326",
    "partner": [
      0
    ]
  },
  {
    "plz": "49328",
    "partner": [
      0
    ]
  },
  {
    "plz": "49356",
    "partner": [
      0
    ]
  },
  {
    "plz": "49377",
    "partner": [
      0
    ]
  },
  {
    "plz": "49393",
    "partner": [
      0
    ]
  },
  {
    "plz": "49401",
    "partner": [
      0
    ]
  },
  {
    "plz": "49406",
    "partner": [
      0
    ]
  },
  {
    "plz": "49406",
    "partner": [
      0
    ]
  },
  {
    "plz": "49406",
    "partner": [
      0
    ]
  },
  {
    "plz": "49413",
    "partner": [
      0
    ]
  },
  {
    "plz": "49419",
    "partner": [
      0
    ]
  },
  {
    "plz": "49424",
    "partner": [
      0
    ]
  },
  {
    "plz": "49429",
    "partner": [
      0
    ]
  },
  {
    "plz": "49434",
    "partner": [
      0
    ]
  },
  {
    "plz": "49439",
    "partner": [
      0
    ]
  },
  {
    "plz": "49448",
    "partner": [
      0
    ]
  },
  {
    "plz": "49448",
    "partner": [
      0
    ]
  },
  {
    "plz": "49448",
    "partner": [
      0
    ]
  },
  {
    "plz": "49448",
    "partner": [
      0
    ]
  },
  {
    "plz": "49448",
    "partner": [
      0
    ]
  },
  {
    "plz": "49448",
    "partner": [
      0
    ]
  },
  {
    "plz": "49451",
    "partner": [
      0
    ]
  },
  {
    "plz": "49453",
    "partner": [
      0
    ]
  },
  {
    "plz": "49453",
    "partner": [
      0
    ]
  },
  {
    "plz": "49453",
    "partner": [
      0
    ]
  },
  {
    "plz": "49453",
    "partner": [
      0
    ]
  },
  {
    "plz": "49453",
    "partner": [
      0
    ]
  },
  {
    "plz": "49456",
    "partner": [
      0
    ]
  },
  {
    "plz": "49457",
    "partner": [
      0
    ]
  },
  {
    "plz": "49457",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49459",
    "partner": [
      0
    ]
  },
  {
    "plz": "49477",
    "partner": [
      0
    ]
  },
  {
    "plz": "49479",
    "partner": [
      0
    ]
  },
  {
    "plz": "49492",
    "partner": [
      0
    ]
  },
  {
    "plz": "49497",
    "partner": [
      0
    ]
  },
  {
    "plz": "49504",
    "partner": [
      0
    ]
  },
  {
    "plz": "49509",
    "partner": [
      0
    ]
  },
  {
    "plz": "49525",
    "partner": [
      0
    ]
  },
  {
    "plz": "49536",
    "partner": [
      0
    ]
  },
  {
    "plz": "49545",
    "partner": [
      0
    ]
  },
  {
    "plz": "49549",
    "partner": [
      0
    ]
  },
  {
    "plz": "49565",
    "partner": [
      0
    ]
  },
  {
    "plz": "49577",
    "partner": [
      0
    ]
  },
  {
    "plz": "49577",
    "partner": [
      0
    ]
  },
  {
    "plz": "49577",
    "partner": [
      0
    ]
  },
  {
    "plz": "49584",
    "partner": [
      0
    ]
  },
  {
    "plz": "49586",
    "partner": [
      0
    ]
  },
  {
    "plz": "49586",
    "partner": [
      0
    ]
  },
  {
    "plz": "49593",
    "partner": [
      0
    ]
  },
  {
    "plz": "49594",
    "partner": [
      0
    ]
  },
  {
    "plz": "49596",
    "partner": [
      0
    ]
  },
  {
    "plz": "49597",
    "partner": [
      0
    ]
  },
  {
    "plz": "49599",
    "partner": [
      0
    ]
  },
  {
    "plz": "49610",
    "partner": [
      0
    ]
  },
  {
    "plz": "49624",
    "partner": [
      0
    ]
  },
  {
    "plz": "49626",
    "partner": [
      0
    ]
  },
  {
    "plz": "49626",
    "partner": [
      0
    ]
  },
  {
    "plz": "49632",
    "partner": [
      0
    ]
  },
  {
    "plz": "49635",
    "partner": [
      0
    ]
  },
  {
    "plz": "49637",
    "partner": [
      0
    ]
  },
  {
    "plz": "49638",
    "partner": [
      0
    ]
  },
  {
    "plz": "49661",
    "partner": [
      0
    ]
  },
  {
    "plz": "49681",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49685",
    "partner": [
      0
    ]
  },
  {
    "plz": "49688",
    "partner": [
      0
    ]
  },
  {
    "plz": "49692",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49696",
    "partner": [
      0
    ]
  },
  {
    "plz": "49699",
    "partner": [
      0
    ]
  },
  {
    "plz": "49716",
    "partner": [
      0
    ]
  },
  {
    "plz": "49733",
    "partner": [
      0
    ]
  },
  {
    "plz": "49740",
    "partner": [
      0
    ]
  },
  {
    "plz": "49744",
    "partner": [
      0
    ]
  },
  {
    "plz": "49751",
    "partner": [
      0
    ]
  },
  {
    "plz": "49751",
    "partner": [
      0
    ]
  },
  {
    "plz": "49751",
    "partner": [
      0
    ]
  },
  {
    "plz": "49751",
    "partner": [
      0
    ]
  },
  {
    "plz": "49757",
    "partner": [
      0
    ]
  },
  {
    "plz": "49757",
    "partner": [
      0
    ]
  },
  {
    "plz": "49757",
    "partner": [
      0
    ]
  },
  {
    "plz": "49762",
    "partner": [
      0
    ]
  },
  {
    "plz": "49762",
    "partner": [
      0
    ]
  },
  {
    "plz": "49762",
    "partner": [
      0
    ]
  },
  {
    "plz": "49762",
    "partner": [
      0
    ]
  },
  {
    "plz": "49767",
    "partner": [
      0
    ]
  },
  {
    "plz": "49770",
    "partner": [
      0
    ]
  },
  {
    "plz": "49770",
    "partner": [
      0
    ]
  },
  {
    "plz": "49774",
    "partner": [
      0
    ]
  },
  {
    "plz": "49777",
    "partner": [
      0
    ]
  },
  {
    "plz": "49777",
    "partner": [
      0
    ]
  },
  {
    "plz": "49777",
    "partner": [
      0
    ]
  },
  {
    "plz": "49779",
    "partner": [
      0
    ]
  },
  {
    "plz": "49779",
    "partner": [
      0
    ]
  },
  {
    "plz": "49808",
    "partner": [
      0
    ]
  },
  {
    "plz": "49809",
    "partner": [
      0
    ]
  },
  {
    "plz": "49811",
    "partner": [
      0
    ]
  },
  {
    "plz": "49824",
    "partner": [
      0
    ]
  },
  {
    "plz": "49824",
    "partner": [
      0
    ]
  },
  {
    "plz": "49824",
    "partner": [
      0
    ]
  },
  {
    "plz": "49828",
    "partner": [
      0
    ]
  },
  {
    "plz": "49828",
    "partner": [
      0
    ]
  },
  {
    "plz": "49828",
    "partner": [
      0
    ]
  },
  {
    "plz": "49828",
    "partner": [
      0
    ]
  },
  {
    "plz": "49828",
    "partner": [
      0
    ]
  },
  {
    "plz": "49832",
    "partner": [
      0
    ]
  },
  {
    "plz": "49832",
    "partner": [
      0
    ]
  },
  {
    "plz": "49832",
    "partner": [
      0
    ]
  },
  {
    "plz": "49832",
    "partner": [
      0
    ]
  },
  {
    "plz": "49832",
    "partner": [
      0
    ]
  },
  {
    "plz": "49835",
    "partner": [
      0
    ]
  },
  {
    "plz": "49838",
    "partner": [
      0
    ]
  },
  {
    "plz": "49838",
    "partner": [
      0
    ]
  },
  {
    "plz": "49838",
    "partner": [
      0
    ]
  },
  {
    "plz": "49838",
    "partner": [
      0
    ]
  },
  {
    "plz": "49838",
    "partner": [
      0
    ]
  },
  {
    "plz": "49843",
    "partner": [
      0
    ]
  },
  {
    "plz": "49843",
    "partner": [
      0
    ]
  },
  {
    "plz": "49843",
    "partner": [
      0
    ]
  },
  {
    "plz": "49843",
    "partner": [
      0
    ]
  },
  {
    "plz": "49844",
    "partner": [
      0
    ]
  },
  {
    "plz": "49846",
    "partner": [
      0
    ]
  },
  {
    "plz": "49847",
    "partner": [
      0
    ]
  },
  {
    "plz": "49847",
    "partner": [
      0
    ]
  },
  {
    "plz": "49849",
    "partner": [
      0
    ]
  },
  {
    "plz": "50126",
    "partner": [
      2
    ]
  },
  {
    "plz": "50127",
    "partner": [
      2
    ]
  },
  {
    "plz": "50129",
    "partner": [
      2
    ]
  },
  {
    "plz": "50169",
    "partner": [
      2
    ]
  },
  {
    "plz": "50170",
    "partner": [
      2
    ]
  },
  {
    "plz": "50171",
    "partner": [
      2
    ]
  },
  {
    "plz": "50181",
    "partner": [
      2
    ]
  },
  {
    "plz": "50189",
    "partner": [
      2
    ]
  },
  {
    "plz": "50226",
    "partner": [
      2
    ]
  },
  {
    "plz": "50259",
    "partner": [
      2
    ]
  },
  {
    "plz": "50321",
    "partner": [
      2
    ]
  },
  {
    "plz": "50354",
    "partner": [
      2
    ]
  },
  {
    "plz": "50374",
    "partner": [
      2
    ]
  },
  {
    "plz": "50389",
    "partner": [
      2
    ]
  },
  {
    "plz": "50667",
    "partner": [
      2
    ]
  },
  {
    "plz": "50668",
    "partner": [
      2
    ]
  },
  {
    "plz": "50670",
    "partner": [
      2
    ]
  },
  {
    "plz": "50672",
    "partner": [
      2
    ]
  },
  {
    "plz": "50674",
    "partner": [
      2
    ]
  },
  {
    "plz": "50676",
    "partner": [
      2
    ]
  },
  {
    "plz": "50677",
    "partner": [
      2
    ]
  },
  {
    "plz": "50678",
    "partner": [
      2
    ]
  },
  {
    "plz": "50679",
    "partner": [
      2
    ]
  },
  {
    "plz": "50733",
    "partner": [
      2
    ]
  },
  {
    "plz": "50735",
    "partner": [
      2
    ]
  },
  {
    "plz": "50737",
    "partner": [
      2
    ]
  },
  {
    "plz": "50739",
    "partner": [
      2
    ]
  },
  {
    "plz": "50765",
    "partner": [
      2
    ]
  },
  {
    "plz": "50767",
    "partner": [
      2
    ]
  },
  {
    "plz": "50769",
    "partner": [
      2
    ]
  },
  {
    "plz": "50823",
    "partner": [
      2
    ]
  },
  {
    "plz": "50825",
    "partner": [
      2
    ]
  },
  {
    "plz": "50827",
    "partner": [
      2
    ]
  },
  {
    "plz": "50829",
    "partner": [
      2
    ]
  },
  {
    "plz": "50858",
    "partner": [
      2
    ]
  },
  {
    "plz": "50859",
    "partner": [
      2
    ]
  },
  {
    "plz": "50931",
    "partner": [
      2
    ]
  },
  {
    "plz": "50933",
    "partner": [
      2
    ]
  },
  {
    "plz": "50935",
    "partner": [
      2
    ]
  },
  {
    "plz": "50937",
    "partner": [
      2
    ]
  },
  {
    "plz": "50939",
    "partner": [
      2
    ]
  },
  {
    "plz": "50968",
    "partner": [
      2
    ]
  },
  {
    "plz": "50969",
    "partner": [
      2
    ]
  },
  {
    "plz": "50996",
    "partner": [
      2
    ]
  },
  {
    "plz": "50997",
    "partner": [
      2
    ]
  },
  {
    "plz": "50999",
    "partner": [
      2
    ]
  },
  {
    "plz": "51061",
    "partner": [
      2
    ]
  },
  {
    "plz": "51063",
    "partner": [
      2
    ]
  },
  {
    "plz": "51065",
    "partner": [
      2
    ]
  },
  {
    "plz": "51067",
    "partner": [
      2
    ]
  },
  {
    "plz": "51069",
    "partner": [
      2
    ]
  },
  {
    "plz": "51103",
    "partner": [
      2
    ]
  },
  {
    "plz": "51105",
    "partner": [
      2
    ]
  },
  {
    "plz": "51107",
    "partner": [
      2
    ]
  },
  {
    "plz": "51109",
    "partner": [
      2
    ]
  },
  {
    "plz": "51143",
    "partner": [
      2
    ]
  },
  {
    "plz": "51145",
    "partner": [
      2
    ]
  },
  {
    "plz": "51147",
    "partner": [
      2
    ]
  },
  {
    "plz": "51149",
    "partner": [
      2
    ]
  },
  {
    "plz": "51371",
    "partner": [
      2
    ]
  },
  {
    "plz": "51373",
    "partner": [
      2
    ]
  },
  {
    "plz": "51375",
    "partner": [
      2
    ]
  },
  {
    "plz": "51377",
    "partner": [
      2
    ]
  },
  {
    "plz": "51379",
    "partner": [
      2
    ]
  },
  {
    "plz": "51381",
    "partner": [
      2
    ]
  },
  {
    "plz": "51399",
    "partner": [
      3
    ]
  },
  {
    "plz": "51427",
    "partner": [
      3
    ]
  },
  {
    "plz": "51429",
    "partner": [
      3
    ]
  },
  {
    "plz": "51465",
    "partner": [
      3
    ]
  },
  {
    "plz": "51467",
    "partner": [
      3
    ]
  },
  {
    "plz": "51467",
    "partner": [
      3
    ]
  },
  {
    "plz": "51469",
    "partner": [
      3
    ]
  },
  {
    "plz": "51491",
    "partner": [
      3
    ]
  },
  {
    "plz": "51503",
    "partner": [
      3
    ]
  },
  {
    "plz": "51515",
    "partner": [
      3
    ]
  },
  {
    "plz": "51519",
    "partner": [
      3
    ]
  },
  {
    "plz": "51545",
    "partner": [
      3
    ]
  },
  {
    "plz": "51570",
    "partner": [
      3
    ]
  },
  {
    "plz": "51580",
    "partner": [
      3
    ]
  },
  {
    "plz": "51588",
    "partner": [
      3
    ]
  },
  {
    "plz": "51597",
    "partner": [
      3
    ]
  },
  {
    "plz": "51598",
    "partner": [
      3
    ]
  },
  {
    "plz": "51643",
    "partner": [
      3
    ]
  },
  {
    "plz": "51645",
    "partner": [
      3
    ]
  },
  {
    "plz": "51647",
    "partner": [
      3
    ]
  },
  {
    "plz": "51674",
    "partner": [
      3
    ]
  },
  {
    "plz": "51688",
    "partner": [
      3
    ]
  },
  {
    "plz": "51702",
    "partner": [
      3
    ]
  },
  {
    "plz": "51709",
    "partner": [
      3
    ]
  },
  {
    "plz": "51766",
    "partner": [
      3
    ]
  },
  {
    "plz": "51789",
    "partner": [
      3
    ]
  },
  {
    "plz": "52062",
    "partner": [
      2
    ]
  },
  {
    "plz": "52064",
    "partner": [
      2
    ]
  },
  {
    "plz": "52066",
    "partner": [
      2
    ]
  },
  {
    "plz": "52068",
    "partner": [
      2
    ]
  },
  {
    "plz": "52070",
    "partner": [
      2
    ]
  },
  {
    "plz": "52072",
    "partner": [
      2
    ]
  },
  {
    "plz": "52074",
    "partner": [
      2
    ]
  },
  {
    "plz": "52076",
    "partner": [
      2
    ]
  },
  {
    "plz": "52078",
    "partner": [
      2
    ]
  },
  {
    "plz": "52080",
    "partner": [
      2
    ]
  },
  {
    "plz": "52134",
    "partner": [
      2
    ]
  },
  {
    "plz": "52146",
    "partner": [
      2
    ]
  },
  {
    "plz": "52152",
    "partner": [
      2
    ]
  },
  {
    "plz": "52156",
    "partner": [
      2
    ]
  },
  {
    "plz": "52159",
    "partner": [
      2
    ]
  },
  {
    "plz": "52222",
    "partner": [
      2
    ]
  },
  {
    "plz": "52223",
    "partner": [
      2
    ]
  },
  {
    "plz": "52224",
    "partner": [
      2
    ]
  },
  {
    "plz": "52249",
    "partner": [
      2
    ]
  },
  {
    "plz": "52349",
    "partner": [
      2
    ]
  },
  {
    "plz": "52351",
    "partner": [
      2
    ]
  },
  {
    "plz": "52353",
    "partner": [
      2
    ]
  },
  {
    "plz": "52355",
    "partner": [
      2
    ]
  },
  {
    "plz": "52372",
    "partner": [
      2
    ]
  },
  {
    "plz": "52379",
    "partner": [
      2
    ]
  },
  {
    "plz": "52382",
    "partner": [
      2
    ]
  },
  {
    "plz": "52385",
    "partner": [
      2
    ]
  },
  {
    "plz": "52388",
    "partner": [
      2
    ]
  },
  {
    "plz": "52391",
    "partner": [
      2
    ]
  },
  {
    "plz": "52393",
    "partner": [
      2
    ]
  },
  {
    "plz": "52396",
    "partner": [
      2
    ]
  },
  {
    "plz": "52399",
    "partner": [
      2
    ]
  },
  {
    "plz": "52428",
    "partner": [
      2
    ]
  },
  {
    "plz": "52441",
    "partner": [
      2
    ]
  },
  {
    "plz": "52445",
    "partner": [
      2
    ]
  },
  {
    "plz": "52457",
    "partner": [
      2
    ]
  },
  {
    "plz": "52459",
    "partner": [
      2
    ]
  },
  {
    "plz": "52477",
    "partner": [
      2
    ]
  },
  {
    "plz": "52499",
    "partner": [
      2
    ]
  },
  {
    "plz": "52511",
    "partner": [
      2
    ]
  },
  {
    "plz": "52525",
    "partner": [
      2
    ]
  },
  {
    "plz": "52525",
    "partner": [
      2
    ]
  },
  {
    "plz": "52531",
    "partner": [
      2
    ]
  },
  {
    "plz": "52538",
    "partner": [
      2
    ]
  },
  {
    "plz": "52538",
    "partner": [
      2
    ]
  },
  {
    "plz": "53111",
    "partner": [
      2
    ]
  },
  {
    "plz": "53113",
    "partner": [
      2
    ]
  },
  {
    "plz": "53115",
    "partner": [
      2
    ]
  },
  {
    "plz": "53117",
    "partner": [
      2
    ]
  },
  {
    "plz": "53119",
    "partner": [
      2
    ]
  },
  {
    "plz": "53121",
    "partner": [
      2
    ]
  },
  {
    "plz": "53123",
    "partner": [
      2
    ]
  },
  {
    "plz": "53125",
    "partner": [
      2
    ]
  },
  {
    "plz": "53127",
    "partner": [
      2
    ]
  },
  {
    "plz": "53129",
    "partner": [
      2
    ]
  },
  {
    "plz": "53173",
    "partner": [
      2
    ]
  },
  {
    "plz": "53175",
    "partner": [
      2
    ]
  },
  {
    "plz": "53177",
    "partner": [
      2
    ]
  },
  {
    "plz": "53179",
    "partner": [
      2
    ]
  },
  {
    "plz": "53225",
    "partner": [
      2
    ]
  },
  {
    "plz": "53227",
    "partner": [
      2
    ]
  },
  {
    "plz": "53229",
    "partner": [
      2
    ]
  },
  {
    "plz": "53332",
    "partner": [
      2
    ]
  },
  {
    "plz": "53340",
    "partner": [
      2
    ]
  },
  {
    "plz": "53343",
    "partner": [
      2
    ]
  },
  {
    "plz": "53347",
    "partner": [
      2
    ]
  },
  {
    "plz": "53359",
    "partner": [
      2
    ]
  },
  {
    "plz": "53424",
    "partner": [
      2
    ]
  },
  {
    "plz": "53426",
    "partner": [
      2
    ]
  },
  {
    "plz": "53426",
    "partner": [
      2
    ]
  },
  {
    "plz": "53426",
    "partner": [
      2
    ]
  },
  {
    "plz": "53474",
    "partner": [
      2
    ]
  },
  {
    "plz": "53489",
    "partner": [
      2
    ]
  },
  {
    "plz": "53498",
    "partner": [
      2
    ]
  },
  {
    "plz": "53498",
    "partner": [
      2
    ]
  },
  {
    "plz": "53498",
    "partner": [
      2
    ]
  },
  {
    "plz": "53501",
    "partner": [
      2
    ]
  },
  {
    "plz": "53505",
    "partner": [
      2
    ]
  },
  {
    "plz": "53505",
    "partner": [
      2
    ]
  },
  {
    "plz": "53505",
    "partner": [
      2
    ]
  },
  {
    "plz": "53505",
    "partner": [
      2
    ]
  },
  {
    "plz": "53506",
    "partner": [
      2
    ]
  },
  {
    "plz": "53506",
    "partner": [
      2
    ]
  },
  {
    "plz": "53506",
    "partner": [
      2
    ]
  },
  {
    "plz": "53506",
    "partner": [
      2
    ]
  },
  {
    "plz": "53506",
    "partner": [
      2
    ]
  },
  {
    "plz": "53506",
    "partner": [
      2
    ]
  },
  {
    "plz": "53507",
    "partner": [
      2
    ]
  },
  {
    "plz": "53508",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53518",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53520",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53533",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53534",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53539",
    "partner": [
      2
    ]
  },
  {
    "plz": "53545",
    "partner": [
      2
    ]
  },
  {
    "plz": "53545",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53547",
    "partner": [
      2
    ]
  },
  {
    "plz": "53557",
    "partner": [
      2
    ]
  },
  {
    "plz": "53560",
    "partner": [
      2
    ]
  },
  {
    "plz": "53560",
    "partner": [
      2
    ]
  },
  {
    "plz": "53562",
    "partner": [
      2
    ]
  },
  {
    "plz": "53562",
    "partner": [
      2
    ]
  },
  {
    "plz": "53567",
    "partner": [
      2
    ]
  },
  {
    "plz": "53567",
    "partner": [
      2
    ]
  },
  {
    "plz": "53572",
    "partner": [
      2
    ]
  },
  {
    "plz": "53572",
    "partner": [
      2
    ]
  },
  {
    "plz": "53572",
    "partner": [
      2
    ]
  },
  {
    "plz": "53577",
    "partner": [
      2
    ]
  },
  {
    "plz": "53578",
    "partner": [
      2
    ]
  },
  {
    "plz": "53579",
    "partner": [
      2
    ]
  },
  {
    "plz": "53604",
    "partner": [
      2
    ]
  },
  {
    "plz": "53619",
    "partner": [
      2
    ]
  },
  {
    "plz": "53639",
    "partner": [
      2
    ]
  },
  {
    "plz": "53721",
    "partner": [
      2
    ]
  },
  {
    "plz": "53757",
    "partner": [
      2
    ]
  },
  {
    "plz": "53773",
    "partner": [
      2
    ]
  },
  {
    "plz": "53783",
    "partner": [
      2
    ]
  },
  {
    "plz": "53797",
    "partner": [
      2
    ]
  },
  {
    "plz": "53804",
    "partner": [
      2
    ]
  },
  {
    "plz": "53809",
    "partner": [
      2
    ]
  },
  {
    "plz": "53819",
    "partner": [
      2
    ]
  },
  {
    "plz": "53840",
    "partner": [
      2
    ]
  },
  {
    "plz": "53842",
    "partner": [
      2
    ]
  },
  {
    "plz": "53844",
    "partner": [
      2
    ]
  },
  {
    "plz": "53859",
    "partner": [
      2
    ]
  },
  {
    "plz": "53879",
    "partner": [
      2
    ]
  },
  {
    "plz": "53881",
    "partner": [
      2
    ]
  },
  {
    "plz": "53894",
    "partner": [
      2
    ]
  },
  {
    "plz": "53902",
    "partner": [
      2
    ]
  },
  {
    "plz": "53909",
    "partner": [
      2
    ]
  },
  {
    "plz": "53913",
    "partner": [
      2
    ]
  },
  {
    "plz": "53919",
    "partner": [
      2
    ]
  },
  {
    "plz": "53925",
    "partner": [
      2
    ]
  },
  {
    "plz": "53937",
    "partner": [
      2
    ]
  },
  {
    "plz": "53940",
    "partner": [
      2
    ]
  },
  {
    "plz": "53945",
    "partner": [
      2
    ]
  },
  {
    "plz": "53947",
    "partner": [
      2
    ]
  },
  {
    "plz": "53949",
    "partner": [
      2
    ]
  },
  {
    "plz": "54290",
    "partner": [
      2
    ]
  },
  {
    "plz": "54292",
    "partner": [
      2
    ]
  },
  {
    "plz": "54293",
    "partner": [
      2
    ]
  },
  {
    "plz": "54294",
    "partner": [
      2
    ]
  },
  {
    "plz": "54295",
    "partner": [
      2
    ]
  },
  {
    "plz": "54296",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54298",
    "partner": [
      2
    ]
  },
  {
    "plz": "54306",
    "partner": [
      2
    ]
  },
  {
    "plz": "54308",
    "partner": [
      2
    ]
  },
  {
    "plz": "54309",
    "partner": [
      2
    ]
  },
  {
    "plz": "54310",
    "partner": [
      2
    ]
  },
  {
    "plz": "54310",
    "partner": [
      2
    ]
  },
  {
    "plz": "54310",
    "partner": [
      2
    ]
  },
  {
    "plz": "54311",
    "partner": [
      2
    ]
  },
  {
    "plz": "54313",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54314",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54316",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54317",
    "partner": [
      2
    ]
  },
  {
    "plz": "54318",
    "partner": [
      2
    ]
  },
  {
    "plz": "54320",
    "partner": [
      2
    ]
  },
  {
    "plz": "54329",
    "partner": [
      2
    ]
  },
  {
    "plz": "54331",
    "partner": [
      2
    ]
  },
  {
    "plz": "54331",
    "partner": [
      2
    ]
  },
  {
    "plz": "54332",
    "partner": [
      2
    ]
  },
  {
    "plz": "54338",
    "partner": [
      2
    ]
  },
  {
    "plz": "54338",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54340",
    "partner": [
      2
    ]
  },
  {
    "plz": "54341",
    "partner": [
      2
    ]
  },
  {
    "plz": "54343",
    "partner": [
      2
    ]
  },
  {
    "plz": "54344",
    "partner": [
      2
    ]
  },
  {
    "plz": "54346",
    "partner": [
      2
    ]
  },
  {
    "plz": "54347",
    "partner": [
      2
    ]
  },
  {
    "plz": "54349",
    "partner": [
      2
    ]
  },
  {
    "plz": "54411",
    "partner": [
      2
    ]
  },
  {
    "plz": "54411",
    "partner": [
      2
    ]
  },
  {
    "plz": "54411",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54413",
    "partner": [
      2
    ]
  },
  {
    "plz": "54421",
    "partner": [
      2
    ]
  },
  {
    "plz": "54421",
    "partner": [
      2
    ]
  },
  {
    "plz": "54422",
    "partner": [
      2
    ]
  },
  {
    "plz": "54422",
    "partner": [
      2
    ]
  },
  {
    "plz": "54422",
    "partner": [
      2
    ]
  },
  {
    "plz": "54422",
    "partner": [
      2
    ]
  },
  {
    "plz": "54424",
    "partner": [
      2
    ]
  },
  {
    "plz": "54424",
    "partner": [
      2
    ]
  },
  {
    "plz": "54424",
    "partner": [
      2
    ]
  },
  {
    "plz": "54424",
    "partner": [
      2
    ]
  },
  {
    "plz": "54424",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54426",
    "partner": [
      2
    ]
  },
  {
    "plz": "54427",
    "partner": [
      2
    ]
  },
  {
    "plz": "54427",
    "partner": [
      2
    ]
  },
  {
    "plz": "54429",
    "partner": [
      2
    ]
  },
  {
    "plz": "54429",
    "partner": [
      2
    ]
  },
  {
    "plz": "54429",
    "partner": [
      2
    ]
  },
  {
    "plz": "54429",
    "partner": [
      2
    ]
  },
  {
    "plz": "54439",
    "partner": [
      2
    ]
  },
  {
    "plz": "54439",
    "partner": [
      2
    ]
  },
  {
    "plz": "54439",
    "partner": [
      2
    ]
  },
  {
    "plz": "54439",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54441",
    "partner": [
      2
    ]
  },
  {
    "plz": "54450",
    "partner": [
      2
    ]
  },
  {
    "plz": "54451",
    "partner": [
      2
    ]
  },
  {
    "plz": "54453",
    "partner": [
      2
    ]
  },
  {
    "plz": "54455",
    "partner": [
      2
    ]
  },
  {
    "plz": "54456",
    "partner": [
      2
    ]
  },
  {
    "plz": "54456",
    "partner": [
      2
    ]
  },
  {
    "plz": "54457",
    "partner": [
      2
    ]
  },
  {
    "plz": "54459",
    "partner": [
      2
    ]
  },
  {
    "plz": "54470",
    "partner": [
      2
    ]
  },
  {
    "plz": "54470",
    "partner": [
      2
    ]
  },
  {
    "plz": "54470",
    "partner": [
      2
    ]
  },
  {
    "plz": "54470",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54472",
    "partner": [
      2
    ]
  },
  {
    "plz": "54483",
    "partner": [
      2
    ]
  },
  {
    "plz": "54484",
    "partner": [
      2
    ]
  },
  {
    "plz": "54486",
    "partner": [
      2
    ]
  },
  {
    "plz": "54487",
    "partner": [
      2
    ]
  },
  {
    "plz": "54492",
    "partner": [
      2
    ]
  },
  {
    "plz": "54492",
    "partner": [
      2
    ]
  },
  {
    "plz": "54492",
    "partner": [
      2
    ]
  },
  {
    "plz": "54492",
    "partner": [
      2
    ]
  },
  {
    "plz": "54492",
    "partner": [
      2
    ]
  },
  {
    "plz": "54492",
    "partner": [
      2
    ]
  },
  {
    "plz": "54497",
    "partner": [
      2
    ]
  },
  {
    "plz": "54497",
    "partner": [
      2
    ]
  },
  {
    "plz": "54498",
    "partner": [
      2
    ]
  },
  {
    "plz": "54516",
    "partner": [
      2
    ]
  },
  {
    "plz": "54516",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54518",
    "partner": [
      2
    ]
  },
  {
    "plz": "54523",
    "partner": [
      2
    ]
  },
  {
    "plz": "54523",
    "partner": [
      2
    ]
  },
  {
    "plz": "54523",
    "partner": [
      2
    ]
  },
  {
    "plz": "54524",
    "partner": [
      2
    ]
  },
  {
    "plz": "54526",
    "partner": [
      2
    ]
  },
  {
    "plz": "54528",
    "partner": [
      2
    ]
  },
  {
    "plz": "54529",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54531",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54533",
    "partner": [
      2
    ]
  },
  {
    "plz": "54534",
    "partner": [
      2
    ]
  },
  {
    "plz": "54534",
    "partner": [
      2
    ]
  },
  {
    "plz": "54534",
    "partner": [
      2
    ]
  },
  {
    "plz": "54534",
    "partner": [
      2
    ]
  },
  {
    "plz": "54536",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54538",
    "partner": [
      2
    ]
  },
  {
    "plz": "54539",
    "partner": [
      2
    ]
  },
  {
    "plz": "54539",
    "partner": [
      2
    ]
  },
  {
    "plz": "54550",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54552",
    "partner": [
      2
    ]
  },
  {
    "plz": "54558",
    "partner": [
      2
    ]
  },
  {
    "plz": "54558",
    "partner": [
      2
    ]
  },
  {
    "plz": "54558",
    "partner": [
      2
    ]
  },
  {
    "plz": "54558",
    "partner": [
      2
    ]
  },
  {
    "plz": "54558",
    "partner": [
      2
    ]
  },
  {
    "plz": "54558",
    "partner": [
      2
    ]
  },
  {
    "plz": "54568",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54570",
    "partner": [
      2
    ]
  },
  {
    "plz": "54574",
    "partner": [
      2
    ]
  },
  {
    "plz": "54574",
    "partner": [
      2
    ]
  },
  {
    "plz": "54574",
    "partner": [
      2
    ]
  },
  {
    "plz": "54576",
    "partner": [
      2
    ]
  },
  {
    "plz": "54576",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54578",
    "partner": [
      2
    ]
  },
  {
    "plz": "54579",
    "partner": [
      2
    ]
  },
  {
    "plz": "54584",
    "partner": [
      2
    ]
  },
  {
    "plz": "54584",
    "partner": [
      2
    ]
  },
  {
    "plz": "54584",
    "partner": [
      2
    ]
  },
  {
    "plz": "54585",
    "partner": [
      2
    ]
  },
  {
    "plz": "54586",
    "partner": [
      2
    ]
  },
  {
    "plz": "54587",
    "partner": [
      2
    ]
  },
  {
    "plz": "54587",
    "partner": [
      2
    ]
  },
  {
    "plz": "54589",
    "partner": [
      2
    ]
  },
  {
    "plz": "54589",
    "partner": [
      2
    ]
  },
  {
    "plz": "54595",
    "partner": [
      2
    ]
  },
  {
    "plz": "54595",
    "partner": [
      2
    ]
  },
  {
    "plz": "54595",
    "partner": [
      2
    ]
  },
  {
    "plz": "54595",
    "partner": [
      2
    ]
  },
  {
    "plz": "54595",
    "partner": [
      2
    ]
  },
  {
    "plz": "54595",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54597",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54608",
    "partner": [
      2
    ]
  },
  {
    "plz": "54610",
    "partner": [
      2
    ]
  },
  {
    "plz": "54611",
    "partner": [
      2
    ]
  },
  {
    "plz": "54611",
    "partner": [
      2
    ]
  },
  {
    "plz": "54612",
    "partner": [
      2
    ]
  },
  {
    "plz": "54612",
    "partner": [
      2
    ]
  },
  {
    "plz": "54612",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54614",
    "partner": [
      2
    ]
  },
  {
    "plz": "54616",
    "partner": [
      2
    ]
  },
  {
    "plz": "54617",
    "partner": [
      2
    ]
  },
  {
    "plz": "54617",
    "partner": [
      2
    ]
  },
  {
    "plz": "54617",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54619",
    "partner": [
      2
    ]
  },
  {
    "plz": "54634",
    "partner": [
      2
    ]
  },
  {
    "plz": "54634",
    "partner": [
      2
    ]
  },
  {
    "plz": "54634",
    "partner": [
      2
    ]
  },
  {
    "plz": "54634",
    "partner": [
      2
    ]
  },
  {
    "plz": "54634",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54636",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54646",
    "partner": [
      2
    ]
  },
  {
    "plz": "54647",
    "partner": [
      2
    ]
  },
  {
    "plz": "54647",
    "partner": [
      2
    ]
  },
  {
    "plz": "54647",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54649",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54655",
    "partner": [
      2
    ]
  },
  {
    "plz": "54657",
    "partner": [
      2
    ]
  },
  {
    "plz": "54657",
    "partner": [
      2
    ]
  },
  {
    "plz": "54657",
    "partner": [
      2
    ]
  },
  {
    "plz": "54662",
    "partner": [
      2
    ]
  },
  {
    "plz": "54662",
    "partner": [
      2
    ]
  },
  {
    "plz": "54662",
    "partner": [
      2
    ]
  },
  {
    "plz": "54662",
    "partner": [
      2
    ]
  },
  {
    "plz": "54662",
    "partner": [
      2
    ]
  },
  {
    "plz": "54664",
    "partner": [
      2
    ]
  },
  {
    "plz": "54664",
    "partner": [
      2
    ]
  },
  {
    "plz": "54664",
    "partner": [
      2
    ]
  },
  {
    "plz": "54666",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54668",
    "partner": [
      2
    ]
  },
  {
    "plz": "54669",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54673",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54675",
    "partner": [
      2
    ]
  },
  {
    "plz": "54687",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "54689",
    "partner": [
      2
    ]
  },
  {
    "plz": "55116",
    "partner": [
      8
    ]
  },
  {
    "plz": "55118",
    "partner": [
      8
    ]
  },
  {
    "plz": "55120",
    "partner": [
      8
    ]
  },
  {
    "plz": "55122",
    "partner": [
      8
    ]
  },
  {
    "plz": "55124",
    "partner": [
      8
    ]
  },
  {
    "plz": "55126",
    "partner": [
      8
    ]
  },
  {
    "plz": "55127",
    "partner": [
      8
    ]
  },
  {
    "plz": "55128",
    "partner": [
      8
    ]
  },
  {
    "plz": "55129",
    "partner": [
      8
    ]
  },
  {
    "plz": "55130",
    "partner": [
      8
    ]
  },
  {
    "plz": "55131",
    "partner": [
      8
    ]
  },
  {
    "plz": "55218",
    "partner": [
      8
    ]
  },
  {
    "plz": "55232",
    "partner": [
      8
    ]
  },
  {
    "plz": "55232",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55234",
    "partner": [
      8
    ]
  },
  {
    "plz": "55237",
    "partner": [
      8
    ]
  },
  {
    "plz": "55237",
    "partner": [
      8
    ]
  },
  {
    "plz": "55237",
    "partner": [
      8
    ]
  },
  {
    "plz": "55239",
    "partner": [
      8
    ]
  },
  {
    "plz": "55246",
    "partner": [
      8
    ]
  },
  {
    "plz": "55252",
    "partner": [
      8
    ]
  },
  {
    "plz": "55257",
    "partner": [
      8
    ]
  },
  {
    "plz": "55262",
    "partner": [
      8
    ]
  },
  {
    "plz": "55263",
    "partner": [
      8
    ]
  },
  {
    "plz": "55268",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55270",
    "partner": [
      8
    ]
  },
  {
    "plz": "55271",
    "partner": [
      8
    ]
  },
  {
    "plz": "55276",
    "partner": [
      8
    ]
  },
  {
    "plz": "55276",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55278",
    "partner": [
      8
    ]
  },
  {
    "plz": "55283",
    "partner": [
      8
    ]
  },
  {
    "plz": "55286",
    "partner": [
      8
    ]
  },
  {
    "plz": "55286",
    "partner": [
      8
    ]
  },
  {
    "plz": "55288",
    "partner": [
      8
    ]
  },
  {
    "plz": "55288",
    "partner": [
      8
    ]
  },
  {
    "plz": "55288",
    "partner": [
      8
    ]
  },
  {
    "plz": "55288",
    "partner": [
      8
    ]
  },
  {
    "plz": "55288",
    "partner": [
      8
    ]
  },
  {
    "plz": "55288",
    "partner": [
      8
    ]
  },
  {
    "plz": "55291",
    "partner": [
      8
    ]
  },
  {
    "plz": "55294",
    "partner": [
      8
    ]
  },
  {
    "plz": "55296",
    "partner": [
      8
    ]
  },
  {
    "plz": "55296",
    "partner": [
      8
    ]
  },
  {
    "plz": "55296",
    "partner": [
      8
    ]
  },
  {
    "plz": "55299",
    "partner": [
      8
    ]
  },
  {
    "plz": "55411",
    "partner": [
      8
    ]
  },
  {
    "plz": "55413",
    "partner": [
      8
    ]
  },
  {
    "plz": "55413",
    "partner": [
      8
    ]
  },
  {
    "plz": "55413",
    "partner": [
      8
    ]
  },
  {
    "plz": "55413",
    "partner": [
      8
    ]
  },
  {
    "plz": "55413",
    "partner": [
      8
    ]
  },
  {
    "plz": "55413",
    "partner": [
      8
    ]
  },
  {
    "plz": "55422",
    "partner": [
      8
    ]
  },
  {
    "plz": "55422",
    "partner": [
      8
    ]
  },
  {
    "plz": "55424",
    "partner": [
      8
    ]
  },
  {
    "plz": "55425",
    "partner": [
      8
    ]
  },
  {
    "plz": "55430",
    "partner": [
      8
    ]
  },
  {
    "plz": "55430",
    "partner": [
      8
    ]
  },
  {
    "plz": "55430",
    "partner": [
      8
    ]
  },
  {
    "plz": "55432",
    "partner": [
      8
    ]
  },
  {
    "plz": "55432",
    "partner": [
      8
    ]
  },
  {
    "plz": "55435",
    "partner": [
      8
    ]
  },
  {
    "plz": "55437",
    "partner": [
      8
    ]
  },
  {
    "plz": "55437",
    "partner": [
      8
    ]
  },
  {
    "plz": "55437",
    "partner": [
      8
    ]
  },
  {
    "plz": "55437",
    "partner": [
      8
    ]
  },
  {
    "plz": "55442",
    "partner": [
      8
    ]
  },
  {
    "plz": "55442",
    "partner": [
      8
    ]
  },
  {
    "plz": "55442",
    "partner": [
      8
    ]
  },
  {
    "plz": "55442",
    "partner": [
      8
    ]
  },
  {
    "plz": "55444",
    "partner": [
      8
    ]
  },
  {
    "plz": "55444",
    "partner": [
      8
    ]
  },
  {
    "plz": "55444",
    "partner": [
      8
    ]
  },
  {
    "plz": "55444",
    "partner": [
      8
    ]
  },
  {
    "plz": "55444",
    "partner": [
      8
    ]
  },
  {
    "plz": "55444",
    "partner": [
      8
    ]
  },
  {
    "plz": "55450",
    "partner": [
      8
    ]
  },
  {
    "plz": "55452",
    "partner": [
      8
    ]
  },
  {
    "plz": "55452",
    "partner": [
      8
    ]
  },
  {
    "plz": "55452",
    "partner": [
      8
    ]
  },
  {
    "plz": "55452",
    "partner": [
      8
    ]
  },
  {
    "plz": "55452",
    "partner": [
      8
    ]
  },
  {
    "plz": "55452",
    "partner": [
      8
    ]
  },
  {
    "plz": "55457",
    "partner": [
      8
    ]
  },
  {
    "plz": "55457",
    "partner": [
      8
    ]
  },
  {
    "plz": "55459",
    "partner": [
      8
    ]
  },
  {
    "plz": "55459",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55469",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55471",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55481",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55483",
    "partner": [
      8
    ]
  },
  {
    "plz": "55487",
    "partner": [
      8
    ]
  },
  {
    "plz": "55487",
    "partner": [
      8
    ]
  },
  {
    "plz": "55487",
    "partner": [
      8
    ]
  },
  {
    "plz": "55487",
    "partner": [
      8
    ]
  },
  {
    "plz": "55487",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55490",
    "partner": [
      8
    ]
  },
  {
    "plz": "55491",
    "partner": [
      8
    ]
  },
  {
    "plz": "55491",
    "partner": [
      8
    ]
  },
  {
    "plz": "55491",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55494",
    "partner": [
      8
    ]
  },
  {
    "plz": "55496",
    "partner": [
      8
    ]
  },
  {
    "plz": "55497",
    "partner": [
      8
    ]
  },
  {
    "plz": "55497",
    "partner": [
      8
    ]
  },
  {
    "plz": "55499",
    "partner": [
      8
    ]
  },
  {
    "plz": "55543",
    "partner": [
      8
    ]
  },
  {
    "plz": "55543",
    "partner": [
      8
    ]
  },
  {
    "plz": "55545",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55546",
    "partner": [
      8
    ]
  },
  {
    "plz": "55559",
    "partner": [
      8
    ]
  },
  {
    "plz": "55566",
    "partner": [
      8
    ]
  },
  {
    "plz": "55566",
    "partner": [
      8
    ]
  },
  {
    "plz": "55566",
    "partner": [
      8
    ]
  },
  {
    "plz": "55566",
    "partner": [
      8
    ]
  },
  {
    "plz": "55566",
    "partner": [
      8
    ]
  },
  {
    "plz": "55566",
    "partner": [
      8
    ]
  },
  {
    "plz": "55568",
    "partner": [
      8
    ]
  },
  {
    "plz": "55568",
    "partner": [
      8
    ]
  },
  {
    "plz": "55568",
    "partner": [
      8
    ]
  },
  {
    "plz": "55569",
    "partner": [
      8
    ]
  },
  {
    "plz": "55569",
    "partner": [
      8
    ]
  },
  {
    "plz": "55569",
    "partner": [
      8
    ]
  },
  {
    "plz": "55569",
    "partner": [
      8
    ]
  },
  {
    "plz": "55571",
    "partner": [
      8
    ]
  },
  {
    "plz": "55576",
    "partner": [
      8
    ]
  },
  {
    "plz": "55576",
    "partner": [
      8
    ]
  },
  {
    "plz": "55576",
    "partner": [
      8
    ]
  },
  {
    "plz": "55576",
    "partner": [
      8
    ]
  },
  {
    "plz": "55576",
    "partner": [
      8
    ]
  },
  {
    "plz": "55578",
    "partner": [
      8
    ]
  },
  {
    "plz": "55578",
    "partner": [
      8
    ]
  },
  {
    "plz": "55578",
    "partner": [
      8
    ]
  },
  {
    "plz": "55578",
    "partner": [
      8
    ]
  },
  {
    "plz": "55578",
    "partner": [
      8
    ]
  },
  {
    "plz": "55583",
    "partner": [
      8
    ]
  },
  {
    "plz": "55585",
    "partner": [
      8
    ]
  },
  {
    "plz": "55585",
    "partner": [
      8
    ]
  },
  {
    "plz": "55585",
    "partner": [
      8
    ]
  },
  {
    "plz": "55585",
    "partner": [
      8
    ]
  },
  {
    "plz": "55585",
    "partner": [
      8
    ]
  },
  {
    "plz": "55585",
    "partner": [
      8
    ]
  },
  {
    "plz": "55590",
    "partner": [
      8
    ]
  },
  {
    "plz": "55592",
    "partner": [
      8
    ]
  },
  {
    "plz": "55592",
    "partner": [
      8
    ]
  },
  {
    "plz": "55592",
    "partner": [
      8
    ]
  },
  {
    "plz": "55592",
    "partner": [
      8
    ]
  },
  {
    "plz": "55592",
    "partner": [
      8
    ]
  },
  {
    "plz": "55593",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55595",
    "partner": [
      8
    ]
  },
  {
    "plz": "55596",
    "partner": [
      8
    ]
  },
  {
    "plz": "55596",
    "partner": [
      8
    ]
  },
  {
    "plz": "55596",
    "partner": [
      8
    ]
  },
  {
    "plz": "55597",
    "partner": [
      8
    ]
  },
  {
    "plz": "55597",
    "partner": [
      8
    ]
  },
  {
    "plz": "55599",
    "partner": [
      8
    ]
  },
  {
    "plz": "55599",
    "partner": [
      8
    ]
  },
  {
    "plz": "55599",
    "partner": [
      8
    ]
  },
  {
    "plz": "55599",
    "partner": [
      8
    ]
  },
  {
    "plz": "55599",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55606",
    "partner": [
      8
    ]
  },
  {
    "plz": "55608",
    "partner": [
      8
    ]
  },
  {
    "plz": "55608",
    "partner": [
      8
    ]
  },
  {
    "plz": "55608",
    "partner": [
      8
    ]
  },
  {
    "plz": "55608",
    "partner": [
      8
    ]
  },
  {
    "plz": "55608",
    "partner": [
      8
    ]
  },
  {
    "plz": "55608",
    "partner": [
      8
    ]
  },
  {
    "plz": "55618",
    "partner": [
      8
    ]
  },
  {
    "plz": "55619",
    "partner": [
      8
    ]
  },
  {
    "plz": "55621",
    "partner": [
      8
    ]
  },
  {
    "plz": "55624",
    "partner": [
      8
    ]
  },
  {
    "plz": "55624",
    "partner": [
      8
    ]
  },
  {
    "plz": "55624",
    "partner": [
      8
    ]
  },
  {
    "plz": "55624",
    "partner": [
      8
    ]
  },
  {
    "plz": "55624",
    "partner": [
      8
    ]
  },
  {
    "plz": "55624",
    "partner": [
      8
    ]
  },
  {
    "plz": "55626",
    "partner": [
      8
    ]
  },
  {
    "plz": "55627",
    "partner": [
      8
    ]
  },
  {
    "plz": "55627",
    "partner": [
      8
    ]
  },
  {
    "plz": "55627",
    "partner": [
      8
    ]
  },
  {
    "plz": "55629",
    "partner": [
      8
    ]
  },
  {
    "plz": "55629",
    "partner": [
      8
    ]
  },
  {
    "plz": "55629",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55743",
    "partner": [
      8
    ]
  },
  {
    "plz": "55756",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55758",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55765",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55767",
    "partner": [
      8
    ]
  },
  {
    "plz": "55768",
    "partner": [
      8
    ]
  },
  {
    "plz": "55774",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55776",
    "partner": [
      8
    ]
  },
  {
    "plz": "55777",
    "partner": [
      8
    ]
  },
  {
    "plz": "55777",
    "partner": [
      8
    ]
  },
  {
    "plz": "55777",
    "partner": [
      8
    ]
  },
  {
    "plz": "55777",
    "partner": [
      8
    ]
  },
  {
    "plz": "55779",
    "partner": [
      8
    ]
  },
  {
    "plz": "55779",
    "partner": [
      8
    ]
  },
  {
    "plz": "56068",
    "partner": [
      3
    ]
  },
  {
    "plz": "56070",
    "partner": [
      3
    ]
  },
  {
    "plz": "56072",
    "partner": [
      3
    ]
  },
  {
    "plz": "56073",
    "partner": [
      3
    ]
  },
  {
    "plz": "56075",
    "partner": [
      3
    ]
  },
  {
    "plz": "56076",
    "partner": [
      3
    ]
  },
  {
    "plz": "56077",
    "partner": [
      3
    ]
  },
  {
    "plz": "56112",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56130",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56132",
    "partner": [
      3
    ]
  },
  {
    "plz": "56133",
    "partner": [
      3
    ]
  },
  {
    "plz": "56154",
    "partner": [
      3
    ]
  },
  {
    "plz": "56170",
    "partner": [
      3
    ]
  },
  {
    "plz": "56170",
    "partner": [
      3
    ]
  },
  {
    "plz": "56170",
    "partner": [
      3
    ]
  },
  {
    "plz": "56179",
    "partner": [
      3
    ]
  },
  {
    "plz": "56179",
    "partner": [
      3
    ]
  },
  {
    "plz": "56182",
    "partner": [
      3
    ]
  },
  {
    "plz": "56191",
    "partner": [
      3
    ]
  },
  {
    "plz": "56203",
    "partner": [
      3
    ]
  },
  {
    "plz": "56203",
    "partner": [
      3
    ]
  },
  {
    "plz": "56203",
    "partner": [
      3
    ]
  },
  {
    "plz": "56204",
    "partner": [
      3
    ]
  },
  {
    "plz": "56206",
    "partner": [
      3
    ]
  },
  {
    "plz": "56206",
    "partner": [
      3
    ]
  },
  {
    "plz": "56218",
    "partner": [
      3
    ]
  },
  {
    "plz": "56220",
    "partner": [
      3
    ]
  },
  {
    "plz": "56220",
    "partner": [
      3
    ]
  },
  {
    "plz": "56220",
    "partner": [
      3
    ]
  },
  {
    "plz": "56220",
    "partner": [
      3
    ]
  },
  {
    "plz": "56220",
    "partner": [
      3
    ]
  },
  {
    "plz": "56235",
    "partner": [
      3
    ]
  },
  {
    "plz": "56235",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56237",
    "partner": [
      3
    ]
  },
  {
    "plz": "56242",
    "partner": [
      3
    ]
  },
  {
    "plz": "56242",
    "partner": [
      3
    ]
  },
  {
    "plz": "56242",
    "partner": [
      3
    ]
  },
  {
    "plz": "56242",
    "partner": [
      3
    ]
  },
  {
    "plz": "56242",
    "partner": [
      3
    ]
  },
  {
    "plz": "56242",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56244",
    "partner": [
      3
    ]
  },
  {
    "plz": "56249",
    "partner": [
      3
    ]
  },
  {
    "plz": "56253",
    "partner": [
      3
    ]
  },
  {
    "plz": "56254",
    "partner": [
      3
    ]
  },
  {
    "plz": "56254",
    "partner": [
      3
    ]
  },
  {
    "plz": "56269",
    "partner": [
      3
    ]
  },
  {
    "plz": "56269",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56271",
    "partner": [
      3
    ]
  },
  {
    "plz": "56276",
    "partner": [
      3
    ]
  },
  {
    "plz": "56276",
    "partner": [
      3
    ]
  },
  {
    "plz": "56281",
    "partner": [
      3
    ]
  },
  {
    "plz": "56281",
    "partner": [
      3
    ]
  },
  {
    "plz": "56281",
    "partner": [
      3
    ]
  },
  {
    "plz": "56281",
    "partner": [
      3
    ]
  },
  {
    "plz": "56281",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56283",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56288",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56290",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56291",
    "partner": [
      3
    ]
  },
  {
    "plz": "56294",
    "partner": [
      3
    ]
  },
  {
    "plz": "56294",
    "partner": [
      3
    ]
  },
  {
    "plz": "56294",
    "partner": [
      3
    ]
  },
  {
    "plz": "56294",
    "partner": [
      3
    ]
  },
  {
    "plz": "56294",
    "partner": [
      3
    ]
  },
  {
    "plz": "56295",
    "partner": [
      3
    ]
  },
  {
    "plz": "56295",
    "partner": [
      3
    ]
  },
  {
    "plz": "56295",
    "partner": [
      3
    ]
  },
  {
    "plz": "56299",
    "partner": [
      3
    ]
  },
  {
    "plz": "56305",
    "partner": [
      3
    ]
  },
  {
    "plz": "56305",
    "partner": [
      3
    ]
  },
  {
    "plz": "56305",
    "partner": [
      3
    ]
  },
  {
    "plz": "56307",
    "partner": [
      3
    ]
  },
  {
    "plz": "56307",
    "partner": [
      3
    ]
  },
  {
    "plz": "56307",
    "partner": [
      3
    ]
  },
  {
    "plz": "56316",
    "partner": [
      3
    ]
  },
  {
    "plz": "56316",
    "partner": [
      3
    ]
  },
  {
    "plz": "56316",
    "partner": [
      3
    ]
  },
  {
    "plz": "56317",
    "partner": [
      3
    ]
  },
  {
    "plz": "56317",
    "partner": [
      3
    ]
  },
  {
    "plz": "56321",
    "partner": [
      3
    ]
  },
  {
    "plz": "56321",
    "partner": [
      3
    ]
  },
  {
    "plz": "56322",
    "partner": [
      3
    ]
  },
  {
    "plz": "56323",
    "partner": [
      3
    ]
  },
  {
    "plz": "56323",
    "partner": [
      3
    ]
  },
  {
    "plz": "56329",
    "partner": [
      3
    ]
  },
  {
    "plz": "56330",
    "partner": [
      3
    ]
  },
  {
    "plz": "56330",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56332",
    "partner": [
      3
    ]
  },
  {
    "plz": "56333",
    "partner": [
      3
    ]
  },
  {
    "plz": "56335",
    "partner": [
      3
    ]
  },
  {
    "plz": "56337",
    "partner": [
      3
    ]
  },
  {
    "plz": "56337",
    "partner": [
      3
    ]
  },
  {
    "plz": "56337",
    "partner": [
      3
    ]
  },
  {
    "plz": "56337",
    "partner": [
      3
    ]
  },
  {
    "plz": "56338",
    "partner": [
      3
    ]
  },
  {
    "plz": "56340",
    "partner": [
      3
    ]
  },
  {
    "plz": "56340",
    "partner": [
      3
    ]
  },
  {
    "plz": "56340",
    "partner": [
      3
    ]
  },
  {
    "plz": "56340",
    "partner": [
      3
    ]
  },
  {
    "plz": "56340",
    "partner": [
      3
    ]
  },
  {
    "plz": "56341",
    "partner": [
      3
    ]
  },
  {
    "plz": "56341",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56346",
    "partner": [
      3
    ]
  },
  {
    "plz": "56348",
    "partner": [
      3
    ]
  },
  {
    "plz": "56348",
    "partner": [
      3
    ]
  },
  {
    "plz": "56348",
    "partner": [
      3
    ]
  },
  {
    "plz": "56348",
    "partner": [
      3
    ]
  },
  {
    "plz": "56348",
    "partner": [
      3
    ]
  },
  {
    "plz": "56348",
    "partner": [
      3
    ]
  },
  {
    "plz": "56349",
    "partner": [
      3
    ]
  },
  {
    "plz": "56349",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56355",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56357",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56368",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56370",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56377",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56379",
    "partner": [
      3
    ]
  },
  {
    "plz": "56410",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56412",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56414",
    "partner": [
      3
    ]
  },
  {
    "plz": "56422",
    "partner": [
      3
    ]
  },
  {
    "plz": "56424",
    "partner": [
      3
    ]
  },
  {
    "plz": "56424",
    "partner": [
      3
    ]
  },
  {
    "plz": "56424",
    "partner": [
      3
    ]
  },
  {
    "plz": "56424",
    "partner": [
      3
    ]
  },
  {
    "plz": "56424",
    "partner": [
      3
    ]
  },
  {
    "plz": "56427",
    "partner": [
      3
    ]
  },
  {
    "plz": "56428",
    "partner": [
      3
    ]
  },
  {
    "plz": "56457",
    "partner": [
      3
    ]
  },
  {
    "plz": "56457",
    "partner": [
      3
    ]
  },
  {
    "plz": "56457",
    "partner": [
      3
    ]
  },
  {
    "plz": "56457",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56459",
    "partner": [
      3
    ]
  },
  {
    "plz": "56462",
    "partner": [
      3
    ]
  },
  {
    "plz": "56462",
    "partner": [
      3
    ]
  },
  {
    "plz": "56470",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56472",
    "partner": [
      3
    ]
  },
  {
    "plz": "56477",
    "partner": [
      3
    ]
  },
  {
    "plz": "56477",
    "partner": [
      3
    ]
  },
  {
    "plz": "56477",
    "partner": [
      3
    ]
  },
  {
    "plz": "56477",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56479",
    "partner": [
      3
    ]
  },
  {
    "plz": "56564",
    "partner": [
      3
    ]
  },
  {
    "plz": "56566",
    "partner": [
      3
    ]
  },
  {
    "plz": "56567",
    "partner": [
      3
    ]
  },
  {
    "plz": "56575",
    "partner": [
      3
    ]
  },
  {
    "plz": "56579",
    "partner": [
      3
    ]
  },
  {
    "plz": "56579",
    "partner": [
      3
    ]
  },
  {
    "plz": "56579",
    "partner": [
      3
    ]
  },
  {
    "plz": "56581",
    "partner": [
      3
    ]
  },
  {
    "plz": "56581",
    "partner": [
      3
    ]
  },
  {
    "plz": "56581",
    "partner": [
      3
    ]
  },
  {
    "plz": "56581",
    "partner": [
      3
    ]
  },
  {
    "plz": "56584",
    "partner": [
      3
    ]
  },
  {
    "plz": "56584",
    "partner": [
      3
    ]
  },
  {
    "plz": "56584",
    "partner": [
      3
    ]
  },
  {
    "plz": "56584",
    "partner": [
      3
    ]
  },
  {
    "plz": "56587",
    "partner": [
      3
    ]
  },
  {
    "plz": "56587",
    "partner": [
      3
    ]
  },
  {
    "plz": "56587",
    "partner": [
      3
    ]
  },
  {
    "plz": "56588",
    "partner": [
      3
    ]
  },
  {
    "plz": "56588",
    "partner": [
      3
    ]
  },
  {
    "plz": "56588",
    "partner": [
      3
    ]
  },
  {
    "plz": "56588",
    "partner": [
      3
    ]
  },
  {
    "plz": "56589",
    "partner": [
      3
    ]
  },
  {
    "plz": "56589",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56593",
    "partner": [
      3
    ]
  },
  {
    "plz": "56594",
    "partner": [
      3
    ]
  },
  {
    "plz": "56598",
    "partner": [
      3
    ]
  },
  {
    "plz": "56598",
    "partner": [
      3
    ]
  },
  {
    "plz": "56599",
    "partner": [
      3
    ]
  },
  {
    "plz": "56626",
    "partner": [
      3
    ]
  },
  {
    "plz": "56630",
    "partner": [
      3
    ]
  },
  {
    "plz": "56637",
    "partner": [
      3
    ]
  },
  {
    "plz": "56642",
    "partner": [
      3
    ]
  },
  {
    "plz": "56645",
    "partner": [
      3
    ]
  },
  {
    "plz": "56648",
    "partner": [
      3
    ]
  },
  {
    "plz": "56651",
    "partner": [
      3
    ]
  },
  {
    "plz": "56651",
    "partner": [
      3
    ]
  },
  {
    "plz": "56651",
    "partner": [
      3
    ]
  },
  {
    "plz": "56651",
    "partner": [
      3
    ]
  },
  {
    "plz": "56651",
    "partner": [
      3
    ]
  },
  {
    "plz": "56651",
    "partner": [
      3
    ]
  },
  {
    "plz": "56653",
    "partner": [
      3
    ]
  },
  {
    "plz": "56653",
    "partner": [
      3
    ]
  },
  {
    "plz": "56653",
    "partner": [
      3
    ]
  },
  {
    "plz": "56653",
    "partner": [
      3
    ]
  },
  {
    "plz": "56656",
    "partner": [
      3
    ]
  },
  {
    "plz": "56659",
    "partner": [
      3
    ]
  },
  {
    "plz": "56727",
    "partner": [
      3
    ]
  },
  {
    "plz": "56727",
    "partner": [
      3
    ]
  },
  {
    "plz": "56727",
    "partner": [
      3
    ]
  },
  {
    "plz": "56727",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56729",
    "partner": [
      3
    ]
  },
  {
    "plz": "56736",
    "partner": [
      3
    ]
  },
  {
    "plz": "56743",
    "partner": [
      3
    ]
  },
  {
    "plz": "56743",
    "partner": [
      3
    ]
  },
  {
    "plz": "56745",
    "partner": [
      3
    ]
  },
  {
    "plz": "56745",
    "partner": [
      3
    ]
  },
  {
    "plz": "56745",
    "partner": [
      3
    ]
  },
  {
    "plz": "56745",
    "partner": [
      3
    ]
  },
  {
    "plz": "56745",
    "partner": [
      3
    ]
  },
  {
    "plz": "56746",
    "partner": [
      3
    ]
  },
  {
    "plz": "56746",
    "partner": [
      3
    ]
  },
  {
    "plz": "56746",
    "partner": [
      3
    ]
  },
  {
    "plz": "56746",
    "partner": [
      3
    ]
  },
  {
    "plz": "56751",
    "partner": [
      3
    ]
  },
  {
    "plz": "56751",
    "partner": [
      3
    ]
  },
  {
    "plz": "56751",
    "partner": [
      3
    ]
  },
  {
    "plz": "56751",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56753",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56754",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56759",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56761",
    "partner": [
      3
    ]
  },
  {
    "plz": "56766",
    "partner": [
      3
    ]
  },
  {
    "plz": "56766",
    "partner": [
      3
    ]
  },
  {
    "plz": "56766",
    "partner": [
      3
    ]
  },
  {
    "plz": "56766",
    "partner": [
      3
    ]
  },
  {
    "plz": "56766",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56767",
    "partner": [
      3
    ]
  },
  {
    "plz": "56769",
    "partner": [
      3
    ]
  },
  {
    "plz": "56769",
    "partner": [
      3
    ]
  },
  {
    "plz": "56769",
    "partner": [
      3
    ]
  },
  {
    "plz": "56769",
    "partner": [
      3
    ]
  },
  {
    "plz": "56812",
    "partner": [
      3
    ]
  },
  {
    "plz": "56812",
    "partner": [
      3
    ]
  },
  {
    "plz": "56812",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56814",
    "partner": [
      3
    ]
  },
  {
    "plz": "56818",
    "partner": [
      3
    ]
  },
  {
    "plz": "56820",
    "partner": [
      3
    ]
  },
  {
    "plz": "56820",
    "partner": [
      3
    ]
  },
  {
    "plz": "56820",
    "partner": [
      3
    ]
  },
  {
    "plz": "56820",
    "partner": [
      3
    ]
  },
  {
    "plz": "56821",
    "partner": [
      3
    ]
  },
  {
    "plz": "56823",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56825",
    "partner": [
      3
    ]
  },
  {
    "plz": "56826",
    "partner": [
      3
    ]
  },
  {
    "plz": "56826",
    "partner": [
      3
    ]
  },
  {
    "plz": "56826",
    "partner": [
      3
    ]
  },
  {
    "plz": "56826",
    "partner": [
      3
    ]
  },
  {
    "plz": "56828",
    "partner": [
      3
    ]
  },
  {
    "plz": "56829",
    "partner": [
      3
    ]
  },
  {
    "plz": "56829",
    "partner": [
      3
    ]
  },
  {
    "plz": "56829",
    "partner": [
      3
    ]
  },
  {
    "plz": "56841",
    "partner": [
      3
    ]
  },
  {
    "plz": "56841",
    "partner": [
      3
    ]
  },
  {
    "plz": "56843",
    "partner": [
      3
    ]
  },
  {
    "plz": "56843",
    "partner": [
      3
    ]
  },
  {
    "plz": "56843",
    "partner": [
      3
    ]
  },
  {
    "plz": "56843",
    "partner": [
      3
    ]
  },
  {
    "plz": "56843",
    "partner": [
      3
    ]
  },
  {
    "plz": "56850",
    "partner": [
      3
    ]
  },
  {
    "plz": "56850",
    "partner": [
      3
    ]
  },
  {
    "plz": "56850",
    "partner": [
      3
    ]
  },
  {
    "plz": "56850",
    "partner": [
      3
    ]
  },
  {
    "plz": "56850",
    "partner": [
      3
    ]
  },
  {
    "plz": "56850",
    "partner": [
      3
    ]
  },
  {
    "plz": "56856",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56858",
    "partner": [
      3
    ]
  },
  {
    "plz": "56859",
    "partner": [
      3
    ]
  },
  {
    "plz": "56859",
    "partner": [
      3
    ]
  },
  {
    "plz": "56859",
    "partner": [
      3
    ]
  },
  {
    "plz": "56861",
    "partner": [
      3
    ]
  },
  {
    "plz": "56862",
    "partner": [
      3
    ]
  },
  {
    "plz": "56864",
    "partner": [
      3
    ]
  },
  {
    "plz": "56864",
    "partner": [
      3
    ]
  },
  {
    "plz": "56864",
    "partner": [
      3
    ]
  },
  {
    "plz": "56864",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56865",
    "partner": [
      3
    ]
  },
  {
    "plz": "56867",
    "partner": [
      3
    ]
  },
  {
    "plz": "56869",
    "partner": [
      3
    ]
  },
  {
    "plz": "57072",
    "partner": [
      3
    ]
  },
  {
    "plz": "57074",
    "partner": [
      3
    ]
  },
  {
    "plz": "57076",
    "partner": [
      3
    ]
  },
  {
    "plz": "57078",
    "partner": [
      3
    ]
  },
  {
    "plz": "57080",
    "partner": [
      3
    ]
  },
  {
    "plz": "57223",
    "partner": [
      3
    ]
  },
  {
    "plz": "57234",
    "partner": [
      3
    ]
  },
  {
    "plz": "57250",
    "partner": [
      3
    ]
  },
  {
    "plz": "57258",
    "partner": [
      3
    ]
  },
  {
    "plz": "57271",
    "partner": [
      3
    ]
  },
  {
    "plz": "57290",
    "partner": [
      3
    ]
  },
  {
    "plz": "57299",
    "partner": [
      3
    ]
  },
  {
    "plz": "57319",
    "partner": [
      3
    ]
  },
  {
    "plz": "57334",
    "partner": [
      3
    ]
  },
  {
    "plz": "57339",
    "partner": [
      3
    ]
  },
  {
    "plz": "57368",
    "partner": [
      3
    ]
  },
  {
    "plz": "57392",
    "partner": [
      3
    ]
  },
  {
    "plz": "57399",
    "partner": [
      3
    ]
  },
  {
    "plz": "57413",
    "partner": [
      3
    ]
  },
  {
    "plz": "57439",
    "partner": [
      3
    ]
  },
  {
    "plz": "57462",
    "partner": [
      3
    ]
  },
  {
    "plz": "57482",
    "partner": [
      3
    ]
  },
  {
    "plz": "57489",
    "partner": [
      3
    ]
  },
  {
    "plz": "57518",
    "partner": [
      3
    ]
  },
  {
    "plz": "57518",
    "partner": [
      3
    ]
  },
  {
    "plz": "57518",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57520",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57537",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57539",
    "partner": [
      3
    ]
  },
  {
    "plz": "57548",
    "partner": [
      3
    ]
  },
  {
    "plz": "57548",
    "partner": [
      3
    ]
  },
  {
    "plz": "57555",
    "partner": [
      3
    ]
  },
  {
    "plz": "57555",
    "partner": [
      3
    ]
  },
  {
    "plz": "57555",
    "partner": [
      3
    ]
  },
  {
    "plz": "57562",
    "partner": [
      3
    ]
  },
  {
    "plz": "57567",
    "partner": [
      3
    ]
  },
  {
    "plz": "57572",
    "partner": [
      3
    ]
  },
  {
    "plz": "57572",
    "partner": [
      3
    ]
  },
  {
    "plz": "57577",
    "partner": [
      3
    ]
  },
  {
    "plz": "57577",
    "partner": [
      3
    ]
  },
  {
    "plz": "57577",
    "partner": [
      3
    ]
  },
  {
    "plz": "57577",
    "partner": [
      3
    ]
  },
  {
    "plz": "57577",
    "partner": [
      3
    ]
  },
  {
    "plz": "57577",
    "partner": [
      3
    ]
  },
  {
    "plz": "57578",
    "partner": [
      3
    ]
  },
  {
    "plz": "57580",
    "partner": [
      3
    ]
  },
  {
    "plz": "57580",
    "partner": [
      3
    ]
  },
  {
    "plz": "57580",
    "partner": [
      3
    ]
  },
  {
    "plz": "57581",
    "partner": [
      3
    ]
  },
  {
    "plz": "57583",
    "partner": [
      3
    ]
  },
  {
    "plz": "57583",
    "partner": [
      3
    ]
  },
  {
    "plz": "57584",
    "partner": [
      3
    ]
  },
  {
    "plz": "57584",
    "partner": [
      3
    ]
  },
  {
    "plz": "57586",
    "partner": [
      3
    ]
  },
  {
    "plz": "57587",
    "partner": [
      3
    ]
  },
  {
    "plz": "57587",
    "partner": [
      3
    ]
  },
  {
    "plz": "57589",
    "partner": [
      3
    ]
  },
  {
    "plz": "57589",
    "partner": [
      3
    ]
  },
  {
    "plz": "57589",
    "partner": [
      3
    ]
  },
  {
    "plz": "57589",
    "partner": [
      3
    ]
  },
  {
    "plz": "57610",
    "partner": [
      3
    ]
  },
  {
    "plz": "57610",
    "partner": [
      3
    ]
  },
  {
    "plz": "57610",
    "partner": [
      3
    ]
  },
  {
    "plz": "57610",
    "partner": [
      3
    ]
  },
  {
    "plz": "57610",
    "partner": [
      3
    ]
  },
  {
    "plz": "57610",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57612",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57614",
    "partner": [
      3
    ]
  },
  {
    "plz": "57627",
    "partner": [
      3
    ]
  },
  {
    "plz": "57627",
    "partner": [
      3
    ]
  },
  {
    "plz": "57627",
    "partner": [
      3
    ]
  },
  {
    "plz": "57627",
    "partner": [
      3
    ]
  },
  {
    "plz": "57627",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57629",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57632",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57635",
    "partner": [
      3
    ]
  },
  {
    "plz": "57636",
    "partner": [
      3
    ]
  },
  {
    "plz": "57636",
    "partner": [
      3
    ]
  },
  {
    "plz": "57638",
    "partner": [
      3
    ]
  },
  {
    "plz": "57638",
    "partner": [
      3
    ]
  },
  {
    "plz": "57639",
    "partner": [
      3
    ]
  },
  {
    "plz": "57639",
    "partner": [
      3
    ]
  },
  {
    "plz": "57641",
    "partner": [
      3
    ]
  },
  {
    "plz": "57642",
    "partner": [
      3
    ]
  },
  {
    "plz": "57644",
    "partner": [
      3
    ]
  },
  {
    "plz": "57644",
    "partner": [
      3
    ]
  },
  {
    "plz": "57644",
    "partner": [
      3
    ]
  },
  {
    "plz": "57644",
    "partner": [
      3
    ]
  },
  {
    "plz": "57644",
    "partner": [
      3
    ]
  },
  {
    "plz": "57645",
    "partner": [
      3
    ]
  },
  {
    "plz": "57645",
    "partner": [
      3
    ]
  },
  {
    "plz": "57647",
    "partner": [
      3
    ]
  },
  {
    "plz": "57647",
    "partner": [
      3
    ]
  },
  {
    "plz": "57647",
    "partner": [
      3
    ]
  },
  {
    "plz": "57647",
    "partner": [
      3
    ]
  },
  {
    "plz": "57647",
    "partner": [
      3
    ]
  },
  {
    "plz": "57648",
    "partner": [
      3
    ]
  },
  {
    "plz": "57648",
    "partner": [
      3
    ]
  },
  {
    "plz": "58089",
    "partner": [
      3
    ]
  },
  {
    "plz": "58091",
    "partner": [
      3
    ]
  },
  {
    "plz": "58093",
    "partner": [
      3
    ]
  },
  {
    "plz": "58095",
    "partner": [
      3
    ]
  },
  {
    "plz": "58097",
    "partner": [
      3
    ]
  },
  {
    "plz": "58099",
    "partner": [
      3
    ]
  },
  {
    "plz": "58119",
    "partner": [
      3
    ]
  },
  {
    "plz": "58135",
    "partner": [
      3
    ]
  },
  {
    "plz": "58239",
    "partner": [
      3
    ]
  },
  {
    "plz": "58256",
    "partner": [
      3
    ]
  },
  {
    "plz": "58285",
    "partner": [
      3
    ]
  },
  {
    "plz": "58300",
    "partner": [
      3
    ]
  },
  {
    "plz": "58313",
    "partner": [
      3
    ]
  },
  {
    "plz": "58332",
    "partner": [
      3
    ]
  },
  {
    "plz": "58339",
    "partner": [
      3
    ]
  },
  {
    "plz": "58452",
    "partner": [
      3
    ]
  },
  {
    "plz": "58453",
    "partner": [
      3
    ]
  },
  {
    "plz": "58454",
    "partner": [
      3
    ]
  },
  {
    "plz": "58455",
    "partner": [
      3
    ]
  },
  {
    "plz": "58456",
    "partner": [
      3
    ]
  },
  {
    "plz": "58507",
    "partner": [
      3
    ]
  },
  {
    "plz": "58509",
    "partner": [
      3
    ]
  },
  {
    "plz": "58511",
    "partner": [
      3
    ]
  },
  {
    "plz": "58513",
    "partner": [
      3
    ]
  },
  {
    "plz": "58515",
    "partner": [
      3
    ]
  },
  {
    "plz": "58540",
    "partner": [
      3
    ]
  },
  {
    "plz": "58553",
    "partner": [
      3
    ]
  },
  {
    "plz": "58566",
    "partner": [
      3
    ]
  },
  {
    "plz": "58579",
    "partner": [
      3
    ]
  },
  {
    "plz": "58636",
    "partner": [
      3
    ]
  },
  {
    "plz": "58638",
    "partner": [
      3
    ]
  },
  {
    "plz": "58640",
    "partner": [
      3
    ]
  },
  {
    "plz": "58642",
    "partner": [
      3
    ]
  },
  {
    "plz": "58644",
    "partner": [
      3
    ]
  },
  {
    "plz": "58675",
    "partner": [
      3
    ]
  },
  {
    "plz": "58706",
    "partner": [
      3
    ]
  },
  {
    "plz": "58708",
    "partner": [
      3
    ]
  },
  {
    "plz": "58710",
    "partner": [
      3
    ]
  },
  {
    "plz": "58730",
    "partner": [
      3
    ]
  },
  {
    "plz": "58739",
    "partner": [
      3
    ]
  },
  {
    "plz": "58762",
    "partner": [
      3
    ]
  },
  {
    "plz": "58769",
    "partner": [
      3
    ]
  },
  {
    "plz": "58791",
    "partner": [
      3
    ]
  },
  {
    "plz": "58802",
    "partner": [
      3
    ]
  },
  {
    "plz": "58809",
    "partner": [
      3
    ]
  },
  {
    "plz": "58840",
    "partner": [
      3
    ]
  },
  {
    "plz": "58849",
    "partner": [
      3
    ]
  },
  {
    "plz": "59063",
    "partner": [
      11
    ]
  },
  {
    "plz": "59065",
    "partner": [
      11
    ]
  },
  {
    "plz": "59067",
    "partner": [
      11
    ]
  },
  {
    "plz": "59069",
    "partner": [
      11
    ]
  },
  {
    "plz": "59071",
    "partner": [
      11
    ]
  },
  {
    "plz": "59073",
    "partner": [
      11
    ]
  },
  {
    "plz": "59075",
    "partner": [
      11
    ]
  },
  {
    "plz": "59077",
    "partner": [
      11
    ]
  },
  {
    "plz": "59174",
    "partner": [
      11
    ]
  },
  {
    "plz": "59192",
    "partner": [
      11
    ]
  },
  {
    "plz": "59199",
    "partner": [
      11
    ]
  },
  {
    "plz": "59227",
    "partner": [
      11
    ]
  },
  {
    "plz": "59229",
    "partner": [
      11
    ]
  },
  {
    "plz": "59269",
    "partner": [
      11
    ]
  },
  {
    "plz": "59302",
    "partner": [
      11
    ]
  },
  {
    "plz": "59320",
    "partner": [
      11
    ]
  },
  {
    "plz": "59329",
    "partner": [
      11
    ]
  },
  {
    "plz": "59348",
    "partner": [
      11
    ]
  },
  {
    "plz": "59368",
    "partner": [
      11
    ]
  },
  {
    "plz": "59379",
    "partner": [
      11
    ]
  },
  {
    "plz": "59387",
    "partner": [
      11
    ]
  },
  {
    "plz": "59394",
    "partner": [
      11
    ]
  },
  {
    "plz": "59399",
    "partner": [
      11
    ]
  },
  {
    "plz": "59423",
    "partner": [
      11
    ]
  },
  {
    "plz": "59425",
    "partner": [
      11
    ]
  },
  {
    "plz": "59427",
    "partner": [
      11
    ]
  },
  {
    "plz": "59439",
    "partner": [
      11
    ]
  },
  {
    "plz": "59457",
    "partner": [
      11
    ]
  },
  {
    "plz": "59469",
    "partner": [
      11
    ]
  },
  {
    "plz": "59494",
    "partner": [
      11
    ]
  },
  {
    "plz": "59505",
    "partner": [
      11
    ]
  },
  {
    "plz": "59510",
    "partner": [
      11
    ]
  },
  {
    "plz": "59514",
    "partner": [
      11
    ]
  },
  {
    "plz": "59519",
    "partner": [
      11
    ]
  },
  {
    "plz": "59555",
    "partner": [
      3
    ]
  },
  {
    "plz": "59556",
    "partner": [
      3
    ]
  },
  {
    "plz": "59557",
    "partner": [
      3
    ]
  },
  {
    "plz": "59558",
    "partner": [
      3
    ]
  },
  {
    "plz": "59581",
    "partner": [
      3
    ]
  },
  {
    "plz": "59590",
    "partner": [
      3
    ]
  },
  {
    "plz": "59597",
    "partner": [
      3
    ]
  },
  {
    "plz": "59602",
    "partner": [
      3
    ]
  },
  {
    "plz": "59609",
    "partner": [
      3
    ]
  },
  {
    "plz": "59755",
    "partner": [
      3
    ]
  },
  {
    "plz": "59757",
    "partner": [
      3
    ]
  },
  {
    "plz": "59759",
    "partner": [
      3
    ]
  },
  {
    "plz": "59821",
    "partner": [
      3
    ]
  },
  {
    "plz": "59823",
    "partner": [
      3
    ]
  },
  {
    "plz": "59846",
    "partner": [
      3
    ]
  },
  {
    "plz": "59872",
    "partner": [
      3
    ]
  },
  {
    "plz": "59889",
    "partner": [
      3
    ]
  },
  {
    "plz": "59909",
    "partner": [
      3
    ]
  },
  {
    "plz": "59929",
    "partner": [
      3
    ]
  },
  {
    "plz": "59939",
    "partner": [
      3
    ]
  },
  {
    "plz": "59955",
    "partner": [
      3
    ]
  },
  {
    "plz": "59964",
    "partner": [
      3
    ]
  },
  {
    "plz": "59969",
    "partner": [
      3
    ]
  },
  {
    "plz": "59969",
    "partner": [
      3
    ]
  },
  {
    "plz": "60306",
    "partner": [
      8
    ]
  },
  {
    "plz": "60308",
    "partner": [
      8
    ]
  },
  {
    "plz": "60310",
    "partner": [
      8
    ]
  },
  {
    "plz": "60311",
    "partner": [
      8
    ]
  },
  {
    "plz": "60312",
    "partner": [
      8
    ]
  },
  {
    "plz": "60313",
    "partner": [
      8
    ]
  },
  {
    "plz": "60314",
    "partner": [
      8
    ]
  },
  {
    "plz": "60316",
    "partner": [
      8
    ]
  },
  {
    "plz": "60318",
    "partner": [
      8
    ]
  },
  {
    "plz": "60320",
    "partner": [
      8
    ]
  },
  {
    "plz": "60322",
    "partner": [
      8
    ]
  },
  {
    "plz": "60323",
    "partner": [
      8
    ]
  },
  {
    "plz": "60325",
    "partner": [
      8
    ]
  },
  {
    "plz": "60326",
    "partner": [
      8
    ]
  },
  {
    "plz": "60327",
    "partner": [
      8
    ]
  },
  {
    "plz": "60329",
    "partner": [
      8
    ]
  },
  {
    "plz": "60385",
    "partner": [
      8
    ]
  },
  {
    "plz": "60386",
    "partner": [
      8
    ]
  },
  {
    "plz": "60388",
    "partner": [
      8
    ]
  },
  {
    "plz": "60389",
    "partner": [
      8
    ]
  },
  {
    "plz": "60431",
    "partner": [
      8
    ]
  },
  {
    "plz": "60433",
    "partner": [
      8
    ]
  },
  {
    "plz": "60435",
    "partner": [
      8
    ]
  },
  {
    "plz": "60437",
    "partner": [
      8
    ]
  },
  {
    "plz": "60438",
    "partner": [
      8
    ]
  },
  {
    "plz": "60439",
    "partner": [
      8
    ]
  },
  {
    "plz": "60486",
    "partner": [
      8
    ]
  },
  {
    "plz": "60487",
    "partner": [
      8
    ]
  },
  {
    "plz": "60488",
    "partner": [
      8
    ]
  },
  {
    "plz": "60489",
    "partner": [
      8
    ]
  },
  {
    "plz": "60528",
    "partner": [
      8
    ]
  },
  {
    "plz": "60529",
    "partner": [
      8
    ]
  },
  {
    "plz": "60549",
    "partner": [
      8
    ]
  },
  {
    "plz": "60594",
    "partner": [
      8
    ]
  },
  {
    "plz": "60596",
    "partner": [
      8
    ]
  },
  {
    "plz": "60598",
    "partner": [
      8
    ]
  },
  {
    "plz": "60599",
    "partner": [
      8
    ]
  },
  {
    "plz": "61118",
    "partner": [
      8
    ]
  },
  {
    "plz": "61130",
    "partner": [
      8
    ]
  },
  {
    "plz": "61130",
    "partner": [
      8
    ]
  },
  {
    "plz": "61137",
    "partner": [
      8
    ]
  },
  {
    "plz": "61138",
    "partner": [
      8
    ]
  },
  {
    "plz": "61169",
    "partner": [
      8
    ]
  },
  {
    "plz": "61184",
    "partner": [
      8
    ]
  },
  {
    "plz": "61191",
    "partner": [
      8
    ]
  },
  {
    "plz": "61194",
    "partner": [
      8
    ]
  },
  {
    "plz": "61197",
    "partner": [
      8
    ]
  },
  {
    "plz": "61200",
    "partner": [
      8
    ]
  },
  {
    "plz": "61200",
    "partner": [
      8
    ]
  },
  {
    "plz": "61200",
    "partner": [
      8
    ]
  },
  {
    "plz": "61203",
    "partner": [
      8
    ]
  },
  {
    "plz": "61203",
    "partner": [
      8
    ]
  },
  {
    "plz": "61203",
    "partner": [
      8
    ]
  },
  {
    "plz": "61203",
    "partner": [
      8
    ]
  },
  {
    "plz": "61206",
    "partner": [
      8
    ]
  },
  {
    "plz": "61209",
    "partner": [
      8
    ]
  },
  {
    "plz": "61209",
    "partner": [
      8
    ]
  },
  {
    "plz": "61231",
    "partner": [
      8
    ]
  },
  {
    "plz": "61239",
    "partner": [
      8
    ]
  },
  {
    "plz": "61250",
    "partner": [
      8
    ]
  },
  {
    "plz": "61250",
    "partner": [
      8
    ]
  },
  {
    "plz": "61267",
    "partner": [
      8
    ]
  },
  {
    "plz": "61273",
    "partner": [
      8
    ]
  },
  {
    "plz": "61273",
    "partner": [
      8
    ]
  },
  {
    "plz": "61276",
    "partner": [
      8
    ]
  },
  {
    "plz": "61279",
    "partner": [
      8
    ]
  },
  {
    "plz": "61348",
    "partner": [
      8
    ]
  },
  {
    "plz": "61350",
    "partner": [
      8
    ]
  },
  {
    "plz": "61352",
    "partner": [
      8
    ]
  },
  {
    "plz": "61381",
    "partner": [
      8
    ]
  },
  {
    "plz": "61389",
    "partner": [
      8
    ]
  },
  {
    "plz": "61440",
    "partner": [
      8
    ]
  },
  {
    "plz": "61449",
    "partner": [
      8
    ]
  },
  {
    "plz": "61462",
    "partner": [
      8
    ]
  },
  {
    "plz": "61476",
    "partner": [
      8
    ]
  },
  {
    "plz": "61479",
    "partner": [
      8
    ]
  },
  {
    "plz": "63065",
    "partner": [
      8
    ]
  },
  {
    "plz": "63067",
    "partner": [
      8
    ]
  },
  {
    "plz": "63069",
    "partner": [
      8
    ]
  },
  {
    "plz": "63071",
    "partner": [
      8
    ]
  },
  {
    "plz": "63073",
    "partner": [
      8
    ]
  },
  {
    "plz": "63075",
    "partner": [
      8
    ]
  },
  {
    "plz": "63110",
    "partner": [
      8
    ]
  },
  {
    "plz": "63128",
    "partner": [
      8
    ]
  },
  {
    "plz": "63150",
    "partner": [
      8
    ]
  },
  {
    "plz": "63150",
    "partner": [
      8
    ]
  },
  {
    "plz": "63165",
    "partner": [
      8
    ]
  },
  {
    "plz": "63179",
    "partner": [
      8
    ]
  },
  {
    "plz": "63225",
    "partner": [
      8
    ]
  },
  {
    "plz": "63263",
    "partner": [
      8
    ]
  },
  {
    "plz": "63303",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63322",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63329",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63450",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63452",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63454",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63456",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63457",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63477",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63486",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63500",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63505",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63512",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63517",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63526",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63533",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63538",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63543",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63543",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63546",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63549",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63571",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63579",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63584",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63589",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63589",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63589",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63594",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63599",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63607",
    "partner": [
      8
    ]
  },
  {
    "plz": "63619",
    "partner": [
      8
    ]
  },
  {
    "plz": "63628",
    "partner": [
      8
    ]
  },
  {
    "plz": "63633",
    "partner": [
      8
    ]
  },
  {
    "plz": "63636",
    "partner": [
      8
    ]
  },
  {
    "plz": "63637",
    "partner": [
      8
    ]
  },
  {
    "plz": "63639",
    "partner": [
      8
    ]
  },
  {
    "plz": "63654",
    "partner": [
      8
    ]
  },
  {
    "plz": "63667",
    "partner": [
      8
    ]
  },
  {
    "plz": "63674",
    "partner": [
      8
    ]
  },
  {
    "plz": "63679",
    "partner": [
      8
    ]
  },
  {
    "plz": "63683",
    "partner": [
      8
    ]
  },
  {
    "plz": "63688",
    "partner": [
      8
    ]
  },
  {
    "plz": "63691",
    "partner": [
      8
    ]
  },
  {
    "plz": "63694",
    "partner": [
      8
    ]
  },
  {
    "plz": "63695",
    "partner": [
      8
    ]
  },
  {
    "plz": "63697",
    "partner": [
      8
    ]
  },
  {
    "plz": "63699",
    "partner": [
      8
    ]
  },
  {
    "plz": "63699",
    "partner": [
      8
    ]
  },
  {
    "plz": "63699",
    "partner": [
      8
    ]
  },
  {
    "plz": "63699",
    "partner": [
      8
    ]
  },
  {
    "plz": "63739",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63741",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63743",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63755",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63762",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63768",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63773",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63776",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63776",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63785",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63791",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63796",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63801",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63808",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63811",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63814",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63820",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63825",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63825",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63825",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63825",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63826",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63828",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63829",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63831",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63834",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63839",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63840",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63843",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63846",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63846",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63849",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63853",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63856",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63857",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63860",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63863",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63863",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63864",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63867",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63868",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63869",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63871",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63872",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63874",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63874",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63875",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63877",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63879",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63897",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63906",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63911",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63916",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63916",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63920",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63924",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63924",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63925",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63925",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63927",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63928",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63928",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63930",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63931",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63933",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63934",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63936",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63937",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "63939",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64283",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64285",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64287",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64289",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64291",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64293",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64295",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64297",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64319",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64331",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64342",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64347",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64354",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64367",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64372",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64380",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64385",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64385",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64390",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64395",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64395",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64395",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64397",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64401",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64404",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64405",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64407",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64409",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64521",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64546",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64560",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64569",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64572",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64579",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64584",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64589",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64625",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64646",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64653",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64658",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64658",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64665",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64668",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64673",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64678",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64683",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64686",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64689",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64711",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64720",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64720",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64732",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64739",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64743",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64747",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64750",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64753",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64753",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64754",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64756",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64757",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64760",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64807",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64823",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64832",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64839",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64846",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64850",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64853",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "64859",
    "partner": [
      8,
      10
    ]
  },
  {
    "plz": "65183",
    "partner": [
      8
    ]
  },
  {
    "plz": "65185",
    "partner": [
      8
    ]
  },
  {
    "plz": "65187",
    "partner": [
      8
    ]
  },
  {
    "plz": "65189",
    "partner": [
      8
    ]
  },
  {
    "plz": "65191",
    "partner": [
      8
    ]
  },
  {
    "plz": "65193",
    "partner": [
      8
    ]
  },
  {
    "plz": "65195",
    "partner": [
      8
    ]
  },
  {
    "plz": "65197",
    "partner": [
      8
    ]
  },
  {
    "plz": "65199",
    "partner": [
      8
    ]
  },
  {
    "plz": "65201",
    "partner": [
      8
    ]
  },
  {
    "plz": "65203",
    "partner": [
      8
    ]
  },
  {
    "plz": "65205",
    "partner": [
      8
    ]
  },
  {
    "plz": "65207",
    "partner": [
      8
    ]
  },
  {
    "plz": "65232",
    "partner": [
      8
    ]
  },
  {
    "plz": "65239",
    "partner": [
      8
    ]
  },
  {
    "plz": "65307",
    "partner": [
      8
    ]
  },
  {
    "plz": "65321",
    "partner": [
      8
    ]
  },
  {
    "plz": "65326",
    "partner": [
      8
    ]
  },
  {
    "plz": "65326",
    "partner": [
      8
    ]
  },
  {
    "plz": "65329",
    "partner": [
      8
    ]
  },
  {
    "plz": "65343",
    "partner": [
      8
    ]
  },
  {
    "plz": "65344",
    "partner": [
      8
    ]
  },
  {
    "plz": "65345",
    "partner": [
      8
    ]
  },
  {
    "plz": "65346",
    "partner": [
      8
    ]
  },
  {
    "plz": "65347",
    "partner": [
      8
    ]
  },
  {
    "plz": "65366",
    "partner": [
      8
    ]
  },
  {
    "plz": "65375",
    "partner": [
      8
    ]
  },
  {
    "plz": "65385",
    "partner": [
      8
    ]
  },
  {
    "plz": "65385",
    "partner": [
      8
    ]
  },
  {
    "plz": "65388",
    "partner": [
      8
    ]
  },
  {
    "plz": "65391",
    "partner": [
      8
    ]
  },
  {
    "plz": "65391",
    "partner": [
      8
    ]
  },
  {
    "plz": "65396",
    "partner": [
      8
    ]
  },
  {
    "plz": "65399",
    "partner": [
      8
    ]
  },
  {
    "plz": "65428",
    "partner": [
      8
    ]
  },
  {
    "plz": "65439",
    "partner": [
      8
    ]
  },
  {
    "plz": "65451",
    "partner": [
      8
    ]
  },
  {
    "plz": "65462",
    "partner": [
      8
    ]
  },
  {
    "plz": "65468",
    "partner": [
      8
    ]
  },
  {
    "plz": "65474",
    "partner": [
      8
    ]
  },
  {
    "plz": "65479",
    "partner": [
      8
    ]
  },
  {
    "plz": "65510",
    "partner": [
      8
    ]
  },
  {
    "plz": "65510",
    "partner": [
      8
    ]
  },
  {
    "plz": "65510",
    "partner": [
      8
    ]
  },
  {
    "plz": "65510",
    "partner": [
      8
    ]
  },
  {
    "plz": "65520",
    "partner": [
      8
    ]
  },
  {
    "plz": "65527",
    "partner": [
      8
    ]
  },
  {
    "plz": "65529",
    "partner": [
      8
    ]
  },
  {
    "plz": "65549",
    "partner": [
      8
    ]
  },
  {
    "plz": "65550",
    "partner": [
      8
    ]
  },
  {
    "plz": "65551",
    "partner": [
      8
    ]
  },
  {
    "plz": "65552",
    "partner": [
      8
    ]
  },
  {
    "plz": "65553",
    "partner": [
      8
    ]
  },
  {
    "plz": "65554",
    "partner": [
      8
    ]
  },
  {
    "plz": "65555",
    "partner": [
      8
    ]
  },
  {
    "plz": "65556",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65558",
    "partner": [
      8
    ]
  },
  {
    "plz": "65582",
    "partner": [
      8
    ]
  },
  {
    "plz": "65582",
    "partner": [
      8
    ]
  },
  {
    "plz": "65582",
    "partner": [
      8
    ]
  },
  {
    "plz": "65589",
    "partner": [
      8
    ]
  },
  {
    "plz": "65594",
    "partner": [
      8
    ]
  },
  {
    "plz": "65597",
    "partner": [
      8
    ]
  },
  {
    "plz": "65599",
    "partner": [
      8
    ]
  },
  {
    "plz": "65604",
    "partner": [
      8
    ]
  },
  {
    "plz": "65606",
    "partner": [
      8
    ]
  },
  {
    "plz": "65611",
    "partner": [
      8
    ]
  },
  {
    "plz": "65614",
    "partner": [
      8
    ]
  },
  {
    "plz": "65618",
    "partner": [
      8
    ]
  },
  {
    "plz": "65620",
    "partner": [
      8
    ]
  },
  {
    "plz": "65623",
    "partner": [
      8
    ]
  },
  {
    "plz": "65623",
    "partner": [
      8
    ]
  },
  {
    "plz": "65623",
    "partner": [
      8
    ]
  },
  {
    "plz": "65623",
    "partner": [
      8
    ]
  },
  {
    "plz": "65624",
    "partner": [
      8
    ]
  },
  {
    "plz": "65626",
    "partner": [
      8
    ]
  },
  {
    "plz": "65626",
    "partner": [
      8
    ]
  },
  {
    "plz": "65627",
    "partner": [
      8
    ]
  },
  {
    "plz": "65629",
    "partner": [
      8
    ]
  },
  {
    "plz": "65719",
    "partner": [
      8
    ]
  },
  {
    "plz": "65760",
    "partner": [
      8
    ]
  },
  {
    "plz": "65779",
    "partner": [
      8
    ]
  },
  {
    "plz": "65795",
    "partner": [
      8
    ]
  },
  {
    "plz": "65812",
    "partner": [
      8
    ]
  },
  {
    "plz": "65817",
    "partner": [
      8
    ]
  },
  {
    "plz": "65824",
    "partner": [
      8
    ]
  },
  {
    "plz": "65830",
    "partner": [
      8
    ]
  },
  {
    "plz": "65835",
    "partner": [
      8
    ]
  },
  {
    "plz": "65843",
    "partner": [
      8
    ]
  },
  {
    "plz": "65929",
    "partner": [
      8
    ]
  },
  {
    "plz": "65931",
    "partner": [
      8
    ]
  },
  {
    "plz": "65933",
    "partner": [
      8
    ]
  },
  {
    "plz": "65934",
    "partner": [
      8
    ]
  },
  {
    "plz": "65936",
    "partner": [
      8
    ]
  },
  {
    "plz": "66111",
    "partner": [
      8
    ]
  },
  {
    "plz": "66113",
    "partner": [
      8
    ]
  },
  {
    "plz": "66115",
    "partner": [
      8
    ]
  },
  {
    "plz": "66117",
    "partner": [
      8
    ]
  },
  {
    "plz": "66119",
    "partner": [
      8
    ]
  },
  {
    "plz": "66121",
    "partner": [
      8
    ]
  },
  {
    "plz": "66123",
    "partner": [
      8
    ]
  },
  {
    "plz": "66125",
    "partner": [
      8
    ]
  },
  {
    "plz": "66126",
    "partner": [
      8
    ]
  },
  {
    "plz": "66127",
    "partner": [
      8
    ]
  },
  {
    "plz": "66128",
    "partner": [
      8
    ]
  },
  {
    "plz": "66129",
    "partner": [
      8
    ]
  },
  {
    "plz": "66130",
    "partner": [
      8
    ]
  },
  {
    "plz": "66131",
    "partner": [
      8
    ]
  },
  {
    "plz": "66132",
    "partner": [
      8
    ]
  },
  {
    "plz": "66133",
    "partner": [
      8
    ]
  },
  {
    "plz": "66265",
    "partner": [
      8
    ]
  },
  {
    "plz": "66271",
    "partner": [
      8
    ]
  },
  {
    "plz": "66280",
    "partner": [
      8
    ]
  },
  {
    "plz": "66287",
    "partner": [
      8
    ]
  },
  {
    "plz": "66292",
    "partner": [
      8
    ]
  },
  {
    "plz": "66299",
    "partner": [
      8
    ]
  },
  {
    "plz": "66333",
    "partner": [
      8
    ]
  },
  {
    "plz": "66346",
    "partner": [
      8
    ]
  },
  {
    "plz": "66352",
    "partner": [
      8
    ]
  },
  {
    "plz": "66359",
    "partner": [
      8
    ]
  },
  {
    "plz": "66386",
    "partner": [
      8
    ]
  },
  {
    "plz": "66399",
    "partner": [
      8
    ]
  },
  {
    "plz": "66424",
    "partner": [
      8
    ]
  },
  {
    "plz": "66440",
    "partner": [
      8
    ]
  },
  {
    "plz": "66450",
    "partner": [
      8
    ]
  },
  {
    "plz": "66453",
    "partner": [
      8
    ]
  },
  {
    "plz": "66459",
    "partner": [
      8
    ]
  },
  {
    "plz": "66482",
    "partner": [
      8
    ]
  },
  {
    "plz": "66482",
    "partner": [
      8
    ]
  },
  {
    "plz": "66482",
    "partner": [
      8
    ]
  },
  {
    "plz": "66482",
    "partner": [
      8
    ]
  },
  {
    "plz": "66482",
    "partner": [
      8
    ]
  },
  {
    "plz": "66482",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66484",
    "partner": [
      8
    ]
  },
  {
    "plz": "66497",
    "partner": [
      8
    ]
  },
  {
    "plz": "66500",
    "partner": [
      8
    ]
  },
  {
    "plz": "66500",
    "partner": [
      8
    ]
  },
  {
    "plz": "66500",
    "partner": [
      8
    ]
  },
  {
    "plz": "66500",
    "partner": [
      8
    ]
  },
  {
    "plz": "66500",
    "partner": [
      8
    ]
  },
  {
    "plz": "66501",
    "partner": [
      8
    ]
  },
  {
    "plz": "66501",
    "partner": [
      8
    ]
  },
  {
    "plz": "66503",
    "partner": [
      8
    ]
  },
  {
    "plz": "66503",
    "partner": [
      8
    ]
  },
  {
    "plz": "66504",
    "partner": [
      8
    ]
  },
  {
    "plz": "66506",
    "partner": [
      8
    ]
  },
  {
    "plz": "66507",
    "partner": [
      8
    ]
  },
  {
    "plz": "66509",
    "partner": [
      8
    ]
  },
  {
    "plz": "66538",
    "partner": [
      8
    ]
  },
  {
    "plz": "66539",
    "partner": [
      8
    ]
  },
  {
    "plz": "66540",
    "partner": [
      8
    ]
  },
  {
    "plz": "66557",
    "partner": [
      8
    ]
  },
  {
    "plz": "66564",
    "partner": [
      8
    ]
  },
  {
    "plz": "66571",
    "partner": [
      8
    ]
  },
  {
    "plz": "66578",
    "partner": [
      8
    ]
  },
  {
    "plz": "66583",
    "partner": [
      8
    ]
  },
  {
    "plz": "66589",
    "partner": [
      8
    ]
  },
  {
    "plz": "66606",
    "partner": [
      8
    ]
  },
  {
    "plz": "66620",
    "partner": [
      8
    ]
  },
  {
    "plz": "66625",
    "partner": [
      8
    ]
  },
  {
    "plz": "66629",
    "partner": [
      8
    ]
  },
  {
    "plz": "66636",
    "partner": [
      8
    ]
  },
  {
    "plz": "66640",
    "partner": [
      8
    ]
  },
  {
    "plz": "66646",
    "partner": [
      8
    ]
  },
  {
    "plz": "66649",
    "partner": [
      8
    ]
  },
  {
    "plz": "66663",
    "partner": [
      8
    ]
  },
  {
    "plz": "66679",
    "partner": [
      8
    ]
  },
  {
    "plz": "66687",
    "partner": [
      8
    ]
  },
  {
    "plz": "66693",
    "partner": [
      8
    ]
  },
  {
    "plz": "66701",
    "partner": [
      8
    ]
  },
  {
    "plz": "66706",
    "partner": [
      8
    ]
  },
  {
    "plz": "66709",
    "partner": [
      8
    ]
  },
  {
    "plz": "66740",
    "partner": [
      8
    ]
  },
  {
    "plz": "66763",
    "partner": [
      8
    ]
  },
  {
    "plz": "66773",
    "partner": [
      8
    ]
  },
  {
    "plz": "66780",
    "partner": [
      8
    ]
  },
  {
    "plz": "66787",
    "partner": [
      8
    ]
  },
  {
    "plz": "66793",
    "partner": [
      8
    ]
  },
  {
    "plz": "66798",
    "partner": [
      8
    ]
  },
  {
    "plz": "66802",
    "partner": [
      8
    ]
  },
  {
    "plz": "66806",
    "partner": [
      8
    ]
  },
  {
    "plz": "66809",
    "partner": [
      8
    ]
  },
  {
    "plz": "66822",
    "partner": [
      8
    ]
  },
  {
    "plz": "66839",
    "partner": [
      8
    ]
  },
  {
    "plz": "66849",
    "partner": [
      8
    ]
  },
  {
    "plz": "66849",
    "partner": [
      8
    ]
  },
  {
    "plz": "66849",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66851",
    "partner": [
      8
    ]
  },
  {
    "plz": "66862",
    "partner": [
      8
    ]
  },
  {
    "plz": "66869",
    "partner": [
      8
    ]
  },
  {
    "plz": "66869",
    "partner": [
      8
    ]
  },
  {
    "plz": "66869",
    "partner": [
      8
    ]
  },
  {
    "plz": "66869",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66871",
    "partner": [
      8
    ]
  },
  {
    "plz": "66877",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66879",
    "partner": [
      8
    ]
  },
  {
    "plz": "66882",
    "partner": [
      8
    ]
  },
  {
    "plz": "66885",
    "partner": [
      8
    ]
  },
  {
    "plz": "66885",
    "partner": [
      8
    ]
  },
  {
    "plz": "66885",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66887",
    "partner": [
      8
    ]
  },
  {
    "plz": "66892",
    "partner": [
      8
    ]
  },
  {
    "plz": "66892",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66894",
    "partner": [
      8
    ]
  },
  {
    "plz": "66901",
    "partner": [
      8
    ]
  },
  {
    "plz": "66903",
    "partner": [
      8
    ]
  },
  {
    "plz": "66903",
    "partner": [
      8
    ]
  },
  {
    "plz": "66903",
    "partner": [
      8
    ]
  },
  {
    "plz": "66903",
    "partner": [
      8
    ]
  },
  {
    "plz": "66903",
    "partner": [
      8
    ]
  },
  {
    "plz": "66903",
    "partner": [
      8
    ]
  },
  {
    "plz": "66904",
    "partner": [
      8
    ]
  },
  {
    "plz": "66904",
    "partner": [
      8
    ]
  },
  {
    "plz": "66904",
    "partner": [
      8
    ]
  },
  {
    "plz": "66907",
    "partner": [
      8
    ]
  },
  {
    "plz": "66907",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66909",
    "partner": [
      8
    ]
  },
  {
    "plz": "66914",
    "partner": [
      8
    ]
  },
  {
    "plz": "66916",
    "partner": [
      8
    ]
  },
  {
    "plz": "66916",
    "partner": [
      8
    ]
  },
  {
    "plz": "66916",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66917",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66919",
    "partner": [
      8
    ]
  },
  {
    "plz": "66953",
    "partner": [
      8
    ]
  },
  {
    "plz": "66954",
    "partner": [
      8
    ]
  },
  {
    "plz": "66954",
    "partner": [
      8
    ]
  },
  {
    "plz": "66955",
    "partner": [
      8
    ]
  },
  {
    "plz": "66955",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66957",
    "partner": [
      8
    ]
  },
  {
    "plz": "66969",
    "partner": [
      8
    ]
  },
  {
    "plz": "66976",
    "partner": [
      8
    ]
  },
  {
    "plz": "66978",
    "partner": [
      8
    ]
  },
  {
    "plz": "66978",
    "partner": [
      8
    ]
  },
  {
    "plz": "66978",
    "partner": [
      8
    ]
  },
  {
    "plz": "66978",
    "partner": [
      8
    ]
  },
  {
    "plz": "66981",
    "partner": [
      8
    ]
  },
  {
    "plz": "66987",
    "partner": [
      8
    ]
  },
  {
    "plz": "66987",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66989",
    "partner": [
      8
    ]
  },
  {
    "plz": "66994",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66996",
    "partner": [
      8
    ]
  },
  {
    "plz": "66999",
    "partner": [
      8
    ]
  },
  {
    "plz": "66999",
    "partner": [
      8
    ]
  },
  {
    "plz": "67059",
    "partner": [
      8
    ]
  },
  {
    "plz": "67061",
    "partner": [
      8
    ]
  },
  {
    "plz": "67063",
    "partner": [
      8
    ]
  },
  {
    "plz": "67065",
    "partner": [
      8
    ]
  },
  {
    "plz": "67067",
    "partner": [
      8
    ]
  },
  {
    "plz": "67069",
    "partner": [
      8
    ]
  },
  {
    "plz": "67071",
    "partner": [
      8
    ]
  },
  {
    "plz": "67098",
    "partner": [
      8
    ]
  },
  {
    "plz": "67098",
    "partner": [
      8
    ]
  },
  {
    "plz": "67098",
    "partner": [
      8
    ]
  },
  {
    "plz": "67098",
    "partner": [
      8
    ]
  },
  {
    "plz": "67105",
    "partner": [
      8
    ]
  },
  {
    "plz": "67112",
    "partner": [
      8
    ]
  },
  {
    "plz": "67117",
    "partner": [
      8
    ]
  },
  {
    "plz": "67122",
    "partner": [
      8
    ]
  },
  {
    "plz": "67125",
    "partner": [
      8
    ]
  },
  {
    "plz": "67126",
    "partner": [
      8
    ]
  },
  {
    "plz": "67127",
    "partner": [
      8
    ]
  },
  {
    "plz": "67133",
    "partner": [
      8
    ]
  },
  {
    "plz": "67134",
    "partner": [
      8
    ]
  },
  {
    "plz": "67136",
    "partner": [
      8
    ]
  },
  {
    "plz": "67141",
    "partner": [
      8
    ]
  },
  {
    "plz": "67146",
    "partner": [
      8
    ]
  },
  {
    "plz": "67147",
    "partner": [
      8
    ]
  },
  {
    "plz": "67149",
    "partner": [
      8
    ]
  },
  {
    "plz": "67150",
    "partner": [
      8
    ]
  },
  {
    "plz": "67152",
    "partner": [
      8
    ]
  },
  {
    "plz": "67157",
    "partner": [
      8
    ]
  },
  {
    "plz": "67157",
    "partner": [
      8
    ]
  },
  {
    "plz": "67158",
    "partner": [
      8
    ]
  },
  {
    "plz": "67159",
    "partner": [
      8
    ]
  },
  {
    "plz": "67161",
    "partner": [
      8
    ]
  },
  {
    "plz": "67165",
    "partner": [
      8
    ]
  },
  {
    "plz": "67166",
    "partner": [
      8
    ]
  },
  {
    "plz": "67167",
    "partner": [
      8
    ]
  },
  {
    "plz": "67169",
    "partner": [
      8
    ]
  },
  {
    "plz": "67227",
    "partner": [
      8
    ]
  },
  {
    "plz": "67229",
    "partner": [
      8
    ]
  },
  {
    "plz": "67229",
    "partner": [
      8
    ]
  },
  {
    "plz": "67229",
    "partner": [
      8
    ]
  },
  {
    "plz": "67240",
    "partner": [
      8
    ]
  },
  {
    "plz": "67245",
    "partner": [
      8
    ]
  },
  {
    "plz": "67246",
    "partner": [
      8
    ]
  },
  {
    "plz": "67251",
    "partner": [
      8
    ]
  },
  {
    "plz": "67256",
    "partner": [
      8
    ]
  },
  {
    "plz": "67258",
    "partner": [
      8
    ]
  },
  {
    "plz": "67259",
    "partner": [
      8
    ]
  },
  {
    "plz": "67259",
    "partner": [
      8
    ]
  },
  {
    "plz": "67259",
    "partner": [
      8
    ]
  },
  {
    "plz": "67259",
    "partner": [
      8
    ]
  },
  {
    "plz": "67269",
    "partner": [
      8
    ]
  },
  {
    "plz": "67271",
    "partner": [
      8
    ]
  },
  {
    "plz": "67271",
    "partner": [
      8
    ]
  },
  {
    "plz": "67271",
    "partner": [
      8
    ]
  },
  {
    "plz": "67271",
    "partner": [
      8
    ]
  },
  {
    "plz": "67271",
    "partner": [
      8
    ]
  },
  {
    "plz": "67271",
    "partner": [
      8
    ]
  },
  {
    "plz": "67273",
    "partner": [
      8
    ]
  },
  {
    "plz": "67273",
    "partner": [
      8
    ]
  },
  {
    "plz": "67273",
    "partner": [
      8
    ]
  },
  {
    "plz": "67273",
    "partner": [
      8
    ]
  },
  {
    "plz": "67278",
    "partner": [
      8
    ]
  },
  {
    "plz": "67280",
    "partner": [
      8
    ]
  },
  {
    "plz": "67280",
    "partner": [
      8
    ]
  },
  {
    "plz": "67281",
    "partner": [
      8
    ]
  },
  {
    "plz": "67281",
    "partner": [
      8
    ]
  },
  {
    "plz": "67281",
    "partner": [
      8
    ]
  },
  {
    "plz": "67283",
    "partner": [
      8
    ]
  },
  {
    "plz": "67292",
    "partner": [
      8
    ]
  },
  {
    "plz": "67292",
    "partner": [
      8
    ]
  },
  {
    "plz": "67292",
    "partner": [
      8
    ]
  },
  {
    "plz": "67292",
    "partner": [
      8
    ]
  },
  {
    "plz": "67292",
    "partner": [
      8
    ]
  },
  {
    "plz": "67292",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67294",
    "partner": [
      8
    ]
  },
  {
    "plz": "67295",
    "partner": [
      8
    ]
  },
  {
    "plz": "67297",
    "partner": [
      8
    ]
  },
  {
    "plz": "67304",
    "partner": [
      8
    ]
  },
  {
    "plz": "67304",
    "partner": [
      8
    ]
  },
  {
    "plz": "67305",
    "partner": [
      8
    ]
  },
  {
    "plz": "67305",
    "partner": [
      8
    ]
  },
  {
    "plz": "67307",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67308",
    "partner": [
      8
    ]
  },
  {
    "plz": "67310",
    "partner": [
      8
    ]
  },
  {
    "plz": "67311",
    "partner": [
      8
    ]
  },
  {
    "plz": "67316",
    "partner": [
      8
    ]
  },
  {
    "plz": "67316",
    "partner": [
      8
    ]
  },
  {
    "plz": "67316",
    "partner": [
      8
    ]
  },
  {
    "plz": "67316",
    "partner": [
      8
    ]
  },
  {
    "plz": "67317",
    "partner": [
      8
    ]
  },
  {
    "plz": "67319",
    "partner": [
      8
    ]
  },
  {
    "plz": "67319",
    "partner": [
      8
    ]
  },
  {
    "plz": "67346",
    "partner": [
      8
    ]
  },
  {
    "plz": "67346",
    "partner": [
      8
    ]
  },
  {
    "plz": "67354",
    "partner": [
      8
    ]
  },
  {
    "plz": "67360",
    "partner": [
      8
    ]
  },
  {
    "plz": "67361",
    "partner": [
      8
    ]
  },
  {
    "plz": "67363",
    "partner": [
      8
    ]
  },
  {
    "plz": "67365",
    "partner": [
      8
    ]
  },
  {
    "plz": "67366",
    "partner": [
      8
    ]
  },
  {
    "plz": "67368",
    "partner": [
      8
    ]
  },
  {
    "plz": "67373",
    "partner": [
      8
    ]
  },
  {
    "plz": "67374",
    "partner": [
      8
    ]
  },
  {
    "plz": "67376",
    "partner": [
      8
    ]
  },
  {
    "plz": "67377",
    "partner": [
      8
    ]
  },
  {
    "plz": "67378",
    "partner": [
      8
    ]
  },
  {
    "plz": "67433",
    "partner": [
      8
    ]
  },
  {
    "plz": "67434",
    "partner": [
      8
    ]
  },
  {
    "plz": "67435",
    "partner": [
      8
    ]
  },
  {
    "plz": "67435",
    "partner": [
      8
    ]
  },
  {
    "plz": "67435",
    "partner": [
      8
    ]
  },
  {
    "plz": "67454",
    "partner": [
      8
    ]
  },
  {
    "plz": "67459",
    "partner": [
      8
    ]
  },
  {
    "plz": "67466",
    "partner": [
      8
    ]
  },
  {
    "plz": "67466",
    "partner": [
      8
    ]
  },
  {
    "plz": "67466",
    "partner": [
      8
    ]
  },
  {
    "plz": "67466",
    "partner": [
      8
    ]
  },
  {
    "plz": "67468",
    "partner": [
      8
    ]
  },
  {
    "plz": "67468",
    "partner": [
      8
    ]
  },
  {
    "plz": "67468",
    "partner": [
      8
    ]
  },
  {
    "plz": "67468",
    "partner": [
      8
    ]
  },
  {
    "plz": "67468",
    "partner": [
      8
    ]
  },
  {
    "plz": "67468",
    "partner": [
      8
    ]
  },
  {
    "plz": "67471",
    "partner": [
      8
    ]
  },
  {
    "plz": "67472",
    "partner": [
      8
    ]
  },
  {
    "plz": "67473",
    "partner": [
      8
    ]
  },
  {
    "plz": "67475",
    "partner": [
      8
    ]
  },
  {
    "plz": "67480",
    "partner": [
      8
    ]
  },
  {
    "plz": "67482",
    "partner": [
      8
    ]
  },
  {
    "plz": "67482",
    "partner": [
      8
    ]
  },
  {
    "plz": "67482",
    "partner": [
      8
    ]
  },
  {
    "plz": "67482",
    "partner": [
      8
    ]
  },
  {
    "plz": "67483",
    "partner": [
      8
    ]
  },
  {
    "plz": "67483",
    "partner": [
      8
    ]
  },
  {
    "plz": "67483",
    "partner": [
      8
    ]
  },
  {
    "plz": "67487",
    "partner": [
      8
    ]
  },
  {
    "plz": "67487",
    "partner": [
      8
    ]
  },
  {
    "plz": "67489",
    "partner": [
      8
    ]
  },
  {
    "plz": "67547",
    "partner": [
      8
    ]
  },
  {
    "plz": "67549",
    "partner": [
      8
    ]
  },
  {
    "plz": "67550",
    "partner": [
      8
    ]
  },
  {
    "plz": "67551",
    "partner": [
      8
    ]
  },
  {
    "plz": "67574",
    "partner": [
      8
    ]
  },
  {
    "plz": "67575",
    "partner": [
      8
    ]
  },
  {
    "plz": "67577",
    "partner": [
      8
    ]
  },
  {
    "plz": "67578",
    "partner": [
      8
    ]
  },
  {
    "plz": "67580",
    "partner": [
      8
    ]
  },
  {
    "plz": "67582",
    "partner": [
      8
    ]
  },
  {
    "plz": "67583",
    "partner": [
      8
    ]
  },
  {
    "plz": "67585",
    "partner": [
      8
    ]
  },
  {
    "plz": "67586",
    "partner": [
      8
    ]
  },
  {
    "plz": "67587",
    "partner": [
      8
    ]
  },
  {
    "plz": "67590",
    "partner": [
      8
    ]
  },
  {
    "plz": "67591",
    "partner": [
      8
    ]
  },
  {
    "plz": "67591",
    "partner": [
      8
    ]
  },
  {
    "plz": "67591",
    "partner": [
      8
    ]
  },
  {
    "plz": "67591",
    "partner": [
      8
    ]
  },
  {
    "plz": "67591",
    "partner": [
      8
    ]
  },
  {
    "plz": "67592",
    "partner": [
      8
    ]
  },
  {
    "plz": "67593",
    "partner": [
      8
    ]
  },
  {
    "plz": "67593",
    "partner": [
      8
    ]
  },
  {
    "plz": "67595",
    "partner": [
      8
    ]
  },
  {
    "plz": "67596",
    "partner": [
      8
    ]
  },
  {
    "plz": "67596",
    "partner": [
      8
    ]
  },
  {
    "plz": "67598",
    "partner": [
      8
    ]
  },
  {
    "plz": "67599",
    "partner": [
      8
    ]
  },
  {
    "plz": "67655",
    "partner": [
      8
    ]
  },
  {
    "plz": "67657",
    "partner": [
      8
    ]
  },
  {
    "plz": "67659",
    "partner": [
      8
    ]
  },
  {
    "plz": "67661",
    "partner": [
      8
    ]
  },
  {
    "plz": "67661",
    "partner": [
      8
    ]
  },
  {
    "plz": "67661",
    "partner": [
      8
    ]
  },
  {
    "plz": "67661",
    "partner": [
      8
    ]
  },
  {
    "plz": "67663",
    "partner": [
      8
    ]
  },
  {
    "plz": "67677",
    "partner": [
      8
    ]
  },
  {
    "plz": "67677",
    "partner": [
      8
    ]
  },
  {
    "plz": "67677",
    "partner": [
      8
    ]
  },
  {
    "plz": "67678",
    "partner": [
      8
    ]
  },
  {
    "plz": "67680",
    "partner": [
      8
    ]
  },
  {
    "plz": "67680",
    "partner": [
      8
    ]
  },
  {
    "plz": "67681",
    "partner": [
      8
    ]
  },
  {
    "plz": "67681",
    "partner": [
      8
    ]
  },
  {
    "plz": "67685",
    "partner": [
      8
    ]
  },
  {
    "plz": "67685",
    "partner": [
      8
    ]
  },
  {
    "plz": "67685",
    "partner": [
      8
    ]
  },
  {
    "plz": "67685",
    "partner": [
      8
    ]
  },
  {
    "plz": "67685",
    "partner": [
      8
    ]
  },
  {
    "plz": "67685",
    "partner": [
      8
    ]
  },
  {
    "plz": "67686",
    "partner": [
      8
    ]
  },
  {
    "plz": "67688",
    "partner": [
      8
    ]
  },
  {
    "plz": "67691",
    "partner": [
      8
    ]
  },
  {
    "plz": "67691",
    "partner": [
      8
    ]
  },
  {
    "plz": "67693",
    "partner": [
      8
    ]
  },
  {
    "plz": "67693",
    "partner": [
      8
    ]
  },
  {
    "plz": "67697",
    "partner": [
      8
    ]
  },
  {
    "plz": "67699",
    "partner": [
      8
    ]
  },
  {
    "plz": "67699",
    "partner": [
      8
    ]
  },
  {
    "plz": "67699",
    "partner": [
      8
    ]
  },
  {
    "plz": "67700",
    "partner": [
      8
    ]
  },
  {
    "plz": "67701",
    "partner": [
      8
    ]
  },
  {
    "plz": "67705",
    "partner": [
      8
    ]
  },
  {
    "plz": "67705",
    "partner": [
      8
    ]
  },
  {
    "plz": "67705",
    "partner": [
      8
    ]
  },
  {
    "plz": "67706",
    "partner": [
      8
    ]
  },
  {
    "plz": "67706",
    "partner": [
      8
    ]
  },
  {
    "plz": "67707",
    "partner": [
      8
    ]
  },
  {
    "plz": "67707",
    "partner": [
      8
    ]
  },
  {
    "plz": "67714",
    "partner": [
      8
    ]
  },
  {
    "plz": "67714",
    "partner": [
      8
    ]
  },
  {
    "plz": "67714",
    "partner": [
      8
    ]
  },
  {
    "plz": "67714",
    "partner": [
      8
    ]
  },
  {
    "plz": "67715",
    "partner": [
      8
    ]
  },
  {
    "plz": "67716",
    "partner": [
      8
    ]
  },
  {
    "plz": "67718",
    "partner": [
      8
    ]
  },
  {
    "plz": "67722",
    "partner": [
      8
    ]
  },
  {
    "plz": "67724",
    "partner": [
      8
    ]
  },
  {
    "plz": "67724",
    "partner": [
      8
    ]
  },
  {
    "plz": "67724",
    "partner": [
      8
    ]
  },
  {
    "plz": "67724",
    "partner": [
      8
    ]
  },
  {
    "plz": "67724",
    "partner": [
      8
    ]
  },
  {
    "plz": "67725",
    "partner": [
      8
    ]
  },
  {
    "plz": "67725",
    "partner": [
      8
    ]
  },
  {
    "plz": "67725",
    "partner": [
      8
    ]
  },
  {
    "plz": "67727",
    "partner": [
      8
    ]
  },
  {
    "plz": "67728",
    "partner": [
      8
    ]
  },
  {
    "plz": "67729",
    "partner": [
      8
    ]
  },
  {
    "plz": "67731",
    "partner": [
      8
    ]
  },
  {
    "plz": "67732",
    "partner": [
      8
    ]
  },
  {
    "plz": "67734",
    "partner": [
      8
    ]
  },
  {
    "plz": "67734",
    "partner": [
      8
    ]
  },
  {
    "plz": "67735",
    "partner": [
      8
    ]
  },
  {
    "plz": "67737",
    "partner": [
      8
    ]
  },
  {
    "plz": "67737",
    "partner": [
      8
    ]
  },
  {
    "plz": "67737",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67742",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67744",
    "partner": [
      8
    ]
  },
  {
    "plz": "67745",
    "partner": [
      8
    ]
  },
  {
    "plz": "67746",
    "partner": [
      8
    ]
  },
  {
    "plz": "67746",
    "partner": [
      8
    ]
  },
  {
    "plz": "67746",
    "partner": [
      8
    ]
  },
  {
    "plz": "67748",
    "partner": [
      8
    ]
  },
  {
    "plz": "67749",
    "partner": [
      8
    ]
  },
  {
    "plz": "67749",
    "partner": [
      8
    ]
  },
  {
    "plz": "67752",
    "partner": [
      8
    ]
  },
  {
    "plz": "67752",
    "partner": [
      8
    ]
  },
  {
    "plz": "67752",
    "partner": [
      8
    ]
  },
  {
    "plz": "67753",
    "partner": [
      8
    ]
  },
  {
    "plz": "67753",
    "partner": [
      8
    ]
  },
  {
    "plz": "67753",
    "partner": [
      8
    ]
  },
  {
    "plz": "67753",
    "partner": [
      8
    ]
  },
  {
    "plz": "67753",
    "partner": [
      8
    ]
  },
  {
    "plz": "67753",
    "partner": [
      8
    ]
  },
  {
    "plz": "67754",
    "partner": [
      8
    ]
  },
  {
    "plz": "67756",
    "partner": [
      8
    ]
  },
  {
    "plz": "67756",
    "partner": [
      8
    ]
  },
  {
    "plz": "67757",
    "partner": [
      8
    ]
  },
  {
    "plz": "67759",
    "partner": [
      8
    ]
  },
  {
    "plz": "67759",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67806",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67808",
    "partner": [
      8
    ]
  },
  {
    "plz": "67811",
    "partner": [
      8
    ]
  },
  {
    "plz": "67813",
    "partner": [
      8
    ]
  },
  {
    "plz": "67813",
    "partner": [
      8
    ]
  },
  {
    "plz": "67813",
    "partner": [
      8
    ]
  },
  {
    "plz": "67814",
    "partner": [
      8
    ]
  },
  {
    "plz": "67814",
    "partner": [
      8
    ]
  },
  {
    "plz": "67816",
    "partner": [
      8
    ]
  },
  {
    "plz": "67816",
    "partner": [
      8
    ]
  },
  {
    "plz": "67816",
    "partner": [
      8
    ]
  },
  {
    "plz": "67816",
    "partner": [
      8
    ]
  },
  {
    "plz": "67817",
    "partner": [
      8
    ]
  },
  {
    "plz": "67819",
    "partner": [
      8
    ]
  },
  {
    "plz": "67821",
    "partner": [
      8
    ]
  },
  {
    "plz": "67821",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67822",
    "partner": [
      8
    ]
  },
  {
    "plz": "67823",
    "partner": [
      8
    ]
  },
  {
    "plz": "67823",
    "partner": [
      8
    ]
  },
  {
    "plz": "67823",
    "partner": [
      8
    ]
  },
  {
    "plz": "67823",
    "partner": [
      8
    ]
  },
  {
    "plz": "67823",
    "partner": [
      8
    ]
  },
  {
    "plz": "67823",
    "partner": [
      8
    ]
  },
  {
    "plz": "67824",
    "partner": [
      8
    ]
  },
  {
    "plz": "67826",
    "partner": [
      8
    ]
  },
  {
    "plz": "67826",
    "partner": [
      8
    ]
  },
  {
    "plz": "67827",
    "partner": [
      8
    ]
  },
  {
    "plz": "67829",
    "partner": [
      8
    ]
  },
  {
    "plz": "67829",
    "partner": [
      8
    ]
  },
  {
    "plz": "67829",
    "partner": [
      8
    ]
  },
  {
    "plz": "68159",
    "partner": [
      8
    ]
  },
  {
    "plz": "68161",
    "partner": [
      8
    ]
  },
  {
    "plz": "68163",
    "partner": [
      8
    ]
  },
  {
    "plz": "68165",
    "partner": [
      8
    ]
  },
  {
    "plz": "68167",
    "partner": [
      8
    ]
  },
  {
    "plz": "68169",
    "partner": [
      8
    ]
  },
  {
    "plz": "68199",
    "partner": [
      8
    ]
  },
  {
    "plz": "68219",
    "partner": [
      8
    ]
  },
  {
    "plz": "68229",
    "partner": [
      8
    ]
  },
  {
    "plz": "68239",
    "partner": [
      8
    ]
  },
  {
    "plz": "68259",
    "partner": [
      8
    ]
  },
  {
    "plz": "68305",
    "partner": [
      8
    ]
  },
  {
    "plz": "68307",
    "partner": [
      8
    ]
  },
  {
    "plz": "68309",
    "partner": [
      8
    ]
  },
  {
    "plz": "68519",
    "partner": [
      8
    ]
  },
  {
    "plz": "68526",
    "partner": [
      8
    ]
  },
  {
    "plz": "68535",
    "partner": [
      8
    ]
  },
  {
    "plz": "68542",
    "partner": [
      8
    ]
  },
  {
    "plz": "68549",
    "partner": [
      8
    ]
  },
  {
    "plz": "68623",
    "partner": [
      8
    ]
  },
  {
    "plz": "68623",
    "partner": [
      8
    ]
  },
  {
    "plz": "68642",
    "partner": [
      8
    ]
  },
  {
    "plz": "68647",
    "partner": [
      8
    ]
  },
  {
    "plz": "68649",
    "partner": [
      8
    ]
  },
  {
    "plz": "68723",
    "partner": [
      8
    ]
  },
  {
    "plz": "68723",
    "partner": [
      8
    ]
  },
  {
    "plz": "68723",
    "partner": [
      8
    ]
  },
  {
    "plz": "68753",
    "partner": [
      8
    ]
  },
  {
    "plz": "68766",
    "partner": [
      8
    ]
  },
  {
    "plz": "68775",
    "partner": [
      8
    ]
  },
  {
    "plz": "68782",
    "partner": [
      8
    ]
  },
  {
    "plz": "68789",
    "partner": [
      8
    ]
  },
  {
    "plz": "68794",
    "partner": [
      8
    ]
  },
  {
    "plz": "68799",
    "partner": [
      8
    ]
  },
  {
    "plz": "68804",
    "partner": [
      8
    ]
  },
  {
    "plz": "68809",
    "partner": [
      8
    ]
  },
  {
    "plz": "69115",
    "partner": [
      8
    ]
  },
  {
    "plz": "69117",
    "partner": [
      8
    ]
  },
  {
    "plz": "69118",
    "partner": [
      8
    ]
  },
  {
    "plz": "69120",
    "partner": [
      8
    ]
  },
  {
    "plz": "69121",
    "partner": [
      8
    ]
  },
  {
    "plz": "69123",
    "partner": [
      8
    ]
  },
  {
    "plz": "69124",
    "partner": [
      8
    ]
  },
  {
    "plz": "69126",
    "partner": [
      8
    ]
  },
  {
    "plz": "69151",
    "partner": [
      8
    ]
  },
  {
    "plz": "69168",
    "partner": [
      8
    ]
  },
  {
    "plz": "69181",
    "partner": [
      8
    ]
  },
  {
    "plz": "69190",
    "partner": [
      8
    ]
  },
  {
    "plz": "69198",
    "partner": [
      8
    ]
  },
  {
    "plz": "69207",
    "partner": [
      8
    ]
  },
  {
    "plz": "69214",
    "partner": [
      8
    ]
  },
  {
    "plz": "69221",
    "partner": [
      8
    ]
  },
  {
    "plz": "69226",
    "partner": [
      8
    ]
  },
  {
    "plz": "69231",
    "partner": [
      8
    ]
  },
  {
    "plz": "69234",
    "partner": [
      8
    ]
  },
  {
    "plz": "69239",
    "partner": [
      8
    ]
  },
  {
    "plz": "69242",
    "partner": [
      8
    ]
  },
  {
    "plz": "69245",
    "partner": [
      8
    ]
  },
  {
    "plz": "69250",
    "partner": [
      8
    ]
  },
  {
    "plz": "69251",
    "partner": [
      8
    ]
  },
  {
    "plz": "69253",
    "partner": [
      8
    ]
  },
  {
    "plz": "69254",
    "partner": [
      8
    ]
  },
  {
    "plz": "69256",
    "partner": [
      8
    ]
  },
  {
    "plz": "69257",
    "partner": [
      8
    ]
  },
  {
    "plz": "69259",
    "partner": [
      8
    ]
  },
  {
    "plz": "69412",
    "partner": [
      8
    ]
  },
  {
    "plz": "69412",
    "partner": [
      8
    ]
  },
  {
    "plz": "69427",
    "partner": [
      8
    ]
  },
  {
    "plz": "69429",
    "partner": [
      8
    ]
  },
  {
    "plz": "69429",
    "partner": [
      8
    ]
  },
  {
    "plz": "69434",
    "partner": [
      8
    ]
  },
  {
    "plz": "69434",
    "partner": [
      8
    ]
  },
  {
    "plz": "69434",
    "partner": [
      8
    ]
  },
  {
    "plz": "69436",
    "partner": [
      8
    ]
  },
  {
    "plz": "69437",
    "partner": [
      8
    ]
  },
  {
    "plz": "69439",
    "partner": [
      8
    ]
  },
  {
    "plz": "69469",
    "partner": [
      8
    ]
  },
  {
    "plz": "69483",
    "partner": [
      8
    ]
  },
  {
    "plz": "69488",
    "partner": [
      8
    ]
  },
  {
    "plz": "69493",
    "partner": [
      8
    ]
  },
  {
    "plz": "69502",
    "partner": [
      8
    ]
  },
  {
    "plz": "69509",
    "partner": [
      8
    ]
  },
  {
    "plz": "69514",
    "partner": [
      8
    ]
  },
  {
    "plz": "69517",
    "partner": [
      8
    ]
  },
  {
    "plz": "69518",
    "partner": [
      8
    ]
  },
  {
    "plz": "70173",
    "partner": [
      8
    ]
  },
  {
    "plz": "70174",
    "partner": [
      8
    ]
  },
  {
    "plz": "70176",
    "partner": [
      8
    ]
  },
  {
    "plz": "70178",
    "partner": [
      8
    ]
  },
  {
    "plz": "70180",
    "partner": [
      8
    ]
  },
  {
    "plz": "70182",
    "partner": [
      8
    ]
  },
  {
    "plz": "70184",
    "partner": [
      8
    ]
  },
  {
    "plz": "70186",
    "partner": [
      8
    ]
  },
  {
    "plz": "70188",
    "partner": [
      8
    ]
  },
  {
    "plz": "70190",
    "partner": [
      8
    ]
  },
  {
    "plz": "70191",
    "partner": [
      8
    ]
  },
  {
    "plz": "70192",
    "partner": [
      8
    ]
  },
  {
    "plz": "70193",
    "partner": [
      8
    ]
  },
  {
    "plz": "70195",
    "partner": [
      8
    ]
  },
  {
    "plz": "70197",
    "partner": [
      8
    ]
  },
  {
    "plz": "70199",
    "partner": [
      8
    ]
  },
  {
    "plz": "70327",
    "partner": [
      8
    ]
  },
  {
    "plz": "70329",
    "partner": [
      8
    ]
  },
  {
    "plz": "70372",
    "partner": [
      8
    ]
  },
  {
    "plz": "70374",
    "partner": [
      8
    ]
  },
  {
    "plz": "70376",
    "partner": [
      8
    ]
  },
  {
    "plz": "70378",
    "partner": [
      8
    ]
  },
  {
    "plz": "70378",
    "partner": [
      8
    ]
  },
  {
    "plz": "70435",
    "partner": [
      8
    ]
  },
  {
    "plz": "70437",
    "partner": [
      8
    ]
  },
  {
    "plz": "70439",
    "partner": [
      8
    ]
  },
  {
    "plz": "70469",
    "partner": [
      8
    ]
  },
  {
    "plz": "70499",
    "partner": [
      8
    ]
  },
  {
    "plz": "70563",
    "partner": [
      8
    ]
  },
  {
    "plz": "70565",
    "partner": [
      8
    ]
  },
  {
    "plz": "70567",
    "partner": [
      8
    ]
  },
  {
    "plz": "70569",
    "partner": [
      8
    ]
  },
  {
    "plz": "70597",
    "partner": [
      8
    ]
  },
  {
    "plz": "70599",
    "partner": [
      8
    ]
  },
  {
    "plz": "70619",
    "partner": [
      8
    ]
  },
  {
    "plz": "70629",
    "partner": [
      8
    ]
  },
  {
    "plz": "70734",
    "partner": [
      8
    ]
  },
  {
    "plz": "70736",
    "partner": [
      8
    ]
  },
  {
    "plz": "70771",
    "partner": [
      8
    ]
  },
  {
    "plz": "70794",
    "partner": [
      8
    ]
  },
  {
    "plz": "70806",
    "partner": [
      8
    ]
  },
  {
    "plz": "70825",
    "partner": [
      8
    ]
  },
  {
    "plz": "70839",
    "partner": [
      8
    ]
  },
  {
    "plz": "71032",
    "partner": [
      8
    ]
  },
  {
    "plz": "71034",
    "partner": [
      8
    ]
  },
  {
    "plz": "71063",
    "partner": [
      8
    ]
  },
  {
    "plz": "71065",
    "partner": [
      8
    ]
  },
  {
    "plz": "71067",
    "partner": [
      8
    ]
  },
  {
    "plz": "71069",
    "partner": [
      8
    ]
  },
  {
    "plz": "71083",
    "partner": [
      8
    ]
  },
  {
    "plz": "71088",
    "partner": [
      8
    ]
  },
  {
    "plz": "71093",
    "partner": [
      8
    ]
  },
  {
    "plz": "71101",
    "partner": [
      8
    ]
  },
  {
    "plz": "71106",
    "partner": [
      8
    ]
  },
  {
    "plz": "71111",
    "partner": [
      8
    ]
  },
  {
    "plz": "71111",
    "partner": [
      8
    ]
  },
  {
    "plz": "71111",
    "partner": [
      8
    ]
  },
  {
    "plz": "71111",
    "partner": [
      8
    ]
  },
  {
    "plz": "71111",
    "partner": [
      8
    ]
  },
  {
    "plz": "71111",
    "partner": [
      8
    ]
  },
  {
    "plz": "71116",
    "partner": [
      8
    ]
  },
  {
    "plz": "71120",
    "partner": [
      8
    ]
  },
  {
    "plz": "71126",
    "partner": [
      8
    ]
  },
  {
    "plz": "71131",
    "partner": [
      8
    ]
  },
  {
    "plz": "71134",
    "partner": [
      8
    ]
  },
  {
    "plz": "71139",
    "partner": [
      8
    ]
  },
  {
    "plz": "71144",
    "partner": [
      8
    ]
  },
  {
    "plz": "71144",
    "partner": [
      8
    ]
  },
  {
    "plz": "71144",
    "partner": [
      8
    ]
  },
  {
    "plz": "71144",
    "partner": [
      8
    ]
  },
  {
    "plz": "71149",
    "partner": [
      8
    ]
  },
  {
    "plz": "71154",
    "partner": [
      8
    ]
  },
  {
    "plz": "71155",
    "partner": [
      8
    ]
  },
  {
    "plz": "71157",
    "partner": [
      8
    ]
  },
  {
    "plz": "71159",
    "partner": [
      8
    ]
  },
  {
    "plz": "71229",
    "partner": [
      8
    ]
  },
  {
    "plz": "71254",
    "partner": [
      8
    ]
  },
  {
    "plz": "71263",
    "partner": [
      8
    ]
  },
  {
    "plz": "71272",
    "partner": [
      8
    ]
  },
  {
    "plz": "71272",
    "partner": [
      8
    ]
  },
  {
    "plz": "71277",
    "partner": [
      8
    ]
  },
  {
    "plz": "71282",
    "partner": [
      8
    ]
  },
  {
    "plz": "71287",
    "partner": [
      8
    ]
  },
  {
    "plz": "71292",
    "partner": [
      8
    ]
  },
  {
    "plz": "71296",
    "partner": [
      8
    ]
  },
  {
    "plz": "71297",
    "partner": [
      8
    ]
  },
  {
    "plz": "71299",
    "partner": [
      8
    ]
  },
  {
    "plz": "71332",
    "partner": [
      8
    ]
  },
  {
    "plz": "71334",
    "partner": [
      8
    ]
  },
  {
    "plz": "71336",
    "partner": [
      8
    ]
  },
  {
    "plz": "71364",
    "partner": [
      8
    ]
  },
  {
    "plz": "71364",
    "partner": [
      8
    ]
  },
  {
    "plz": "71364",
    "partner": [
      8
    ]
  },
  {
    "plz": "71384",
    "partner": [
      8
    ]
  },
  {
    "plz": "71394",
    "partner": [
      8
    ]
  },
  {
    "plz": "71397",
    "partner": [
      8
    ]
  },
  {
    "plz": "71404",
    "partner": [
      8
    ]
  },
  {
    "plz": "71409",
    "partner": [
      8
    ]
  },
  {
    "plz": "71522",
    "partner": [
      8
    ]
  },
  {
    "plz": "71540",
    "partner": [
      8
    ]
  },
  {
    "plz": "71540",
    "partner": [
      8
    ]
  },
  {
    "plz": "71543",
    "partner": [
      8
    ]
  },
  {
    "plz": "71543",
    "partner": [
      8
    ]
  },
  {
    "plz": "71546",
    "partner": [
      8
    ]
  },
  {
    "plz": "71549",
    "partner": [
      8
    ]
  },
  {
    "plz": "71549",
    "partner": [
      8
    ]
  },
  {
    "plz": "71554",
    "partner": [
      8
    ]
  },
  {
    "plz": "71560",
    "partner": [
      8
    ]
  },
  {
    "plz": "71560",
    "partner": [
      8
    ]
  },
  {
    "plz": "71560",
    "partner": [
      8
    ]
  },
  {
    "plz": "71560",
    "partner": [
      8
    ]
  },
  {
    "plz": "71563",
    "partner": [
      8
    ]
  },
  {
    "plz": "71566",
    "partner": [
      8
    ]
  },
  {
    "plz": "71570",
    "partner": [
      8
    ]
  },
  {
    "plz": "71570",
    "partner": [
      8
    ]
  },
  {
    "plz": "71573",
    "partner": [
      8
    ]
  },
  {
    "plz": "71576",
    "partner": [
      8
    ]
  },
  {
    "plz": "71577",
    "partner": [
      8
    ]
  },
  {
    "plz": "71577",
    "partner": [
      8
    ]
  },
  {
    "plz": "71579",
    "partner": [
      8
    ]
  },
  {
    "plz": "71579",
    "partner": [
      8
    ]
  },
  {
    "plz": "71634",
    "partner": [
      8
    ]
  },
  {
    "plz": "71636",
    "partner": [
      8
    ]
  },
  {
    "plz": "71638",
    "partner": [
      8
    ]
  },
  {
    "plz": "71640",
    "partner": [
      8
    ]
  },
  {
    "plz": "71642",
    "partner": [
      8
    ]
  },
  {
    "plz": "71665",
    "partner": [
      8
    ]
  },
  {
    "plz": "71672",
    "partner": [
      8
    ]
  },
  {
    "plz": "71672",
    "partner": [
      8
    ]
  },
  {
    "plz": "71679",
    "partner": [
      8
    ]
  },
  {
    "plz": "71686",
    "partner": [
      8
    ]
  },
  {
    "plz": "71691",
    "partner": [
      8
    ]
  },
  {
    "plz": "71696",
    "partner": [
      8
    ]
  },
  {
    "plz": "71701",
    "partner": [
      8
    ]
  },
  {
    "plz": "71706",
    "partner": [
      8
    ]
  },
  {
    "plz": "71706",
    "partner": [
      8
    ]
  },
  {
    "plz": "71711",
    "partner": [
      8
    ]
  },
  {
    "plz": "71711",
    "partner": [
      8
    ]
  },
  {
    "plz": "71711",
    "partner": [
      8
    ]
  },
  {
    "plz": "71717",
    "partner": [
      8
    ]
  },
  {
    "plz": "71720",
    "partner": [
      8
    ]
  },
  {
    "plz": "71720",
    "partner": [
      8
    ]
  },
  {
    "plz": "71720",
    "partner": [
      8
    ]
  },
  {
    "plz": "71723",
    "partner": [
      8
    ]
  },
  {
    "plz": "71726",
    "partner": [
      8
    ]
  },
  {
    "plz": "71729",
    "partner": [
      8
    ]
  },
  {
    "plz": "71729",
    "partner": [
      8
    ]
  },
  {
    "plz": "71732",
    "partner": [
      8
    ]
  },
  {
    "plz": "71735",
    "partner": [
      8
    ]
  },
  {
    "plz": "71737",
    "partner": [
      8
    ]
  },
  {
    "plz": "71739",
    "partner": [
      8
    ]
  },
  {
    "plz": "72070",
    "partner": [
      8
    ]
  },
  {
    "plz": "72070",
    "partner": [
      8
    ]
  },
  {
    "plz": "72072",
    "partner": [
      8
    ]
  },
  {
    "plz": "72074",
    "partner": [
      8
    ]
  },
  {
    "plz": "72076",
    "partner": [
      8
    ]
  },
  {
    "plz": "72108",
    "partner": [
      8
    ]
  },
  {
    "plz": "72116",
    "partner": [
      8
    ]
  },
  {
    "plz": "72119",
    "partner": [
      8
    ]
  },
  {
    "plz": "72124",
    "partner": [
      8
    ]
  },
  {
    "plz": "72127",
    "partner": [
      8
    ]
  },
  {
    "plz": "72131",
    "partner": [
      8
    ]
  },
  {
    "plz": "72135",
    "partner": [
      8
    ]
  },
  {
    "plz": "72138",
    "partner": [
      8
    ]
  },
  {
    "plz": "72138",
    "partner": [
      8
    ]
  },
  {
    "plz": "72141",
    "partner": [
      8
    ]
  },
  {
    "plz": "72144",
    "partner": [
      8
    ]
  },
  {
    "plz": "72145",
    "partner": [
      8
    ]
  },
  {
    "plz": "72147",
    "partner": [
      8
    ]
  },
  {
    "plz": "72149",
    "partner": [
      8
    ]
  },
  {
    "plz": "72160",
    "partner": [
      8
    ]
  },
  {
    "plz": "72160",
    "partner": [
      8
    ]
  },
  {
    "plz": "72160",
    "partner": [
      8
    ]
  },
  {
    "plz": "72172",
    "partner": [
      8
    ]
  },
  {
    "plz": "72175",
    "partner": [
      8
    ]
  },
  {
    "plz": "72178",
    "partner": [
      8
    ]
  },
  {
    "plz": "72181",
    "partner": [
      8
    ]
  },
  {
    "plz": "72184",
    "partner": [
      8
    ]
  },
  {
    "plz": "72186",
    "partner": [
      8
    ]
  },
  {
    "plz": "72186",
    "partner": [
      8
    ]
  },
  {
    "plz": "72189",
    "partner": [
      8
    ]
  },
  {
    "plz": "72202",
    "partner": [
      8
    ]
  },
  {
    "plz": "72213",
    "partner": [
      8
    ]
  },
  {
    "plz": "72218",
    "partner": [
      8
    ]
  },
  {
    "plz": "72221",
    "partner": [
      8
    ]
  },
  {
    "plz": "72224",
    "partner": [
      8
    ]
  },
  {
    "plz": "72226",
    "partner": [
      8
    ]
  },
  {
    "plz": "72227",
    "partner": [
      8
    ]
  },
  {
    "plz": "72229",
    "partner": [
      8
    ]
  },
  {
    "plz": "72250",
    "partner": [
      8
    ]
  },
  {
    "plz": "72250",
    "partner": [
      8
    ]
  },
  {
    "plz": "72270",
    "partner": [
      8
    ]
  },
  {
    "plz": "72275",
    "partner": [
      8
    ]
  },
  {
    "plz": "72280",
    "partner": [
      8
    ]
  },
  {
    "plz": "72285",
    "partner": [
      8
    ]
  },
  {
    "plz": "72290",
    "partner": [
      8
    ]
  },
  {
    "plz": "72293",
    "partner": [
      8
    ]
  },
  {
    "plz": "72294",
    "partner": [
      8
    ]
  },
  {
    "plz": "72296",
    "partner": [
      8
    ]
  },
  {
    "plz": "72297",
    "partner": [
      8
    ]
  },
  {
    "plz": "72297",
    "partner": [
      8
    ]
  },
  {
    "plz": "72297",
    "partner": [
      8
    ]
  },
  {
    "plz": "72299",
    "partner": [
      8
    ]
  },
  {
    "plz": "72336",
    "partner": [
      8
    ]
  },
  {
    "plz": "72348",
    "partner": [
      8
    ]
  },
  {
    "plz": "72351",
    "partner": [
      8
    ]
  },
  {
    "plz": "72355",
    "partner": [
      8
    ]
  },
  {
    "plz": "72356",
    "partner": [
      8
    ]
  },
  {
    "plz": "72358",
    "partner": [
      8
    ]
  },
  {
    "plz": "72359",
    "partner": [
      8
    ]
  },
  {
    "plz": "72361",
    "partner": [
      8
    ]
  },
  {
    "plz": "72362",
    "partner": [
      8
    ]
  },
  {
    "plz": "72364",
    "partner": [
      8
    ]
  },
  {
    "plz": "72365",
    "partner": [
      8
    ]
  },
  {
    "plz": "72367",
    "partner": [
      8
    ]
  },
  {
    "plz": "72369",
    "partner": [
      8
    ]
  },
  {
    "plz": "72379",
    "partner": [
      8
    ]
  },
  {
    "plz": "72379",
    "partner": [
      8
    ]
  },
  {
    "plz": "72393",
    "partner": [
      8
    ]
  },
  {
    "plz": "72401",
    "partner": [
      8
    ]
  },
  {
    "plz": "72406",
    "partner": [
      8
    ]
  },
  {
    "plz": "72411",
    "partner": [
      8
    ]
  },
  {
    "plz": "72414",
    "partner": [
      8
    ]
  },
  {
    "plz": "72415",
    "partner": [
      8
    ]
  },
  {
    "plz": "72417",
    "partner": [
      8
    ]
  },
  {
    "plz": "72419",
    "partner": [
      8
    ]
  },
  {
    "plz": "72419",
    "partner": [
      8
    ]
  },
  {
    "plz": "72458",
    "partner": [
      8
    ]
  },
  {
    "plz": "72458",
    "partner": [
      8
    ]
  },
  {
    "plz": "72459",
    "partner": [
      8
    ]
  },
  {
    "plz": "72461",
    "partner": [
      8
    ]
  },
  {
    "plz": "72469",
    "partner": [
      8
    ]
  },
  {
    "plz": "72474",
    "partner": [
      8
    ]
  },
  {
    "plz": "72475",
    "partner": [
      8
    ]
  },
  {
    "plz": "72477",
    "partner": [
      8
    ]
  },
  {
    "plz": "72479",
    "partner": [
      8
    ]
  },
  {
    "plz": "72488",
    "partner": [
      8
    ]
  },
  {
    "plz": "72501",
    "partner": [
      8
    ]
  },
  {
    "plz": "72501",
    "partner": [
      8
    ]
  },
  {
    "plz": "72505",
    "partner": [
      8
    ]
  },
  {
    "plz": "72510",
    "partner": [
      8
    ]
  },
  {
    "plz": "72511",
    "partner": [
      8
    ]
  },
  {
    "plz": "72513",
    "partner": [
      8
    ]
  },
  {
    "plz": "72514",
    "partner": [
      8
    ]
  },
  {
    "plz": "72516",
    "partner": [
      8
    ]
  },
  {
    "plz": "72517",
    "partner": [
      8
    ]
  },
  {
    "plz": "72519",
    "partner": [
      8
    ]
  },
  {
    "plz": "72525",
    "partner": [
      8
    ]
  },
  {
    "plz": "72531",
    "partner": [
      8
    ]
  },
  {
    "plz": "72532",
    "partner": [
      8
    ]
  },
  {
    "plz": "72534",
    "partner": [
      8
    ]
  },
  {
    "plz": "72535",
    "partner": [
      8
    ]
  },
  {
    "plz": "72537",
    "partner": [
      8
    ]
  },
  {
    "plz": "72539",
    "partner": [
      8
    ]
  },
  {
    "plz": "72555",
    "partner": [
      8
    ]
  },
  {
    "plz": "72574",
    "partner": [
      8
    ]
  },
  {
    "plz": "72581",
    "partner": [
      8
    ]
  },
  {
    "plz": "72582",
    "partner": [
      8
    ]
  },
  {
    "plz": "72584",
    "partner": [
      8
    ]
  },
  {
    "plz": "72585",
    "partner": [
      8
    ]
  },
  {
    "plz": "72587",
    "partner": [
      8
    ]
  },
  {
    "plz": "72589",
    "partner": [
      8
    ]
  },
  {
    "plz": "72622",
    "partner": [
      8
    ]
  },
  {
    "plz": "72631",
    "partner": [
      8
    ]
  },
  {
    "plz": "72636",
    "partner": [
      8
    ]
  },
  {
    "plz": "72639",
    "partner": [
      8
    ]
  },
  {
    "plz": "72644",
    "partner": [
      8
    ]
  },
  {
    "plz": "72649",
    "partner": [
      8
    ]
  },
  {
    "plz": "72654",
    "partner": [
      8
    ]
  },
  {
    "plz": "72655",
    "partner": [
      8
    ]
  },
  {
    "plz": "72657",
    "partner": [
      8
    ]
  },
  {
    "plz": "72658",
    "partner": [
      8
    ]
  },
  {
    "plz": "72660",
    "partner": [
      8
    ]
  },
  {
    "plz": "72661",
    "partner": [
      8
    ]
  },
  {
    "plz": "72663",
    "partner": [
      8
    ]
  },
  {
    "plz": "72664",
    "partner": [
      8
    ]
  },
  {
    "plz": "72666",
    "partner": [
      8
    ]
  },
  {
    "plz": "72667",
    "partner": [
      8
    ]
  },
  {
    "plz": "72669",
    "partner": [
      8
    ]
  },
  {
    "plz": "72760",
    "partner": [
      8
    ]
  },
  {
    "plz": "72762",
    "partner": [
      8
    ]
  },
  {
    "plz": "72764",
    "partner": [
      8
    ]
  },
  {
    "plz": "72766",
    "partner": [
      8
    ]
  },
  {
    "plz": "72768",
    "partner": [
      8
    ]
  },
  {
    "plz": "72770",
    "partner": [
      8
    ]
  },
  {
    "plz": "72793",
    "partner": [
      8
    ]
  },
  {
    "plz": "72800",
    "partner": [
      8
    ]
  },
  {
    "plz": "72805",
    "partner": [
      8
    ]
  },
  {
    "plz": "72810",
    "partner": [
      8
    ]
  },
  {
    "plz": "72813",
    "partner": [
      8
    ]
  },
  {
    "plz": "72813",
    "partner": [
      8
    ]
  },
  {
    "plz": "72818",
    "partner": [
      8
    ]
  },
  {
    "plz": "72820",
    "partner": [
      8
    ]
  },
  {
    "plz": "72827",
    "partner": [
      8
    ]
  },
  {
    "plz": "72829",
    "partner": [
      8
    ]
  },
  {
    "plz": "73033",
    "partner": [
      8
    ]
  },
  {
    "plz": "73035",
    "partner": [
      8
    ]
  },
  {
    "plz": "73037",
    "partner": [
      8
    ]
  },
  {
    "plz": "73037",
    "partner": [
      8
    ]
  },
  {
    "plz": "73037",
    "partner": [
      8
    ]
  },
  {
    "plz": "73054",
    "partner": [
      8
    ]
  },
  {
    "plz": "73054",
    "partner": [
      8
    ]
  },
  {
    "plz": "73061",
    "partner": [
      8
    ]
  },
  {
    "plz": "73066",
    "partner": [
      8
    ]
  },
  {
    "plz": "73072",
    "partner": [
      8
    ]
  },
  {
    "plz": "73072",
    "partner": [
      8
    ]
  },
  {
    "plz": "73072",
    "partner": [
      8
    ]
  },
  {
    "plz": "73072",
    "partner": [
      8
    ]
  },
  {
    "plz": "73079",
    "partner": [
      8
    ]
  },
  {
    "plz": "73079",
    "partner": [
      8
    ]
  },
  {
    "plz": "73084",
    "partner": [
      8
    ]
  },
  {
    "plz": "73087",
    "partner": [
      8
    ]
  },
  {
    "plz": "73087",
    "partner": [
      8
    ]
  },
  {
    "plz": "73092",
    "partner": [
      8
    ]
  },
  {
    "plz": "73095",
    "partner": [
      8
    ]
  },
  {
    "plz": "73098",
    "partner": [
      8
    ]
  },
  {
    "plz": "73099",
    "partner": [
      8
    ]
  },
  {
    "plz": "73101",
    "partner": [
      8
    ]
  },
  {
    "plz": "73102",
    "partner": [
      8
    ]
  },
  {
    "plz": "73104",
    "partner": [
      8
    ]
  },
  {
    "plz": "73105",
    "partner": [
      8
    ]
  },
  {
    "plz": "73107",
    "partner": [
      8
    ]
  },
  {
    "plz": "73107",
    "partner": [
      8
    ]
  },
  {
    "plz": "73107",
    "partner": [
      8
    ]
  },
  {
    "plz": "73108",
    "partner": [
      8
    ]
  },
  {
    "plz": "73110",
    "partner": [
      8
    ]
  },
  {
    "plz": "73111",
    "partner": [
      8
    ]
  },
  {
    "plz": "73113",
    "partner": [
      8
    ]
  },
  {
    "plz": "73114",
    "partner": [
      8
    ]
  },
  {
    "plz": "73116",
    "partner": [
      8
    ]
  },
  {
    "plz": "73116",
    "partner": [
      8
    ]
  },
  {
    "plz": "73116",
    "partner": [
      8
    ]
  },
  {
    "plz": "73117",
    "partner": [
      8
    ]
  },
  {
    "plz": "73119",
    "partner": [
      8
    ]
  },
  {
    "plz": "73207",
    "partner": [
      8
    ]
  },
  {
    "plz": "73230",
    "partner": [
      8
    ]
  },
  {
    "plz": "73235",
    "partner": [
      8
    ]
  },
  {
    "plz": "73235",
    "partner": [
      8
    ]
  },
  {
    "plz": "73240",
    "partner": [
      8
    ]
  },
  {
    "plz": "73249",
    "partner": [
      8
    ]
  },
  {
    "plz": "73249",
    "partner": [
      8
    ]
  },
  {
    "plz": "73249",
    "partner": [
      8
    ]
  },
  {
    "plz": "73252",
    "partner": [
      8
    ]
  },
  {
    "plz": "73257",
    "partner": [
      8
    ]
  },
  {
    "plz": "73262",
    "partner": [
      8
    ]
  },
  {
    "plz": "73265",
    "partner": [
      8
    ]
  },
  {
    "plz": "73266",
    "partner": [
      8
    ]
  },
  {
    "plz": "73266",
    "partner": [
      8
    ]
  },
  {
    "plz": "73266",
    "partner": [
      8
    ]
  },
  {
    "plz": "73266",
    "partner": [
      8
    ]
  },
  {
    "plz": "73268",
    "partner": [
      8
    ]
  },
  {
    "plz": "73269",
    "partner": [
      8
    ]
  },
  {
    "plz": "73271",
    "partner": [
      8
    ]
  },
  {
    "plz": "73272",
    "partner": [
      8
    ]
  },
  {
    "plz": "73274",
    "partner": [
      8
    ]
  },
  {
    "plz": "73275",
    "partner": [
      8
    ]
  },
  {
    "plz": "73277",
    "partner": [
      8
    ]
  },
  {
    "plz": "73278",
    "partner": [
      8
    ]
  },
  {
    "plz": "73312",
    "partner": [
      8
    ]
  },
  {
    "plz": "73312",
    "partner": [
      8
    ]
  },
  {
    "plz": "73312",
    "partner": [
      8
    ]
  },
  {
    "plz": "73312",
    "partner": [
      8
    ]
  },
  {
    "plz": "73326",
    "partner": [
      8
    ]
  },
  {
    "plz": "73329",
    "partner": [
      8
    ]
  },
  {
    "plz": "73333",
    "partner": [
      8
    ]
  },
  {
    "plz": "73337",
    "partner": [
      8
    ]
  },
  {
    "plz": "73340",
    "partner": [
      8
    ]
  },
  {
    "plz": "73342",
    "partner": [
      8
    ]
  },
  {
    "plz": "73344",
    "partner": [
      8
    ]
  },
  {
    "plz": "73345",
    "partner": [
      8
    ]
  },
  {
    "plz": "73345",
    "partner": [
      8
    ]
  },
  {
    "plz": "73345",
    "partner": [
      8
    ]
  },
  {
    "plz": "73345",
    "partner": [
      8
    ]
  },
  {
    "plz": "73347",
    "partner": [
      8
    ]
  },
  {
    "plz": "73349",
    "partner": [
      8
    ]
  },
  {
    "plz": "73349",
    "partner": [
      8
    ]
  },
  {
    "plz": "73349",
    "partner": [
      8
    ]
  },
  {
    "plz": "73349",
    "partner": [
      8
    ]
  },
  {
    "plz": "73430",
    "partner": [
      8
    ]
  },
  {
    "plz": "73431",
    "partner": [
      8
    ]
  },
  {
    "plz": "73432",
    "partner": [
      8
    ]
  },
  {
    "plz": "73433",
    "partner": [
      8
    ]
  },
  {
    "plz": "73434",
    "partner": [
      8
    ]
  },
  {
    "plz": "73434",
    "partner": [
      8
    ]
  },
  {
    "plz": "73441",
    "partner": [
      8
    ]
  },
  {
    "plz": "73441",
    "partner": [
      8
    ]
  },
  {
    "plz": "73441",
    "partner": [
      8
    ]
  },
  {
    "plz": "73441",
    "partner": [
      8
    ]
  },
  {
    "plz": "73447",
    "partner": [
      8
    ]
  },
  {
    "plz": "73450",
    "partner": [
      8
    ]
  },
  {
    "plz": "73450",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73453",
    "partner": [
      8
    ]
  },
  {
    "plz": "73457",
    "partner": [
      8
    ]
  },
  {
    "plz": "73460",
    "partner": [
      8
    ]
  },
  {
    "plz": "73463",
    "partner": [
      8
    ]
  },
  {
    "plz": "73466",
    "partner": [
      8
    ]
  },
  {
    "plz": "73467",
    "partner": [
      8
    ]
  },
  {
    "plz": "73469",
    "partner": [
      8
    ]
  },
  {
    "plz": "73479",
    "partner": [
      8
    ]
  },
  {
    "plz": "73479",
    "partner": [
      8
    ]
  },
  {
    "plz": "73485",
    "partner": [
      8
    ]
  },
  {
    "plz": "73485",
    "partner": [
      8
    ]
  },
  {
    "plz": "73486",
    "partner": [
      8
    ]
  },
  {
    "plz": "73488",
    "partner": [
      8
    ]
  },
  {
    "plz": "73489",
    "partner": [
      8
    ]
  },
  {
    "plz": "73489",
    "partner": [
      8
    ]
  },
  {
    "plz": "73489",
    "partner": [
      8
    ]
  },
  {
    "plz": "73491",
    "partner": [
      8
    ]
  },
  {
    "plz": "73492",
    "partner": [
      8
    ]
  },
  {
    "plz": "73494",
    "partner": [
      8
    ]
  },
  {
    "plz": "73495",
    "partner": [
      8
    ]
  },
  {
    "plz": "73497",
    "partner": [
      8
    ]
  },
  {
    "plz": "73499",
    "partner": [
      8
    ]
  },
  {
    "plz": "73525",
    "partner": [
      8
    ]
  },
  {
    "plz": "73527",
    "partner": [
      8
    ]
  },
  {
    "plz": "73527",
    "partner": [
      8
    ]
  },
  {
    "plz": "73529",
    "partner": [
      8
    ]
  },
  {
    "plz": "73529",
    "partner": [
      8
    ]
  },
  {
    "plz": "73529",
    "partner": [
      8
    ]
  },
  {
    "plz": "73529",
    "partner": [
      8
    ]
  },
  {
    "plz": "73540",
    "partner": [
      8
    ]
  },
  {
    "plz": "73547",
    "partner": [
      8
    ]
  },
  {
    "plz": "73547",
    "partner": [
      8
    ]
  },
  {
    "plz": "73550",
    "partner": [
      8
    ]
  },
  {
    "plz": "73550",
    "partner": [
      8
    ]
  },
  {
    "plz": "73553",
    "partner": [
      8
    ]
  },
  {
    "plz": "73553",
    "partner": [
      8
    ]
  },
  {
    "plz": "73553",
    "partner": [
      8
    ]
  },
  {
    "plz": "73553",
    "partner": [
      8
    ]
  },
  {
    "plz": "73553",
    "partner": [
      8
    ]
  },
  {
    "plz": "73553",
    "partner": [
      8
    ]
  },
  {
    "plz": "73557",
    "partner": [
      8
    ]
  },
  {
    "plz": "73560",
    "partner": [
      8
    ]
  },
  {
    "plz": "73563",
    "partner": [
      8
    ]
  },
  {
    "plz": "73563",
    "partner": [
      8
    ]
  },
  {
    "plz": "73565",
    "partner": [
      8
    ]
  },
  {
    "plz": "73565",
    "partner": [
      8
    ]
  },
  {
    "plz": "73565",
    "partner": [
      8
    ]
  },
  {
    "plz": "73566",
    "partner": [
      8
    ]
  },
  {
    "plz": "73566",
    "partner": [
      8
    ]
  },
  {
    "plz": "73566",
    "partner": [
      8
    ]
  },
  {
    "plz": "73566",
    "partner": [
      8
    ]
  },
  {
    "plz": "73568",
    "partner": [
      8
    ]
  },
  {
    "plz": "73568",
    "partner": [
      8
    ]
  },
  {
    "plz": "73568",
    "partner": [
      8
    ]
  },
  {
    "plz": "73569",
    "partner": [
      8
    ]
  },
  {
    "plz": "73569",
    "partner": [
      8
    ]
  },
  {
    "plz": "73569",
    "partner": [
      8
    ]
  },
  {
    "plz": "73569",
    "partner": [
      8
    ]
  },
  {
    "plz": "73569",
    "partner": [
      8
    ]
  },
  {
    "plz": "73571",
    "partner": [
      8
    ]
  },
  {
    "plz": "73572",
    "partner": [
      8
    ]
  },
  {
    "plz": "73574",
    "partner": [
      8
    ]
  },
  {
    "plz": "73574",
    "partner": [
      8
    ]
  },
  {
    "plz": "73574",
    "partner": [
      8
    ]
  },
  {
    "plz": "73575",
    "partner": [
      8
    ]
  },
  {
    "plz": "73577",
    "partner": [
      8
    ]
  },
  {
    "plz": "73577",
    "partner": [
      8
    ]
  },
  {
    "plz": "73577",
    "partner": [
      8
    ]
  },
  {
    "plz": "73579",
    "partner": [
      8
    ]
  },
  {
    "plz": "73614",
    "partner": [
      8
    ]
  },
  {
    "plz": "73614",
    "partner": [
      8
    ]
  },
  {
    "plz": "73630",
    "partner": [
      8
    ]
  },
  {
    "plz": "73635",
    "partner": [
      8
    ]
  },
  {
    "plz": "73635",
    "partner": [
      8
    ]
  },
  {
    "plz": "73642",
    "partner": [
      8
    ]
  },
  {
    "plz": "73642",
    "partner": [
      8
    ]
  },
  {
    "plz": "73642",
    "partner": [
      8
    ]
  },
  {
    "plz": "73642",
    "partner": [
      8
    ]
  },
  {
    "plz": "73642",
    "partner": [
      8
    ]
  },
  {
    "plz": "73650",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73655",
    "partner": [
      8
    ]
  },
  {
    "plz": "73660",
    "partner": [
      8
    ]
  },
  {
    "plz": "73663",
    "partner": [
      8
    ]
  },
  {
    "plz": "73666",
    "partner": [
      8
    ]
  },
  {
    "plz": "73667",
    "partner": [
      8
    ]
  },
  {
    "plz": "73667",
    "partner": [
      8
    ]
  },
  {
    "plz": "73669",
    "partner": [
      8
    ]
  },
  {
    "plz": "73728",
    "partner": [
      8
    ]
  },
  {
    "plz": "73730",
    "partner": [
      8
    ]
  },
  {
    "plz": "73732",
    "partner": [
      8
    ]
  },
  {
    "plz": "73733",
    "partner": [
      8
    ]
  },
  {
    "plz": "73734",
    "partner": [
      8
    ]
  },
  {
    "plz": "73760",
    "partner": [
      8
    ]
  },
  {
    "plz": "73765",
    "partner": [
      8
    ]
  },
  {
    "plz": "73770",
    "partner": [
      8
    ]
  },
  {
    "plz": "73773",
    "partner": [
      8
    ]
  },
  {
    "plz": "73776",
    "partner": [
      8
    ]
  },
  {
    "plz": "73779",
    "partner": [
      8
    ]
  },
  {
    "plz": "74072",
    "partner": [
      8
    ]
  },
  {
    "plz": "74074",
    "partner": [
      8
    ]
  },
  {
    "plz": "74076",
    "partner": [
      8
    ]
  },
  {
    "plz": "74078",
    "partner": [
      8
    ]
  },
  {
    "plz": "74080",
    "partner": [
      8
    ]
  },
  {
    "plz": "74081",
    "partner": [
      8
    ]
  },
  {
    "plz": "74172",
    "partner": [
      8
    ]
  },
  {
    "plz": "74177",
    "partner": [
      8
    ]
  },
  {
    "plz": "74182",
    "partner": [
      8
    ]
  },
  {
    "plz": "74189",
    "partner": [
      8
    ]
  },
  {
    "plz": "74193",
    "partner": [
      8
    ]
  },
  {
    "plz": "74196",
    "partner": [
      8
    ]
  },
  {
    "plz": "74196",
    "partner": [
      8
    ]
  },
  {
    "plz": "74199",
    "partner": [
      8
    ]
  },
  {
    "plz": "74206",
    "partner": [
      8
    ]
  },
  {
    "plz": "74211",
    "partner": [
      8
    ]
  },
  {
    "plz": "74214",
    "partner": [
      8
    ]
  },
  {
    "plz": "74219",
    "partner": [
      8
    ]
  },
  {
    "plz": "74223",
    "partner": [
      8
    ]
  },
  {
    "plz": "74226",
    "partner": [
      8
    ]
  },
  {
    "plz": "74229",
    "partner": [
      8
    ]
  },
  {
    "plz": "74232",
    "partner": [
      8
    ]
  },
  {
    "plz": "74235",
    "partner": [
      8
    ]
  },
  {
    "plz": "74238",
    "partner": [
      8
    ]
  },
  {
    "plz": "74239",
    "partner": [
      8
    ]
  },
  {
    "plz": "74243",
    "partner": [
      8
    ]
  },
  {
    "plz": "74245",
    "partner": [
      8
    ]
  },
  {
    "plz": "74246",
    "partner": [
      8
    ]
  },
  {
    "plz": "74248",
    "partner": [
      8
    ]
  },
  {
    "plz": "74249",
    "partner": [
      8
    ]
  },
  {
    "plz": "74249",
    "partner": [
      8
    ]
  },
  {
    "plz": "74249",
    "partner": [
      8
    ]
  },
  {
    "plz": "74249",
    "partner": [
      8
    ]
  },
  {
    "plz": "74251",
    "partner": [
      8
    ]
  },
  {
    "plz": "74252",
    "partner": [
      8
    ]
  },
  {
    "plz": "74254",
    "partner": [
      8
    ]
  },
  {
    "plz": "74255",
    "partner": [
      8
    ]
  },
  {
    "plz": "74257",
    "partner": [
      8
    ]
  },
  {
    "plz": "74259",
    "partner": [
      8
    ]
  },
  {
    "plz": "74321",
    "partner": [
      8
    ]
  },
  {
    "plz": "74336",
    "partner": [
      8
    ]
  },
  {
    "plz": "74343",
    "partner": [
      8
    ]
  },
  {
    "plz": "74348",
    "partner": [
      8
    ]
  },
  {
    "plz": "74354",
    "partner": [
      8
    ]
  },
  {
    "plz": "74357",
    "partner": [
      8
    ]
  },
  {
    "plz": "74357",
    "partner": [
      8
    ]
  },
  {
    "plz": "74360",
    "partner": [
      8
    ]
  },
  {
    "plz": "74360",
    "partner": [
      8
    ]
  },
  {
    "plz": "74363",
    "partner": [
      8
    ]
  },
  {
    "plz": "74366",
    "partner": [
      8
    ]
  },
  {
    "plz": "74369",
    "partner": [
      8
    ]
  },
  {
    "plz": "74372",
    "partner": [
      8
    ]
  },
  {
    "plz": "74374",
    "partner": [
      8
    ]
  },
  {
    "plz": "74376",
    "partner": [
      8
    ]
  },
  {
    "plz": "74379",
    "partner": [
      8
    ]
  },
  {
    "plz": "74382",
    "partner": [
      8
    ]
  },
  {
    "plz": "74385",
    "partner": [
      8
    ]
  },
  {
    "plz": "74388",
    "partner": [
      8
    ]
  },
  {
    "plz": "74389",
    "partner": [
      8
    ]
  },
  {
    "plz": "74391",
    "partner": [
      8
    ]
  },
  {
    "plz": "74392",
    "partner": [
      8
    ]
  },
  {
    "plz": "74394",
    "partner": [
      8
    ]
  },
  {
    "plz": "74395",
    "partner": [
      8
    ]
  },
  {
    "plz": "74397",
    "partner": [
      8
    ]
  },
  {
    "plz": "74399",
    "partner": [
      8
    ]
  },
  {
    "plz": "74405",
    "partner": [
      8
    ]
  },
  {
    "plz": "74417",
    "partner": [
      8
    ]
  },
  {
    "plz": "74420",
    "partner": [
      8
    ]
  },
  {
    "plz": "74423",
    "partner": [
      8
    ]
  },
  {
    "plz": "74424",
    "partner": [
      8
    ]
  },
  {
    "plz": "74426",
    "partner": [
      8
    ]
  },
  {
    "plz": "74427",
    "partner": [
      8
    ]
  },
  {
    "plz": "74429",
    "partner": [
      8
    ]
  },
  {
    "plz": "74523",
    "partner": [
      8
    ]
  },
  {
    "plz": "74523",
    "partner": [
      8
    ]
  },
  {
    "plz": "74523",
    "partner": [
      8
    ]
  },
  {
    "plz": "74523",
    "partner": [
      8
    ]
  },
  {
    "plz": "74523",
    "partner": [
      8
    ]
  },
  {
    "plz": "74523",
    "partner": [
      8
    ]
  },
  {
    "plz": "74532",
    "partner": [
      8
    ]
  },
  {
    "plz": "74532",
    "partner": [
      8
    ]
  },
  {
    "plz": "74535",
    "partner": [
      8
    ]
  },
  {
    "plz": "74538",
    "partner": [
      8
    ]
  },
  {
    "plz": "74541",
    "partner": [
      8
    ]
  },
  {
    "plz": "74542",
    "partner": [
      8
    ]
  },
  {
    "plz": "74544",
    "partner": [
      8
    ]
  },
  {
    "plz": "74545",
    "partner": [
      8
    ]
  },
  {
    "plz": "74545",
    "partner": [
      8
    ]
  },
  {
    "plz": "74547",
    "partner": [
      8
    ]
  },
  {
    "plz": "74549",
    "partner": [
      8
    ]
  },
  {
    "plz": "74564",
    "partner": [
      8
    ]
  },
  {
    "plz": "74564",
    "partner": [
      8
    ]
  },
  {
    "plz": "74564",
    "partner": [
      8
    ]
  },
  {
    "plz": "74564",
    "partner": [
      8
    ]
  },
  {
    "plz": "74572",
    "partner": [
      8
    ]
  },
  {
    "plz": "74575",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74579",
    "partner": [
      8
    ]
  },
  {
    "plz": "74582",
    "partner": [
      8
    ]
  },
  {
    "plz": "74585",
    "partner": [
      8
    ]
  },
  {
    "plz": "74585",
    "partner": [
      8
    ]
  },
  {
    "plz": "74586",
    "partner": [
      8
    ]
  },
  {
    "plz": "74589",
    "partner": [
      8
    ]
  },
  {
    "plz": "74592",
    "partner": [
      8
    ]
  },
  {
    "plz": "74594",
    "partner": [
      8
    ]
  },
  {
    "plz": "74594",
    "partner": [
      8
    ]
  },
  {
    "plz": "74595",
    "partner": [
      8
    ]
  },
  {
    "plz": "74597",
    "partner": [
      8
    ]
  },
  {
    "plz": "74597",
    "partner": [
      8
    ]
  },
  {
    "plz": "74597",
    "partner": [
      8
    ]
  },
  {
    "plz": "74597",
    "partner": [
      8
    ]
  },
  {
    "plz": "74599",
    "partner": [
      8
    ]
  },
  {
    "plz": "74613",
    "partner": [
      8
    ]
  },
  {
    "plz": "74626",
    "partner": [
      8
    ]
  },
  {
    "plz": "74629",
    "partner": [
      8
    ]
  },
  {
    "plz": "74632",
    "partner": [
      8
    ]
  },
  {
    "plz": "74632",
    "partner": [
      8
    ]
  },
  {
    "plz": "74632",
    "partner": [
      8
    ]
  },
  {
    "plz": "74635",
    "partner": [
      8
    ]
  },
  {
    "plz": "74638",
    "partner": [
      8
    ]
  },
  {
    "plz": "74639",
    "partner": [
      8
    ]
  },
  {
    "plz": "74639",
    "partner": [
      8
    ]
  },
  {
    "plz": "74653",
    "partner": [
      8
    ]
  },
  {
    "plz": "74653",
    "partner": [
      8
    ]
  },
  {
    "plz": "74670",
    "partner": [
      8
    ]
  },
  {
    "plz": "74673",
    "partner": [
      8
    ]
  },
  {
    "plz": "74676",
    "partner": [
      8
    ]
  },
  {
    "plz": "74677",
    "partner": [
      8
    ]
  },
  {
    "plz": "74679",
    "partner": [
      8
    ]
  },
  {
    "plz": "74706",
    "partner": [
      8
    ]
  },
  {
    "plz": "74722",
    "partner": [
      8
    ]
  },
  {
    "plz": "74731",
    "partner": [
      8
    ]
  },
  {
    "plz": "74731",
    "partner": [
      8
    ]
  },
  {
    "plz": "74736",
    "partner": [
      8
    ]
  },
  {
    "plz": "74740",
    "partner": [
      8
    ]
  },
  {
    "plz": "74743",
    "partner": [
      8
    ]
  },
  {
    "plz": "74744",
    "partner": [
      8
    ]
  },
  {
    "plz": "74746",
    "partner": [
      8
    ]
  },
  {
    "plz": "74747",
    "partner": [
      8
    ]
  },
  {
    "plz": "74749",
    "partner": [
      8
    ]
  },
  {
    "plz": "74821",
    "partner": [
      8
    ]
  },
  {
    "plz": "74831",
    "partner": [
      8
    ]
  },
  {
    "plz": "74834",
    "partner": [
      8
    ]
  },
  {
    "plz": "74834",
    "partner": [
      8
    ]
  },
  {
    "plz": "74838",
    "partner": [
      8
    ]
  },
  {
    "plz": "74842",
    "partner": [
      8
    ]
  },
  {
    "plz": "74847",
    "partner": [
      8
    ]
  },
  {
    "plz": "74850",
    "partner": [
      8
    ]
  },
  {
    "plz": "74855",
    "partner": [
      8
    ]
  },
  {
    "plz": "74858",
    "partner": [
      8
    ]
  },
  {
    "plz": "74861",
    "partner": [
      8
    ]
  },
  {
    "plz": "74862",
    "partner": [
      8
    ]
  },
  {
    "plz": "74864",
    "partner": [
      8
    ]
  },
  {
    "plz": "74865",
    "partner": [
      8
    ]
  },
  {
    "plz": "74867",
    "partner": [
      8
    ]
  },
  {
    "plz": "74869",
    "partner": [
      8
    ]
  },
  {
    "plz": "74889",
    "partner": [
      8
    ]
  },
  {
    "plz": "74906",
    "partner": [
      8
    ]
  },
  {
    "plz": "74909",
    "partner": [
      8
    ]
  },
  {
    "plz": "74912",
    "partner": [
      8
    ]
  },
  {
    "plz": "74915",
    "partner": [
      8
    ]
  },
  {
    "plz": "74918",
    "partner": [
      8
    ]
  },
  {
    "plz": "74921",
    "partner": [
      8
    ]
  },
  {
    "plz": "74924",
    "partner": [
      8
    ]
  },
  {
    "plz": "74925",
    "partner": [
      8
    ]
  },
  {
    "plz": "74927",
    "partner": [
      8
    ]
  },
  {
    "plz": "74928",
    "partner": [
      8
    ]
  },
  {
    "plz": "74930",
    "partner": [
      8
    ]
  },
  {
    "plz": "74931",
    "partner": [
      8
    ]
  },
  {
    "plz": "74933",
    "partner": [
      8
    ]
  },
  {
    "plz": "74934",
    "partner": [
      8
    ]
  },
  {
    "plz": "74936",
    "partner": [
      8
    ]
  },
  {
    "plz": "74937",
    "partner": [
      8
    ]
  },
  {
    "plz": "74939",
    "partner": [
      8
    ]
  },
  {
    "plz": "75015",
    "partner": [
      8
    ]
  },
  {
    "plz": "75031",
    "partner": [
      8
    ]
  },
  {
    "plz": "75031",
    "partner": [
      8
    ]
  },
  {
    "plz": "75031",
    "partner": [
      8
    ]
  },
  {
    "plz": "75038",
    "partner": [
      8
    ]
  },
  {
    "plz": "75045",
    "partner": [
      8
    ]
  },
  {
    "plz": "75050",
    "partner": [
      8
    ]
  },
  {
    "plz": "75053",
    "partner": [
      8
    ]
  },
  {
    "plz": "75056",
    "partner": [
      8
    ]
  },
  {
    "plz": "75057",
    "partner": [
      8
    ]
  },
  {
    "plz": "75059",
    "partner": [
      8
    ]
  },
  {
    "plz": "75059",
    "partner": [
      8
    ]
  },
  {
    "plz": "75172",
    "partner": [
      8
    ]
  },
  {
    "plz": "75173",
    "partner": [
      8
    ]
  },
  {
    "plz": "75175",
    "partner": [
      8
    ]
  },
  {
    "plz": "75177",
    "partner": [
      8
    ]
  },
  {
    "plz": "75177",
    "partner": [
      8
    ]
  },
  {
    "plz": "75179",
    "partner": [
      8
    ]
  },
  {
    "plz": "75180",
    "partner": [
      8
    ]
  },
  {
    "plz": "75181",
    "partner": [
      8
    ]
  },
  {
    "plz": "75196",
    "partner": [
      8
    ]
  },
  {
    "plz": "75203",
    "partner": [
      8
    ]
  },
  {
    "plz": "75210",
    "partner": [
      8
    ]
  },
  {
    "plz": "75217",
    "partner": [
      8
    ]
  },
  {
    "plz": "75223",
    "partner": [
      8
    ]
  },
  {
    "plz": "75228",
    "partner": [
      8
    ]
  },
  {
    "plz": "75233",
    "partner": [
      8
    ]
  },
  {
    "plz": "75236",
    "partner": [
      8
    ]
  },
  {
    "plz": "75239",
    "partner": [
      8
    ]
  },
  {
    "plz": "75242",
    "partner": [
      8
    ]
  },
  {
    "plz": "75245",
    "partner": [
      8
    ]
  },
  {
    "plz": "75248",
    "partner": [
      8
    ]
  },
  {
    "plz": "75249",
    "partner": [
      8
    ]
  },
  {
    "plz": "75305",
    "partner": [
      8
    ]
  },
  {
    "plz": "75323",
    "partner": [
      8
    ]
  },
  {
    "plz": "75328",
    "partner": [
      8
    ]
  },
  {
    "plz": "75331",
    "partner": [
      8
    ]
  },
  {
    "plz": "75334",
    "partner": [
      8
    ]
  },
  {
    "plz": "75335",
    "partner": [
      8
    ]
  },
  {
    "plz": "75337",
    "partner": [
      8
    ]
  },
  {
    "plz": "75339",
    "partner": [
      8
    ]
  },
  {
    "plz": "75365",
    "partner": [
      8
    ]
  },
  {
    "plz": "75378",
    "partner": [
      8
    ]
  },
  {
    "plz": "75382",
    "partner": [
      8
    ]
  },
  {
    "plz": "75385",
    "partner": [
      8
    ]
  },
  {
    "plz": "75385",
    "partner": [
      8
    ]
  },
  {
    "plz": "75385",
    "partner": [
      8
    ]
  },
  {
    "plz": "75385",
    "partner": [
      8
    ]
  },
  {
    "plz": "75387",
    "partner": [
      8
    ]
  },
  {
    "plz": "75389",
    "partner": [
      8
    ]
  },
  {
    "plz": "75391",
    "partner": [
      8
    ]
  },
  {
    "plz": "75392",
    "partner": [
      8
    ]
  },
  {
    "plz": "75394",
    "partner": [
      8
    ]
  },
  {
    "plz": "75395",
    "partner": [
      8
    ]
  },
  {
    "plz": "75397",
    "partner": [
      8
    ]
  },
  {
    "plz": "75399",
    "partner": [
      8
    ]
  },
  {
    "plz": "75399",
    "partner": [
      8
    ]
  },
  {
    "plz": "75399",
    "partner": [
      8
    ]
  },
  {
    "plz": "75417",
    "partner": [
      8
    ]
  },
  {
    "plz": "75428",
    "partner": [
      8
    ]
  },
  {
    "plz": "75433",
    "partner": [
      8
    ]
  },
  {
    "plz": "75438",
    "partner": [
      8
    ]
  },
  {
    "plz": "75443",
    "partner": [
      8
    ]
  },
  {
    "plz": "75446",
    "partner": [
      8
    ]
  },
  {
    "plz": "75447",
    "partner": [
      8
    ]
  },
  {
    "plz": "75449",
    "partner": [
      8
    ]
  },
  {
    "plz": "76131",
    "partner": [
      8
    ]
  },
  {
    "plz": "76133",
    "partner": [
      8
    ]
  },
  {
    "plz": "76135",
    "partner": [
      8
    ]
  },
  {
    "plz": "76137",
    "partner": [
      8
    ]
  },
  {
    "plz": "76139",
    "partner": [
      8
    ]
  },
  {
    "plz": "76149",
    "partner": [
      8
    ]
  },
  {
    "plz": "76185",
    "partner": [
      8
    ]
  },
  {
    "plz": "76187",
    "partner": [
      8
    ]
  },
  {
    "plz": "76189",
    "partner": [
      8
    ]
  },
  {
    "plz": "76199",
    "partner": [
      8
    ]
  },
  {
    "plz": "76227",
    "partner": [
      8
    ]
  },
  {
    "plz": "76228",
    "partner": [
      8
    ]
  },
  {
    "plz": "76229",
    "partner": [
      8
    ]
  },
  {
    "plz": "76275",
    "partner": [
      8
    ]
  },
  {
    "plz": "76287",
    "partner": [
      8
    ]
  },
  {
    "plz": "76297",
    "partner": [
      8
    ]
  },
  {
    "plz": "76307",
    "partner": [
      8
    ]
  },
  {
    "plz": "76316",
    "partner": [
      8
    ]
  },
  {
    "plz": "76327",
    "partner": [
      8
    ]
  },
  {
    "plz": "76332",
    "partner": [
      8
    ]
  },
  {
    "plz": "76332",
    "partner": [
      8
    ]
  },
  {
    "plz": "76337",
    "partner": [
      8
    ]
  },
  {
    "plz": "76344",
    "partner": [
      8
    ]
  },
  {
    "plz": "76351",
    "partner": [
      8
    ]
  },
  {
    "plz": "76356",
    "partner": [
      8
    ]
  },
  {
    "plz": "76356",
    "partner": [
      8
    ]
  },
  {
    "plz": "76359",
    "partner": [
      8
    ]
  },
  {
    "plz": "76359",
    "partner": [
      8
    ]
  },
  {
    "plz": "76359",
    "partner": [
      8
    ]
  },
  {
    "plz": "76437",
    "partner": [
      8
    ]
  },
  {
    "plz": "76448",
    "partner": [
      8
    ]
  },
  {
    "plz": "76456",
    "partner": [
      8
    ]
  },
  {
    "plz": "76461",
    "partner": [
      8
    ]
  },
  {
    "plz": "76467",
    "partner": [
      8
    ]
  },
  {
    "plz": "76470",
    "partner": [
      8
    ]
  },
  {
    "plz": "76473",
    "partner": [
      8
    ]
  },
  {
    "plz": "76474",
    "partner": [
      8
    ]
  },
  {
    "plz": "76476",
    "partner": [
      8
    ]
  },
  {
    "plz": "76477",
    "partner": [
      8
    ]
  },
  {
    "plz": "76479",
    "partner": [
      8
    ]
  },
  {
    "plz": "76530",
    "partner": [
      8
    ]
  },
  {
    "plz": "76532",
    "partner": [
      8
    ]
  },
  {
    "plz": "76534",
    "partner": [
      8
    ]
  },
  {
    "plz": "76547",
    "partner": [
      8
    ]
  },
  {
    "plz": "76549",
    "partner": [
      8
    ]
  },
  {
    "plz": "76571",
    "partner": [
      8
    ]
  },
  {
    "plz": "76593",
    "partner": [
      8
    ]
  },
  {
    "plz": "76596",
    "partner": [
      8
    ]
  },
  {
    "plz": "76597",
    "partner": [
      8
    ]
  },
  {
    "plz": "76599",
    "partner": [
      8
    ]
  },
  {
    "plz": "76646",
    "partner": [
      8
    ]
  },
  {
    "plz": "76661",
    "partner": [
      8
    ]
  },
  {
    "plz": "76669",
    "partner": [
      8
    ]
  },
  {
    "plz": "76676",
    "partner": [
      8
    ]
  },
  {
    "plz": "76684",
    "partner": [
      8
    ]
  },
  {
    "plz": "76689",
    "partner": [
      8
    ]
  },
  {
    "plz": "76694",
    "partner": [
      8
    ]
  },
  {
    "plz": "76698",
    "partner": [
      8
    ]
  },
  {
    "plz": "76703",
    "partner": [
      8
    ]
  },
  {
    "plz": "76706",
    "partner": [
      8
    ]
  },
  {
    "plz": "76707",
    "partner": [
      8
    ]
  },
  {
    "plz": "76709",
    "partner": [
      8
    ]
  },
  {
    "plz": "76726",
    "partner": [
      8
    ]
  },
  {
    "plz": "76726",
    "partner": [
      8
    ]
  },
  {
    "plz": "76726",
    "partner": [
      8
    ]
  },
  {
    "plz": "76744",
    "partner": [
      8
    ]
  },
  {
    "plz": "76744",
    "partner": [
      8
    ]
  },
  {
    "plz": "76744",
    "partner": [
      8
    ]
  },
  {
    "plz": "76751",
    "partner": [
      8
    ]
  },
  {
    "plz": "76756",
    "partner": [
      8
    ]
  },
  {
    "plz": "76761",
    "partner": [
      8
    ]
  },
  {
    "plz": "76764",
    "partner": [
      8
    ]
  },
  {
    "plz": "76767",
    "partner": [
      8
    ]
  },
  {
    "plz": "76768",
    "partner": [
      8
    ]
  },
  {
    "plz": "76770",
    "partner": [
      8
    ]
  },
  {
    "plz": "76771",
    "partner": [
      8
    ]
  },
  {
    "plz": "76773",
    "partner": [
      8
    ]
  },
  {
    "plz": "76774",
    "partner": [
      8
    ]
  },
  {
    "plz": "76776",
    "partner": [
      8
    ]
  },
  {
    "plz": "76777",
    "partner": [
      8
    ]
  },
  {
    "plz": "76779",
    "partner": [
      8
    ]
  },
  {
    "plz": "76779",
    "partner": [
      8
    ]
  },
  {
    "plz": "76829",
    "partner": [
      8
    ]
  },
  {
    "plz": "76829",
    "partner": [
      8
    ]
  },
  {
    "plz": "76829",
    "partner": [
      8
    ]
  },
  {
    "plz": "76829",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76831",
    "partner": [
      8
    ]
  },
  {
    "plz": "76833",
    "partner": [
      8
    ]
  },
  {
    "plz": "76833",
    "partner": [
      8
    ]
  },
  {
    "plz": "76833",
    "partner": [
      8
    ]
  },
  {
    "plz": "76833",
    "partner": [
      8
    ]
  },
  {
    "plz": "76833",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76835",
    "partner": [
      8
    ]
  },
  {
    "plz": "76846",
    "partner": [
      8
    ]
  },
  {
    "plz": "76848",
    "partner": [
      8
    ]
  },
  {
    "plz": "76848",
    "partner": [
      8
    ]
  },
  {
    "plz": "76848",
    "partner": [
      8
    ]
  },
  {
    "plz": "76848",
    "partner": [
      8
    ]
  },
  {
    "plz": "76848",
    "partner": [
      8
    ]
  },
  {
    "plz": "76848",
    "partner": [
      8
    ]
  },
  {
    "plz": "76855",
    "partner": [
      8
    ]
  },
  {
    "plz": "76855",
    "partner": [
      8
    ]
  },
  {
    "plz": "76855",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76857",
    "partner": [
      8
    ]
  },
  {
    "plz": "76863",
    "partner": [
      8
    ]
  },
  {
    "plz": "76863",
    "partner": [
      8
    ]
  },
  {
    "plz": "76865",
    "partner": [
      8
    ]
  },
  {
    "plz": "76865",
    "partner": [
      8
    ]
  },
  {
    "plz": "76870",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76872",
    "partner": [
      8
    ]
  },
  {
    "plz": "76877",
    "partner": [
      8
    ]
  },
  {
    "plz": "76877",
    "partner": [
      8
    ]
  },
  {
    "plz": "76879",
    "partner": [
      8
    ]
  },
  {
    "plz": "76879",
    "partner": [
      8
    ]
  },
  {
    "plz": "76879",
    "partner": [
      8
    ]
  },
  {
    "plz": "76879",
    "partner": [
      8
    ]
  },
  {
    "plz": "76879",
    "partner": [
      8
    ]
  },
  {
    "plz": "76887",
    "partner": [
      8
    ]
  },
  {
    "plz": "76887",
    "partner": [
      8
    ]
  },
  {
    "plz": "76887",
    "partner": [
      8
    ]
  },
  {
    "plz": "76887",
    "partner": [
      8
    ]
  },
  {
    "plz": "76887",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76889",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "76891",
    "partner": [
      8
    ]
  },
  {
    "plz": "77652",
    "partner": [
      8
    ]
  },
  {
    "plz": "77654",
    "partner": [
      8
    ]
  },
  {
    "plz": "77656",
    "partner": [
      8
    ]
  },
  {
    "plz": "77694",
    "partner": [
      8
    ]
  },
  {
    "plz": "77704",
    "partner": [
      8
    ]
  },
  {
    "plz": "77709",
    "partner": [
      8
    ]
  },
  {
    "plz": "77709",
    "partner": [
      8
    ]
  },
  {
    "plz": "77716",
    "partner": [
      8
    ]
  },
  {
    "plz": "77716",
    "partner": [
      8
    ]
  },
  {
    "plz": "77716",
    "partner": [
      8
    ]
  },
  {
    "plz": "77723",
    "partner": [
      8
    ]
  },
  {
    "plz": "77728",
    "partner": [
      8
    ]
  },
  {
    "plz": "77731",
    "partner": [
      8
    ]
  },
  {
    "plz": "77736",
    "partner": [
      8
    ]
  },
  {
    "plz": "77740",
    "partner": [
      8
    ]
  },
  {
    "plz": "77743",
    "partner": [
      8
    ]
  },
  {
    "plz": "77746",
    "partner": [
      8
    ]
  },
  {
    "plz": "77749",
    "partner": [
      8
    ]
  },
  {
    "plz": "77756",
    "partner": [
      8
    ]
  },
  {
    "plz": "77761",
    "partner": [
      8
    ]
  },
  {
    "plz": "77767",
    "partner": [
      8
    ]
  },
  {
    "plz": "77770",
    "partner": [
      8
    ]
  },
  {
    "plz": "77773",
    "partner": [
      8
    ]
  },
  {
    "plz": "77776",
    "partner": [
      8
    ]
  },
  {
    "plz": "77781",
    "partner": [
      8
    ]
  },
  {
    "plz": "77784",
    "partner": [
      8
    ]
  },
  {
    "plz": "77787",
    "partner": [
      8
    ]
  },
  {
    "plz": "77790",
    "partner": [
      8
    ]
  },
  {
    "plz": "77791",
    "partner": [
      8
    ]
  },
  {
    "plz": "77793",
    "partner": [
      8
    ]
  },
  {
    "plz": "77794",
    "partner": [
      8
    ]
  },
  {
    "plz": "77796",
    "partner": [
      8
    ]
  },
  {
    "plz": "77797",
    "partner": [
      8
    ]
  },
  {
    "plz": "77799",
    "partner": [
      8
    ]
  },
  {
    "plz": "77815",
    "partner": [
      8
    ]
  },
  {
    "plz": "77830",
    "partner": [
      8
    ]
  },
  {
    "plz": "77833",
    "partner": [
      8
    ]
  },
  {
    "plz": "77836",
    "partner": [
      8
    ]
  },
  {
    "plz": "77839",
    "partner": [
      8
    ]
  },
  {
    "plz": "77855",
    "partner": [
      8
    ]
  },
  {
    "plz": "77866",
    "partner": [
      8
    ]
  },
  {
    "plz": "77871",
    "partner": [
      8
    ]
  },
  {
    "plz": "77876",
    "partner": [
      8
    ]
  },
  {
    "plz": "77880",
    "partner": [
      8
    ]
  },
  {
    "plz": "77883",
    "partner": [
      8
    ]
  },
  {
    "plz": "77886",
    "partner": [
      8
    ]
  },
  {
    "plz": "77887",
    "partner": [
      8
    ]
  },
  {
    "plz": "77889",
    "partner": [
      8
    ]
  },
  {
    "plz": "77933",
    "partner": [
      8
    ]
  },
  {
    "plz": "77948",
    "partner": [
      8
    ]
  },
  {
    "plz": "77955",
    "partner": [
      8
    ]
  },
  {
    "plz": "77960",
    "partner": [
      8
    ]
  },
  {
    "plz": "77963",
    "partner": [
      8
    ]
  },
  {
    "plz": "77966",
    "partner": [
      8
    ]
  },
  {
    "plz": "77971",
    "partner": [
      8
    ]
  },
  {
    "plz": "77972",
    "partner": [
      8
    ]
  },
  {
    "plz": "77974",
    "partner": [
      8
    ]
  },
  {
    "plz": "77975",
    "partner": [
      8
    ]
  },
  {
    "plz": "77977",
    "partner": [
      8
    ]
  },
  {
    "plz": "77978",
    "partner": [
      8
    ]
  },
  {
    "plz": "78048",
    "partner": [
      8
    ]
  },
  {
    "plz": "78050",
    "partner": [
      8
    ]
  },
  {
    "plz": "78052",
    "partner": [
      8
    ]
  },
  {
    "plz": "78054",
    "partner": [
      8
    ]
  },
  {
    "plz": "78056",
    "partner": [
      8
    ]
  },
  {
    "plz": "78073",
    "partner": [
      8
    ]
  },
  {
    "plz": "78078",
    "partner": [
      8
    ]
  },
  {
    "plz": "78083",
    "partner": [
      8
    ]
  },
  {
    "plz": "78083",
    "partner": [
      8
    ]
  },
  {
    "plz": "78086",
    "partner": [
      8
    ]
  },
  {
    "plz": "78087",
    "partner": [
      8
    ]
  },
  {
    "plz": "78089",
    "partner": [
      8
    ]
  },
  {
    "plz": "78098",
    "partner": [
      8
    ]
  },
  {
    "plz": "78112",
    "partner": [
      8
    ]
  },
  {
    "plz": "78112",
    "partner": [
      8
    ]
  },
  {
    "plz": "78120",
    "partner": [
      8
    ]
  },
  {
    "plz": "78126",
    "partner": [
      8
    ]
  },
  {
    "plz": "78132",
    "partner": [
      8
    ]
  },
  {
    "plz": "78136",
    "partner": [
      8
    ]
  },
  {
    "plz": "78141",
    "partner": [
      8
    ]
  },
  {
    "plz": "78144",
    "partner": [
      8
    ]
  },
  {
    "plz": "78147",
    "partner": [
      8
    ]
  },
  {
    "plz": "78148",
    "partner": [
      8
    ]
  },
  {
    "plz": "78166",
    "partner": [
      8
    ]
  },
  {
    "plz": "78176",
    "partner": [
      8
    ]
  },
  {
    "plz": "78183",
    "partner": [
      8
    ]
  },
  {
    "plz": "78187",
    "partner": [
      8
    ]
  },
  {
    "plz": "78194",
    "partner": [
      8
    ]
  },
  {
    "plz": "78199",
    "partner": [
      8
    ]
  },
  {
    "plz": "78224",
    "partner": [
      8
    ]
  },
  {
    "plz": "78234",
    "partner": [
      8
    ]
  },
  {
    "plz": "78239",
    "partner": [
      8
    ]
  },
  {
    "plz": "78244",
    "partner": [
      8
    ]
  },
  {
    "plz": "78247",
    "partner": [
      8
    ]
  },
  {
    "plz": "78250",
    "partner": [
      8
    ]
  },
  {
    "plz": "78253",
    "partner": [
      8
    ]
  },
  {
    "plz": "78256",
    "partner": [
      8
    ]
  },
  {
    "plz": "78259",
    "partner": [
      8
    ]
  },
  {
    "plz": "78262",
    "partner": [
      8
    ]
  },
  {
    "plz": "78266",
    "partner": [
      8
    ]
  },
  {
    "plz": "78267",
    "partner": [
      8
    ]
  },
  {
    "plz": "78269",
    "partner": [
      8
    ]
  },
  {
    "plz": "78315",
    "partner": [
      8
    ]
  },
  {
    "plz": "78333",
    "partner": [
      8
    ]
  },
  {
    "plz": "78337",
    "partner": [
      8
    ]
  },
  {
    "plz": "78343",
    "partner": [
      8
    ]
  },
  {
    "plz": "78345",
    "partner": [
      8
    ]
  },
  {
    "plz": "78351",
    "partner": [
      8
    ]
  },
  {
    "plz": "78354",
    "partner": [
      8
    ]
  },
  {
    "plz": "78355",
    "partner": [
      8
    ]
  },
  {
    "plz": "78357",
    "partner": [
      8
    ]
  },
  {
    "plz": "78359",
    "partner": [
      8
    ]
  },
  {
    "plz": "78462",
    "partner": [
      8
    ]
  },
  {
    "plz": "78464",
    "partner": [
      8
    ]
  },
  {
    "plz": "78465",
    "partner": [
      8
    ]
  },
  {
    "plz": "78465",
    "partner": [
      8
    ]
  },
  {
    "plz": "78467",
    "partner": [
      8
    ]
  },
  {
    "plz": "78476",
    "partner": [
      8
    ]
  },
  {
    "plz": "78479",
    "partner": [
      8
    ]
  },
  {
    "plz": "78532",
    "partner": [
      8
    ]
  },
  {
    "plz": "78549",
    "partner": [
      8
    ]
  },
  {
    "plz": "78554",
    "partner": [
      8
    ]
  },
  {
    "plz": "78554",
    "partner": [
      8
    ]
  },
  {
    "plz": "78554",
    "partner": [
      8
    ]
  },
  {
    "plz": "78559",
    "partner": [
      8
    ]
  },
  {
    "plz": "78564",
    "partner": [
      8
    ]
  },
  {
    "plz": "78564",
    "partner": [
      8
    ]
  },
  {
    "plz": "78567",
    "partner": [
      8
    ]
  },
  {
    "plz": "78567",
    "partner": [
      8
    ]
  },
  {
    "plz": "78570",
    "partner": [
      8
    ]
  },
  {
    "plz": "78573",
    "partner": [
      8
    ]
  },
  {
    "plz": "78576",
    "partner": [
      8
    ]
  },
  {
    "plz": "78579",
    "partner": [
      8
    ]
  },
  {
    "plz": "78580",
    "partner": [
      8
    ]
  },
  {
    "plz": "78580",
    "partner": [
      8
    ]
  },
  {
    "plz": "78582",
    "partner": [
      8
    ]
  },
  {
    "plz": "78583",
    "partner": [
      8
    ]
  },
  {
    "plz": "78585",
    "partner": [
      8
    ]
  },
  {
    "plz": "78586",
    "partner": [
      8
    ]
  },
  {
    "plz": "78588",
    "partner": [
      8
    ]
  },
  {
    "plz": "78589",
    "partner": [
      8
    ]
  },
  {
    "plz": "78591",
    "partner": [
      8
    ]
  },
  {
    "plz": "78592",
    "partner": [
      8
    ]
  },
  {
    "plz": "78594",
    "partner": [
      8
    ]
  },
  {
    "plz": "78595",
    "partner": [
      8
    ]
  },
  {
    "plz": "78597",
    "partner": [
      8
    ]
  },
  {
    "plz": "78598",
    "partner": [
      8
    ]
  },
  {
    "plz": "78600",
    "partner": [
      8
    ]
  },
  {
    "plz": "78601",
    "partner": [
      8
    ]
  },
  {
    "plz": "78603",
    "partner": [
      8
    ]
  },
  {
    "plz": "78604",
    "partner": [
      8
    ]
  },
  {
    "plz": "78606",
    "partner": [
      8
    ]
  },
  {
    "plz": "78607",
    "partner": [
      8
    ]
  },
  {
    "plz": "78609",
    "partner": [
      8
    ]
  },
  {
    "plz": "78628",
    "partner": [
      8
    ]
  },
  {
    "plz": "78647",
    "partner": [
      8
    ]
  },
  {
    "plz": "78652",
    "partner": [
      8
    ]
  },
  {
    "plz": "78652",
    "partner": [
      8
    ]
  },
  {
    "plz": "78652",
    "partner": [
      8
    ]
  },
  {
    "plz": "78655",
    "partner": [
      8
    ]
  },
  {
    "plz": "78658",
    "partner": [
      8
    ]
  },
  {
    "plz": "78661",
    "partner": [
      8
    ]
  },
  {
    "plz": "78662",
    "partner": [
      8
    ]
  },
  {
    "plz": "78664",
    "partner": [
      8
    ]
  },
  {
    "plz": "78665",
    "partner": [
      8
    ]
  },
  {
    "plz": "78667",
    "partner": [
      8
    ]
  },
  {
    "plz": "78669",
    "partner": [
      8
    ]
  },
  {
    "plz": "78713",
    "partner": [
      8
    ]
  },
  {
    "plz": "78713",
    "partner": [
      8
    ]
  },
  {
    "plz": "78727",
    "partner": [
      8
    ]
  },
  {
    "plz": "78730",
    "partner": [
      8
    ]
  },
  {
    "plz": "78733",
    "partner": [
      8
    ]
  },
  {
    "plz": "78733",
    "partner": [
      8
    ]
  },
  {
    "plz": "78733",
    "partner": [
      8
    ]
  },
  {
    "plz": "78736",
    "partner": [
      8
    ]
  },
  {
    "plz": "78737",
    "partner": [
      8
    ]
  },
  {
    "plz": "78739",
    "partner": [
      8
    ]
  },
  {
    "plz": "79098",
    "partner": [
      8
    ]
  },
  {
    "plz": "79100",
    "partner": [
      8
    ]
  },
  {
    "plz": "79102",
    "partner": [
      8
    ]
  },
  {
    "plz": "79104",
    "partner": [
      8
    ]
  },
  {
    "plz": "79106",
    "partner": [
      8
    ]
  },
  {
    "plz": "79108",
    "partner": [
      8
    ]
  },
  {
    "plz": "79110",
    "partner": [
      8
    ]
  },
  {
    "plz": "79111",
    "partner": [
      8
    ]
  },
  {
    "plz": "79112",
    "partner": [
      8
    ]
  },
  {
    "plz": "79114",
    "partner": [
      8
    ]
  },
  {
    "plz": "79115",
    "partner": [
      8
    ]
  },
  {
    "plz": "79117",
    "partner": [
      8
    ]
  },
  {
    "plz": "79183",
    "partner": [
      8
    ]
  },
  {
    "plz": "79189",
    "partner": [
      8
    ]
  },
  {
    "plz": "79194",
    "partner": [
      8
    ]
  },
  {
    "plz": "79194",
    "partner": [
      8
    ]
  },
  {
    "plz": "79199",
    "partner": [
      8
    ]
  },
  {
    "plz": "79206",
    "partner": [
      8
    ]
  },
  {
    "plz": "79211",
    "partner": [
      8
    ]
  },
  {
    "plz": "79215",
    "partner": [
      8
    ]
  },
  {
    "plz": "79215",
    "partner": [
      8
    ]
  },
  {
    "plz": "79219",
    "partner": [
      8
    ]
  },
  {
    "plz": "79224",
    "partner": [
      8
    ]
  },
  {
    "plz": "79227",
    "partner": [
      8
    ]
  },
  {
    "plz": "79232",
    "partner": [
      8
    ]
  },
  {
    "plz": "79235",
    "partner": [
      8
    ]
  },
  {
    "plz": "79238",
    "partner": [
      8
    ]
  },
  {
    "plz": "79241",
    "partner": [
      8
    ]
  },
  {
    "plz": "79244",
    "partner": [
      8
    ]
  },
  {
    "plz": "79249",
    "partner": [
      8
    ]
  },
  {
    "plz": "79252",
    "partner": [
      8
    ]
  },
  {
    "plz": "79254",
    "partner": [
      8
    ]
  },
  {
    "plz": "79256",
    "partner": [
      8
    ]
  },
  {
    "plz": "79258",
    "partner": [
      8
    ]
  },
  {
    "plz": "79261",
    "partner": [
      8
    ]
  },
  {
    "plz": "79263",
    "partner": [
      8
    ]
  },
  {
    "plz": "79268",
    "partner": [
      8
    ]
  },
  {
    "plz": "79271",
    "partner": [
      8
    ]
  },
  {
    "plz": "79274",
    "partner": [
      8
    ]
  },
  {
    "plz": "79276",
    "partner": [
      8
    ]
  },
  {
    "plz": "79279",
    "partner": [
      8
    ]
  },
  {
    "plz": "79280",
    "partner": [
      8
    ]
  },
  {
    "plz": "79282",
    "partner": [
      8
    ]
  },
  {
    "plz": "79283",
    "partner": [
      8
    ]
  },
  {
    "plz": "79285",
    "partner": [
      8
    ]
  },
  {
    "plz": "79286",
    "partner": [
      8
    ]
  },
  {
    "plz": "79288",
    "partner": [
      8
    ]
  },
  {
    "plz": "79289",
    "partner": [
      8
    ]
  },
  {
    "plz": "79291",
    "partner": [
      8
    ]
  },
  {
    "plz": "79292",
    "partner": [
      8
    ]
  },
  {
    "plz": "79294",
    "partner": [
      8
    ]
  },
  {
    "plz": "79295",
    "partner": [
      8
    ]
  },
  {
    "plz": "79297",
    "partner": [
      8
    ]
  },
  {
    "plz": "79299",
    "partner": [
      8
    ]
  },
  {
    "plz": "79312",
    "partner": [
      8
    ]
  },
  {
    "plz": "79312",
    "partner": [
      8
    ]
  },
  {
    "plz": "79331",
    "partner": [
      8
    ]
  },
  {
    "plz": "79336",
    "partner": [
      8
    ]
  },
  {
    "plz": "79341",
    "partner": [
      8
    ]
  },
  {
    "plz": "79346",
    "partner": [
      8
    ]
  },
  {
    "plz": "79348",
    "partner": [
      8
    ]
  },
  {
    "plz": "79350",
    "partner": [
      8
    ]
  },
  {
    "plz": "79353",
    "partner": [
      8
    ]
  },
  {
    "plz": "79356",
    "partner": [
      8
    ]
  },
  {
    "plz": "79359",
    "partner": [
      8
    ]
  },
  {
    "plz": "79361",
    "partner": [
      8
    ]
  },
  {
    "plz": "79362",
    "partner": [
      8
    ]
  },
  {
    "plz": "79364",
    "partner": [
      8
    ]
  },
  {
    "plz": "79365",
    "partner": [
      8
    ]
  },
  {
    "plz": "79367",
    "partner": [
      8
    ]
  },
  {
    "plz": "79369",
    "partner": [
      8
    ]
  },
  {
    "plz": "79379",
    "partner": [
      8
    ]
  },
  {
    "plz": "79395",
    "partner": [
      8
    ]
  },
  {
    "plz": "79400",
    "partner": [
      8
    ]
  },
  {
    "plz": "79410",
    "partner": [
      8
    ]
  },
  {
    "plz": "79415",
    "partner": [
      8
    ]
  },
  {
    "plz": "79418",
    "partner": [
      8
    ]
  },
  {
    "plz": "79423",
    "partner": [
      8
    ]
  },
  {
    "plz": "79424",
    "partner": [
      8
    ]
  },
  {
    "plz": "79426",
    "partner": [
      8
    ]
  },
  {
    "plz": "79427",
    "partner": [
      8
    ]
  },
  {
    "plz": "79429",
    "partner": [
      8
    ]
  },
  {
    "plz": "79539",
    "partner": [
      8
    ]
  },
  {
    "plz": "79540",
    "partner": [
      8
    ]
  },
  {
    "plz": "79541",
    "partner": [
      8
    ]
  },
  {
    "plz": "79576",
    "partner": [
      8
    ]
  },
  {
    "plz": "79585",
    "partner": [
      8
    ]
  },
  {
    "plz": "79588",
    "partner": [
      8
    ]
  },
  {
    "plz": "79589",
    "partner": [
      8
    ]
  },
  {
    "plz": "79591",
    "partner": [
      8
    ]
  },
  {
    "plz": "79592",
    "partner": [
      8
    ]
  },
  {
    "plz": "79594",
    "partner": [
      8
    ]
  },
  {
    "plz": "79595",
    "partner": [
      8
    ]
  },
  {
    "plz": "79597",
    "partner": [
      8
    ]
  },
  {
    "plz": "79599",
    "partner": [
      8
    ]
  },
  {
    "plz": "79618",
    "partner": [
      8
    ]
  },
  {
    "plz": "79639",
    "partner": [
      8
    ]
  },
  {
    "plz": "79650",
    "partner": [
      8
    ]
  },
  {
    "plz": "79664",
    "partner": [
      8
    ]
  },
  {
    "plz": "79669",
    "partner": [
      8
    ]
  },
  {
    "plz": "79674",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79677",
    "partner": [
      8
    ]
  },
  {
    "plz": "79682",
    "partner": [
      8
    ]
  },
  {
    "plz": "79685",
    "partner": [
      8
    ]
  },
  {
    "plz": "79686",
    "partner": [
      8
    ]
  },
  {
    "plz": "79688",
    "partner": [
      8
    ]
  },
  {
    "plz": "79689",
    "partner": [
      8
    ]
  },
  {
    "plz": "79692",
    "partner": [
      8
    ]
  },
  {
    "plz": "79694",
    "partner": [
      8
    ]
  },
  {
    "plz": "79695",
    "partner": [
      8
    ]
  },
  {
    "plz": "79713",
    "partner": [
      8
    ]
  },
  {
    "plz": "79725",
    "partner": [
      8
    ]
  },
  {
    "plz": "79730",
    "partner": [
      8
    ]
  },
  {
    "plz": "79733",
    "partner": [
      8
    ]
  },
  {
    "plz": "79736",
    "partner": [
      8
    ]
  },
  {
    "plz": "79737",
    "partner": [
      8
    ]
  },
  {
    "plz": "79739",
    "partner": [
      8
    ]
  },
  {
    "plz": "79761",
    "partner": [
      8
    ]
  },
  {
    "plz": "79771",
    "partner": [
      8
    ]
  },
  {
    "plz": "79774",
    "partner": [
      8
    ]
  },
  {
    "plz": "79777",
    "partner": [
      8
    ]
  },
  {
    "plz": "79780",
    "partner": [
      8
    ]
  },
  {
    "plz": "79787",
    "partner": [
      8
    ]
  },
  {
    "plz": "79790",
    "partner": [
      8
    ]
  },
  {
    "plz": "79793",
    "partner": [
      8
    ]
  },
  {
    "plz": "79798",
    "partner": [
      8
    ]
  },
  {
    "plz": "79801",
    "partner": [
      8
    ]
  },
  {
    "plz": "79802",
    "partner": [
      8
    ]
  },
  {
    "plz": "79804",
    "partner": [
      8
    ]
  },
  {
    "plz": "79805",
    "partner": [
      8
    ]
  },
  {
    "plz": "79807",
    "partner": [
      8
    ]
  },
  {
    "plz": "79809",
    "partner": [
      8
    ]
  },
  {
    "plz": "79822",
    "partner": [
      8
    ]
  },
  {
    "plz": "79837",
    "partner": [
      8
    ]
  },
  {
    "plz": "79837",
    "partner": [
      8
    ]
  },
  {
    "plz": "79837",
    "partner": [
      8
    ]
  },
  {
    "plz": "79837",
    "partner": [
      8
    ]
  },
  {
    "plz": "79843",
    "partner": [
      8
    ]
  },
  {
    "plz": "79848",
    "partner": [
      8
    ]
  },
  {
    "plz": "79853",
    "partner": [
      8
    ]
  },
  {
    "plz": "79856",
    "partner": [
      8
    ]
  },
  {
    "plz": "79859",
    "partner": [
      8
    ]
  },
  {
    "plz": "79862",
    "partner": [
      8
    ]
  },
  {
    "plz": "79865",
    "partner": [
      8
    ]
  },
  {
    "plz": "79868",
    "partner": [
      8
    ]
  },
  {
    "plz": "79871",
    "partner": [
      8
    ]
  },
  {
    "plz": "79872",
    "partner": [
      8
    ]
  },
  {
    "plz": "79874",
    "partner": [
      8
    ]
  },
  {
    "plz": "79875",
    "partner": [
      8
    ]
  },
  {
    "plz": "79875",
    "partner": [
      8
    ]
  },
  {
    "plz": "79877",
    "partner": [
      8
    ]
  },
  {
    "plz": "79879",
    "partner": [
      8
    ]
  },
  {
    "plz": "80331",
    "partner": [
      9
    ]
  },
  {
    "plz": "80333",
    "partner": [
      9
    ]
  },
  {
    "plz": "80335",
    "partner": [
      9
    ]
  },
  {
    "plz": "80336",
    "partner": [
      9
    ]
  },
  {
    "plz": "80337",
    "partner": [
      9
    ]
  },
  {
    "plz": "80339",
    "partner": [
      9
    ]
  },
  {
    "plz": "80469",
    "partner": [
      9
    ]
  },
  {
    "plz": "80538",
    "partner": [
      9
    ]
  },
  {
    "plz": "80539",
    "partner": [
      9
    ]
  },
  {
    "plz": "80634",
    "partner": [
      9
    ]
  },
  {
    "plz": "80636",
    "partner": [
      9
    ]
  },
  {
    "plz": "80637",
    "partner": [
      9
    ]
  },
  {
    "plz": "80638",
    "partner": [
      9
    ]
  },
  {
    "plz": "80639",
    "partner": [
      9
    ]
  },
  {
    "plz": "80686",
    "partner": [
      9
    ]
  },
  {
    "plz": "80687",
    "partner": [
      9
    ]
  },
  {
    "plz": "80689",
    "partner": [
      9
    ]
  },
  {
    "plz": "80796",
    "partner": [
      9
    ]
  },
  {
    "plz": "80797",
    "partner": [
      9
    ]
  },
  {
    "plz": "80798",
    "partner": [
      9
    ]
  },
  {
    "plz": "80799",
    "partner": [
      9
    ]
  },
  {
    "plz": "80801",
    "partner": [
      9
    ]
  },
  {
    "plz": "80802",
    "partner": [
      9
    ]
  },
  {
    "plz": "80803",
    "partner": [
      9
    ]
  },
  {
    "plz": "80804",
    "partner": [
      9
    ]
  },
  {
    "plz": "80805",
    "partner": [
      9
    ]
  },
  {
    "plz": "80807",
    "partner": [
      9
    ]
  },
  {
    "plz": "80809",
    "partner": [
      9
    ]
  },
  {
    "plz": "80933",
    "partner": [
      9
    ]
  },
  {
    "plz": "80935",
    "partner": [
      9
    ]
  },
  {
    "plz": "80937",
    "partner": [
      9
    ]
  },
  {
    "plz": "80939",
    "partner": [
      9
    ]
  },
  {
    "plz": "80992",
    "partner": [
      9
    ]
  },
  {
    "plz": "80993",
    "partner": [
      9
    ]
  },
  {
    "plz": "80995",
    "partner": [
      9
    ]
  },
  {
    "plz": "80997",
    "partner": [
      9
    ]
  },
  {
    "plz": "80999",
    "partner": [
      9
    ]
  },
  {
    "plz": "81241",
    "partner": [
      9
    ]
  },
  {
    "plz": "81243",
    "partner": [
      9
    ]
  },
  {
    "plz": "81245",
    "partner": [
      9
    ]
  },
  {
    "plz": "81247",
    "partner": [
      9
    ]
  },
  {
    "plz": "81248",
    "partner": [
      9
    ]
  },
  {
    "plz": "81249",
    "partner": [
      9
    ]
  },
  {
    "plz": "81369",
    "partner": [
      9
    ]
  },
  {
    "plz": "81371",
    "partner": [
      9
    ]
  },
  {
    "plz": "81373",
    "partner": [
      9
    ]
  },
  {
    "plz": "81375",
    "partner": [
      9
    ]
  },
  {
    "plz": "81377",
    "partner": [
      9
    ]
  },
  {
    "plz": "81379",
    "partner": [
      9
    ]
  },
  {
    "plz": "81475",
    "partner": [
      9
    ]
  },
  {
    "plz": "81476",
    "partner": [
      9
    ]
  },
  {
    "plz": "81477",
    "partner": [
      9
    ]
  },
  {
    "plz": "81479",
    "partner": [
      9
    ]
  },
  {
    "plz": "81539",
    "partner": [
      9
    ]
  },
  {
    "plz": "81541",
    "partner": [
      9
    ]
  },
  {
    "plz": "81543",
    "partner": [
      9
    ]
  },
  {
    "plz": "81545",
    "partner": [
      9
    ]
  },
  {
    "plz": "81547",
    "partner": [
      9
    ]
  },
  {
    "plz": "81549",
    "partner": [
      9
    ]
  },
  {
    "plz": "81667",
    "partner": [
      9
    ]
  },
  {
    "plz": "81669",
    "partner": [
      9
    ]
  },
  {
    "plz": "81671",
    "partner": [
      9
    ]
  },
  {
    "plz": "81673",
    "partner": [
      9
    ]
  },
  {
    "plz": "81675",
    "partner": [
      9
    ]
  },
  {
    "plz": "81677",
    "partner": [
      9
    ]
  },
  {
    "plz": "81679",
    "partner": [
      9
    ]
  },
  {
    "plz": "81735",
    "partner": [
      9
    ]
  },
  {
    "plz": "81737",
    "partner": [
      9
    ]
  },
  {
    "plz": "81739",
    "partner": [
      9
    ]
  },
  {
    "plz": "81825",
    "partner": [
      9
    ]
  },
  {
    "plz": "81827",
    "partner": [
      9
    ]
  },
  {
    "plz": "81829",
    "partner": [
      9
    ]
  },
  {
    "plz": "81925",
    "partner": [
      9
    ]
  },
  {
    "plz": "81927",
    "partner": [
      9
    ]
  },
  {
    "plz": "81929",
    "partner": [
      9
    ]
  },
  {
    "plz": "82008",
    "partner": [
      9
    ]
  },
  {
    "plz": "82024",
    "partner": [
      9
    ]
  },
  {
    "plz": "82031",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82041",
    "partner": [
      9
    ]
  },
  {
    "plz": "82049",
    "partner": [
      9
    ]
  },
  {
    "plz": "82054",
    "partner": [
      9
    ]
  },
  {
    "plz": "82057",
    "partner": [
      9
    ]
  },
  {
    "plz": "82061",
    "partner": [
      9
    ]
  },
  {
    "plz": "82064",
    "partner": [
      9
    ]
  },
  {
    "plz": "82064",
    "partner": [
      9
    ]
  },
  {
    "plz": "82064",
    "partner": [
      9
    ]
  },
  {
    "plz": "82065",
    "partner": [
      9
    ]
  },
  {
    "plz": "82067",
    "partner": [
      9
    ]
  },
  {
    "plz": "82067",
    "partner": [
      9
    ]
  },
  {
    "plz": "82067",
    "partner": [
      9
    ]
  },
  {
    "plz": "82069",
    "partner": [
      9
    ]
  },
  {
    "plz": "82069",
    "partner": [
      9
    ]
  },
  {
    "plz": "82110",
    "partner": [
      9
    ]
  },
  {
    "plz": "82131",
    "partner": [
      9
    ]
  },
  {
    "plz": "82131",
    "partner": [
      9
    ]
  },
  {
    "plz": "82131",
    "partner": [
      9
    ]
  },
  {
    "plz": "82140",
    "partner": [
      9
    ]
  },
  {
    "plz": "82152",
    "partner": [
      9
    ]
  },
  {
    "plz": "82152",
    "partner": [
      9
    ]
  },
  {
    "plz": "82166",
    "partner": [
      9
    ]
  },
  {
    "plz": "82178",
    "partner": [
      9
    ]
  },
  {
    "plz": "82194",
    "partner": [
      9
    ]
  },
  {
    "plz": "82205",
    "partner": [
      9
    ]
  },
  {
    "plz": "82205",
    "partner": [
      9
    ]
  },
  {
    "plz": "82211",
    "partner": [
      9
    ]
  },
  {
    "plz": "82216",
    "partner": [
      9
    ]
  },
  {
    "plz": "82223",
    "partner": [
      9
    ]
  },
  {
    "plz": "82223",
    "partner": [
      9
    ]
  },
  {
    "plz": "82229",
    "partner": [
      9
    ]
  },
  {
    "plz": "82234",
    "partner": [
      9
    ]
  },
  {
    "plz": "82237",
    "partner": [
      9
    ]
  },
  {
    "plz": "82239",
    "partner": [
      9
    ]
  },
  {
    "plz": "82256",
    "partner": [
      9
    ]
  },
  {
    "plz": "82266",
    "partner": [
      9
    ]
  },
  {
    "plz": "82269",
    "partner": [
      9
    ]
  },
  {
    "plz": "82272",
    "partner": [
      9
    ]
  },
  {
    "plz": "82275",
    "partner": [
      9
    ]
  },
  {
    "plz": "82276",
    "partner": [
      9
    ]
  },
  {
    "plz": "82278",
    "partner": [
      9
    ]
  },
  {
    "plz": "82279",
    "partner": [
      9
    ]
  },
  {
    "plz": "82281",
    "partner": [
      9
    ]
  },
  {
    "plz": "82284",
    "partner": [
      9
    ]
  },
  {
    "plz": "82285",
    "partner": [
      9
    ]
  },
  {
    "plz": "82287",
    "partner": [
      9
    ]
  },
  {
    "plz": "82288",
    "partner": [
      9
    ]
  },
  {
    "plz": "82290",
    "partner": [
      9
    ]
  },
  {
    "plz": "82291",
    "partner": [
      9
    ]
  },
  {
    "plz": "82293",
    "partner": [
      9
    ]
  },
  {
    "plz": "82294",
    "partner": [
      9
    ]
  },
  {
    "plz": "82296",
    "partner": [
      9
    ]
  },
  {
    "plz": "82297",
    "partner": [
      9
    ]
  },
  {
    "plz": "82299",
    "partner": [
      9
    ]
  },
  {
    "plz": "82319",
    "partner": [
      9
    ]
  },
  {
    "plz": "82319",
    "partner": [
      9
    ]
  },
  {
    "plz": "82327",
    "partner": [
      9
    ]
  },
  {
    "plz": "82335",
    "partner": [
      9
    ]
  },
  {
    "plz": "82340",
    "partner": [
      9
    ]
  },
  {
    "plz": "82343",
    "partner": [
      9
    ]
  },
  {
    "plz": "82346",
    "partner": [
      9
    ]
  },
  {
    "plz": "82347",
    "partner": [
      9
    ]
  },
  {
    "plz": "82349",
    "partner": [
      9
    ]
  },
  {
    "plz": "82349",
    "partner": [
      9
    ]
  },
  {
    "plz": "82362",
    "partner": [
      9
    ]
  },
  {
    "plz": "82362",
    "partner": [
      9
    ]
  },
  {
    "plz": "82377",
    "partner": [
      9
    ]
  },
  {
    "plz": "82380",
    "partner": [
      9
    ]
  },
  {
    "plz": "82380",
    "partner": [
      9
    ]
  },
  {
    "plz": "82380",
    "partner": [
      9
    ]
  },
  {
    "plz": "82380",
    "partner": [
      9
    ]
  },
  {
    "plz": "82383",
    "partner": [
      9
    ]
  },
  {
    "plz": "82386",
    "partner": [
      9
    ]
  },
  {
    "plz": "82386",
    "partner": [
      9
    ]
  },
  {
    "plz": "82387",
    "partner": [
      9
    ]
  },
  {
    "plz": "82389",
    "partner": [
      9
    ]
  },
  {
    "plz": "82390",
    "partner": [
      9
    ]
  },
  {
    "plz": "82392",
    "partner": [
      9
    ]
  },
  {
    "plz": "82393",
    "partner": [
      9
    ]
  },
  {
    "plz": "82395",
    "partner": [
      9
    ]
  },
  {
    "plz": "82396",
    "partner": [
      9
    ]
  },
  {
    "plz": "82398",
    "partner": [
      9
    ]
  },
  {
    "plz": "82399",
    "partner": [
      9
    ]
  },
  {
    "plz": "82401",
    "partner": [
      9
    ]
  },
  {
    "plz": "82402",
    "partner": [
      9
    ]
  },
  {
    "plz": "82404",
    "partner": [
      9
    ]
  },
  {
    "plz": "82405",
    "partner": [
      9
    ]
  },
  {
    "plz": "82407",
    "partner": [
      9
    ]
  },
  {
    "plz": "82409",
    "partner": [
      9
    ]
  },
  {
    "plz": "82418",
    "partner": [
      9
    ]
  },
  {
    "plz": "82418",
    "partner": [
      9
    ]
  },
  {
    "plz": "82418",
    "partner": [
      9
    ]
  },
  {
    "plz": "82418",
    "partner": [
      9
    ]
  },
  {
    "plz": "82431",
    "partner": [
      9
    ]
  },
  {
    "plz": "82432",
    "partner": [
      9
    ]
  },
  {
    "plz": "82432",
    "partner": [
      9
    ]
  },
  {
    "plz": "82432",
    "partner": [
      9
    ]
  },
  {
    "plz": "82432",
    "partner": [
      9
    ]
  },
  {
    "plz": "82432",
    "partner": [
      9
    ]
  },
  {
    "plz": "82433",
    "partner": [
      9
    ]
  },
  {
    "plz": "82435",
    "partner": [
      9
    ]
  },
  {
    "plz": "82436",
    "partner": [
      9
    ]
  },
  {
    "plz": "82438",
    "partner": [
      9
    ]
  },
  {
    "plz": "82439",
    "partner": [
      9
    ]
  },
  {
    "plz": "82439",
    "partner": [
      9
    ]
  },
  {
    "plz": "82441",
    "partner": [
      9
    ]
  },
  {
    "plz": "82442",
    "partner": [
      9
    ]
  },
  {
    "plz": "82444",
    "partner": [
      9
    ]
  },
  {
    "plz": "82445",
    "partner": [
      9
    ]
  },
  {
    "plz": "82447",
    "partner": [
      9
    ]
  },
  {
    "plz": "82449",
    "partner": [
      9
    ]
  },
  {
    "plz": "82467",
    "partner": [
      9
    ]
  },
  {
    "plz": "82467",
    "partner": [
      9
    ]
  },
  {
    "plz": "82475",
    "partner": [
      9
    ]
  },
  {
    "plz": "82481",
    "partner": [
      9
    ]
  },
  {
    "plz": "82487",
    "partner": [
      9
    ]
  },
  {
    "plz": "82488",
    "partner": [
      9
    ]
  },
  {
    "plz": "82490",
    "partner": [
      9
    ]
  },
  {
    "plz": "82491",
    "partner": [
      9
    ]
  },
  {
    "plz": "82493",
    "partner": [
      9
    ]
  },
  {
    "plz": "82493",
    "partner": [
      9
    ]
  },
  {
    "plz": "82493",
    "partner": [
      9
    ]
  },
  {
    "plz": "82493",
    "partner": [
      9
    ]
  },
  {
    "plz": "82494",
    "partner": [
      9
    ]
  },
  {
    "plz": "82496",
    "partner": [
      9
    ]
  },
  {
    "plz": "82497",
    "partner": [
      9
    ]
  },
  {
    "plz": "82499",
    "partner": [
      9
    ]
  },
  {
    "plz": "82515",
    "partner": [
      9
    ]
  },
  {
    "plz": "82538",
    "partner": [
      9
    ]
  },
  {
    "plz": "82541",
    "partner": [
      9
    ]
  },
  {
    "plz": "82544",
    "partner": [
      9
    ]
  },
  {
    "plz": "82547",
    "partner": [
      9
    ]
  },
  {
    "plz": "82549",
    "partner": [
      9
    ]
  },
  {
    "plz": "83022",
    "partner": [
      9
    ]
  },
  {
    "plz": "83024",
    "partner": [
      9
    ]
  },
  {
    "plz": "83026",
    "partner": [
      9
    ]
  },
  {
    "plz": "83043",
    "partner": [
      9
    ]
  },
  {
    "plz": "83052",
    "partner": [
      9
    ]
  },
  {
    "plz": "83059",
    "partner": [
      9
    ]
  },
  {
    "plz": "83064",
    "partner": [
      9
    ]
  },
  {
    "plz": "83071",
    "partner": [
      9
    ]
  },
  {
    "plz": "83075",
    "partner": [
      9
    ]
  },
  {
    "plz": "83080",
    "partner": [
      9
    ]
  },
  {
    "plz": "83083",
    "partner": [
      9
    ]
  },
  {
    "plz": "83088",
    "partner": [
      9
    ]
  },
  {
    "plz": "83093",
    "partner": [
      9
    ]
  },
  {
    "plz": "83098",
    "partner": [
      9
    ]
  },
  {
    "plz": "83101",
    "partner": [
      9
    ]
  },
  {
    "plz": "83104",
    "partner": [
      9
    ]
  },
  {
    "plz": "83109",
    "partner": [
      9
    ]
  },
  {
    "plz": "83112",
    "partner": [
      9
    ]
  },
  {
    "plz": "83115",
    "partner": [
      9
    ]
  },
  {
    "plz": "83119",
    "partner": [
      9
    ]
  },
  {
    "plz": "83122",
    "partner": [
      9
    ]
  },
  {
    "plz": "83123",
    "partner": [
      9
    ]
  },
  {
    "plz": "83125",
    "partner": [
      9
    ]
  },
  {
    "plz": "83126",
    "partner": [
      9
    ]
  },
  {
    "plz": "83128",
    "partner": [
      9
    ]
  },
  {
    "plz": "83129",
    "partner": [
      9
    ]
  },
  {
    "plz": "83131",
    "partner": [
      9
    ]
  },
  {
    "plz": "83132",
    "partner": [
      9
    ]
  },
  {
    "plz": "83134",
    "partner": [
      9
    ]
  },
  {
    "plz": "83135",
    "partner": [
      9
    ]
  },
  {
    "plz": "83137",
    "partner": [
      9
    ]
  },
  {
    "plz": "83139",
    "partner": [
      9
    ]
  },
  {
    "plz": "83209",
    "partner": [
      9
    ]
  },
  {
    "plz": "83209",
    "partner": [
      9
    ]
  },
  {
    "plz": "83224",
    "partner": [
      9
    ]
  },
  {
    "plz": "83224",
    "partner": [
      9
    ]
  },
  {
    "plz": "83229",
    "partner": [
      9
    ]
  },
  {
    "plz": "83233",
    "partner": [
      9
    ]
  },
  {
    "plz": "83236",
    "partner": [
      9
    ]
  },
  {
    "plz": "83242",
    "partner": [
      9
    ]
  },
  {
    "plz": "83246",
    "partner": [
      9
    ]
  },
  {
    "plz": "83250",
    "partner": [
      9
    ]
  },
  {
    "plz": "83250",
    "partner": [
      9
    ]
  },
  {
    "plz": "83250",
    "partner": [
      9
    ]
  },
  {
    "plz": "83253",
    "partner": [
      9
    ]
  },
  {
    "plz": "83254",
    "partner": [
      9
    ]
  },
  {
    "plz": "83256",
    "partner": [
      9
    ]
  },
  {
    "plz": "83256",
    "partner": [
      9
    ]
  },
  {
    "plz": "83257",
    "partner": [
      9
    ]
  },
  {
    "plz": "83259",
    "partner": [
      9
    ]
  },
  {
    "plz": "83278",
    "partner": [
      9
    ]
  },
  {
    "plz": "83301",
    "partner": [
      9
    ]
  },
  {
    "plz": "83308",
    "partner": [
      9
    ]
  },
  {
    "plz": "83313",
    "partner": [
      9
    ]
  },
  {
    "plz": "83317",
    "partner": [
      9
    ]
  },
  {
    "plz": "83324",
    "partner": [
      9
    ]
  },
  {
    "plz": "83329",
    "partner": [
      9
    ]
  },
  {
    "plz": "83329",
    "partner": [
      9
    ]
  },
  {
    "plz": "83329",
    "partner": [
      9
    ]
  },
  {
    "plz": "83334",
    "partner": [
      9
    ]
  },
  {
    "plz": "83339",
    "partner": [
      9
    ]
  },
  {
    "plz": "83342",
    "partner": [
      9
    ]
  },
  {
    "plz": "83346",
    "partner": [
      9
    ]
  },
  {
    "plz": "83346",
    "partner": [
      9
    ]
  },
  {
    "plz": "83346",
    "partner": [
      9
    ]
  },
  {
    "plz": "83349",
    "partner": [
      9
    ]
  },
  {
    "plz": "83352",
    "partner": [
      9
    ]
  },
  {
    "plz": "83355",
    "partner": [
      9
    ]
  },
  {
    "plz": "83358",
    "partner": [
      9
    ]
  },
  {
    "plz": "83358",
    "partner": [
      9
    ]
  },
  {
    "plz": "83361",
    "partner": [
      9
    ]
  },
  {
    "plz": "83362",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83364",
    "partner": [
      9
    ]
  },
  {
    "plz": "83365",
    "partner": [
      9
    ]
  },
  {
    "plz": "83367",
    "partner": [
      9
    ]
  },
  {
    "plz": "83367",
    "partner": [
      9
    ]
  },
  {
    "plz": "83367",
    "partner": [
      9
    ]
  },
  {
    "plz": "83367",
    "partner": [
      9
    ]
  },
  {
    "plz": "83367",
    "partner": [
      9
    ]
  },
  {
    "plz": "83368",
    "partner": [
      9
    ]
  },
  {
    "plz": "83370",
    "partner": [
      9
    ]
  },
  {
    "plz": "83371",
    "partner": [
      9
    ]
  },
  {
    "plz": "83373",
    "partner": [
      9
    ]
  },
  {
    "plz": "83374",
    "partner": [
      9
    ]
  },
  {
    "plz": "83376",
    "partner": [
      9
    ]
  },
  {
    "plz": "83376",
    "partner": [
      9
    ]
  },
  {
    "plz": "83376",
    "partner": [
      9
    ]
  },
  {
    "plz": "83376",
    "partner": [
      9
    ]
  },
  {
    "plz": "83377",
    "partner": [
      9
    ]
  },
  {
    "plz": "83377",
    "partner": [
      9
    ]
  },
  {
    "plz": "83379",
    "partner": [
      9
    ]
  },
  {
    "plz": "83395",
    "partner": [
      9
    ]
  },
  {
    "plz": "83404",
    "partner": [
      9
    ]
  },
  {
    "plz": "83410",
    "partner": [
      9
    ]
  },
  {
    "plz": "83413",
    "partner": [
      9
    ]
  },
  {
    "plz": "83416",
    "partner": [
      9
    ]
  },
  {
    "plz": "83417",
    "partner": [
      9
    ]
  },
  {
    "plz": "83435",
    "partner": [
      9
    ]
  },
  {
    "plz": "83451",
    "partner": [
      9
    ]
  },
  {
    "plz": "83454",
    "partner": [
      9
    ]
  },
  {
    "plz": "83457",
    "partner": [
      9
    ]
  },
  {
    "plz": "83458",
    "partner": [
      9
    ]
  },
  {
    "plz": "83471",
    "partner": [
      9
    ]
  },
  {
    "plz": "83471",
    "partner": [
      9
    ]
  },
  {
    "plz": "83471",
    "partner": [
      9
    ]
  },
  {
    "plz": "83471",
    "partner": [
      9
    ]
  },
  {
    "plz": "83471",
    "partner": [
      9
    ]
  },
  {
    "plz": "83483",
    "partner": [
      9
    ]
  },
  {
    "plz": "83486",
    "partner": [
      9
    ]
  },
  {
    "plz": "83486",
    "partner": [
      9
    ]
  },
  {
    "plz": "83486",
    "partner": [
      9
    ]
  },
  {
    "plz": "83486",
    "partner": [
      9
    ]
  },
  {
    "plz": "83486",
    "partner": [
      9
    ]
  },
  {
    "plz": "83487",
    "partner": [
      9
    ]
  },
  {
    "plz": "83512",
    "partner": [
      9
    ]
  },
  {
    "plz": "83512",
    "partner": [
      9
    ]
  },
  {
    "plz": "83527",
    "partner": [
      9
    ]
  },
  {
    "plz": "83527",
    "partner": [
      9
    ]
  },
  {
    "plz": "83530",
    "partner": [
      9
    ]
  },
  {
    "plz": "83533",
    "partner": [
      9
    ]
  },
  {
    "plz": "83536",
    "partner": [
      9
    ]
  },
  {
    "plz": "83539",
    "partner": [
      9
    ]
  },
  {
    "plz": "83543",
    "partner": [
      9
    ]
  },
  {
    "plz": "83544",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83546",
    "partner": [
      9
    ]
  },
  {
    "plz": "83547",
    "partner": [
      9
    ]
  },
  {
    "plz": "83549",
    "partner": [
      9
    ]
  },
  {
    "plz": "83550",
    "partner": [
      9
    ]
  },
  {
    "plz": "83553",
    "partner": [
      9
    ]
  },
  {
    "plz": "83555",
    "partner": [
      9
    ]
  },
  {
    "plz": "83555",
    "partner": [
      9
    ]
  },
  {
    "plz": "83555",
    "partner": [
      9
    ]
  },
  {
    "plz": "83555",
    "partner": [
      9
    ]
  },
  {
    "plz": "83555",
    "partner": [
      9
    ]
  },
  {
    "plz": "83556",
    "partner": [
      9
    ]
  },
  {
    "plz": "83558",
    "partner": [
      9
    ]
  },
  {
    "plz": "83559",
    "partner": [
      9
    ]
  },
  {
    "plz": "83559",
    "partner": [
      9
    ]
  },
  {
    "plz": "83559",
    "partner": [
      9
    ]
  },
  {
    "plz": "83559",
    "partner": [
      9
    ]
  },
  {
    "plz": "83559",
    "partner": [
      9
    ]
  },
  {
    "plz": "83561",
    "partner": [
      9
    ]
  },
  {
    "plz": "83562",
    "partner": [
      9
    ]
  },
  {
    "plz": "83564",
    "partner": [
      9
    ]
  },
  {
    "plz": "83567",
    "partner": [
      9
    ]
  },
  {
    "plz": "83569",
    "partner": [
      9
    ]
  },
  {
    "plz": "83607",
    "partner": [
      9
    ]
  },
  {
    "plz": "83620",
    "partner": [
      9
    ]
  },
  {
    "plz": "83623",
    "partner": [
      9
    ]
  },
  {
    "plz": "83624",
    "partner": [
      9
    ]
  },
  {
    "plz": "83626",
    "partner": [
      9
    ]
  },
  {
    "plz": "83626",
    "partner": [
      9
    ]
  },
  {
    "plz": "83627",
    "partner": [
      9
    ]
  },
  {
    "plz": "83629",
    "partner": [
      9
    ]
  },
  {
    "plz": "83646",
    "partner": [
      9
    ]
  },
  {
    "plz": "83646",
    "partner": [
      9
    ]
  },
  {
    "plz": "83661",
    "partner": [
      9
    ]
  },
  {
    "plz": "83666",
    "partner": [
      9
    ]
  },
  {
    "plz": "83670",
    "partner": [
      9
    ]
  },
  {
    "plz": "83671",
    "partner": [
      9
    ]
  },
  {
    "plz": "83673",
    "partner": [
      9
    ]
  },
  {
    "plz": "83674",
    "partner": [
      9
    ]
  },
  {
    "plz": "83676",
    "partner": [
      9
    ]
  },
  {
    "plz": "83677",
    "partner": [
      9
    ]
  },
  {
    "plz": "83677",
    "partner": [
      9
    ]
  },
  {
    "plz": "83679",
    "partner": [
      9
    ]
  },
  {
    "plz": "83684",
    "partner": [
      9
    ]
  },
  {
    "plz": "83700",
    "partner": [
      9
    ]
  },
  {
    "plz": "83700",
    "partner": [
      9
    ]
  },
  {
    "plz": "83700",
    "partner": [
      9
    ]
  },
  {
    "plz": "83700",
    "partner": [
      9
    ]
  },
  {
    "plz": "83703",
    "partner": [
      9
    ]
  },
  {
    "plz": "83707",
    "partner": [
      9
    ]
  },
  {
    "plz": "83707",
    "partner": [
      9
    ]
  },
  {
    "plz": "83708",
    "partner": [
      9
    ]
  },
  {
    "plz": "83714",
    "partner": [
      9
    ]
  },
  {
    "plz": "83727",
    "partner": [
      9
    ]
  },
  {
    "plz": "83727",
    "partner": [
      9
    ]
  },
  {
    "plz": "83730",
    "partner": [
      9
    ]
  },
  {
    "plz": "83734",
    "partner": [
      9
    ]
  },
  {
    "plz": "83735",
    "partner": [
      9
    ]
  },
  {
    "plz": "83737",
    "partner": [
      9
    ]
  },
  {
    "plz": "84028",
    "partner": [
      16
    ]
  },
  {
    "plz": "84030",
    "partner": [
      16
    ]
  },
  {
    "plz": "84030",
    "partner": [
      16
    ]
  },
  {
    "plz": "84032",
    "partner": [
      16
    ]
  },
  {
    "plz": "84032",
    "partner": [
      16
    ]
  },
  {
    "plz": "84032",
    "partner": [
      16
    ]
  },
  {
    "plz": "84034",
    "partner": [
      16
    ]
  },
  {
    "plz": "84036",
    "partner": [
      16
    ]
  },
  {
    "plz": "84036",
    "partner": [
      16
    ]
  },
  {
    "plz": "84048",
    "partner": [
      16
    ]
  },
  {
    "plz": "84051",
    "partner": [
      16
    ]
  },
  {
    "plz": "84056",
    "partner": [
      16
    ]
  },
  {
    "plz": "84061",
    "partner": [
      16
    ]
  },
  {
    "plz": "84066",
    "partner": [
      16
    ]
  },
  {
    "plz": "84069",
    "partner": [
      16
    ]
  },
  {
    "plz": "84072",
    "partner": [
      16
    ]
  },
  {
    "plz": "84076",
    "partner": [
      16
    ]
  },
  {
    "plz": "84076",
    "partner": [
      16
    ]
  },
  {
    "plz": "84079",
    "partner": [
      16
    ]
  },
  {
    "plz": "84082",
    "partner": [
      16
    ]
  },
  {
    "plz": "84085",
    "partner": [
      16
    ]
  },
  {
    "plz": "84088",
    "partner": [
      16
    ]
  },
  {
    "plz": "84088",
    "partner": [
      16
    ]
  },
  {
    "plz": "84089",
    "partner": [
      16
    ]
  },
  {
    "plz": "84091",
    "partner": [
      16
    ]
  },
  {
    "plz": "84092",
    "partner": [
      16
    ]
  },
  {
    "plz": "84094",
    "partner": [
      16
    ]
  },
  {
    "plz": "84095",
    "partner": [
      16
    ]
  },
  {
    "plz": "84097",
    "partner": [
      16
    ]
  },
  {
    "plz": "84098",
    "partner": [
      16
    ]
  },
  {
    "plz": "84100",
    "partner": [
      16
    ]
  },
  {
    "plz": "84101",
    "partner": [
      16
    ]
  },
  {
    "plz": "84103",
    "partner": [
      16
    ]
  },
  {
    "plz": "84104",
    "partner": [
      16
    ]
  },
  {
    "plz": "84106",
    "partner": [
      16
    ]
  },
  {
    "plz": "84107",
    "partner": [
      16
    ]
  },
  {
    "plz": "84109",
    "partner": [
      16
    ]
  },
  {
    "plz": "84130",
    "partner": [
      16
    ]
  },
  {
    "plz": "84137",
    "partner": [
      16
    ]
  },
  {
    "plz": "84137",
    "partner": [
      16
    ]
  },
  {
    "plz": "84140",
    "partner": [
      16
    ]
  },
  {
    "plz": "84144",
    "partner": [
      16
    ]
  },
  {
    "plz": "84149",
    "partner": [
      16
    ]
  },
  {
    "plz": "84152",
    "partner": [
      16
    ]
  },
  {
    "plz": "84155",
    "partner": [
      16
    ]
  },
  {
    "plz": "84160",
    "partner": [
      16
    ]
  },
  {
    "plz": "84163",
    "partner": [
      16
    ]
  },
  {
    "plz": "84163",
    "partner": [
      16
    ]
  },
  {
    "plz": "84164",
    "partner": [
      16
    ]
  },
  {
    "plz": "84164",
    "partner": [
      16
    ]
  },
  {
    "plz": "84166",
    "partner": [
      16
    ]
  },
  {
    "plz": "84168",
    "partner": [
      16
    ]
  },
  {
    "plz": "84169",
    "partner": [
      16
    ]
  },
  {
    "plz": "84171",
    "partner": [
      16
    ]
  },
  {
    "plz": "84172",
    "partner": [
      16
    ]
  },
  {
    "plz": "84174",
    "partner": [
      16
    ]
  },
  {
    "plz": "84175",
    "partner": [
      16
    ]
  },
  {
    "plz": "84175",
    "partner": [
      16
    ]
  },
  {
    "plz": "84177",
    "partner": [
      16
    ]
  },
  {
    "plz": "84178",
    "partner": [
      16
    ]
  },
  {
    "plz": "84180",
    "partner": [
      16
    ]
  },
  {
    "plz": "84181",
    "partner": [
      16
    ]
  },
  {
    "plz": "84183",
    "partner": [
      16
    ]
  },
  {
    "plz": "84184",
    "partner": [
      16
    ]
  },
  {
    "plz": "84186",
    "partner": [
      16
    ]
  },
  {
    "plz": "84187",
    "partner": [
      16
    ]
  },
  {
    "plz": "84189",
    "partner": [
      16
    ]
  },
  {
    "plz": "84307",
    "partner": [
      16
    ]
  },
  {
    "plz": "84323",
    "partner": [
      16
    ]
  },
  {
    "plz": "84326",
    "partner": [
      16
    ]
  },
  {
    "plz": "84326",
    "partner": [
      16
    ]
  },
  {
    "plz": "84329",
    "partner": [
      16
    ]
  },
  {
    "plz": "84332",
    "partner": [
      16
    ]
  },
  {
    "plz": "84333",
    "partner": [
      16
    ]
  },
  {
    "plz": "84335",
    "partner": [
      16
    ]
  },
  {
    "plz": "84337",
    "partner": [
      16
    ]
  },
  {
    "plz": "84339",
    "partner": [
      16
    ]
  },
  {
    "plz": "84347",
    "partner": [
      16
    ]
  },
  {
    "plz": "84359",
    "partner": [
      16
    ]
  },
  {
    "plz": "84364",
    "partner": [
      16
    ]
  },
  {
    "plz": "84367",
    "partner": [
      16
    ]
  },
  {
    "plz": "84367",
    "partner": [
      16
    ]
  },
  {
    "plz": "84367",
    "partner": [
      16
    ]
  },
  {
    "plz": "84371",
    "partner": [
      16
    ]
  },
  {
    "plz": "84371",
    "partner": [
      16
    ]
  },
  {
    "plz": "84375",
    "partner": [
      16
    ]
  },
  {
    "plz": "84378",
    "partner": [
      16
    ]
  },
  {
    "plz": "84381",
    "partner": [
      16
    ]
  },
  {
    "plz": "84384",
    "partner": [
      16
    ]
  },
  {
    "plz": "84385",
    "partner": [
      16
    ]
  },
  {
    "plz": "84387",
    "partner": [
      16
    ]
  },
  {
    "plz": "84389",
    "partner": [
      16
    ]
  },
  {
    "plz": "84405",
    "partner": [
      16
    ]
  },
  {
    "plz": "84416",
    "partner": [
      16
    ]
  },
  {
    "plz": "84416",
    "partner": [
      16
    ]
  },
  {
    "plz": "84419",
    "partner": [
      16
    ]
  },
  {
    "plz": "84419",
    "partner": [
      16
    ]
  },
  {
    "plz": "84419",
    "partner": [
      16
    ]
  },
  {
    "plz": "84424",
    "partner": [
      16
    ]
  },
  {
    "plz": "84427",
    "partner": [
      16
    ]
  },
  {
    "plz": "84428",
    "partner": [
      16
    ]
  },
  {
    "plz": "84428",
    "partner": [
      16
    ]
  },
  {
    "plz": "84431",
    "partner": [
      16
    ]
  },
  {
    "plz": "84431",
    "partner": [
      16
    ]
  },
  {
    "plz": "84432",
    "partner": [
      16
    ]
  },
  {
    "plz": "84434",
    "partner": [
      16
    ]
  },
  {
    "plz": "84435",
    "partner": [
      16
    ]
  },
  {
    "plz": "84437",
    "partner": [
      16
    ]
  },
  {
    "plz": "84439",
    "partner": [
      16
    ]
  },
  {
    "plz": "84453",
    "partner": [
      16
    ]
  },
  {
    "plz": "84478",
    "partner": [
      16
    ]
  },
  {
    "plz": "84489",
    "partner": [
      16
    ]
  },
  {
    "plz": "84494",
    "partner": [
      16
    ]
  },
  {
    "plz": "84494",
    "partner": [
      16
    ]
  },
  {
    "plz": "84494",
    "partner": [
      16
    ]
  },
  {
    "plz": "84494",
    "partner": [
      16
    ]
  },
  {
    "plz": "84503",
    "partner": [
      16
    ]
  },
  {
    "plz": "84503",
    "partner": [
      16
    ]
  },
  {
    "plz": "84508",
    "partner": [
      16
    ]
  },
  {
    "plz": "84513",
    "partner": [
      16
    ]
  },
  {
    "plz": "84513",
    "partner": [
      16
    ]
  },
  {
    "plz": "84518",
    "partner": [
      16
    ]
  },
  {
    "plz": "84524",
    "partner": [
      16
    ]
  },
  {
    "plz": "84529",
    "partner": [
      16
    ]
  },
  {
    "plz": "84533",
    "partner": [
      16
    ]
  },
  {
    "plz": "84533",
    "partner": [
      16
    ]
  },
  {
    "plz": "84533",
    "partner": [
      16
    ]
  },
  {
    "plz": "84539",
    "partner": [
      16
    ]
  },
  {
    "plz": "84539",
    "partner": [
      16
    ]
  },
  {
    "plz": "84543",
    "partner": [
      16
    ]
  },
  {
    "plz": "84544",
    "partner": [
      16
    ]
  },
  {
    "plz": "84546",
    "partner": [
      16
    ]
  },
  {
    "plz": "84547",
    "partner": [
      16
    ]
  },
  {
    "plz": "84549",
    "partner": [
      16
    ]
  },
  {
    "plz": "84550",
    "partner": [
      16
    ]
  },
  {
    "plz": "84552",
    "partner": [
      16
    ]
  },
  {
    "plz": "84553",
    "partner": [
      16
    ]
  },
  {
    "plz": "84555",
    "partner": [
      16
    ]
  },
  {
    "plz": "84556",
    "partner": [
      16
    ]
  },
  {
    "plz": "84558",
    "partner": [
      16
    ]
  },
  {
    "plz": "84558",
    "partner": [
      16
    ]
  },
  {
    "plz": "84559",
    "partner": [
      16
    ]
  },
  {
    "plz": "84561",
    "partner": [
      16
    ]
  },
  {
    "plz": "84562",
    "partner": [
      16
    ]
  },
  {
    "plz": "84564",
    "partner": [
      16
    ]
  },
  {
    "plz": "84565",
    "partner": [
      16
    ]
  },
  {
    "plz": "84567",
    "partner": [
      16
    ]
  },
  {
    "plz": "84567",
    "partner": [
      16
    ]
  },
  {
    "plz": "84568",
    "partner": [
      16
    ]
  },
  {
    "plz": "84570",
    "partner": [
      16
    ]
  },
  {
    "plz": "84571",
    "partner": [
      16
    ]
  },
  {
    "plz": "84573",
    "partner": [
      16
    ]
  },
  {
    "plz": "84574",
    "partner": [
      16
    ]
  },
  {
    "plz": "84576",
    "partner": [
      16
    ]
  },
  {
    "plz": "84577",
    "partner": [
      16
    ]
  },
  {
    "plz": "84579",
    "partner": [
      16
    ]
  },
  {
    "plz": "85049",
    "partner": [
      9
    ]
  },
  {
    "plz": "85051",
    "partner": [
      9
    ]
  },
  {
    "plz": "85053",
    "partner": [
      9
    ]
  },
  {
    "plz": "85055",
    "partner": [
      9
    ]
  },
  {
    "plz": "85057",
    "partner": [
      9
    ]
  },
  {
    "plz": "85072",
    "partner": [
      9
    ]
  },
  {
    "plz": "85072",
    "partner": [
      9
    ]
  },
  {
    "plz": "85077",
    "partner": [
      9
    ]
  },
  {
    "plz": "85080",
    "partner": [
      9
    ]
  },
  {
    "plz": "85084",
    "partner": [
      9
    ]
  },
  {
    "plz": "85088",
    "partner": [
      9
    ]
  },
  {
    "plz": "85092",
    "partner": [
      9
    ]
  },
  {
    "plz": "85092",
    "partner": [
      9
    ]
  },
  {
    "plz": "85095",
    "partner": [
      9
    ]
  },
  {
    "plz": "85098",
    "partner": [
      9
    ]
  },
  {
    "plz": "85101",
    "partner": [
      9
    ]
  },
  {
    "plz": "85104",
    "partner": [
      9
    ]
  },
  {
    "plz": "85107",
    "partner": [
      9
    ]
  },
  {
    "plz": "85110",
    "partner": [
      9
    ]
  },
  {
    "plz": "85111",
    "partner": [
      9
    ]
  },
  {
    "plz": "85113",
    "partner": [
      9
    ]
  },
  {
    "plz": "85114",
    "partner": [
      9
    ]
  },
  {
    "plz": "85116",
    "partner": [
      9
    ]
  },
  {
    "plz": "85117",
    "partner": [
      9
    ]
  },
  {
    "plz": "85119",
    "partner": [
      9
    ]
  },
  {
    "plz": "85120",
    "partner": [
      9
    ]
  },
  {
    "plz": "85122",
    "partner": [
      9
    ]
  },
  {
    "plz": "85123",
    "partner": [
      9
    ]
  },
  {
    "plz": "85125",
    "partner": [
      9
    ]
  },
  {
    "plz": "85126",
    "partner": [
      9
    ]
  },
  {
    "plz": "85128",
    "partner": [
      9
    ]
  },
  {
    "plz": "85129",
    "partner": [
      9
    ]
  },
  {
    "plz": "85131",
    "partner": [
      9
    ]
  },
  {
    "plz": "85132",
    "partner": [
      9
    ]
  },
  {
    "plz": "85134",
    "partner": [
      9
    ]
  },
  {
    "plz": "85135",
    "partner": [
      9
    ]
  },
  {
    "plz": "85137",
    "partner": [
      9
    ]
  },
  {
    "plz": "85139",
    "partner": [
      9
    ]
  },
  {
    "plz": "85221",
    "partner": [
      9
    ]
  },
  {
    "plz": "85229",
    "partner": [
      9
    ]
  },
  {
    "plz": "85232",
    "partner": [
      9
    ]
  },
  {
    "plz": "85235",
    "partner": [
      9
    ]
  },
  {
    "plz": "85235",
    "partner": [
      9
    ]
  },
  {
    "plz": "85238",
    "partner": [
      9
    ]
  },
  {
    "plz": "85241",
    "partner": [
      9
    ]
  },
  {
    "plz": "85244",
    "partner": [
      9
    ]
  },
  {
    "plz": "85247",
    "partner": [
      9
    ]
  },
  {
    "plz": "85250",
    "partner": [
      9
    ]
  },
  {
    "plz": "85253",
    "partner": [
      9
    ]
  },
  {
    "plz": "85254",
    "partner": [
      9
    ]
  },
  {
    "plz": "85254",
    "partner": [
      9
    ]
  },
  {
    "plz": "85256",
    "partner": [
      9
    ]
  },
  {
    "plz": "85258",
    "partner": [
      9
    ]
  },
  {
    "plz": "85259",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85276",
    "partner": [
      9
    ]
  },
  {
    "plz": "85283",
    "partner": [
      9
    ]
  },
  {
    "plz": "85290",
    "partner": [
      9
    ]
  },
  {
    "plz": "85293",
    "partner": [
      9
    ]
  },
  {
    "plz": "85296",
    "partner": [
      9
    ]
  },
  {
    "plz": "85298",
    "partner": [
      9
    ]
  },
  {
    "plz": "85301",
    "partner": [
      9
    ]
  },
  {
    "plz": "85302",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85304",
    "partner": [
      9
    ]
  },
  {
    "plz": "85305",
    "partner": [
      9
    ]
  },
  {
    "plz": "85307",
    "partner": [
      9
    ]
  },
  {
    "plz": "85307",
    "partner": [
      9
    ]
  },
  {
    "plz": "85307",
    "partner": [
      9
    ]
  },
  {
    "plz": "85309",
    "partner": [
      9
    ]
  },
  {
    "plz": "85354",
    "partner": [
      9
    ]
  },
  {
    "plz": "85356",
    "partner": [
      9
    ]
  },
  {
    "plz": "85356",
    "partner": [
      9
    ]
  },
  {
    "plz": "85368",
    "partner": [
      9
    ]
  },
  {
    "plz": "85368",
    "partner": [
      9
    ]
  },
  {
    "plz": "85375",
    "partner": [
      9
    ]
  },
  {
    "plz": "85376",
    "partner": [
      9
    ]
  },
  {
    "plz": "85376",
    "partner": [
      9
    ]
  },
  {
    "plz": "85376",
    "partner": [
      9
    ]
  },
  {
    "plz": "85376",
    "partner": [
      9
    ]
  },
  {
    "plz": "85376",
    "partner": [
      9
    ]
  },
  {
    "plz": "85386",
    "partner": [
      9
    ]
  },
  {
    "plz": "85391",
    "partner": [
      9
    ]
  },
  {
    "plz": "85395",
    "partner": [
      9
    ]
  },
  {
    "plz": "85395",
    "partner": [
      9
    ]
  },
  {
    "plz": "85395",
    "partner": [
      9
    ]
  },
  {
    "plz": "85399",
    "partner": [
      9
    ]
  },
  {
    "plz": "85402",
    "partner": [
      9
    ]
  },
  {
    "plz": "85405",
    "partner": [
      9
    ]
  },
  {
    "plz": "85406",
    "partner": [
      9
    ]
  },
  {
    "plz": "85406",
    "partner": [
      9
    ]
  },
  {
    "plz": "85408",
    "partner": [
      9
    ]
  },
  {
    "plz": "85410",
    "partner": [
      9
    ]
  },
  {
    "plz": "85411",
    "partner": [
      9
    ]
  },
  {
    "plz": "85413",
    "partner": [
      9
    ]
  },
  {
    "plz": "85414",
    "partner": [
      9
    ]
  },
  {
    "plz": "85416",
    "partner": [
      9
    ]
  },
  {
    "plz": "85417",
    "partner": [
      9
    ]
  },
  {
    "plz": "85419",
    "partner": [
      9
    ]
  },
  {
    "plz": "85435",
    "partner": [
      9
    ]
  },
  {
    "plz": "85445",
    "partner": [
      9
    ]
  },
  {
    "plz": "85447",
    "partner": [
      9
    ]
  },
  {
    "plz": "85447",
    "partner": [
      9
    ]
  },
  {
    "plz": "85452",
    "partner": [
      9
    ]
  },
  {
    "plz": "85456",
    "partner": [
      9
    ]
  },
  {
    "plz": "85457",
    "partner": [
      9
    ]
  },
  {
    "plz": "85459",
    "partner": [
      9
    ]
  },
  {
    "plz": "85461",
    "partner": [
      9
    ]
  },
  {
    "plz": "85462",
    "partner": [
      9
    ]
  },
  {
    "plz": "85464",
    "partner": [
      9
    ]
  },
  {
    "plz": "85465",
    "partner": [
      9
    ]
  },
  {
    "plz": "85467",
    "partner": [
      9
    ]
  },
  {
    "plz": "85469",
    "partner": [
      9
    ]
  },
  {
    "plz": "85521",
    "partner": [
      9
    ]
  },
  {
    "plz": "85521",
    "partner": [
      9
    ]
  },
  {
    "plz": "85540",
    "partner": [
      9
    ]
  },
  {
    "plz": "85540",
    "partner": [
      9
    ]
  },
  {
    "plz": "85551",
    "partner": [
      9
    ]
  },
  {
    "plz": "85560",
    "partner": [
      9
    ]
  },
  {
    "plz": "85567",
    "partner": [
      9
    ]
  },
  {
    "plz": "85567",
    "partner": [
      9
    ]
  },
  {
    "plz": "85570",
    "partner": [
      9
    ]
  },
  {
    "plz": "85570",
    "partner": [
      9
    ]
  },
  {
    "plz": "85570",
    "partner": [
      9
    ]
  },
  {
    "plz": "85579",
    "partner": [
      9
    ]
  },
  {
    "plz": "85586",
    "partner": [
      9
    ]
  },
  {
    "plz": "85591",
    "partner": [
      9
    ]
  },
  {
    "plz": "85598",
    "partner": [
      9
    ]
  },
  {
    "plz": "85599",
    "partner": [
      9
    ]
  },
  {
    "plz": "85599",
    "partner": [
      9
    ]
  },
  {
    "plz": "85604",
    "partner": [
      9
    ]
  },
  {
    "plz": "85609",
    "partner": [
      9
    ]
  },
  {
    "plz": "85614",
    "partner": [
      9
    ]
  },
  {
    "plz": "85617",
    "partner": [
      9
    ]
  },
  {
    "plz": "85622",
    "partner": [
      9
    ]
  },
  {
    "plz": "85622",
    "partner": [
      9
    ]
  },
  {
    "plz": "85625",
    "partner": [
      9
    ]
  },
  {
    "plz": "85625",
    "partner": [
      9
    ]
  },
  {
    "plz": "85630",
    "partner": [
      9
    ]
  },
  {
    "plz": "85635",
    "partner": [
      9
    ]
  },
  {
    "plz": "85640",
    "partner": [
      9
    ]
  },
  {
    "plz": "85643",
    "partner": [
      9
    ]
  },
  {
    "plz": "85646",
    "partner": [
      9
    ]
  },
  {
    "plz": "85646",
    "partner": [
      9
    ]
  },
  {
    "plz": "85646",
    "partner": [
      9
    ]
  },
  {
    "plz": "85649",
    "partner": [
      9
    ]
  },
  {
    "plz": "85652",
    "partner": [
      9
    ]
  },
  {
    "plz": "85653",
    "partner": [
      9
    ]
  },
  {
    "plz": "85656",
    "partner": [
      9
    ]
  },
  {
    "plz": "85658",
    "partner": [
      9
    ]
  },
  {
    "plz": "85659",
    "partner": [
      9
    ]
  },
  {
    "plz": "85661",
    "partner": [
      9
    ]
  },
  {
    "plz": "85662",
    "partner": [
      9
    ]
  },
  {
    "plz": "85664",
    "partner": [
      9
    ]
  },
  {
    "plz": "85665",
    "partner": [
      9
    ]
  },
  {
    "plz": "85667",
    "partner": [
      9
    ]
  },
  {
    "plz": "85669",
    "partner": [
      9
    ]
  },
  {
    "plz": "85669",
    "partner": [
      9
    ]
  },
  {
    "plz": "85716",
    "partner": [
      9
    ]
  },
  {
    "plz": "85737",
    "partner": [
      9
    ]
  },
  {
    "plz": "85737",
    "partner": [
      9
    ]
  },
  {
    "plz": "85748",
    "partner": [
      9
    ]
  },
  {
    "plz": "85757",
    "partner": [
      9
    ]
  },
  {
    "plz": "85764",
    "partner": [
      9
    ]
  },
  {
    "plz": "85764",
    "partner": [
      9
    ]
  },
  {
    "plz": "85774",
    "partner": [
      9
    ]
  },
  {
    "plz": "85777",
    "partner": [
      9
    ]
  },
  {
    "plz": "85778",
    "partner": [
      9
    ]
  },
  {
    "plz": "86150",
    "partner": [
      9
    ]
  },
  {
    "plz": "86152",
    "partner": [
      9
    ]
  },
  {
    "plz": "86153",
    "partner": [
      9
    ]
  },
  {
    "plz": "86154",
    "partner": [
      9
    ]
  },
  {
    "plz": "86156",
    "partner": [
      9
    ]
  },
  {
    "plz": "86157",
    "partner": [
      9
    ]
  },
  {
    "plz": "86159",
    "partner": [
      9
    ]
  },
  {
    "plz": "86161",
    "partner": [
      9
    ]
  },
  {
    "plz": "86163",
    "partner": [
      9
    ]
  },
  {
    "plz": "86165",
    "partner": [
      9
    ]
  },
  {
    "plz": "86167",
    "partner": [
      9
    ]
  },
  {
    "plz": "86169",
    "partner": [
      9
    ]
  },
  {
    "plz": "86179",
    "partner": [
      9
    ]
  },
  {
    "plz": "86199",
    "partner": [
      9
    ]
  },
  {
    "plz": "86316",
    "partner": [
      9
    ]
  },
  {
    "plz": "86343",
    "partner": [
      9
    ]
  },
  {
    "plz": "86356",
    "partner": [
      9
    ]
  },
  {
    "plz": "86368",
    "partner": [
      9
    ]
  },
  {
    "plz": "86381",
    "partner": [
      9
    ]
  },
  {
    "plz": "86391",
    "partner": [
      9
    ]
  },
  {
    "plz": "86399",
    "partner": [
      9
    ]
  },
  {
    "plz": "86405",
    "partner": [
      9
    ]
  },
  {
    "plz": "86415",
    "partner": [
      9
    ]
  },
  {
    "plz": "86420",
    "partner": [
      9
    ]
  },
  {
    "plz": "86424",
    "partner": [
      9
    ]
  },
  {
    "plz": "86438",
    "partner": [
      9
    ]
  },
  {
    "plz": "86441",
    "partner": [
      9
    ]
  },
  {
    "plz": "86444",
    "partner": [
      9
    ]
  },
  {
    "plz": "86447",
    "partner": [
      9
    ]
  },
  {
    "plz": "86447",
    "partner": [
      9
    ]
  },
  {
    "plz": "86450",
    "partner": [
      9
    ]
  },
  {
    "plz": "86453",
    "partner": [
      9
    ]
  },
  {
    "plz": "86456",
    "partner": [
      9
    ]
  },
  {
    "plz": "86459",
    "partner": [
      9
    ]
  },
  {
    "plz": "86462",
    "partner": [
      9
    ]
  },
  {
    "plz": "86465",
    "partner": [
      9
    ]
  },
  {
    "plz": "86465",
    "partner": [
      9
    ]
  },
  {
    "plz": "86470",
    "partner": [
      9
    ]
  },
  {
    "plz": "86473",
    "partner": [
      9
    ]
  },
  {
    "plz": "86476",
    "partner": [
      9
    ]
  },
  {
    "plz": "86477",
    "partner": [
      9
    ]
  },
  {
    "plz": "86479",
    "partner": [
      9
    ]
  },
  {
    "plz": "86480",
    "partner": [
      9
    ]
  },
  {
    "plz": "86480",
    "partner": [
      9
    ]
  },
  {
    "plz": "86482",
    "partner": [
      9
    ]
  },
  {
    "plz": "86483",
    "partner": [
      9
    ]
  },
  {
    "plz": "86485",
    "partner": [
      9
    ]
  },
  {
    "plz": "86486",
    "partner": [
      9
    ]
  },
  {
    "plz": "86488",
    "partner": [
      9
    ]
  },
  {
    "plz": "86489",
    "partner": [
      9
    ]
  },
  {
    "plz": "86491",
    "partner": [
      9
    ]
  },
  {
    "plz": "86492",
    "partner": [
      9
    ]
  },
  {
    "plz": "86494",
    "partner": [
      9
    ]
  },
  {
    "plz": "86495",
    "partner": [
      9
    ]
  },
  {
    "plz": "86497",
    "partner": [
      9
    ]
  },
  {
    "plz": "86498",
    "partner": [
      9
    ]
  },
  {
    "plz": "86500",
    "partner": [
      9
    ]
  },
  {
    "plz": "86502",
    "partner": [
      9
    ]
  },
  {
    "plz": "86504",
    "partner": [
      9
    ]
  },
  {
    "plz": "86505",
    "partner": [
      9
    ]
  },
  {
    "plz": "86507",
    "partner": [
      9
    ]
  },
  {
    "plz": "86507",
    "partner": [
      9
    ]
  },
  {
    "plz": "86508",
    "partner": [
      9
    ]
  },
  {
    "plz": "86510",
    "partner": [
      9
    ]
  },
  {
    "plz": "86511",
    "partner": [
      9
    ]
  },
  {
    "plz": "86513",
    "partner": [
      9
    ]
  },
  {
    "plz": "86514",
    "partner": [
      9
    ]
  },
  {
    "plz": "86517",
    "partner": [
      9
    ]
  },
  {
    "plz": "86519",
    "partner": [
      9
    ]
  },
  {
    "plz": "86529",
    "partner": [
      9
    ]
  },
  {
    "plz": "86551",
    "partner": [
      9
    ]
  },
  {
    "plz": "86554",
    "partner": [
      9
    ]
  },
  {
    "plz": "86556",
    "partner": [
      9
    ]
  },
  {
    "plz": "86558",
    "partner": [
      9
    ]
  },
  {
    "plz": "86559",
    "partner": [
      9
    ]
  },
  {
    "plz": "86561",
    "partner": [
      9
    ]
  },
  {
    "plz": "86562",
    "partner": [
      9
    ]
  },
  {
    "plz": "86564",
    "partner": [
      9
    ]
  },
  {
    "plz": "86565",
    "partner": [
      9
    ]
  },
  {
    "plz": "86567",
    "partner": [
      9
    ]
  },
  {
    "plz": "86568",
    "partner": [
      9
    ]
  },
  {
    "plz": "86570",
    "partner": [
      9
    ]
  },
  {
    "plz": "86571",
    "partner": [
      9
    ]
  },
  {
    "plz": "86573",
    "partner": [
      9
    ]
  },
  {
    "plz": "86574",
    "partner": [
      9
    ]
  },
  {
    "plz": "86576",
    "partner": [
      9
    ]
  },
  {
    "plz": "86577",
    "partner": [
      9
    ]
  },
  {
    "plz": "86579",
    "partner": [
      9
    ]
  },
  {
    "plz": "86609",
    "partner": [
      9
    ]
  },
  {
    "plz": "86633",
    "partner": [
      9
    ]
  },
  {
    "plz": "86637",
    "partner": [
      9
    ]
  },
  {
    "plz": "86637",
    "partner": [
      9
    ]
  },
  {
    "plz": "86637",
    "partner": [
      9
    ]
  },
  {
    "plz": "86637",
    "partner": [
      9
    ]
  },
  {
    "plz": "86641",
    "partner": [
      9
    ]
  },
  {
    "plz": "86643",
    "partner": [
      9
    ]
  },
  {
    "plz": "86647",
    "partner": [
      9
    ]
  },
  {
    "plz": "86650",
    "partner": [
      9
    ]
  },
  {
    "plz": "86653",
    "partner": [
      9
    ]
  },
  {
    "plz": "86653",
    "partner": [
      9
    ]
  },
  {
    "plz": "86655",
    "partner": [
      9
    ]
  },
  {
    "plz": "86657",
    "partner": [
      9
    ]
  },
  {
    "plz": "86660",
    "partner": [
      9
    ]
  },
  {
    "plz": "86663",
    "partner": [
      9
    ]
  },
  {
    "plz": "86666",
    "partner": [
      9
    ]
  },
  {
    "plz": "86668",
    "partner": [
      9
    ]
  },
  {
    "plz": "86669",
    "partner": [
      9
    ]
  },
  {
    "plz": "86672",
    "partner": [
      9
    ]
  },
  {
    "plz": "86673",
    "partner": [
      9
    ]
  },
  {
    "plz": "86674",
    "partner": [
      9
    ]
  },
  {
    "plz": "86675",
    "partner": [
      9
    ]
  },
  {
    "plz": "86676",
    "partner": [
      9
    ]
  },
  {
    "plz": "86678",
    "partner": [
      9
    ]
  },
  {
    "plz": "86679",
    "partner": [
      9
    ]
  },
  {
    "plz": "86681",
    "partner": [
      9
    ]
  },
  {
    "plz": "86682",
    "partner": [
      9
    ]
  },
  {
    "plz": "86684",
    "partner": [
      9
    ]
  },
  {
    "plz": "86685",
    "partner": [
      9
    ]
  },
  {
    "plz": "86687",
    "partner": [
      9
    ]
  },
  {
    "plz": "86688",
    "partner": [
      9
    ]
  },
  {
    "plz": "86690",
    "partner": [
      9
    ]
  },
  {
    "plz": "86692",
    "partner": [
      9
    ]
  },
  {
    "plz": "86694",
    "partner": [
      9
    ]
  },
  {
    "plz": "86695",
    "partner": [
      9
    ]
  },
  {
    "plz": "86695",
    "partner": [
      9
    ]
  },
  {
    "plz": "86697",
    "partner": [
      9
    ]
  },
  {
    "plz": "86698",
    "partner": [
      9
    ]
  },
  {
    "plz": "86700",
    "partner": [
      9
    ]
  },
  {
    "plz": "86701",
    "partner": [
      9
    ]
  },
  {
    "plz": "86703",
    "partner": [
      9
    ]
  },
  {
    "plz": "86704",
    "partner": [
      9
    ]
  },
  {
    "plz": "86706",
    "partner": [
      9
    ]
  },
  {
    "plz": "86707",
    "partner": [
      9
    ]
  },
  {
    "plz": "86707",
    "partner": [
      9
    ]
  },
  {
    "plz": "86709",
    "partner": [
      9
    ]
  },
  {
    "plz": "86720",
    "partner": [
      9
    ]
  },
  {
    "plz": "86732",
    "partner": [
      9
    ]
  },
  {
    "plz": "86733",
    "partner": [
      9
    ]
  },
  {
    "plz": "86735",
    "partner": [
      9
    ]
  },
  {
    "plz": "86735",
    "partner": [
      9
    ]
  },
  {
    "plz": "86736",
    "partner": [
      9
    ]
  },
  {
    "plz": "86738",
    "partner": [
      9
    ]
  },
  {
    "plz": "86739",
    "partner": [
      9
    ]
  },
  {
    "plz": "86739",
    "partner": [
      9
    ]
  },
  {
    "plz": "86741",
    "partner": [
      9
    ]
  },
  {
    "plz": "86742",
    "partner": [
      9
    ]
  },
  {
    "plz": "86744",
    "partner": [
      9
    ]
  },
  {
    "plz": "86745",
    "partner": [
      9
    ]
  },
  {
    "plz": "86747",
    "partner": [
      9
    ]
  },
  {
    "plz": "86748",
    "partner": [
      9
    ]
  },
  {
    "plz": "86750",
    "partner": [
      9
    ]
  },
  {
    "plz": "86751",
    "partner": [
      9
    ]
  },
  {
    "plz": "86753",
    "partner": [
      9
    ]
  },
  {
    "plz": "86754",
    "partner": [
      9
    ]
  },
  {
    "plz": "86756",
    "partner": [
      9
    ]
  },
  {
    "plz": "86757",
    "partner": [
      9
    ]
  },
  {
    "plz": "86759",
    "partner": [
      9
    ]
  },
  {
    "plz": "86807",
    "partner": [
      9
    ]
  },
  {
    "plz": "86825",
    "partner": [
      9
    ]
  },
  {
    "plz": "86830",
    "partner": [
      9
    ]
  },
  {
    "plz": "86833",
    "partner": [
      9
    ]
  },
  {
    "plz": "86836",
    "partner": [
      9
    ]
  },
  {
    "plz": "86836",
    "partner": [
      9
    ]
  },
  {
    "plz": "86836",
    "partner": [
      9
    ]
  },
  {
    "plz": "86836",
    "partner": [
      9
    ]
  },
  {
    "plz": "86842",
    "partner": [
      9
    ]
  },
  {
    "plz": "86845",
    "partner": [
      9
    ]
  },
  {
    "plz": "86850",
    "partner": [
      9
    ]
  },
  {
    "plz": "86853",
    "partner": [
      9
    ]
  },
  {
    "plz": "86854",
    "partner": [
      9
    ]
  },
  {
    "plz": "86856",
    "partner": [
      9
    ]
  },
  {
    "plz": "86857",
    "partner": [
      9
    ]
  },
  {
    "plz": "86859",
    "partner": [
      9
    ]
  },
  {
    "plz": "86860",
    "partner": [
      9
    ]
  },
  {
    "plz": "86862",
    "partner": [
      9
    ]
  },
  {
    "plz": "86863",
    "partner": [
      9
    ]
  },
  {
    "plz": "86865",
    "partner": [
      9
    ]
  },
  {
    "plz": "86866",
    "partner": [
      9
    ]
  },
  {
    "plz": "86868",
    "partner": [
      9
    ]
  },
  {
    "plz": "86869",
    "partner": [
      9
    ]
  },
  {
    "plz": "86871",
    "partner": [
      9
    ]
  },
  {
    "plz": "86872",
    "partner": [
      9
    ]
  },
  {
    "plz": "86874",
    "partner": [
      9
    ]
  },
  {
    "plz": "86875",
    "partner": [
      9
    ]
  },
  {
    "plz": "86877",
    "partner": [
      9
    ]
  },
  {
    "plz": "86879",
    "partner": [
      9
    ]
  },
  {
    "plz": "86899",
    "partner": [
      9
    ]
  },
  {
    "plz": "86911",
    "partner": [
      9
    ]
  },
  {
    "plz": "86916",
    "partner": [
      9
    ]
  },
  {
    "plz": "86919",
    "partner": [
      9
    ]
  },
  {
    "plz": "86920",
    "partner": [
      9
    ]
  },
  {
    "plz": "86920",
    "partner": [
      9
    ]
  },
  {
    "plz": "86922",
    "partner": [
      9
    ]
  },
  {
    "plz": "86923",
    "partner": [
      9
    ]
  },
  {
    "plz": "86925",
    "partner": [
      9
    ]
  },
  {
    "plz": "86926",
    "partner": [
      9
    ]
  },
  {
    "plz": "86926",
    "partner": [
      9
    ]
  },
  {
    "plz": "86926",
    "partner": [
      9
    ]
  },
  {
    "plz": "86928",
    "partner": [
      9
    ]
  },
  {
    "plz": "86929",
    "partner": [
      9
    ]
  },
  {
    "plz": "86931",
    "partner": [
      9
    ]
  },
  {
    "plz": "86932",
    "partner": [
      9
    ]
  },
  {
    "plz": "86932",
    "partner": [
      9
    ]
  },
  {
    "plz": "86934",
    "partner": [
      9
    ]
  },
  {
    "plz": "86935",
    "partner": [
      9
    ]
  },
  {
    "plz": "86937",
    "partner": [
      9
    ]
  },
  {
    "plz": "86938",
    "partner": [
      9
    ]
  },
  {
    "plz": "86940",
    "partner": [
      9
    ]
  },
  {
    "plz": "86941",
    "partner": [
      9
    ]
  },
  {
    "plz": "86943",
    "partner": [
      9
    ]
  },
  {
    "plz": "86944",
    "partner": [
      9
    ]
  },
  {
    "plz": "86946",
    "partner": [
      9
    ]
  },
  {
    "plz": "86947",
    "partner": [
      9
    ]
  },
  {
    "plz": "86949",
    "partner": [
      9
    ]
  },
  {
    "plz": "86956",
    "partner": [
      9
    ]
  },
  {
    "plz": "86971",
    "partner": [
      9
    ]
  },
  {
    "plz": "86972",
    "partner": [
      9
    ]
  },
  {
    "plz": "86974",
    "partner": [
      9
    ]
  },
  {
    "plz": "86975",
    "partner": [
      9
    ]
  },
  {
    "plz": "86977",
    "partner": [
      9
    ]
  },
  {
    "plz": "86978",
    "partner": [
      9
    ]
  },
  {
    "plz": "86980",
    "partner": [
      9
    ]
  },
  {
    "plz": "86981",
    "partner": [
      9
    ]
  },
  {
    "plz": "86983",
    "partner": [
      9
    ]
  },
  {
    "plz": "86984",
    "partner": [
      9
    ]
  },
  {
    "plz": "86986",
    "partner": [
      9
    ]
  },
  {
    "plz": "86987",
    "partner": [
      9
    ]
  },
  {
    "plz": "86989",
    "partner": [
      9
    ]
  },
  {
    "plz": "86989",
    "partner": [
      9
    ]
  },
  {
    "plz": "87435",
    "partner": [
      9
    ]
  },
  {
    "plz": "87437",
    "partner": [
      9
    ]
  },
  {
    "plz": "87439",
    "partner": [
      9
    ]
  },
  {
    "plz": "87448",
    "partner": [
      9
    ]
  },
  {
    "plz": "87452",
    "partner": [
      9
    ]
  },
  {
    "plz": "87459",
    "partner": [
      9
    ]
  },
  {
    "plz": "87463",
    "partner": [
      9
    ]
  },
  {
    "plz": "87466",
    "partner": [
      9
    ]
  },
  {
    "plz": "87471",
    "partner": [
      9
    ]
  },
  {
    "plz": "87474",
    "partner": [
      9
    ]
  },
  {
    "plz": "87477",
    "partner": [
      9
    ]
  },
  {
    "plz": "87480",
    "partner": [
      9
    ]
  },
  {
    "plz": "87484",
    "partner": [
      9
    ]
  },
  {
    "plz": "87487",
    "partner": [
      9
    ]
  },
  {
    "plz": "87488",
    "partner": [
      9
    ]
  },
  {
    "plz": "87490",
    "partner": [
      9
    ]
  },
  {
    "plz": "87493",
    "partner": [
      9
    ]
  },
  {
    "plz": "87494",
    "partner": [
      9
    ]
  },
  {
    "plz": "87496",
    "partner": [
      9
    ]
  },
  {
    "plz": "87497",
    "partner": [
      9
    ]
  },
  {
    "plz": "87499",
    "partner": [
      9
    ]
  },
  {
    "plz": "87509",
    "partner": [
      9
    ]
  },
  {
    "plz": "87527",
    "partner": [
      9
    ]
  },
  {
    "plz": "87527",
    "partner": [
      9
    ]
  },
  {
    "plz": "87534",
    "partner": [
      9
    ]
  },
  {
    "plz": "87538",
    "partner": [
      9
    ]
  },
  {
    "plz": "87538",
    "partner": [
      9
    ]
  },
  {
    "plz": "87538",
    "partner": [
      9
    ]
  },
  {
    "plz": "87538",
    "partner": [
      9
    ]
  },
  {
    "plz": "87541",
    "partner": [
      9
    ]
  },
  {
    "plz": "87544",
    "partner": [
      9
    ]
  },
  {
    "plz": "87545",
    "partner": [
      9
    ]
  },
  {
    "plz": "87547",
    "partner": [
      9
    ]
  },
  {
    "plz": "87549",
    "partner": [
      9
    ]
  },
  {
    "plz": "87561",
    "partner": [
      9
    ]
  },
  {
    "plz": "87600",
    "partner": [
      9
    ]
  },
  {
    "plz": "87616",
    "partner": [
      9
    ]
  },
  {
    "plz": "87616",
    "partner": [
      9
    ]
  },
  {
    "plz": "87629",
    "partner": [
      9
    ]
  },
  {
    "plz": "87634",
    "partner": [
      9
    ]
  },
  {
    "plz": "87634",
    "partner": [
      9
    ]
  },
  {
    "plz": "87637",
    "partner": [
      9
    ]
  },
  {
    "plz": "87637",
    "partner": [
      9
    ]
  },
  {
    "plz": "87640",
    "partner": [
      9
    ]
  },
  {
    "plz": "87642",
    "partner": [
      9
    ]
  },
  {
    "plz": "87645",
    "partner": [
      9
    ]
  },
  {
    "plz": "87647",
    "partner": [
      9
    ]
  },
  {
    "plz": "87647",
    "partner": [
      9
    ]
  },
  {
    "plz": "87648",
    "partner": [
      9
    ]
  },
  {
    "plz": "87650",
    "partner": [
      9
    ]
  },
  {
    "plz": "87651",
    "partner": [
      9
    ]
  },
  {
    "plz": "87653",
    "partner": [
      9
    ]
  },
  {
    "plz": "87654",
    "partner": [
      9
    ]
  },
  {
    "plz": "87656",
    "partner": [
      9
    ]
  },
  {
    "plz": "87657",
    "partner": [
      9
    ]
  },
  {
    "plz": "87659",
    "partner": [
      9
    ]
  },
  {
    "plz": "87660",
    "partner": [
      9
    ]
  },
  {
    "plz": "87662",
    "partner": [
      9
    ]
  },
  {
    "plz": "87662",
    "partner": [
      9
    ]
  },
  {
    "plz": "87663",
    "partner": [
      9
    ]
  },
  {
    "plz": "87665",
    "partner": [
      9
    ]
  },
  {
    "plz": "87666",
    "partner": [
      9
    ]
  },
  {
    "plz": "87668",
    "partner": [
      9
    ]
  },
  {
    "plz": "87669",
    "partner": [
      9
    ]
  },
  {
    "plz": "87671",
    "partner": [
      9
    ]
  },
  {
    "plz": "87671",
    "partner": [
      9
    ]
  },
  {
    "plz": "87672",
    "partner": [
      9
    ]
  },
  {
    "plz": "87674",
    "partner": [
      9
    ]
  },
  {
    "plz": "87675",
    "partner": [
      9
    ]
  },
  {
    "plz": "87675",
    "partner": [
      9
    ]
  },
  {
    "plz": "87677",
    "partner": [
      9
    ]
  },
  {
    "plz": "87679",
    "partner": [
      9
    ]
  },
  {
    "plz": "87700",
    "partner": [
      9
    ]
  },
  {
    "plz": "87719",
    "partner": [
      9
    ]
  },
  {
    "plz": "87724",
    "partner": [
      9
    ]
  },
  {
    "plz": "87724",
    "partner": [
      9
    ]
  },
  {
    "plz": "87724",
    "partner": [
      9
    ]
  },
  {
    "plz": "87727",
    "partner": [
      9
    ]
  },
  {
    "plz": "87730",
    "partner": [
      9
    ]
  },
  {
    "plz": "87733",
    "partner": [
      9
    ]
  },
  {
    "plz": "87734",
    "partner": [
      9
    ]
  },
  {
    "plz": "87736",
    "partner": [
      9
    ]
  },
  {
    "plz": "87737",
    "partner": [
      9
    ]
  },
  {
    "plz": "87739",
    "partner": [
      9
    ]
  },
  {
    "plz": "87740",
    "partner": [
      9
    ]
  },
  {
    "plz": "87742",
    "partner": [
      9
    ]
  },
  {
    "plz": "87742",
    "partner": [
      9
    ]
  },
  {
    "plz": "87743",
    "partner": [
      9
    ]
  },
  {
    "plz": "87743",
    "partner": [
      9
    ]
  },
  {
    "plz": "87745",
    "partner": [
      9
    ]
  },
  {
    "plz": "87746",
    "partner": [
      9
    ]
  },
  {
    "plz": "87748",
    "partner": [
      9
    ]
  },
  {
    "plz": "87749",
    "partner": [
      9
    ]
  },
  {
    "plz": "87751",
    "partner": [
      9
    ]
  },
  {
    "plz": "87752",
    "partner": [
      9
    ]
  },
  {
    "plz": "87754",
    "partner": [
      9
    ]
  },
  {
    "plz": "87755",
    "partner": [
      9
    ]
  },
  {
    "plz": "87757",
    "partner": [
      9
    ]
  },
  {
    "plz": "87757",
    "partner": [
      9
    ]
  },
  {
    "plz": "87758",
    "partner": [
      9
    ]
  },
  {
    "plz": "87760",
    "partner": [
      9
    ]
  },
  {
    "plz": "87761",
    "partner": [
      9
    ]
  },
  {
    "plz": "87763",
    "partner": [
      9
    ]
  },
  {
    "plz": "87764",
    "partner": [
      9
    ]
  },
  {
    "plz": "87766",
    "partner": [
      9
    ]
  },
  {
    "plz": "87767",
    "partner": [
      9
    ]
  },
  {
    "plz": "87769",
    "partner": [
      9
    ]
  },
  {
    "plz": "87770",
    "partner": [
      9
    ]
  },
  {
    "plz": "87770",
    "partner": [
      9
    ]
  },
  {
    "plz": "87772",
    "partner": [
      9
    ]
  },
  {
    "plz": "87773",
    "partner": [
      9
    ]
  },
  {
    "plz": "87775",
    "partner": [
      9
    ]
  },
  {
    "plz": "87776",
    "partner": [
      9
    ]
  },
  {
    "plz": "87778",
    "partner": [
      9
    ]
  },
  {
    "plz": "87779",
    "partner": [
      9
    ]
  },
  {
    "plz": "87781",
    "partner": [
      9
    ]
  },
  {
    "plz": "87782",
    "partner": [
      9
    ]
  },
  {
    "plz": "87782",
    "partner": [
      9
    ]
  },
  {
    "plz": "87782",
    "partner": [
      9
    ]
  },
  {
    "plz": "87784",
    "partner": [
      9
    ]
  },
  {
    "plz": "87785",
    "partner": [
      9
    ]
  },
  {
    "plz": "87787",
    "partner": [
      9
    ]
  },
  {
    "plz": "87789",
    "partner": [
      9
    ]
  },
  {
    "plz": "88045",
    "partner": [
      9
    ]
  },
  {
    "plz": "88046",
    "partner": [
      9
    ]
  },
  {
    "plz": "88048",
    "partner": [
      9
    ]
  },
  {
    "plz": "88069",
    "partner": [
      9
    ]
  },
  {
    "plz": "88074",
    "partner": [
      9
    ]
  },
  {
    "plz": "88079",
    "partner": [
      9
    ]
  },
  {
    "plz": "88079",
    "partner": [
      9
    ]
  },
  {
    "plz": "88079",
    "partner": [
      9
    ]
  },
  {
    "plz": "88085",
    "partner": [
      9
    ]
  },
  {
    "plz": "88090",
    "partner": [
      9
    ]
  },
  {
    "plz": "88094",
    "partner": [
      9
    ]
  },
  {
    "plz": "88097",
    "partner": [
      9
    ]
  },
  {
    "plz": "88099",
    "partner": [
      9
    ]
  },
  {
    "plz": "88131",
    "partner": [
      9
    ]
  },
  {
    "plz": "88131",
    "partner": [
      9
    ]
  },
  {
    "plz": "88138",
    "partner": [
      9
    ]
  },
  {
    "plz": "88138",
    "partner": [
      9
    ]
  },
  {
    "plz": "88138",
    "partner": [
      9
    ]
  },
  {
    "plz": "88142",
    "partner": [
      9
    ]
  },
  {
    "plz": "88145",
    "partner": [
      9
    ]
  },
  {
    "plz": "88145",
    "partner": [
      9
    ]
  },
  {
    "plz": "88147",
    "partner": [
      9
    ]
  },
  {
    "plz": "88149",
    "partner": [
      9
    ]
  },
  {
    "plz": "88161",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88167",
    "partner": [
      9
    ]
  },
  {
    "plz": "88171",
    "partner": [
      9
    ]
  },
  {
    "plz": "88175",
    "partner": [
      9
    ]
  },
  {
    "plz": "88175",
    "partner": [
      9
    ]
  },
  {
    "plz": "88178",
    "partner": [
      9
    ]
  },
  {
    "plz": "88179",
    "partner": [
      9
    ]
  },
  {
    "plz": "88212",
    "partner": [
      9
    ]
  },
  {
    "plz": "88213",
    "partner": [
      9
    ]
  },
  {
    "plz": "88214",
    "partner": [
      9
    ]
  },
  {
    "plz": "88239",
    "partner": [
      9
    ]
  },
  {
    "plz": "88250",
    "partner": [
      9
    ]
  },
  {
    "plz": "88255",
    "partner": [
      9
    ]
  },
  {
    "plz": "88255",
    "partner": [
      9
    ]
  },
  {
    "plz": "88260",
    "partner": [
      9
    ]
  },
  {
    "plz": "88263",
    "partner": [
      9
    ]
  },
  {
    "plz": "88267",
    "partner": [
      9
    ]
  },
  {
    "plz": "88271",
    "partner": [
      9
    ]
  },
  {
    "plz": "88273",
    "partner": [
      9
    ]
  },
  {
    "plz": "88276",
    "partner": [
      9
    ]
  },
  {
    "plz": "88279",
    "partner": [
      9
    ]
  },
  {
    "plz": "88281",
    "partner": [
      9
    ]
  },
  {
    "plz": "88284",
    "partner": [
      9
    ]
  },
  {
    "plz": "88285",
    "partner": [
      9
    ]
  },
  {
    "plz": "88287",
    "partner": [
      9
    ]
  },
  {
    "plz": "88289",
    "partner": [
      9
    ]
  },
  {
    "plz": "88299",
    "partner": [
      9
    ]
  },
  {
    "plz": "88316",
    "partner": [
      9
    ]
  },
  {
    "plz": "88317",
    "partner": [
      9
    ]
  },
  {
    "plz": "88319",
    "partner": [
      9
    ]
  },
  {
    "plz": "88326",
    "partner": [
      9
    ]
  },
  {
    "plz": "88339",
    "partner": [
      9
    ]
  },
  {
    "plz": "88348",
    "partner": [
      9
    ]
  },
  {
    "plz": "88348",
    "partner": [
      9
    ]
  },
  {
    "plz": "88353",
    "partner": [
      9
    ]
  },
  {
    "plz": "88356",
    "partner": [
      9
    ]
  },
  {
    "plz": "88361",
    "partner": [
      9
    ]
  },
  {
    "plz": "88361",
    "partner": [
      9
    ]
  },
  {
    "plz": "88361",
    "partner": [
      9
    ]
  },
  {
    "plz": "88364",
    "partner": [
      9
    ]
  },
  {
    "plz": "88367",
    "partner": [
      9
    ]
  },
  {
    "plz": "88368",
    "partner": [
      9
    ]
  },
  {
    "plz": "88370",
    "partner": [
      9
    ]
  },
  {
    "plz": "88371",
    "partner": [
      9
    ]
  },
  {
    "plz": "88373",
    "partner": [
      9
    ]
  },
  {
    "plz": "88373",
    "partner": [
      9
    ]
  },
  {
    "plz": "88374",
    "partner": [
      9
    ]
  },
  {
    "plz": "88376",
    "partner": [
      9
    ]
  },
  {
    "plz": "88377",
    "partner": [
      9
    ]
  },
  {
    "plz": "88379",
    "partner": [
      9
    ]
  },
  {
    "plz": "88379",
    "partner": [
      9
    ]
  },
  {
    "plz": "88400",
    "partner": [
      9
    ]
  },
  {
    "plz": "88400",
    "partner": [
      9
    ]
  },
  {
    "plz": "88400",
    "partner": [
      9
    ]
  },
  {
    "plz": "88400",
    "partner": [
      9
    ]
  },
  {
    "plz": "88410",
    "partner": [
      9
    ]
  },
  {
    "plz": "88416",
    "partner": [
      9
    ]
  },
  {
    "plz": "88416",
    "partner": [
      9
    ]
  },
  {
    "plz": "88416",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88422",
    "partner": [
      9
    ]
  },
  {
    "plz": "88427",
    "partner": [
      9
    ]
  },
  {
    "plz": "88430",
    "partner": [
      9
    ]
  },
  {
    "plz": "88430",
    "partner": [
      9
    ]
  },
  {
    "plz": "88433",
    "partner": [
      9
    ]
  },
  {
    "plz": "88436",
    "partner": [
      9
    ]
  },
  {
    "plz": "88436",
    "partner": [
      9
    ]
  },
  {
    "plz": "88437",
    "partner": [
      9
    ]
  },
  {
    "plz": "88441",
    "partner": [
      9
    ]
  },
  {
    "plz": "88444",
    "partner": [
      9
    ]
  },
  {
    "plz": "88447",
    "partner": [
      9
    ]
  },
  {
    "plz": "88448",
    "partner": [
      9
    ]
  },
  {
    "plz": "88450",
    "partner": [
      9
    ]
  },
  {
    "plz": "88451",
    "partner": [
      9
    ]
  },
  {
    "plz": "88453",
    "partner": [
      9
    ]
  },
  {
    "plz": "88454",
    "partner": [
      9
    ]
  },
  {
    "plz": "88456",
    "partner": [
      9
    ]
  },
  {
    "plz": "88457",
    "partner": [
      9
    ]
  },
  {
    "plz": "88459",
    "partner": [
      9
    ]
  },
  {
    "plz": "88471",
    "partner": [
      9
    ]
  },
  {
    "plz": "88477",
    "partner": [
      9
    ]
  },
  {
    "plz": "88480",
    "partner": [
      9
    ]
  },
  {
    "plz": "88481",
    "partner": [
      9
    ]
  },
  {
    "plz": "88483",
    "partner": [
      9
    ]
  },
  {
    "plz": "88484",
    "partner": [
      9
    ]
  },
  {
    "plz": "88486",
    "partner": [
      9
    ]
  },
  {
    "plz": "88487",
    "partner": [
      9
    ]
  },
  {
    "plz": "88489",
    "partner": [
      9
    ]
  },
  {
    "plz": "88499",
    "partner": [
      9
    ]
  },
  {
    "plz": "88499",
    "partner": [
      9
    ]
  },
  {
    "plz": "88499",
    "partner": [
      9
    ]
  },
  {
    "plz": "88512",
    "partner": [
      9
    ]
  },
  {
    "plz": "88515",
    "partner": [
      9
    ]
  },
  {
    "plz": "88518",
    "partner": [
      9
    ]
  },
  {
    "plz": "88521",
    "partner": [
      9
    ]
  },
  {
    "plz": "88524",
    "partner": [
      9
    ]
  },
  {
    "plz": "88525",
    "partner": [
      9
    ]
  },
  {
    "plz": "88527",
    "partner": [
      9
    ]
  },
  {
    "plz": "88529",
    "partner": [
      9
    ]
  },
  {
    "plz": "88605",
    "partner": [
      9
    ]
  },
  {
    "plz": "88605",
    "partner": [
      9
    ]
  },
  {
    "plz": "88630",
    "partner": [
      9
    ]
  },
  {
    "plz": "88631",
    "partner": [
      9
    ]
  },
  {
    "plz": "88633",
    "partner": [
      9
    ]
  },
  {
    "plz": "88634",
    "partner": [
      9
    ]
  },
  {
    "plz": "88636",
    "partner": [
      9
    ]
  },
  {
    "plz": "88637",
    "partner": [
      9
    ]
  },
  {
    "plz": "88637",
    "partner": [
      9
    ]
  },
  {
    "plz": "88639",
    "partner": [
      9
    ]
  },
  {
    "plz": "88662",
    "partner": [
      9
    ]
  },
  {
    "plz": "88677",
    "partner": [
      9
    ]
  },
  {
    "plz": "88682",
    "partner": [
      9
    ]
  },
  {
    "plz": "88690",
    "partner": [
      9
    ]
  },
  {
    "plz": "88693",
    "partner": [
      9
    ]
  },
  {
    "plz": "88696",
    "partner": [
      9
    ]
  },
  {
    "plz": "88697",
    "partner": [
      9
    ]
  },
  {
    "plz": "88699",
    "partner": [
      9
    ]
  },
  {
    "plz": "88709",
    "partner": [
      9
    ]
  },
  {
    "plz": "88709",
    "partner": [
      9
    ]
  },
  {
    "plz": "88718",
    "partner": [
      9
    ]
  },
  {
    "plz": "88719",
    "partner": [
      9
    ]
  },
  {
    "plz": "89073",
    "partner": [
      9
    ]
  },
  {
    "plz": "89075",
    "partner": [
      9
    ]
  },
  {
    "plz": "89077",
    "partner": [
      9
    ]
  },
  {
    "plz": "89079",
    "partner": [
      9
    ]
  },
  {
    "plz": "89081",
    "partner": [
      9
    ]
  },
  {
    "plz": "89081",
    "partner": [
      9
    ]
  },
  {
    "plz": "89129",
    "partner": [
      9
    ]
  },
  {
    "plz": "89129",
    "partner": [
      9
    ]
  },
  {
    "plz": "89129",
    "partner": [
      9
    ]
  },
  {
    "plz": "89129",
    "partner": [
      9
    ]
  },
  {
    "plz": "89129",
    "partner": [
      9
    ]
  },
  {
    "plz": "89134",
    "partner": [
      9
    ]
  },
  {
    "plz": "89143",
    "partner": [
      9
    ]
  },
  {
    "plz": "89143",
    "partner": [
      9
    ]
  },
  {
    "plz": "89150",
    "partner": [
      9
    ]
  },
  {
    "plz": "89155",
    "partner": [
      9
    ]
  },
  {
    "plz": "89160",
    "partner": [
      9
    ]
  },
  {
    "plz": "89160",
    "partner": [
      9
    ]
  },
  {
    "plz": "89165",
    "partner": [
      9
    ]
  },
  {
    "plz": "89165",
    "partner": [
      9
    ]
  },
  {
    "plz": "89165",
    "partner": [
      9
    ]
  },
  {
    "plz": "89165",
    "partner": [
      9
    ]
  },
  {
    "plz": "89165",
    "partner": [
      9
    ]
  },
  {
    "plz": "89168",
    "partner": [
      9
    ]
  },
  {
    "plz": "89171",
    "partner": [
      9
    ]
  },
  {
    "plz": "89173",
    "partner": [
      9
    ]
  },
  {
    "plz": "89174",
    "partner": [
      9
    ]
  },
  {
    "plz": "89176",
    "partner": [
      9
    ]
  },
  {
    "plz": "89177",
    "partner": [
      9
    ]
  },
  {
    "plz": "89177",
    "partner": [
      9
    ]
  },
  {
    "plz": "89179",
    "partner": [
      9
    ]
  },
  {
    "plz": "89180",
    "partner": [
      9
    ]
  },
  {
    "plz": "89182",
    "partner": [
      9
    ]
  },
  {
    "plz": "89183",
    "partner": [
      9
    ]
  },
  {
    "plz": "89183",
    "partner": [
      9
    ]
  },
  {
    "plz": "89185",
    "partner": [
      9
    ]
  },
  {
    "plz": "89186",
    "partner": [
      9
    ]
  },
  {
    "plz": "89188",
    "partner": [
      9
    ]
  },
  {
    "plz": "89189",
    "partner": [
      9
    ]
  },
  {
    "plz": "89191",
    "partner": [
      9
    ]
  },
  {
    "plz": "89192",
    "partner": [
      9
    ]
  },
  {
    "plz": "89194",
    "partner": [
      9
    ]
  },
  {
    "plz": "89195",
    "partner": [
      9
    ]
  },
  {
    "plz": "89197",
    "partner": [
      9
    ]
  },
  {
    "plz": "89198",
    "partner": [
      9
    ]
  },
  {
    "plz": "89231",
    "partner": [
      9
    ]
  },
  {
    "plz": "89233",
    "partner": [
      9
    ]
  },
  {
    "plz": "89250",
    "partner": [
      9
    ]
  },
  {
    "plz": "89257",
    "partner": [
      9
    ]
  },
  {
    "plz": "89264",
    "partner": [
      9
    ]
  },
  {
    "plz": "89269",
    "partner": [
      9
    ]
  },
  {
    "plz": "89275",
    "partner": [
      9
    ]
  },
  {
    "plz": "89278",
    "partner": [
      9
    ]
  },
  {
    "plz": "89281",
    "partner": [
      9
    ]
  },
  {
    "plz": "89284",
    "partner": [
      9
    ]
  },
  {
    "plz": "89287",
    "partner": [
      9
    ]
  },
  {
    "plz": "89290",
    "partner": [
      9
    ]
  },
  {
    "plz": "89290",
    "partner": [
      9
    ]
  },
  {
    "plz": "89291",
    "partner": [
      9
    ]
  },
  {
    "plz": "89293",
    "partner": [
      9
    ]
  },
  {
    "plz": "89294",
    "partner": [
      9
    ]
  },
  {
    "plz": "89296",
    "partner": [
      9
    ]
  },
  {
    "plz": "89297",
    "partner": [
      9
    ]
  },
  {
    "plz": "89299",
    "partner": [
      9
    ]
  },
  {
    "plz": "89312",
    "partner": [
      9
    ]
  },
  {
    "plz": "89331",
    "partner": [
      9
    ]
  },
  {
    "plz": "89335",
    "partner": [
      9
    ]
  },
  {
    "plz": "89335",
    "partner": [
      9
    ]
  },
  {
    "plz": "89335",
    "partner": [
      9
    ]
  },
  {
    "plz": "89335",
    "partner": [
      9
    ]
  },
  {
    "plz": "89340",
    "partner": [
      9
    ]
  },
  {
    "plz": "89343",
    "partner": [
      9
    ]
  },
  {
    "plz": "89344",
    "partner": [
      9
    ]
  },
  {
    "plz": "89346",
    "partner": [
      9
    ]
  },
  {
    "plz": "89347",
    "partner": [
      9
    ]
  },
  {
    "plz": "89349",
    "partner": [
      9
    ]
  },
  {
    "plz": "89350",
    "partner": [
      9
    ]
  },
  {
    "plz": "89352",
    "partner": [
      9
    ]
  },
  {
    "plz": "89353",
    "partner": [
      9
    ]
  },
  {
    "plz": "89355",
    "partner": [
      9
    ]
  },
  {
    "plz": "89356",
    "partner": [
      9
    ]
  },
  {
    "plz": "89358",
    "partner": [
      9
    ]
  },
  {
    "plz": "89359",
    "partner": [
      9
    ]
  },
  {
    "plz": "89361",
    "partner": [
      9
    ]
  },
  {
    "plz": "89362",
    "partner": [
      9
    ]
  },
  {
    "plz": "89364",
    "partner": [
      9
    ]
  },
  {
    "plz": "89365",
    "partner": [
      9
    ]
  },
  {
    "plz": "89367",
    "partner": [
      9
    ]
  },
  {
    "plz": "89368",
    "partner": [
      9
    ]
  },
  {
    "plz": "89407",
    "partner": [
      9
    ]
  },
  {
    "plz": "89415",
    "partner": [
      9
    ]
  },
  {
    "plz": "89420",
    "partner": [
      9
    ]
  },
  {
    "plz": "89423",
    "partner": [
      9
    ]
  },
  {
    "plz": "89426",
    "partner": [
      9
    ]
  },
  {
    "plz": "89426",
    "partner": [
      9
    ]
  },
  {
    "plz": "89428",
    "partner": [
      9
    ]
  },
  {
    "plz": "89429",
    "partner": [
      9
    ]
  },
  {
    "plz": "89431",
    "partner": [
      9
    ]
  },
  {
    "plz": "89434",
    "partner": [
      9
    ]
  },
  {
    "plz": "89435",
    "partner": [
      9
    ]
  },
  {
    "plz": "89437",
    "partner": [
      9
    ]
  },
  {
    "plz": "89438",
    "partner": [
      9
    ]
  },
  {
    "plz": "89440",
    "partner": [
      9
    ]
  },
  {
    "plz": "89441",
    "partner": [
      9
    ]
  },
  {
    "plz": "89443",
    "partner": [
      9
    ]
  },
  {
    "plz": "89446",
    "partner": [
      9
    ]
  },
  {
    "plz": "89447",
    "partner": [
      9
    ]
  },
  {
    "plz": "89518",
    "partner": [
      9
    ]
  },
  {
    "plz": "89520",
    "partner": [
      9
    ]
  },
  {
    "plz": "89522",
    "partner": [
      9
    ]
  },
  {
    "plz": "89522",
    "partner": [
      9
    ]
  },
  {
    "plz": "89522",
    "partner": [
      9
    ]
  },
  {
    "plz": "89522",
    "partner": [
      9
    ]
  },
  {
    "plz": "89522",
    "partner": [
      9
    ]
  },
  {
    "plz": "89537",
    "partner": [
      9
    ]
  },
  {
    "plz": "89537",
    "partner": [
      9
    ]
  },
  {
    "plz": "89542",
    "partner": [
      9
    ]
  },
  {
    "plz": "89547",
    "partner": [
      9
    ]
  },
  {
    "plz": "89551",
    "partner": [
      9
    ]
  },
  {
    "plz": "89555",
    "partner": [
      9
    ]
  },
  {
    "plz": "89558",
    "partner": [
      9
    ]
  },
  {
    "plz": "89561",
    "partner": [
      9
    ]
  },
  {
    "plz": "89564",
    "partner": [
      9
    ]
  },
  {
    "plz": "89567",
    "partner": [
      9
    ]
  },
  {
    "plz": "89568",
    "partner": [
      9
    ]
  },
  {
    "plz": "89584",
    "partner": [
      9
    ]
  },
  {
    "plz": "89584",
    "partner": [
      9
    ]
  },
  {
    "plz": "89597",
    "partner": [
      9
    ]
  },
  {
    "plz": "89597",
    "partner": [
      9
    ]
  },
  {
    "plz": "89597",
    "partner": [
      9
    ]
  },
  {
    "plz": "89601",
    "partner": [
      9
    ]
  },
  {
    "plz": "89604",
    "partner": [
      9
    ]
  },
  {
    "plz": "89605",
    "partner": [
      9
    ]
  },
  {
    "plz": "89607",
    "partner": [
      9
    ]
  },
  {
    "plz": "89608",
    "partner": [
      9
    ]
  },
  {
    "plz": "89610",
    "partner": [
      9
    ]
  },
  {
    "plz": "89611",
    "partner": [
      9
    ]
  },
  {
    "plz": "89611",
    "partner": [
      9
    ]
  },
  {
    "plz": "89611",
    "partner": [
      9
    ]
  },
  {
    "plz": "89613",
    "partner": [
      9
    ]
  },
  {
    "plz": "89613",
    "partner": [
      9
    ]
  },
  {
    "plz": "89614",
    "partner": [
      9
    ]
  },
  {
    "plz": "89616",
    "partner": [
      9
    ]
  },
  {
    "plz": "89617",
    "partner": [
      9
    ]
  },
  {
    "plz": "89619",
    "partner": [
      9
    ]
  },
  {
    "plz": "90402",
    "partner": [
      17
    ]
  },
  {
    "plz": "90403",
    "partner": [
      17
    ]
  },
  {
    "plz": "90408",
    "partner": [
      17
    ]
  },
  {
    "plz": "90409",
    "partner": [
      17
    ]
  },
  {
    "plz": "90411",
    "partner": [
      17
    ]
  },
  {
    "plz": "90419",
    "partner": [
      17
    ]
  },
  {
    "plz": "90425",
    "partner": [
      17
    ]
  },
  {
    "plz": "90427",
    "partner": [
      17
    ]
  },
  {
    "plz": "90429",
    "partner": [
      17
    ]
  },
  {
    "plz": "90431",
    "partner": [
      17
    ]
  },
  {
    "plz": "90439",
    "partner": [
      17
    ]
  },
  {
    "plz": "90441",
    "partner": [
      17
    ]
  },
  {
    "plz": "90443",
    "partner": [
      17
    ]
  },
  {
    "plz": "90449",
    "partner": [
      17
    ]
  },
  {
    "plz": "90451",
    "partner": [
      17
    ]
  },
  {
    "plz": "90453",
    "partner": [
      17
    ]
  },
  {
    "plz": "90455",
    "partner": [
      17
    ]
  },
  {
    "plz": "90459",
    "partner": [
      17
    ]
  },
  {
    "plz": "90461",
    "partner": [
      17
    ]
  },
  {
    "plz": "90469",
    "partner": [
      17
    ]
  },
  {
    "plz": "90471",
    "partner": [
      17
    ]
  },
  {
    "plz": "90473",
    "partner": [
      17
    ]
  },
  {
    "plz": "90475",
    "partner": [
      17
    ]
  },
  {
    "plz": "90478",
    "partner": [
      17
    ]
  },
  {
    "plz": "90480",
    "partner": [
      17
    ]
  },
  {
    "plz": "90482",
    "partner": [
      17
    ]
  },
  {
    "plz": "90489",
    "partner": [
      17
    ]
  },
  {
    "plz": "90491",
    "partner": [
      17
    ]
  },
  {
    "plz": "90513",
    "partner": [
      17
    ]
  },
  {
    "plz": "90518",
    "partner": [
      17
    ]
  },
  {
    "plz": "90522",
    "partner": [
      17
    ]
  },
  {
    "plz": "90530",
    "partner": [
      17
    ]
  },
  {
    "plz": "90537",
    "partner": [
      17
    ]
  },
  {
    "plz": "90542",
    "partner": [
      17
    ]
  },
  {
    "plz": "90547",
    "partner": [
      17
    ]
  },
  {
    "plz": "90552",
    "partner": [
      17
    ]
  },
  {
    "plz": "90556",
    "partner": [
      17
    ]
  },
  {
    "plz": "90556",
    "partner": [
      17
    ]
  },
  {
    "plz": "90559",
    "partner": [
      17
    ]
  },
  {
    "plz": "90562",
    "partner": [
      17
    ]
  },
  {
    "plz": "90562",
    "partner": [
      17
    ]
  },
  {
    "plz": "90571",
    "partner": [
      17
    ]
  },
  {
    "plz": "90574",
    "partner": [
      17
    ]
  },
  {
    "plz": "90579",
    "partner": [
      17
    ]
  },
  {
    "plz": "90584",
    "partner": [
      17
    ]
  },
  {
    "plz": "90587",
    "partner": [
      17
    ]
  },
  {
    "plz": "90587",
    "partner": [
      17
    ]
  },
  {
    "plz": "90587",
    "partner": [
      17
    ]
  },
  {
    "plz": "90592",
    "partner": [
      17
    ]
  },
  {
    "plz": "90596",
    "partner": [
      17
    ]
  },
  {
    "plz": "90599",
    "partner": [
      17
    ]
  },
  {
    "plz": "90602",
    "partner": [
      17
    ]
  },
  {
    "plz": "90607",
    "partner": [
      17
    ]
  },
  {
    "plz": "90610",
    "partner": [
      17
    ]
  },
  {
    "plz": "90613",
    "partner": [
      17
    ]
  },
  {
    "plz": "90614",
    "partner": [
      17
    ]
  },
  {
    "plz": "90616",
    "partner": [
      17
    ]
  },
  {
    "plz": "90617",
    "partner": [
      17
    ]
  },
  {
    "plz": "90619",
    "partner": [
      17
    ]
  },
  {
    "plz": "90762",
    "partner": [
      17
    ]
  },
  {
    "plz": "90763",
    "partner": [
      17
    ]
  },
  {
    "plz": "90765",
    "partner": [
      17
    ]
  },
  {
    "plz": "90766",
    "partner": [
      17
    ]
  },
  {
    "plz": "90768",
    "partner": [
      17
    ]
  },
  {
    "plz": "91052",
    "partner": [
      17
    ]
  },
  {
    "plz": "91054",
    "partner": [
      17
    ]
  },
  {
    "plz": "91054",
    "partner": [
      17
    ]
  },
  {
    "plz": "91056",
    "partner": [
      17
    ]
  },
  {
    "plz": "91058",
    "partner": [
      17
    ]
  },
  {
    "plz": "91074",
    "partner": [
      17
    ]
  },
  {
    "plz": "91077",
    "partner": [
      17
    ]
  },
  {
    "plz": "91077",
    "partner": [
      17
    ]
  },
  {
    "plz": "91077",
    "partner": [
      17
    ]
  },
  {
    "plz": "91077",
    "partner": [
      17
    ]
  },
  {
    "plz": "91077",
    "partner": [
      17
    ]
  },
  {
    "plz": "91077",
    "partner": [
      17
    ]
  },
  {
    "plz": "91080",
    "partner": [
      17
    ]
  },
  {
    "plz": "91080",
    "partner": [
      17
    ]
  },
  {
    "plz": "91080",
    "partner": [
      17
    ]
  },
  {
    "plz": "91083",
    "partner": [
      17
    ]
  },
  {
    "plz": "91085",
    "partner": [
      17
    ]
  },
  {
    "plz": "91086",
    "partner": [
      17
    ]
  },
  {
    "plz": "91088",
    "partner": [
      17
    ]
  },
  {
    "plz": "91090",
    "partner": [
      17
    ]
  },
  {
    "plz": "91091",
    "partner": [
      17
    ]
  },
  {
    "plz": "91093",
    "partner": [
      17
    ]
  },
  {
    "plz": "91094",
    "partner": [
      17
    ]
  },
  {
    "plz": "91096",
    "partner": [
      17
    ]
  },
  {
    "plz": "91097",
    "partner": [
      17
    ]
  },
  {
    "plz": "91099",
    "partner": [
      17
    ]
  },
  {
    "plz": "91126",
    "partner": [
      17
    ]
  },
  {
    "plz": "91126",
    "partner": [
      17
    ]
  },
  {
    "plz": "91126",
    "partner": [
      17
    ]
  },
  {
    "plz": "91154",
    "partner": [
      17
    ]
  },
  {
    "plz": "91161",
    "partner": [
      17
    ]
  },
  {
    "plz": "91166",
    "partner": [
      17
    ]
  },
  {
    "plz": "91171",
    "partner": [
      17
    ]
  },
  {
    "plz": "91174",
    "partner": [
      17
    ]
  },
  {
    "plz": "91177",
    "partner": [
      17
    ]
  },
  {
    "plz": "91180",
    "partner": [
      17
    ]
  },
  {
    "plz": "91183",
    "partner": [
      17
    ]
  },
  {
    "plz": "91186",
    "partner": [
      17
    ]
  },
  {
    "plz": "91187",
    "partner": [
      17
    ]
  },
  {
    "plz": "91189",
    "partner": [
      17
    ]
  },
  {
    "plz": "91207",
    "partner": [
      17
    ]
  },
  {
    "plz": "91217",
    "partner": [
      17
    ]
  },
  {
    "plz": "91220",
    "partner": [
      17
    ]
  },
  {
    "plz": "91224",
    "partner": [
      17
    ]
  },
  {
    "plz": "91227",
    "partner": [
      17
    ]
  },
  {
    "plz": "91230",
    "partner": [
      17
    ]
  },
  {
    "plz": "91233",
    "partner": [
      17
    ]
  },
  {
    "plz": "91235",
    "partner": [
      17
    ]
  },
  {
    "plz": "91235",
    "partner": [
      17
    ]
  },
  {
    "plz": "91236",
    "partner": [
      17
    ]
  },
  {
    "plz": "91238",
    "partner": [
      17
    ]
  },
  {
    "plz": "91238",
    "partner": [
      17
    ]
  },
  {
    "plz": "91239",
    "partner": [
      17
    ]
  },
  {
    "plz": "91241",
    "partner": [
      17
    ]
  },
  {
    "plz": "91242",
    "partner": [
      17
    ]
  },
  {
    "plz": "91244",
    "partner": [
      17
    ]
  },
  {
    "plz": "91245",
    "partner": [
      17
    ]
  },
  {
    "plz": "91247",
    "partner": [
      17
    ]
  },
  {
    "plz": "91249",
    "partner": [
      17
    ]
  },
  {
    "plz": "91257",
    "partner": [
      17
    ]
  },
  {
    "plz": "91275",
    "partner": [
      17
    ]
  },
  {
    "plz": "91278",
    "partner": [
      17
    ]
  },
  {
    "plz": "91281",
    "partner": [
      17
    ]
  },
  {
    "plz": "91282",
    "partner": [
      17
    ]
  },
  {
    "plz": "91284",
    "partner": [
      17
    ]
  },
  {
    "plz": "91286",
    "partner": [
      17
    ]
  },
  {
    "plz": "91287",
    "partner": [
      17
    ]
  },
  {
    "plz": "91289",
    "partner": [
      17
    ]
  },
  {
    "plz": "91301",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91315",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91320",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91322",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91325",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91327",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91330",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91332",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91334",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91336",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91338",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91341",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91344",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91344",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91344",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91346",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91347",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91349",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91350",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91352",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91353",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91355",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91356",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91358",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91358",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91359",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91361",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91362",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91364",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91365",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91367",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91369",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "91413",
    "partner": [
      17
    ]
  },
  {
    "plz": "91438",
    "partner": [
      17
    ]
  },
  {
    "plz": "91438",
    "partner": [
      17
    ]
  },
  {
    "plz": "91443",
    "partner": [
      17
    ]
  },
  {
    "plz": "91448",
    "partner": [
      17
    ]
  },
  {
    "plz": "91452",
    "partner": [
      17
    ]
  },
  {
    "plz": "91456",
    "partner": [
      17
    ]
  },
  {
    "plz": "91459",
    "partner": [
      17
    ]
  },
  {
    "plz": "91460",
    "partner": [
      17
    ]
  },
  {
    "plz": "91462",
    "partner": [
      17
    ]
  },
  {
    "plz": "91463",
    "partner": [
      17
    ]
  },
  {
    "plz": "91465",
    "partner": [
      17
    ]
  },
  {
    "plz": "91466",
    "partner": [
      17
    ]
  },
  {
    "plz": "91468",
    "partner": [
      17
    ]
  },
  {
    "plz": "91469",
    "partner": [
      17
    ]
  },
  {
    "plz": "91469",
    "partner": [
      17
    ]
  },
  {
    "plz": "91471",
    "partner": [
      17
    ]
  },
  {
    "plz": "91472",
    "partner": [
      17
    ]
  },
  {
    "plz": "91474",
    "partner": [
      17
    ]
  },
  {
    "plz": "91475",
    "partner": [
      17
    ]
  },
  {
    "plz": "91477",
    "partner": [
      17
    ]
  },
  {
    "plz": "91478",
    "partner": [
      17
    ]
  },
  {
    "plz": "91480",
    "partner": [
      17
    ]
  },
  {
    "plz": "91481",
    "partner": [
      17
    ]
  },
  {
    "plz": "91483",
    "partner": [
      17
    ]
  },
  {
    "plz": "91484",
    "partner": [
      17
    ]
  },
  {
    "plz": "91486",
    "partner": [
      17
    ]
  },
  {
    "plz": "91487",
    "partner": [
      17
    ]
  },
  {
    "plz": "91489",
    "partner": [
      17
    ]
  },
  {
    "plz": "91489",
    "partner": [
      17
    ]
  },
  {
    "plz": "91522",
    "partner": [
      17
    ]
  },
  {
    "plz": "91541",
    "partner": [
      17
    ]
  },
  {
    "plz": "91550",
    "partner": [
      17
    ]
  },
  {
    "plz": "91555",
    "partner": [
      17
    ]
  },
  {
    "plz": "91560",
    "partner": [
      17
    ]
  },
  {
    "plz": "91564",
    "partner": [
      17
    ]
  },
  {
    "plz": "91567",
    "partner": [
      17
    ]
  },
  {
    "plz": "91572",
    "partner": [
      17
    ]
  },
  {
    "plz": "91575",
    "partner": [
      17
    ]
  },
  {
    "plz": "91578",
    "partner": [
      17
    ]
  },
  {
    "plz": "91580",
    "partner": [
      17
    ]
  },
  {
    "plz": "91583",
    "partner": [
      17
    ]
  },
  {
    "plz": "91583",
    "partner": [
      17
    ]
  },
  {
    "plz": "91586",
    "partner": [
      17
    ]
  },
  {
    "plz": "91587",
    "partner": [
      17
    ]
  },
  {
    "plz": "91589",
    "partner": [
      17
    ]
  },
  {
    "plz": "91590",
    "partner": [
      17
    ]
  },
  {
    "plz": "91592",
    "partner": [
      17
    ]
  },
  {
    "plz": "91593",
    "partner": [
      17
    ]
  },
  {
    "plz": "91595",
    "partner": [
      17
    ]
  },
  {
    "plz": "91596",
    "partner": [
      17
    ]
  },
  {
    "plz": "91598",
    "partner": [
      17
    ]
  },
  {
    "plz": "91599",
    "partner": [
      17
    ]
  },
  {
    "plz": "91601",
    "partner": [
      17
    ]
  },
  {
    "plz": "91602",
    "partner": [
      17
    ]
  },
  {
    "plz": "91604",
    "partner": [
      17
    ]
  },
  {
    "plz": "91605",
    "partner": [
      17
    ]
  },
  {
    "plz": "91607",
    "partner": [
      17
    ]
  },
  {
    "plz": "91608",
    "partner": [
      17
    ]
  },
  {
    "plz": "91610",
    "partner": [
      17
    ]
  },
  {
    "plz": "91611",
    "partner": [
      17
    ]
  },
  {
    "plz": "91613",
    "partner": [
      17
    ]
  },
  {
    "plz": "91614",
    "partner": [
      17
    ]
  },
  {
    "plz": "91616",
    "partner": [
      17
    ]
  },
  {
    "plz": "91617",
    "partner": [
      17
    ]
  },
  {
    "plz": "91619",
    "partner": [
      17
    ]
  },
  {
    "plz": "91620",
    "partner": [
      17
    ]
  },
  {
    "plz": "91622",
    "partner": [
      17
    ]
  },
  {
    "plz": "91623",
    "partner": [
      17
    ]
  },
  {
    "plz": "91625",
    "partner": [
      17
    ]
  },
  {
    "plz": "91626",
    "partner": [
      17
    ]
  },
  {
    "plz": "91628",
    "partner": [
      17
    ]
  },
  {
    "plz": "91629",
    "partner": [
      17
    ]
  },
  {
    "plz": "91631",
    "partner": [
      17
    ]
  },
  {
    "plz": "91632",
    "partner": [
      17
    ]
  },
  {
    "plz": "91634",
    "partner": [
      17
    ]
  },
  {
    "plz": "91635",
    "partner": [
      17
    ]
  },
  {
    "plz": "91637",
    "partner": [
      17
    ]
  },
  {
    "plz": "91639",
    "partner": [
      17
    ]
  },
  {
    "plz": "91710",
    "partner": [
      17
    ]
  },
  {
    "plz": "91717",
    "partner": [
      17
    ]
  },
  {
    "plz": "91717",
    "partner": [
      17
    ]
  },
  {
    "plz": "91719",
    "partner": [
      17
    ]
  },
  {
    "plz": "91720",
    "partner": [
      17
    ]
  },
  {
    "plz": "91722",
    "partner": [
      17
    ]
  },
  {
    "plz": "91723",
    "partner": [
      17
    ]
  },
  {
    "plz": "91725",
    "partner": [
      17
    ]
  },
  {
    "plz": "91726",
    "partner": [
      17
    ]
  },
  {
    "plz": "91728",
    "partner": [
      17
    ]
  },
  {
    "plz": "91729",
    "partner": [
      17
    ]
  },
  {
    "plz": "91731",
    "partner": [
      17
    ]
  },
  {
    "plz": "91732",
    "partner": [
      17
    ]
  },
  {
    "plz": "91734",
    "partner": [
      17
    ]
  },
  {
    "plz": "91735",
    "partner": [
      17
    ]
  },
  {
    "plz": "91737",
    "partner": [
      17
    ]
  },
  {
    "plz": "91738",
    "partner": [
      17
    ]
  },
  {
    "plz": "91740",
    "partner": [
      17
    ]
  },
  {
    "plz": "91741",
    "partner": [
      17
    ]
  },
  {
    "plz": "91743",
    "partner": [
      17
    ]
  },
  {
    "plz": "91744",
    "partner": [
      17
    ]
  },
  {
    "plz": "91746",
    "partner": [
      17
    ]
  },
  {
    "plz": "91747",
    "partner": [
      17
    ]
  },
  {
    "plz": "91749",
    "partner": [
      17
    ]
  },
  {
    "plz": "91757",
    "partner": [
      17
    ]
  },
  {
    "plz": "91781",
    "partner": [
      17
    ]
  },
  {
    "plz": "91785",
    "partner": [
      17
    ]
  },
  {
    "plz": "91788",
    "partner": [
      17
    ]
  },
  {
    "plz": "91790",
    "partner": [
      17
    ]
  },
  {
    "plz": "91790",
    "partner": [
      17
    ]
  },
  {
    "plz": "91790",
    "partner": [
      17
    ]
  },
  {
    "plz": "91790",
    "partner": [
      17
    ]
  },
  {
    "plz": "91792",
    "partner": [
      17
    ]
  },
  {
    "plz": "91793",
    "partner": [
      17
    ]
  },
  {
    "plz": "91795",
    "partner": [
      17
    ]
  },
  {
    "plz": "91796",
    "partner": [
      17
    ]
  },
  {
    "plz": "91798",
    "partner": [
      17
    ]
  },
  {
    "plz": "91799",
    "partner": [
      17
    ]
  },
  {
    "plz": "91801",
    "partner": [
      17
    ]
  },
  {
    "plz": "91802",
    "partner": [
      17
    ]
  },
  {
    "plz": "91804",
    "partner": [
      17
    ]
  },
  {
    "plz": "91805",
    "partner": [
      17
    ]
  },
  {
    "plz": "91807",
    "partner": [
      17
    ]
  },
  {
    "plz": "91807",
    "partner": [
      17
    ]
  },
  {
    "plz": "91807",
    "partner": [
      17
    ]
  },
  {
    "plz": "91809",
    "partner": [
      17
    ]
  },
  {
    "plz": "92224",
    "partner": [
      17
    ]
  },
  {
    "plz": "92237",
    "partner": [
      17
    ]
  },
  {
    "plz": "92242",
    "partner": [
      17
    ]
  },
  {
    "plz": "92245",
    "partner": [
      17
    ]
  },
  {
    "plz": "92249",
    "partner": [
      17
    ]
  },
  {
    "plz": "92253",
    "partner": [
      17
    ]
  },
  {
    "plz": "92256",
    "partner": [
      17
    ]
  },
  {
    "plz": "92259",
    "partner": [
      17
    ]
  },
  {
    "plz": "92260",
    "partner": [
      17
    ]
  },
  {
    "plz": "92262",
    "partner": [
      17
    ]
  },
  {
    "plz": "92263",
    "partner": [
      17
    ]
  },
  {
    "plz": "92265",
    "partner": [
      17
    ]
  },
  {
    "plz": "92266",
    "partner": [
      17
    ]
  },
  {
    "plz": "92268",
    "partner": [
      17
    ]
  },
  {
    "plz": "92269",
    "partner": [
      17
    ]
  },
  {
    "plz": "92271",
    "partner": [
      17
    ]
  },
  {
    "plz": "92272",
    "partner": [
      17
    ]
  },
  {
    "plz": "92274",
    "partner": [
      17
    ]
  },
  {
    "plz": "92275",
    "partner": [
      17
    ]
  },
  {
    "plz": "92277",
    "partner": [
      17
    ]
  },
  {
    "plz": "92278",
    "partner": [
      17
    ]
  },
  {
    "plz": "92280",
    "partner": [
      17
    ]
  },
  {
    "plz": "92281",
    "partner": [
      17
    ]
  },
  {
    "plz": "92283",
    "partner": [
      17
    ]
  },
  {
    "plz": "92284",
    "partner": [
      17
    ]
  },
  {
    "plz": "92286",
    "partner": [
      17
    ]
  },
  {
    "plz": "92287",
    "partner": [
      17
    ]
  },
  {
    "plz": "92289",
    "partner": [
      17
    ]
  },
  {
    "plz": "92318",
    "partner": [
      17
    ]
  },
  {
    "plz": "92331",
    "partner": [
      17
    ]
  },
  {
    "plz": "92331",
    "partner": [
      17
    ]
  },
  {
    "plz": "92334",
    "partner": [
      17
    ]
  },
  {
    "plz": "92339",
    "partner": [
      17
    ]
  },
  {
    "plz": "92342",
    "partner": [
      17
    ]
  },
  {
    "plz": "92342",
    "partner": [
      17
    ]
  },
  {
    "plz": "92345",
    "partner": [
      17
    ]
  },
  {
    "plz": "92345",
    "partner": [
      17
    ]
  },
  {
    "plz": "92348",
    "partner": [
      17
    ]
  },
  {
    "plz": "92348",
    "partner": [
      17
    ]
  },
  {
    "plz": "92353",
    "partner": [
      17
    ]
  },
  {
    "plz": "92355",
    "partner": [
      17
    ]
  },
  {
    "plz": "92358",
    "partner": [
      17
    ]
  },
  {
    "plz": "92360",
    "partner": [
      17
    ]
  },
  {
    "plz": "92361",
    "partner": [
      17
    ]
  },
  {
    "plz": "92363",
    "partner": [
      17
    ]
  },
  {
    "plz": "92364",
    "partner": [
      17
    ]
  },
  {
    "plz": "92366",
    "partner": [
      17
    ]
  },
  {
    "plz": "92367",
    "partner": [
      17
    ]
  },
  {
    "plz": "92369",
    "partner": [
      17
    ]
  },
  {
    "plz": "92421",
    "partner": [
      16
    ]
  },
  {
    "plz": "92431",
    "partner": [
      16
    ]
  },
  {
    "plz": "92436",
    "partner": [
      16
    ]
  },
  {
    "plz": "92439",
    "partner": [
      16
    ]
  },
  {
    "plz": "92442",
    "partner": [
      16
    ]
  },
  {
    "plz": "92444",
    "partner": [
      18
    ]
  },
  {
    "plz": "92445",
    "partner": [
      16
    ]
  },
  {
    "plz": "92447",
    "partner": [
      18
    ]
  },
  {
    "plz": "92449",
    "partner": [
      16
    ]
  },
  {
    "plz": "92507",
    "partner": [
      18
    ]
  },
  {
    "plz": "92521",
    "partner": [
      18
    ]
  },
  {
    "plz": "92526",
    "partner": [
      18
    ]
  },
  {
    "plz": "92533",
    "partner": [
      18
    ]
  },
  {
    "plz": "92536",
    "partner": [
      18
    ]
  },
  {
    "plz": "92539",
    "partner": [
      18
    ]
  },
  {
    "plz": "92540",
    "partner": [
      18
    ]
  },
  {
    "plz": "92542",
    "partner": [
      18
    ]
  },
  {
    "plz": "92543",
    "partner": [
      18
    ]
  },
  {
    "plz": "92545",
    "partner": [
      18
    ]
  },
  {
    "plz": "92546",
    "partner": [
      18
    ]
  },
  {
    "plz": "92548",
    "partner": [
      18
    ]
  },
  {
    "plz": "92549",
    "partner": [
      18
    ]
  },
  {
    "plz": "92551",
    "partner": [
      18
    ]
  },
  {
    "plz": "92552",
    "partner": [
      18
    ]
  },
  {
    "plz": "92554",
    "partner": [
      18
    ]
  },
  {
    "plz": "92555",
    "partner": [
      18
    ]
  },
  {
    "plz": "92557",
    "partner": [
      18
    ]
  },
  {
    "plz": "92559",
    "partner": [
      18
    ]
  },
  {
    "plz": "92637",
    "partner": [
      18
    ]
  },
  {
    "plz": "92637",
    "partner": [
      18
    ]
  },
  {
    "plz": "92648",
    "partner": [
      18
    ]
  },
  {
    "plz": "92655",
    "partner": [
      18
    ]
  },
  {
    "plz": "92660",
    "partner": [
      18
    ]
  },
  {
    "plz": "92665",
    "partner": [
      18
    ]
  },
  {
    "plz": "92665",
    "partner": [
      18
    ]
  },
  {
    "plz": "92670",
    "partner": [
      18
    ]
  },
  {
    "plz": "92676",
    "partner": [
      18
    ]
  },
  {
    "plz": "92676",
    "partner": [
      18
    ]
  },
  {
    "plz": "92681",
    "partner": [
      18
    ]
  },
  {
    "plz": "92685",
    "partner": [
      18
    ]
  },
  {
    "plz": "92690",
    "partner": [
      18
    ]
  },
  {
    "plz": "92690",
    "partner": [
      18
    ]
  },
  {
    "plz": "92693",
    "partner": [
      18
    ]
  },
  {
    "plz": "92694",
    "partner": [
      18
    ]
  },
  {
    "plz": "92696",
    "partner": [
      18
    ]
  },
  {
    "plz": "92697",
    "partner": [
      18
    ]
  },
  {
    "plz": "92699",
    "partner": [
      18
    ]
  },
  {
    "plz": "92699",
    "partner": [
      18
    ]
  },
  {
    "plz": "92700",
    "partner": [
      18
    ]
  },
  {
    "plz": "92702",
    "partner": [
      18
    ]
  },
  {
    "plz": "92703",
    "partner": [
      18
    ]
  },
  {
    "plz": "92705",
    "partner": [
      18
    ]
  },
  {
    "plz": "92706",
    "partner": [
      18
    ]
  },
  {
    "plz": "92708",
    "partner": [
      18
    ]
  },
  {
    "plz": "92709",
    "partner": [
      18
    ]
  },
  {
    "plz": "92711",
    "partner": [
      18
    ]
  },
  {
    "plz": "92712",
    "partner": [
      18
    ]
  },
  {
    "plz": "92714",
    "partner": [
      18
    ]
  },
  {
    "plz": "92715",
    "partner": [
      18
    ]
  },
  {
    "plz": "92717",
    "partner": [
      18
    ]
  },
  {
    "plz": "92718",
    "partner": [
      18
    ]
  },
  {
    "plz": "92720",
    "partner": [
      18
    ]
  },
  {
    "plz": "92721",
    "partner": [
      18
    ]
  },
  {
    "plz": "92723",
    "partner": [
      18
    ]
  },
  {
    "plz": "92723",
    "partner": [
      18
    ]
  },
  {
    "plz": "92724",
    "partner": [
      18
    ]
  },
  {
    "plz": "92726",
    "partner": [
      18
    ]
  },
  {
    "plz": "92727",
    "partner": [
      18
    ]
  },
  {
    "plz": "92729",
    "partner": [
      18
    ]
  },
  {
    "plz": "93047",
    "partner": [
      16
    ]
  },
  {
    "plz": "93049",
    "partner": [
      16
    ]
  },
  {
    "plz": "93051",
    "partner": [
      16
    ]
  },
  {
    "plz": "93053",
    "partner": [
      16
    ]
  },
  {
    "plz": "93055",
    "partner": [
      16
    ]
  },
  {
    "plz": "93057",
    "partner": [
      16
    ]
  },
  {
    "plz": "93059",
    "partner": [
      16
    ]
  },
  {
    "plz": "93073",
    "partner": [
      16
    ]
  },
  {
    "plz": "93077",
    "partner": [
      16
    ]
  },
  {
    "plz": "93080",
    "partner": [
      16
    ]
  },
  {
    "plz": "93083",
    "partner": [
      16
    ]
  },
  {
    "plz": "93086",
    "partner": [
      16
    ]
  },
  {
    "plz": "93087",
    "partner": [
      16
    ]
  },
  {
    "plz": "93089",
    "partner": [
      16
    ]
  },
  {
    "plz": "93090",
    "partner": [
      16
    ]
  },
  {
    "plz": "93092",
    "partner": [
      16
    ]
  },
  {
    "plz": "93093",
    "partner": [
      16
    ]
  },
  {
    "plz": "93093",
    "partner": [
      16
    ]
  },
  {
    "plz": "93095",
    "partner": [
      16
    ]
  },
  {
    "plz": "93096",
    "partner": [
      16
    ]
  },
  {
    "plz": "93098",
    "partner": [
      16
    ]
  },
  {
    "plz": "93099",
    "partner": [
      16
    ]
  },
  {
    "plz": "93101",
    "partner": [
      16
    ]
  },
  {
    "plz": "93102",
    "partner": [
      16
    ]
  },
  {
    "plz": "93104",
    "partner": [
      16
    ]
  },
  {
    "plz": "93104",
    "partner": [
      16
    ]
  },
  {
    "plz": "93105",
    "partner": [
      16
    ]
  },
  {
    "plz": "93107",
    "partner": [
      16
    ]
  },
  {
    "plz": "93109",
    "partner": [
      16
    ]
  },
  {
    "plz": "93128",
    "partner": [
      16
    ]
  },
  {
    "plz": "93133",
    "partner": [
      16
    ]
  },
  {
    "plz": "93138",
    "partner": [
      16
    ]
  },
  {
    "plz": "93142",
    "partner": [
      16
    ]
  },
  {
    "plz": "93149",
    "partner": [
      16
    ]
  },
  {
    "plz": "93149",
    "partner": [
      16
    ]
  },
  {
    "plz": "93152",
    "partner": [
      16
    ]
  },
  {
    "plz": "93152",
    "partner": [
      16
    ]
  },
  {
    "plz": "93152",
    "partner": [
      16
    ]
  },
  {
    "plz": "93152",
    "partner": [
      16
    ]
  },
  {
    "plz": "93152",
    "partner": [
      16
    ]
  },
  {
    "plz": "93155",
    "partner": [
      16
    ]
  },
  {
    "plz": "93158",
    "partner": [
      16
    ]
  },
  {
    "plz": "93161",
    "partner": [
      16
    ]
  },
  {
    "plz": "93164",
    "partner": [
      16
    ]
  },
  {
    "plz": "93164",
    "partner": [
      16
    ]
  },
  {
    "plz": "93167",
    "partner": [
      16
    ]
  },
  {
    "plz": "93167",
    "partner": [
      16
    ]
  },
  {
    "plz": "93170",
    "partner": [
      16
    ]
  },
  {
    "plz": "93173",
    "partner": [
      16
    ]
  },
  {
    "plz": "93176",
    "partner": [
      16
    ]
  },
  {
    "plz": "93177",
    "partner": [
      16
    ]
  },
  {
    "plz": "93179",
    "partner": [
      16
    ]
  },
  {
    "plz": "93180",
    "partner": [
      16
    ]
  },
  {
    "plz": "93182",
    "partner": [
      16
    ]
  },
  {
    "plz": "93183",
    "partner": [
      16
    ]
  },
  {
    "plz": "93183",
    "partner": [
      16
    ]
  },
  {
    "plz": "93185",
    "partner": [
      16
    ]
  },
  {
    "plz": "93186",
    "partner": [
      16
    ]
  },
  {
    "plz": "93188",
    "partner": [
      16
    ]
  },
  {
    "plz": "93189",
    "partner": [
      16
    ]
  },
  {
    "plz": "93191",
    "partner": [
      16
    ]
  },
  {
    "plz": "93192",
    "partner": [
      16
    ]
  },
  {
    "plz": "93194",
    "partner": [
      16
    ]
  },
  {
    "plz": "93195",
    "partner": [
      16
    ]
  },
  {
    "plz": "93195",
    "partner": [
      16
    ]
  },
  {
    "plz": "93197",
    "partner": [
      16
    ]
  },
  {
    "plz": "93199",
    "partner": [
      16
    ]
  },
  {
    "plz": "93309",
    "partner": [
      16
    ]
  },
  {
    "plz": "93326",
    "partner": [
      16
    ]
  },
  {
    "plz": "93333",
    "partner": [
      16
    ]
  },
  {
    "plz": "93336",
    "partner": [
      16
    ]
  },
  {
    "plz": "93339",
    "partner": [
      16
    ]
  },
  {
    "plz": "93342",
    "partner": [
      16
    ]
  },
  {
    "plz": "93343",
    "partner": [
      16
    ]
  },
  {
    "plz": "93345",
    "partner": [
      16
    ]
  },
  {
    "plz": "93346",
    "partner": [
      16
    ]
  },
  {
    "plz": "93348",
    "partner": [
      16
    ]
  },
  {
    "plz": "93349",
    "partner": [
      16
    ]
  },
  {
    "plz": "93351",
    "partner": [
      16
    ]
  },
  {
    "plz": "93352",
    "partner": [
      16
    ]
  },
  {
    "plz": "93354",
    "partner": [
      16
    ]
  },
  {
    "plz": "93354",
    "partner": [
      16
    ]
  },
  {
    "plz": "93356",
    "partner": [
      16
    ]
  },
  {
    "plz": "93358",
    "partner": [
      16
    ]
  },
  {
    "plz": "93359",
    "partner": [
      16
    ]
  },
  {
    "plz": "93413",
    "partner": [
      16
    ]
  },
  {
    "plz": "93426",
    "partner": [
      16
    ]
  },
  {
    "plz": "93437",
    "partner": [
      16
    ]
  },
  {
    "plz": "93437",
    "partner": [
      16
    ]
  },
  {
    "plz": "93437",
    "partner": [
      16
    ]
  },
  {
    "plz": "93444",
    "partner": [
      16
    ]
  },
  {
    "plz": "93449",
    "partner": [
      16
    ]
  },
  {
    "plz": "93453",
    "partner": [
      16
    ]
  },
  {
    "plz": "93455",
    "partner": [
      16
    ]
  },
  {
    "plz": "93458",
    "partner": [
      16
    ]
  },
  {
    "plz": "93462",
    "partner": [
      16
    ]
  },
  {
    "plz": "93464",
    "partner": [
      16
    ]
  },
  {
    "plz": "93466",
    "partner": [
      16
    ]
  },
  {
    "plz": "93468",
    "partner": [
      16
    ]
  },
  {
    "plz": "93470",
    "partner": [
      16
    ]
  },
  {
    "plz": "93471",
    "partner": [
      16
    ]
  },
  {
    "plz": "93473",
    "partner": [
      16
    ]
  },
  {
    "plz": "93474",
    "partner": [
      16
    ]
  },
  {
    "plz": "93476",
    "partner": [
      16
    ]
  },
  {
    "plz": "93477",
    "partner": [
      16
    ]
  },
  {
    "plz": "93479",
    "partner": [
      16
    ]
  },
  {
    "plz": "93479",
    "partner": [
      16
    ]
  },
  {
    "plz": "93480",
    "partner": [
      16
    ]
  },
  {
    "plz": "93482",
    "partner": [
      16
    ]
  },
  {
    "plz": "93483",
    "partner": [
      16
    ]
  },
  {
    "plz": "93485",
    "partner": [
      16
    ]
  },
  {
    "plz": "93486",
    "partner": [
      16
    ]
  },
  {
    "plz": "93488",
    "partner": [
      16
    ]
  },
  {
    "plz": "93489",
    "partner": [
      16
    ]
  },
  {
    "plz": "93491",
    "partner": [
      16
    ]
  },
  {
    "plz": "93492",
    "partner": [
      16
    ]
  },
  {
    "plz": "93494",
    "partner": [
      16
    ]
  },
  {
    "plz": "93495",
    "partner": [
      16
    ]
  },
  {
    "plz": "93497",
    "partner": [
      16
    ]
  },
  {
    "plz": "93499",
    "partner": [
      16
    ]
  },
  {
    "plz": "94032",
    "partner": [
      16
    ]
  },
  {
    "plz": "94034",
    "partner": [
      16
    ]
  },
  {
    "plz": "94036",
    "partner": [
      16
    ]
  },
  {
    "plz": "94036",
    "partner": [
      16
    ]
  },
  {
    "plz": "94051",
    "partner": [
      16
    ]
  },
  {
    "plz": "94060",
    "partner": [
      16
    ]
  },
  {
    "plz": "94060",
    "partner": [
      16
    ]
  },
  {
    "plz": "94065",
    "partner": [
      16
    ]
  },
  {
    "plz": "94072",
    "partner": [
      16
    ]
  },
  {
    "plz": "94078",
    "partner": [
      16
    ]
  },
  {
    "plz": "94081",
    "partner": [
      16
    ]
  },
  {
    "plz": "94086",
    "partner": [
      16
    ]
  },
  {
    "plz": "94089",
    "partner": [
      16
    ]
  },
  {
    "plz": "94094",
    "partner": [
      16
    ]
  },
  {
    "plz": "94094",
    "partner": [
      16
    ]
  },
  {
    "plz": "94099",
    "partner": [
      16
    ]
  },
  {
    "plz": "94104",
    "partner": [
      16
    ]
  },
  {
    "plz": "94104",
    "partner": [
      16
    ]
  },
  {
    "plz": "94107",
    "partner": [
      16
    ]
  },
  {
    "plz": "94107",
    "partner": [
      16
    ]
  },
  {
    "plz": "94110",
    "partner": [
      16
    ]
  },
  {
    "plz": "94113",
    "partner": [
      16
    ]
  },
  {
    "plz": "94116",
    "partner": [
      16
    ]
  },
  {
    "plz": "94118",
    "partner": [
      16
    ]
  },
  {
    "plz": "94121",
    "partner": [
      16
    ]
  },
  {
    "plz": "94124",
    "partner": [
      16
    ]
  },
  {
    "plz": "94127",
    "partner": [
      16
    ]
  },
  {
    "plz": "94130",
    "partner": [
      16
    ]
  },
  {
    "plz": "94133",
    "partner": [
      16
    ]
  },
  {
    "plz": "94136",
    "partner": [
      16
    ]
  },
  {
    "plz": "94137",
    "partner": [
      16
    ]
  },
  {
    "plz": "94139",
    "partner": [
      16
    ]
  },
  {
    "plz": "94140",
    "partner": [
      16
    ]
  },
  {
    "plz": "94142",
    "partner": [
      16
    ]
  },
  {
    "plz": "94143",
    "partner": [
      16
    ]
  },
  {
    "plz": "94145",
    "partner": [
      16
    ]
  },
  {
    "plz": "94146",
    "partner": [
      16
    ]
  },
  {
    "plz": "94148",
    "partner": [
      16
    ]
  },
  {
    "plz": "94149",
    "partner": [
      16
    ]
  },
  {
    "plz": "94151",
    "partner": [
      16
    ]
  },
  {
    "plz": "94152",
    "partner": [
      16
    ]
  },
  {
    "plz": "94154",
    "partner": [
      16
    ]
  },
  {
    "plz": "94157",
    "partner": [
      16
    ]
  },
  {
    "plz": "94158",
    "partner": [
      16
    ]
  },
  {
    "plz": "94160",
    "partner": [
      16
    ]
  },
  {
    "plz": "94161",
    "partner": [
      16
    ]
  },
  {
    "plz": "94163",
    "partner": [
      16
    ]
  },
  {
    "plz": "94164",
    "partner": [
      16
    ]
  },
  {
    "plz": "94166",
    "partner": [
      16
    ]
  },
  {
    "plz": "94167",
    "partner": [
      16
    ]
  },
  {
    "plz": "94169",
    "partner": [
      16
    ]
  },
  {
    "plz": "94209",
    "partner": [
      16
    ]
  },
  {
    "plz": "94227",
    "partner": [
      16
    ]
  },
  {
    "plz": "94227",
    "partner": [
      16
    ]
  },
  {
    "plz": "94227",
    "partner": [
      16
    ]
  },
  {
    "plz": "94234",
    "partner": [
      16
    ]
  },
  {
    "plz": "94239",
    "partner": [
      16
    ]
  },
  {
    "plz": "94239",
    "partner": [
      16
    ]
  },
  {
    "plz": "94239",
    "partner": [
      16
    ]
  },
  {
    "plz": "94244",
    "partner": [
      16
    ]
  },
  {
    "plz": "94244",
    "partner": [
      16
    ]
  },
  {
    "plz": "94249",
    "partner": [
      16
    ]
  },
  {
    "plz": "94250",
    "partner": [
      16
    ]
  },
  {
    "plz": "94252",
    "partner": [
      16
    ]
  },
  {
    "plz": "94253",
    "partner": [
      16
    ]
  },
  {
    "plz": "94255",
    "partner": [
      16
    ]
  },
  {
    "plz": "94256",
    "partner": [
      16
    ]
  },
  {
    "plz": "94258",
    "partner": [
      16
    ]
  },
  {
    "plz": "94259",
    "partner": [
      16
    ]
  },
  {
    "plz": "94261",
    "partner": [
      16
    ]
  },
  {
    "plz": "94262",
    "partner": [
      16
    ]
  },
  {
    "plz": "94264",
    "partner": [
      16
    ]
  },
  {
    "plz": "94265",
    "partner": [
      16
    ]
  },
  {
    "plz": "94267",
    "partner": [
      16
    ]
  },
  {
    "plz": "94269",
    "partner": [
      16
    ]
  },
  {
    "plz": "94315",
    "partner": [
      16
    ]
  },
  {
    "plz": "94327",
    "partner": [
      16
    ]
  },
  {
    "plz": "94330",
    "partner": [
      16
    ]
  },
  {
    "plz": "94330",
    "partner": [
      16
    ]
  },
  {
    "plz": "94333",
    "partner": [
      16
    ]
  },
  {
    "plz": "94336",
    "partner": [
      16
    ]
  },
  {
    "plz": "94336",
    "partner": [
      16
    ]
  },
  {
    "plz": "94339",
    "partner": [
      16
    ]
  },
  {
    "plz": "94342",
    "partner": [
      16
    ]
  },
  {
    "plz": "94342",
    "partner": [
      16
    ]
  },
  {
    "plz": "94344",
    "partner": [
      16
    ]
  },
  {
    "plz": "94345",
    "partner": [
      16
    ]
  },
  {
    "plz": "94347",
    "partner": [
      16
    ]
  },
  {
    "plz": "94348",
    "partner": [
      16
    ]
  },
  {
    "plz": "94350",
    "partner": [
      16
    ]
  },
  {
    "plz": "94351",
    "partner": [
      16
    ]
  },
  {
    "plz": "94353",
    "partner": [
      16
    ]
  },
  {
    "plz": "94354",
    "partner": [
      16
    ]
  },
  {
    "plz": "94356",
    "partner": [
      16
    ]
  },
  {
    "plz": "94357",
    "partner": [
      16
    ]
  },
  {
    "plz": "94357",
    "partner": [
      16
    ]
  },
  {
    "plz": "94359",
    "partner": [
      16
    ]
  },
  {
    "plz": "94360",
    "partner": [
      16
    ]
  },
  {
    "plz": "94362",
    "partner": [
      16
    ]
  },
  {
    "plz": "94363",
    "partner": [
      16
    ]
  },
  {
    "plz": "94365",
    "partner": [
      16
    ]
  },
  {
    "plz": "94366",
    "partner": [
      16
    ]
  },
  {
    "plz": "94368",
    "partner": [
      16
    ]
  },
  {
    "plz": "94369",
    "partner": [
      16
    ]
  },
  {
    "plz": "94371",
    "partner": [
      16
    ]
  },
  {
    "plz": "94372",
    "partner": [
      16
    ]
  },
  {
    "plz": "94374",
    "partner": [
      16
    ]
  },
  {
    "plz": "94374",
    "partner": [
      16
    ]
  },
  {
    "plz": "94374",
    "partner": [
      16
    ]
  },
  {
    "plz": "94375",
    "partner": [
      16
    ]
  },
  {
    "plz": "94377",
    "partner": [
      16
    ]
  },
  {
    "plz": "94379",
    "partner": [
      16
    ]
  },
  {
    "plz": "94405",
    "partner": [
      16
    ]
  },
  {
    "plz": "94419",
    "partner": [
      16
    ]
  },
  {
    "plz": "94424",
    "partner": [
      16
    ]
  },
  {
    "plz": "94428",
    "partner": [
      16
    ]
  },
  {
    "plz": "94431",
    "partner": [
      16
    ]
  },
  {
    "plz": "94436",
    "partner": [
      16
    ]
  },
  {
    "plz": "94437",
    "partner": [
      16
    ]
  },
  {
    "plz": "94439",
    "partner": [
      16
    ]
  },
  {
    "plz": "94447",
    "partner": [
      16
    ]
  },
  {
    "plz": "94469",
    "partner": [
      16
    ]
  },
  {
    "plz": "94474",
    "partner": [
      16
    ]
  },
  {
    "plz": "94481",
    "partner": [
      16
    ]
  },
  {
    "plz": "94481",
    "partner": [
      16
    ]
  },
  {
    "plz": "94486",
    "partner": [
      16
    ]
  },
  {
    "plz": "94491",
    "partner": [
      16
    ]
  },
  {
    "plz": "94496",
    "partner": [
      16
    ]
  },
  {
    "plz": "94501",
    "partner": [
      16
    ]
  },
  {
    "plz": "94501",
    "partner": [
      16
    ]
  },
  {
    "plz": "94501",
    "partner": [
      16
    ]
  },
  {
    "plz": "94501",
    "partner": [
      16
    ]
  },
  {
    "plz": "94501",
    "partner": [
      16
    ]
  },
  {
    "plz": "94505",
    "partner": [
      16
    ]
  },
  {
    "plz": "94508",
    "partner": [
      16
    ]
  },
  {
    "plz": "94513",
    "partner": [
      16
    ]
  },
  {
    "plz": "94518",
    "partner": [
      16
    ]
  },
  {
    "plz": "94518",
    "partner": [
      16
    ]
  },
  {
    "plz": "94522",
    "partner": [
      16
    ]
  },
  {
    "plz": "94526",
    "partner": [
      16
    ]
  },
  {
    "plz": "94526",
    "partner": [
      16
    ]
  },
  {
    "plz": "94527",
    "partner": [
      16
    ]
  },
  {
    "plz": "94529",
    "partner": [
      16
    ]
  },
  {
    "plz": "94530",
    "partner": [
      16
    ]
  },
  {
    "plz": "94532",
    "partner": [
      16
    ]
  },
  {
    "plz": "94533",
    "partner": [
      16
    ]
  },
  {
    "plz": "94535",
    "partner": [
      16
    ]
  },
  {
    "plz": "94536",
    "partner": [
      16
    ]
  },
  {
    "plz": "94538",
    "partner": [
      16
    ]
  },
  {
    "plz": "94539",
    "partner": [
      16
    ]
  },
  {
    "plz": "94541",
    "partner": [
      16
    ]
  },
  {
    "plz": "94542",
    "partner": [
      16
    ]
  },
  {
    "plz": "94544",
    "partner": [
      16
    ]
  },
  {
    "plz": "94545",
    "partner": [
      16
    ]
  },
  {
    "plz": "94547",
    "partner": [
      16
    ]
  },
  {
    "plz": "94548",
    "partner": [
      16
    ]
  },
  {
    "plz": "94550",
    "partner": [
      16
    ]
  },
  {
    "plz": "94551",
    "partner": [
      16
    ]
  },
  {
    "plz": "94551",
    "partner": [
      16
    ]
  },
  {
    "plz": "94551",
    "partner": [
      16
    ]
  },
  {
    "plz": "94553",
    "partner": [
      16
    ]
  },
  {
    "plz": "94554",
    "partner": [
      16
    ]
  },
  {
    "plz": "94556",
    "partner": [
      16
    ]
  },
  {
    "plz": "94557",
    "partner": [
      16
    ]
  },
  {
    "plz": "94559",
    "partner": [
      16
    ]
  },
  {
    "plz": "94560",
    "partner": [
      16
    ]
  },
  {
    "plz": "94562",
    "partner": [
      16
    ]
  },
  {
    "plz": "94563",
    "partner": [
      16
    ]
  },
  {
    "plz": "94566",
    "partner": [
      16
    ]
  },
  {
    "plz": "94568",
    "partner": [
      16
    ]
  },
  {
    "plz": "94568",
    "partner": [
      16
    ]
  },
  {
    "plz": "94569",
    "partner": [
      16
    ]
  },
  {
    "plz": "94571",
    "partner": [
      16
    ]
  },
  {
    "plz": "94572",
    "partner": [
      16
    ]
  },
  {
    "plz": "94574",
    "partner": [
      16
    ]
  },
  {
    "plz": "94575",
    "partner": [
      16
    ]
  },
  {
    "plz": "94577",
    "partner": [
      16
    ]
  },
  {
    "plz": "94579",
    "partner": [
      16
    ]
  },
  {
    "plz": "95028",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95030",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95032",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95100",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95100",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95111",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95119",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95126",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95131",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95131",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95138",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95145",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95152",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95158",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95158",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95163",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95168",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95173",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95176",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95179",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95180",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95182",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95183",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95183",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95183",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95185",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95186",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95188",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95189",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95191",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95192",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95194",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95195",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95197",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95199",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95213",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95233",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95234",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95236",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95236",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95237",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95239",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95326",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95336",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95339",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95339",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95346",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95349",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95352",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95355",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95356",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95358",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95359",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95361",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95362",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95364",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95365",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95367",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95369",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95444",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95445",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95447",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95448",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95460",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95460",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95460",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95463",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95466",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95466",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95469",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95473",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95473",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95473",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95478",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95482",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95485",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95488",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95490",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95490",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95491",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95493",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95494",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95496",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95497",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95499",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95500",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95502",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95503",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95505",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95506",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95508",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95509",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95511",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95512",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95514",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95515",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95515",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95517",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95517",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95519",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95519",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95615",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95632",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95643",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95652",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95659",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95666",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95666",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95671",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95676",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95679",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95680",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95682",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95683",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95685",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95686",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95688",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95689",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95689",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95691",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95692",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95694",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95695",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95697",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95698",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95700",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95701",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95703",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95703",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95704",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95706",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95707",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "95709",
    "partner": [
      19,
      18
    ]
  },
  {
    "plz": "96047",
    "partner": [
      19
    ]
  },
  {
    "plz": "96049",
    "partner": [
      19
    ]
  },
  {
    "plz": "96050",
    "partner": [
      19
    ]
  },
  {
    "plz": "96052",
    "partner": [
      19
    ]
  },
  {
    "plz": "96103",
    "partner": [
      19
    ]
  },
  {
    "plz": "96106",
    "partner": [
      19
    ]
  },
  {
    "plz": "96110",
    "partner": [
      19
    ]
  },
  {
    "plz": "96114",
    "partner": [
      19
    ]
  },
  {
    "plz": "96117",
    "partner": [
      19
    ]
  },
  {
    "plz": "96120",
    "partner": [
      19
    ]
  },
  {
    "plz": "96123",
    "partner": [
      19
    ]
  },
  {
    "plz": "96126",
    "partner": [
      19
    ]
  },
  {
    "plz": "96126",
    "partner": [
      19
    ]
  },
  {
    "plz": "96129",
    "partner": [
      19
    ]
  },
  {
    "plz": "96132",
    "partner": [
      19
    ]
  },
  {
    "plz": "96135",
    "partner": [
      19
    ]
  },
  {
    "plz": "96138",
    "partner": [
      19
    ]
  },
  {
    "plz": "96142",
    "partner": [
      19
    ]
  },
  {
    "plz": "96145",
    "partner": [
      19
    ]
  },
  {
    "plz": "96146",
    "partner": [
      19
    ]
  },
  {
    "plz": "96148",
    "partner": [
      19
    ]
  },
  {
    "plz": "96149",
    "partner": [
      19
    ]
  },
  {
    "plz": "96151",
    "partner": [
      19
    ]
  },
  {
    "plz": "96152",
    "partner": [
      19
    ]
  },
  {
    "plz": "96154",
    "partner": [
      19
    ]
  },
  {
    "plz": "96155",
    "partner": [
      19
    ]
  },
  {
    "plz": "96157",
    "partner": [
      19
    ]
  },
  {
    "plz": "96158",
    "partner": [
      19
    ]
  },
  {
    "plz": "96160",
    "partner": [
      19
    ]
  },
  {
    "plz": "96161",
    "partner": [
      19
    ]
  },
  {
    "plz": "96163",
    "partner": [
      19
    ]
  },
  {
    "plz": "96164",
    "partner": [
      19
    ]
  },
  {
    "plz": "96166",
    "partner": [
      19
    ]
  },
  {
    "plz": "96167",
    "partner": [
      19
    ]
  },
  {
    "plz": "96169",
    "partner": [
      19
    ]
  },
  {
    "plz": "96170",
    "partner": [
      19
    ]
  },
  {
    "plz": "96170",
    "partner": [
      19
    ]
  },
  {
    "plz": "96172",
    "partner": [
      19
    ]
  },
  {
    "plz": "96173",
    "partner": [
      19
    ]
  },
  {
    "plz": "96175",
    "partner": [
      19
    ]
  },
  {
    "plz": "96176",
    "partner": [
      19
    ]
  },
  {
    "plz": "96178",
    "partner": [
      19
    ]
  },
  {
    "plz": "96179",
    "partner": [
      19
    ]
  },
  {
    "plz": "96181",
    "partner": [
      19
    ]
  },
  {
    "plz": "96182",
    "partner": [
      19
    ]
  },
  {
    "plz": "96184",
    "partner": [
      19
    ]
  },
  {
    "plz": "96185",
    "partner": [
      19
    ]
  },
  {
    "plz": "96187",
    "partner": [
      19
    ]
  },
  {
    "plz": "96188",
    "partner": [
      19
    ]
  },
  {
    "plz": "96190",
    "partner": [
      19
    ]
  },
  {
    "plz": "96191",
    "partner": [
      19
    ]
  },
  {
    "plz": "96193",
    "partner": [
      19
    ]
  },
  {
    "plz": "96194",
    "partner": [
      19
    ]
  },
  {
    "plz": "96196",
    "partner": [
      19
    ]
  },
  {
    "plz": "96197",
    "partner": [
      19
    ]
  },
  {
    "plz": "96199",
    "partner": [
      19
    ]
  },
  {
    "plz": "96215",
    "partner": [
      19
    ]
  },
  {
    "plz": "96224",
    "partner": [
      19
    ]
  },
  {
    "plz": "96231",
    "partner": [
      19
    ]
  },
  {
    "plz": "96237",
    "partner": [
      19
    ]
  },
  {
    "plz": "96242",
    "partner": [
      19
    ]
  },
  {
    "plz": "96247",
    "partner": [
      19
    ]
  },
  {
    "plz": "96250",
    "partner": [
      19
    ]
  },
  {
    "plz": "96253",
    "partner": [
      19
    ]
  },
  {
    "plz": "96257",
    "partner": [
      19
    ]
  },
  {
    "plz": "96257",
    "partner": [
      19
    ]
  },
  {
    "plz": "96260",
    "partner": [
      19
    ]
  },
  {
    "plz": "96264",
    "partner": [
      19
    ]
  },
  {
    "plz": "96268",
    "partner": [
      19
    ]
  },
  {
    "plz": "96269",
    "partner": [
      19
    ]
  },
  {
    "plz": "96271",
    "partner": [
      19
    ]
  },
  {
    "plz": "96272",
    "partner": [
      19
    ]
  },
  {
    "plz": "96274",
    "partner": [
      19
    ]
  },
  {
    "plz": "96275",
    "partner": [
      19
    ]
  },
  {
    "plz": "96277",
    "partner": [
      19
    ]
  },
  {
    "plz": "96279",
    "partner": [
      19
    ]
  },
  {
    "plz": "96317",
    "partner": [
      19
    ]
  },
  {
    "plz": "96317",
    "partner": [
      19
    ]
  },
  {
    "plz": "96328",
    "partner": [
      19
    ]
  },
  {
    "plz": "96332",
    "partner": [
      19
    ]
  },
  {
    "plz": "96337",
    "partner": [
      19
    ]
  },
  {
    "plz": "96342",
    "partner": [
      19
    ]
  },
  {
    "plz": "96346",
    "partner": [
      19
    ]
  },
  {
    "plz": "96349",
    "partner": [
      19
    ]
  },
  {
    "plz": "96349",
    "partner": [
      19
    ]
  },
  {
    "plz": "96352",
    "partner": [
      19
    ]
  },
  {
    "plz": "96355",
    "partner": [
      19
    ]
  },
  {
    "plz": "96358",
    "partner": [
      19
    ]
  },
  {
    "plz": "96358",
    "partner": [
      19
    ]
  },
  {
    "plz": "96361",
    "partner": [
      19
    ]
  },
  {
    "plz": "96364",
    "partner": [
      19
    ]
  },
  {
    "plz": "96365",
    "partner": [
      19
    ]
  },
  {
    "plz": "96367",
    "partner": [
      19
    ]
  },
  {
    "plz": "96369",
    "partner": [
      19
    ]
  },
  {
    "plz": "96369",
    "partner": [
      19
    ]
  },
  {
    "plz": "96450",
    "partner": [
      6
    ]
  },
  {
    "plz": "96465",
    "partner": [
      6
    ]
  },
  {
    "plz": "96472",
    "partner": [
      6
    ]
  },
  {
    "plz": "96476",
    "partner": [
      6
    ]
  },
  {
    "plz": "96479",
    "partner": [
      6
    ]
  },
  {
    "plz": "96482",
    "partner": [
      6
    ]
  },
  {
    "plz": "96484",
    "partner": [
      6
    ]
  },
  {
    "plz": "96486",
    "partner": [
      6
    ]
  },
  {
    "plz": "96487",
    "partner": [
      6
    ]
  },
  {
    "plz": "96489",
    "partner": [
      6
    ]
  },
  {
    "plz": "96515",
    "partner": [
      6
    ]
  },
  {
    "plz": "96523",
    "partner": [
      6
    ]
  },
  {
    "plz": "96524",
    "partner": [
      6
    ]
  },
  {
    "plz": "96528",
    "partner": [
      6
    ]
  },
  {
    "plz": "96528",
    "partner": [
      6
    ]
  },
  {
    "plz": "97070",
    "partner": [
      6
    ]
  },
  {
    "plz": "97072",
    "partner": [
      6
    ]
  },
  {
    "plz": "97074",
    "partner": [
      6
    ]
  },
  {
    "plz": "97076",
    "partner": [
      6
    ]
  },
  {
    "plz": "97078",
    "partner": [
      6
    ]
  },
  {
    "plz": "97080",
    "partner": [
      6
    ]
  },
  {
    "plz": "97082",
    "partner": [
      6
    ]
  },
  {
    "plz": "97084",
    "partner": [
      6
    ]
  },
  {
    "plz": "97199",
    "partner": [
      6
    ]
  },
  {
    "plz": "97204",
    "partner": [
      6
    ]
  },
  {
    "plz": "97209",
    "partner": [
      6
    ]
  },
  {
    "plz": "97215",
    "partner": [
      6
    ]
  },
  {
    "plz": "97215",
    "partner": [
      6
    ]
  },
  {
    "plz": "97215",
    "partner": [
      6
    ]
  },
  {
    "plz": "97218",
    "partner": [
      6
    ]
  },
  {
    "plz": "97222",
    "partner": [
      6
    ]
  },
  {
    "plz": "97225",
    "partner": [
      6
    ]
  },
  {
    "plz": "97228",
    "partner": [
      6
    ]
  },
  {
    "plz": "97230",
    "partner": [
      6
    ]
  },
  {
    "plz": "97232",
    "partner": [
      6
    ]
  },
  {
    "plz": "97234",
    "partner": [
      6
    ]
  },
  {
    "plz": "97236",
    "partner": [
      6
    ]
  },
  {
    "plz": "97237",
    "partner": [
      6
    ]
  },
  {
    "plz": "97239",
    "partner": [
      6
    ]
  },
  {
    "plz": "97241",
    "partner": [
      6
    ]
  },
  {
    "plz": "97241",
    "partner": [
      6
    ]
  },
  {
    "plz": "97243",
    "partner": [
      6
    ]
  },
  {
    "plz": "97244",
    "partner": [
      6
    ]
  },
  {
    "plz": "97246",
    "partner": [
      6
    ]
  },
  {
    "plz": "97247",
    "partner": [
      6
    ]
  },
  {
    "plz": "97249",
    "partner": [
      6
    ]
  },
  {
    "plz": "97250",
    "partner": [
      6
    ]
  },
  {
    "plz": "97252",
    "partner": [
      6
    ]
  },
  {
    "plz": "97253",
    "partner": [
      6
    ]
  },
  {
    "plz": "97255",
    "partner": [
      6
    ]
  },
  {
    "plz": "97255",
    "partner": [
      6
    ]
  },
  {
    "plz": "97256",
    "partner": [
      6
    ]
  },
  {
    "plz": "97258",
    "partner": [
      6
    ]
  },
  {
    "plz": "97258",
    "partner": [
      6
    ]
  },
  {
    "plz": "97258",
    "partner": [
      6
    ]
  },
  {
    "plz": "97258",
    "partner": [
      6
    ]
  },
  {
    "plz": "97259",
    "partner": [
      6
    ]
  },
  {
    "plz": "97261",
    "partner": [
      6
    ]
  },
  {
    "plz": "97262",
    "partner": [
      6
    ]
  },
  {
    "plz": "97264",
    "partner": [
      6
    ]
  },
  {
    "plz": "97265",
    "partner": [
      6
    ]
  },
  {
    "plz": "97267",
    "partner": [
      6
    ]
  },
  {
    "plz": "97268",
    "partner": [
      6
    ]
  },
  {
    "plz": "97270",
    "partner": [
      6
    ]
  },
  {
    "plz": "97271",
    "partner": [
      6
    ]
  },
  {
    "plz": "97273",
    "partner": [
      6
    ]
  },
  {
    "plz": "97274",
    "partner": [
      6
    ]
  },
  {
    "plz": "97276",
    "partner": [
      6
    ]
  },
  {
    "plz": "97277",
    "partner": [
      6
    ]
  },
  {
    "plz": "97279",
    "partner": [
      6
    ]
  },
  {
    "plz": "97280",
    "partner": [
      6
    ]
  },
  {
    "plz": "97282",
    "partner": [
      6
    ]
  },
  {
    "plz": "97283",
    "partner": [
      6
    ]
  },
  {
    "plz": "97285",
    "partner": [
      6
    ]
  },
  {
    "plz": "97285",
    "partner": [
      6
    ]
  },
  {
    "plz": "97286",
    "partner": [
      6
    ]
  },
  {
    "plz": "97286",
    "partner": [
      6
    ]
  },
  {
    "plz": "97288",
    "partner": [
      6
    ]
  },
  {
    "plz": "97289",
    "partner": [
      6
    ]
  },
  {
    "plz": "97291",
    "partner": [
      6
    ]
  },
  {
    "plz": "97292",
    "partner": [
      6
    ]
  },
  {
    "plz": "97292",
    "partner": [
      6
    ]
  },
  {
    "plz": "97292",
    "partner": [
      6
    ]
  },
  {
    "plz": "97294",
    "partner": [
      6
    ]
  },
  {
    "plz": "97295",
    "partner": [
      6
    ]
  },
  {
    "plz": "97297",
    "partner": [
      6
    ]
  },
  {
    "plz": "97299",
    "partner": [
      6
    ]
  },
  {
    "plz": "97318",
    "partner": [
      6
    ]
  },
  {
    "plz": "97318",
    "partner": [
      6
    ]
  },
  {
    "plz": "97320",
    "partner": [
      6
    ]
  },
  {
    "plz": "97320",
    "partner": [
      6
    ]
  },
  {
    "plz": "97320",
    "partner": [
      6
    ]
  },
  {
    "plz": "97320",
    "partner": [
      6
    ]
  },
  {
    "plz": "97320",
    "partner": [
      6
    ]
  },
  {
    "plz": "97332",
    "partner": [
      6
    ]
  },
  {
    "plz": "97334",
    "partner": [
      6
    ]
  },
  {
    "plz": "97334",
    "partner": [
      6
    ]
  },
  {
    "plz": "97337",
    "partner": [
      6
    ]
  },
  {
    "plz": "97340",
    "partner": [
      6
    ]
  },
  {
    "plz": "97340",
    "partner": [
      6
    ]
  },
  {
    "plz": "97340",
    "partner": [
      6
    ]
  },
  {
    "plz": "97342",
    "partner": [
      6
    ]
  },
  {
    "plz": "97342",
    "partner": [
      6
    ]
  },
  {
    "plz": "97342",
    "partner": [
      6
    ]
  },
  {
    "plz": "97346",
    "partner": [
      6
    ]
  },
  {
    "plz": "97348",
    "partner": [
      6
    ]
  },
  {
    "plz": "97348",
    "partner": [
      6
    ]
  },
  {
    "plz": "97348",
    "partner": [
      6
    ]
  },
  {
    "plz": "97350",
    "partner": [
      6
    ]
  },
  {
    "plz": "97353",
    "partner": [
      6
    ]
  },
  {
    "plz": "97355",
    "partner": [
      6
    ]
  },
  {
    "plz": "97355",
    "partner": [
      6
    ]
  },
  {
    "plz": "97355",
    "partner": [
      6
    ]
  },
  {
    "plz": "97355",
    "partner": [
      6
    ]
  },
  {
    "plz": "97355",
    "partner": [
      6
    ]
  },
  {
    "plz": "97357",
    "partner": [
      6
    ]
  },
  {
    "plz": "97359",
    "partner": [
      6
    ]
  },
  {
    "plz": "97421",
    "partner": [
      6
    ]
  },
  {
    "plz": "97422",
    "partner": [
      6
    ]
  },
  {
    "plz": "97424",
    "partner": [
      6
    ]
  },
  {
    "plz": "97437",
    "partner": [
      6
    ]
  },
  {
    "plz": "97440",
    "partner": [
      6
    ]
  },
  {
    "plz": "97447",
    "partner": [
      6
    ]
  },
  {
    "plz": "97447",
    "partner": [
      6
    ]
  },
  {
    "plz": "97450",
    "partner": [
      6
    ]
  },
  {
    "plz": "97453",
    "partner": [
      6
    ]
  },
  {
    "plz": "97456",
    "partner": [
      6
    ]
  },
  {
    "plz": "97461",
    "partner": [
      6
    ]
  },
  {
    "plz": "97464",
    "partner": [
      6
    ]
  },
  {
    "plz": "97469",
    "partner": [
      6
    ]
  },
  {
    "plz": "97475",
    "partner": [
      6
    ]
  },
  {
    "plz": "97478",
    "partner": [
      6
    ]
  },
  {
    "plz": "97478",
    "partner": [
      6
    ]
  },
  {
    "plz": "97483",
    "partner": [
      6
    ]
  },
  {
    "plz": "97486",
    "partner": [
      6
    ]
  },
  {
    "plz": "97486",
    "partner": [
      6
    ]
  },
  {
    "plz": "97488",
    "partner": [
      6
    ]
  },
  {
    "plz": "97490",
    "partner": [
      6
    ]
  },
  {
    "plz": "97491",
    "partner": [
      6
    ]
  },
  {
    "plz": "97493",
    "partner": [
      6
    ]
  },
  {
    "plz": "97494",
    "partner": [
      6
    ]
  },
  {
    "plz": "97496",
    "partner": [
      6
    ]
  },
  {
    "plz": "97497",
    "partner": [
      6
    ]
  },
  {
    "plz": "97499",
    "partner": [
      6
    ]
  },
  {
    "plz": "97500",
    "partner": [
      6
    ]
  },
  {
    "plz": "97502",
    "partner": [
      6
    ]
  },
  {
    "plz": "97503",
    "partner": [
      6
    ]
  },
  {
    "plz": "97505",
    "partner": [
      6
    ]
  },
  {
    "plz": "97506",
    "partner": [
      6
    ]
  },
  {
    "plz": "97508",
    "partner": [
      6
    ]
  },
  {
    "plz": "97509",
    "partner": [
      6
    ]
  },
  {
    "plz": "97509",
    "partner": [
      6
    ]
  },
  {
    "plz": "97511",
    "partner": [
      6
    ]
  },
  {
    "plz": "97513",
    "partner": [
      6
    ]
  },
  {
    "plz": "97514",
    "partner": [
      6
    ]
  },
  {
    "plz": "97514",
    "partner": [
      6
    ]
  },
  {
    "plz": "97516",
    "partner": [
      6
    ]
  },
  {
    "plz": "97517",
    "partner": [
      6
    ]
  },
  {
    "plz": "97519",
    "partner": [
      6
    ]
  },
  {
    "plz": "97520",
    "partner": [
      6
    ]
  },
  {
    "plz": "97522",
    "partner": [
      6
    ]
  },
  {
    "plz": "97523",
    "partner": [
      6
    ]
  },
  {
    "plz": "97525",
    "partner": [
      6
    ]
  },
  {
    "plz": "97526",
    "partner": [
      6
    ]
  },
  {
    "plz": "97526",
    "partner": [
      6
    ]
  },
  {
    "plz": "97528",
    "partner": [
      6
    ]
  },
  {
    "plz": "97529",
    "partner": [
      6
    ]
  },
  {
    "plz": "97531",
    "partner": [
      6
    ]
  },
  {
    "plz": "97532",
    "partner": [
      6
    ]
  },
  {
    "plz": "97534",
    "partner": [
      6
    ]
  },
  {
    "plz": "97535",
    "partner": [
      6
    ]
  },
  {
    "plz": "97537",
    "partner": [
      6
    ]
  },
  {
    "plz": "97539",
    "partner": [
      6
    ]
  },
  {
    "plz": "97616",
    "partner": [
      6
    ]
  },
  {
    "plz": "97616",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97618",
    "partner": [
      6
    ]
  },
  {
    "plz": "97631",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97633",
    "partner": [
      6
    ]
  },
  {
    "plz": "97638",
    "partner": [
      6
    ]
  },
  {
    "plz": "97640",
    "partner": [
      6
    ]
  },
  {
    "plz": "97640",
    "partner": [
      6
    ]
  },
  {
    "plz": "97640",
    "partner": [
      6
    ]
  },
  {
    "plz": "97645",
    "partner": [
      6
    ]
  },
  {
    "plz": "97647",
    "partner": [
      6
    ]
  },
  {
    "plz": "97647",
    "partner": [
      6
    ]
  },
  {
    "plz": "97647",
    "partner": [
      6
    ]
  },
  {
    "plz": "97647",
    "partner": [
      6
    ]
  },
  {
    "plz": "97647",
    "partner": [
      6
    ]
  },
  {
    "plz": "97647",
    "partner": [
      6
    ]
  },
  {
    "plz": "97650",
    "partner": [
      6
    ]
  },
  {
    "plz": "97653",
    "partner": [
      6
    ]
  },
  {
    "plz": "97654",
    "partner": [
      6
    ]
  },
  {
    "plz": "97656",
    "partner": [
      6
    ]
  },
  {
    "plz": "97657",
    "partner": [
      6
    ]
  },
  {
    "plz": "97659",
    "partner": [
      6
    ]
  },
  {
    "plz": "97688",
    "partner": [
      6
    ]
  },
  {
    "plz": "97702",
    "partner": [
      6
    ]
  },
  {
    "plz": "97705",
    "partner": [
      6
    ]
  },
  {
    "plz": "97708",
    "partner": [
      6
    ]
  },
  {
    "plz": "97711",
    "partner": [
      6
    ]
  },
  {
    "plz": "97711",
    "partner": [
      6
    ]
  },
  {
    "plz": "97714",
    "partner": [
      6
    ]
  },
  {
    "plz": "97717",
    "partner": [
      6
    ]
  },
  {
    "plz": "97717",
    "partner": [
      6
    ]
  },
  {
    "plz": "97717",
    "partner": [
      6
    ]
  },
  {
    "plz": "97720",
    "partner": [
      6
    ]
  },
  {
    "plz": "97723",
    "partner": [
      6
    ]
  },
  {
    "plz": "97724",
    "partner": [
      6
    ]
  },
  {
    "plz": "97725",
    "partner": [
      6
    ]
  },
  {
    "plz": "97727",
    "partner": [
      6
    ]
  },
  {
    "plz": "97729",
    "partner": [
      6
    ]
  },
  {
    "plz": "97737",
    "partner": [
      6
    ]
  },
  {
    "plz": "97753",
    "partner": [
      6
    ]
  },
  {
    "plz": "97762",
    "partner": [
      6
    ]
  },
  {
    "plz": "97769",
    "partner": [
      6
    ]
  },
  {
    "plz": "97772",
    "partner": [
      6
    ]
  },
  {
    "plz": "97773",
    "partner": [
      6
    ]
  },
  {
    "plz": "97775",
    "partner": [
      6
    ]
  },
  {
    "plz": "97776",
    "partner": [
      6
    ]
  },
  {
    "plz": "97778",
    "partner": [
      6
    ]
  },
  {
    "plz": "97779",
    "partner": [
      6
    ]
  },
  {
    "plz": "97780",
    "partner": [
      6
    ]
  },
  {
    "plz": "97782",
    "partner": [
      6
    ]
  },
  {
    "plz": "97783",
    "partner": [
      6
    ]
  },
  {
    "plz": "97785",
    "partner": [
      6
    ]
  },
  {
    "plz": "97786",
    "partner": [
      6
    ]
  },
  {
    "plz": "97788",
    "partner": [
      6
    ]
  },
  {
    "plz": "97789",
    "partner": [
      6
    ]
  },
  {
    "plz": "97791",
    "partner": [
      6
    ]
  },
  {
    "plz": "97792",
    "partner": [
      6
    ]
  },
  {
    "plz": "97794",
    "partner": [
      6
    ]
  },
  {
    "plz": "97795",
    "partner": [
      6
    ]
  },
  {
    "plz": "97797",
    "partner": [
      6
    ]
  },
  {
    "plz": "97799",
    "partner": [
      6
    ]
  },
  {
    "plz": "97816",
    "partner": [
      6
    ]
  },
  {
    "plz": "97828",
    "partner": [
      6
    ]
  },
  {
    "plz": "97833",
    "partner": [
      6
    ]
  },
  {
    "plz": "97834",
    "partner": [
      6
    ]
  },
  {
    "plz": "97836",
    "partner": [
      6
    ]
  },
  {
    "plz": "97836",
    "partner": [
      6
    ]
  },
  {
    "plz": "97836",
    "partner": [
      6
    ]
  },
  {
    "plz": "97837",
    "partner": [
      6
    ]
  },
  {
    "plz": "97839",
    "partner": [
      6
    ]
  },
  {
    "plz": "97840",
    "partner": [
      6
    ]
  },
  {
    "plz": "97840",
    "partner": [
      6
    ]
  },
  {
    "plz": "97840",
    "partner": [
      6
    ]
  },
  {
    "plz": "97842",
    "partner": [
      6
    ]
  },
  {
    "plz": "97843",
    "partner": [
      6
    ]
  },
  {
    "plz": "97845",
    "partner": [
      6
    ]
  },
  {
    "plz": "97846",
    "partner": [
      6
    ]
  },
  {
    "plz": "97848",
    "partner": [
      6
    ]
  },
  {
    "plz": "97849",
    "partner": [
      6
    ]
  },
  {
    "plz": "97851",
    "partner": [
      6
    ]
  },
  {
    "plz": "97852",
    "partner": [
      6
    ]
  },
  {
    "plz": "97852",
    "partner": [
      6
    ]
  },
  {
    "plz": "97854",
    "partner": [
      6
    ]
  },
  {
    "plz": "97855",
    "partner": [
      6
    ]
  },
  {
    "plz": "97857",
    "partner": [
      6
    ]
  },
  {
    "plz": "97859",
    "partner": [
      6
    ]
  },
  {
    "plz": "97877",
    "partner": [
      6
    ]
  },
  {
    "plz": "97892",
    "partner": [
      6
    ]
  },
  {
    "plz": "97896",
    "partner": [
      6
    ]
  },
  {
    "plz": "97896",
    "partner": [
      6
    ]
  },
  {
    "plz": "97896",
    "partner": [
      6
    ]
  },
  {
    "plz": "97900",
    "partner": [
      6
    ]
  },
  {
    "plz": "97901",
    "partner": [
      6
    ]
  },
  {
    "plz": "97903",
    "partner": [
      6
    ]
  },
  {
    "plz": "97904",
    "partner": [
      6
    ]
  },
  {
    "plz": "97906",
    "partner": [
      6
    ]
  },
  {
    "plz": "97907",
    "partner": [
      6
    ]
  },
  {
    "plz": "97907",
    "partner": [
      6
    ]
  },
  {
    "plz": "97909",
    "partner": [
      6
    ]
  },
  {
    "plz": "97922",
    "partner": [
      6
    ]
  },
  {
    "plz": "97941",
    "partner": [
      6
    ]
  },
  {
    "plz": "97944",
    "partner": [
      6
    ]
  },
  {
    "plz": "97947",
    "partner": [
      6
    ]
  },
  {
    "plz": "97950",
    "partner": [
      6
    ]
  },
  {
    "plz": "97953",
    "partner": [
      6
    ]
  },
  {
    "plz": "97956",
    "partner": [
      6
    ]
  },
  {
    "plz": "97957",
    "partner": [
      6
    ]
  },
  {
    "plz": "97957",
    "partner": [
      6
    ]
  },
  {
    "plz": "97959",
    "partner": [
      6
    ]
  },
  {
    "plz": "97980",
    "partner": [
      6
    ]
  },
  {
    "plz": "97980",
    "partner": [
      6
    ]
  },
  {
    "plz": "97990",
    "partner": [
      6
    ]
  },
  {
    "plz": "97990",
    "partner": [
      6
    ]
  },
  {
    "plz": "97993",
    "partner": [
      6
    ]
  },
  {
    "plz": "97996",
    "partner": [
      6
    ]
  },
  {
    "plz": "97999",
    "partner": [
      6
    ]
  },
  {
    "plz": "98527",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98528",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98529",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98530",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98530",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98530",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98530",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98530",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98530",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98544",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98547",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98547",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98547",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98553",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98553",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98553",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98553",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98559",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98574",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98587",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98590",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98590",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98590",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98593",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98596",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98597",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98597",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98617",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98630",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98631",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98634",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98639",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98646",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98646",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98646",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98646",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98660",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98663",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98663",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98663",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98663",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98663",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98666",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98666",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98667",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98667",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98667",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98667",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98669",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98673",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98673",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98673",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98693",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98693",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98694",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98701",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98716",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98724",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98724",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98743",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98744",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98744",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98744",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98744",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98744",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98746",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "98746",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99084",
    "partner": [
      1
    ]
  },
  {
    "plz": "99085",
    "partner": [
      1
    ]
  },
  {
    "plz": "99086",
    "partner": [
      1
    ]
  },
  {
    "plz": "99087",
    "partner": [
      1
    ]
  },
  {
    "plz": "99089",
    "partner": [
      1
    ]
  },
  {
    "plz": "99090",
    "partner": [
      1
    ]
  },
  {
    "plz": "99091",
    "partner": [
      1
    ]
  },
  {
    "plz": "99092",
    "partner": [
      1
    ]
  },
  {
    "plz": "99094",
    "partner": [
      1
    ]
  },
  {
    "plz": "99095",
    "partner": [
      1
    ]
  },
  {
    "plz": "99096",
    "partner": [
      1
    ]
  },
  {
    "plz": "99097",
    "partner": [
      1
    ]
  },
  {
    "plz": "99098",
    "partner": [
      1
    ]
  },
  {
    "plz": "99099",
    "partner": [
      1
    ]
  },
  {
    "plz": "99100",
    "partner": [
      1
    ]
  },
  {
    "plz": "99100",
    "partner": [
      1
    ]
  },
  {
    "plz": "99100",
    "partner": [
      1
    ]
  },
  {
    "plz": "99100",
    "partner": [
      1
    ]
  },
  {
    "plz": "99100",
    "partner": [
      1
    ]
  },
  {
    "plz": "99100",
    "partner": [
      1
    ]
  },
  {
    "plz": "99102",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99189",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99192",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99195",
    "partner": [
      1
    ]
  },
  {
    "plz": "99198",
    "partner": [
      1
    ]
  },
  {
    "plz": "99198",
    "partner": [
      1
    ]
  },
  {
    "plz": "99198",
    "partner": [
      1
    ]
  },
  {
    "plz": "99198",
    "partner": [
      1
    ]
  },
  {
    "plz": "99310",
    "partner": [
      1
    ]
  },
  {
    "plz": "99310",
    "partner": [
      1
    ]
  },
  {
    "plz": "99310",
    "partner": [
      1
    ]
  },
  {
    "plz": "99310",
    "partner": [
      1
    ]
  },
  {
    "plz": "99310",
    "partner": [
      1
    ]
  },
  {
    "plz": "99310",
    "partner": [
      1
    ]
  },
  {
    "plz": "99326",
    "partner": [
      1
    ]
  },
  {
    "plz": "99330",
    "partner": [
      1
    ]
  },
  {
    "plz": "99331",
    "partner": [
      1
    ]
  },
  {
    "plz": "99334",
    "partner": [
      1
    ]
  },
  {
    "plz": "99334",
    "partner": [
      1
    ]
  },
  {
    "plz": "99334",
    "partner": [
      1
    ]
  },
  {
    "plz": "99338",
    "partner": [
      1
    ]
  },
  {
    "plz": "99423",
    "partner": [
      1
    ]
  },
  {
    "plz": "99425",
    "partner": [
      1
    ]
  },
  {
    "plz": "99427",
    "partner": [
      1
    ]
  },
  {
    "plz": "99428",
    "partner": [
      1
    ]
  },
  {
    "plz": "99428",
    "partner": [
      1
    ]
  },
  {
    "plz": "99438",
    "partner": [
      1
    ]
  },
  {
    "plz": "99438",
    "partner": [
      1
    ]
  },
  {
    "plz": "99438",
    "partner": [
      1
    ]
  },
  {
    "plz": "99438",
    "partner": [
      1
    ]
  },
  {
    "plz": "99438",
    "partner": [
      1
    ]
  },
  {
    "plz": "99438",
    "partner": [
      1
    ]
  },
  {
    "plz": "99439",
    "partner": [
      1
    ]
  },
  {
    "plz": "99439",
    "partner": [
      1
    ]
  },
  {
    "plz": "99439",
    "partner": [
      1
    ]
  },
  {
    "plz": "99439",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99441",
    "partner": [
      1
    ]
  },
  {
    "plz": "99444",
    "partner": [
      1
    ]
  },
  {
    "plz": "99448",
    "partner": [
      1
    ]
  },
  {
    "plz": "99448",
    "partner": [
      1
    ]
  },
  {
    "plz": "99448",
    "partner": [
      1
    ]
  },
  {
    "plz": "99448",
    "partner": [
      1
    ]
  },
  {
    "plz": "99510",
    "partner": [
      1
    ]
  },
  {
    "plz": "99510",
    "partner": [
      1
    ]
  },
  {
    "plz": "99510",
    "partner": [
      1
    ]
  },
  {
    "plz": "99510",
    "partner": [
      1
    ]
  },
  {
    "plz": "99510",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99518",
    "partner": [
      1
    ]
  },
  {
    "plz": "99610",
    "partner": [
      1
    ]
  },
  {
    "plz": "99610",
    "partner": [
      1
    ]
  },
  {
    "plz": "99610",
    "partner": [
      1
    ]
  },
  {
    "plz": "99610",
    "partner": [
      1
    ]
  },
  {
    "plz": "99625",
    "partner": [
      1
    ]
  },
  {
    "plz": "99625",
    "partner": [
      1
    ]
  },
  {
    "plz": "99625",
    "partner": [
      1
    ]
  },
  {
    "plz": "99628",
    "partner": [
      1
    ]
  },
  {
    "plz": "99631",
    "partner": [
      1
    ]
  },
  {
    "plz": "99631",
    "partner": [
      1
    ]
  },
  {
    "plz": "99634",
    "partner": [
      1
    ]
  },
  {
    "plz": "99634",
    "partner": [
      1
    ]
  },
  {
    "plz": "99634",
    "partner": [
      1
    ]
  },
  {
    "plz": "99634",
    "partner": [
      1
    ]
  },
  {
    "plz": "99636",
    "partner": [
      1
    ]
  },
  {
    "plz": "99636",
    "partner": [
      1
    ]
  },
  {
    "plz": "99638",
    "partner": [
      1
    ]
  },
  {
    "plz": "99638",
    "partner": [
      1
    ]
  },
  {
    "plz": "99638",
    "partner": [
      1
    ]
  },
  {
    "plz": "99638",
    "partner": [
      1
    ]
  },
  {
    "plz": "99638",
    "partner": [
      1
    ]
  },
  {
    "plz": "99706",
    "partner": [
      1
    ]
  },
  {
    "plz": "99707",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99713",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99718",
    "partner": [
      1
    ]
  },
  {
    "plz": "99734",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99735",
    "partner": [
      1
    ]
  },
  {
    "plz": "99752",
    "partner": [
      1
    ]
  },
  {
    "plz": "99752",
    "partner": [
      1
    ]
  },
  {
    "plz": "99752",
    "partner": [
      1
    ]
  },
  {
    "plz": "99755",
    "partner": [
      1
    ]
  },
  {
    "plz": "99755",
    "partner": [
      1
    ]
  },
  {
    "plz": "99759",
    "partner": [
      1
    ]
  },
  {
    "plz": "99759",
    "partner": [
      1
    ]
  },
  {
    "plz": "99759",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99765",
    "partner": [
      1
    ]
  },
  {
    "plz": "99768",
    "partner": [
      1
    ]
  },
  {
    "plz": "99817",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99819",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99820",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99826",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99826",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99826",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99826",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99826",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99826",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99830",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99831",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99834",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99837",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99842",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99846",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99848",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99867",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99869",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99880",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99885",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99885",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99887",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99887",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99891",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99894",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99897",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99947",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99947",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99947",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99947",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99947",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99955",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99958",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99958",
    "partner": [
      7,
      6
    ]
  },
  {
    "plz": "99974",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99974",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99974",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99974",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99974",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99976",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99986",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99986",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99986",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99986",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99986",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99988",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99988",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99988",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99988",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99991",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99994",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99994",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99996",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99996",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99998",
    "partner": [
      15,
      14
    ]
  },
  {
    "plz": "99998",
    "partner": [
      15,
      14
    ]
  }
]